<template>
    <div ref="batchChangeArveRef" class="batchChangeArve">
        <a-drawer :getContainer="() => $refs.batchChangeArveRef" width="80vw" :visible="visible" @close="closeModalHandler"
            title="批量修改确认">
            <div class="container">
                <div class="mainInfo">
                    <a-form ref="formRef" :model="tableData">
                        <a-table row-key="preMatchingId" ref="tableRef" :columns="columns" :data-source="tableData"
                            :pagination="false"
                            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{
                                x: 600,
                                scrollToFirstRowOnChange: true,
                            }">
                            <template #demandNumber="{ record }">
                                <div v-if="record.demandType == '2'">{{ record.pipelineDetailVO.pipelineId }}</div>
                                <div v-else>{{ record.demandNumber }}</div>
                            </template>
                            <template #demandName="{ record }">
                                <div @click="() => checkMatchDetails(record)">
                                    <div class="underLine" v-if="record.demandType == '2'">{{
                                        record.pipelineDetailVO.opportunityName }}</div>
                                    <div class="underLine" v-else>{{ record.demandName }}</div>
                                </div>
                            </template>
                            <template #fullName="{ record }">
                                <div @click="() => openEmpModal(record)" class="underLine">{{ record.fullName }}</div>
                            </template>
                            <template #arveAssumption="{ record }">
                                <a-form-item style="position:relative;top:8px" name="arveAssumption" :rules="[
                                    {
                                        validator: () => validateArve(record, 'arveAssumption'),
                                        trigger: ['change', 'blur'],
                                    }]">
                                    <a-input style="width:150px" v-model:value="record.arveAssumption" placeholder="请输入" />
                                </a-form-item>
                            </template>
                            <template #arveAssumptionStartDate="{ record }">
                                <a-form-item style="position:relative;top:8px" name="arveAssumptionStartDate" :rules="[
                                    {
                                        validator: () => validateArve(record, 'arveAssumptionStartDate'),
                                        trigger: ['change', 'blur'],
                                    }]">
                                    <a-date-picker valueFormat="YYYY-MM-DD" style="width:150px"
                                        v-model:value="record.arveAssumptionStartDate" placeholder="请输入" />
                                </a-form-item>
                            </template>
                        </a-table>
                    </a-form>

                </div>
            </div>
            <a-space class="button bottom-btn btnContainer">
                <div class="left">
                    <a-button type="primary" @click="openBatchModal" :disabled="selectedRowKeys.length < 2">批量修改</a-button>
                </div>
                <div class="right">
                    <a-button type="primary" @click="cancelHandler" ghost>取消</a-button>
                    <a-button type="primary" @click="confirmBatch">确定</a-button>
                </div>
            </a-space>
        </a-drawer>
        <!-- pipeline：0，处理中1 的 才可以弹窗内修改ARVE -->
        <div v-if="showMateInfo">
            <mate-info :showMateInfo="showMateInfo" :empDetail="empDetail" @closeMataInfoModal="closeMataInfoModal"
                type="List" :canChangeARVE="false" />
        </div>

        <CustomizeInfo ref="CustomizeInfoRef" :nowStep="0" type="List" :canChangeARVE="false" />
        <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo"
            @close-modal="closePipelineModal" :canChangeARVE="false" :nowStep="0" />

        <!-- 顾问信息弹窗 -->
        <div v-if="empVisible">
            <emp-info :isShowingEmpInfo="empVisible" :getId="empId" @close-emp-info="closeEmpModal" title="查看顾问信息"
                :isHaveAdd="false" />
        </div>

        <!-- 批量修改弹窗 -->
        <batchEditModal :modelValue="batchEditvisible" @closeModal="closeBatchModalHandler" />
    </div>
</template>
  
<script>
import { defineComponent, ref, watch } from "vue";
import MateInfo from "./MateInfo.vue";
import EmpInfo from "@/components/EmpInfo.vue";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
import batchEditModal from "@/views/home/ResourceManagement/Match/newMatch/components/batchEdit.vue"
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
export default defineComponent({
    name: "batchChangeArve",
    props: {
        list: {
            type: Array,
            default: () => { return [] }
        },
        visible: Boolean,
    },
    components: {
        PipelineModal,
        MateInfo,
        CustomizeInfo,
        EmpInfo,
        batchEditModal
    },
    emits: ['closeModal', 'refreshTable'],
    setup(props, { emit }) {
        const { $api } = useGlobalPropertyHook()
        const closeModalHandler = () => {
            emit('closeModal')
        }
        const tableData = ref()
        watch(() => props.visible, () => {
            tableData.value = JSON.parse(JSON.stringify(props.list))
        }, { immediate: true })
        const selectedRowKeys = ref([])
        const onSelectChange = (value) => {
            selectedRowKeys.value = value;
        };
        const columns = [
            {
                title: "需求编号",
                dataIndex: "demandNumber",
                key: "demandNumber",
                ellipsis: true,
                width: 100,
                slots: { customRender: "demandNumber" }
            },
            {
                title: "需求名称",
                dataIndex: "demandName",
                key: "demandName",
                ellipsis: true,
                width: 160,
                slots: { customRender: "demandName" }
            },
            {
                title: "姓名",
                dataIndex: "fullName",
                key: "fullName",
                ellipsis: true,
                width: 120,
                slots: { customRender: "fullName" }
            },
            {
                title: "职级",
                dataIndex: "psGroup",
                key: "psGroup",
                ellipsis: true,
                width: 80,
            },
            {
                title: "工作地点",
                dataIndex: "staffWorkLocation",
                key: "staffWorkLocation",
                ellipsis: true,
                width: 110,
            },
            {
                title: "ARVE Assumption %",
                dataIndex: "arveAssumption",
                key: "arveAssumption",
                ellipsis: true,
                width: 190,
                slots: { customRender: "arveAssumption" }
            },
            {
                title: "ARVE Assumption Start Date",
                dataIndex: "arveAssumptionStartDate",
                key: "arveAssumptionStartDate",
                ellipsis: true,
                width: 190,
                slots: { customRender: "arveAssumptionStartDate" }
            },
        ]
        const validateArve = (value, type) => {
            if (!value[type]) {
                let string = ''
                if (type == 'arveAssumption') {
                    string = '请输入ARVE数字'
                } else {
                    let info = type
                    if (type == 'arveAssumptionStartDate') {
                        info = 'ARVE AssumptionStartDate'
                    }
                    string = `${info}为必填项`
                }
                return Promise.reject(string)
            } else if (type == 'arveAssumption') {
                const isValidNumber = (input) => {
                    const regex = /^(0$|[1-9][0-9]?|100)$/;
                    return regex.test(input);
                }
                let flag = isValidNumber(value[type])
                if (!flag) {
                    return Promise.reject('请输入0-100之间的数字')
                } else {
                    return Promise.resolve()
                }
            } else {
                return Promise.resolve()
            }
        }
        // 三个需求名称弹窗开始
        const showMateInfo = ref(false)
        const empDetail = ref()
        const pipelineVisible = ref(false)
        let optType = 'match'
        let CustomizeInfoRef = ref()
        const pipelineInfo = ref({})

        const closeMataInfoModal = () => {
            showMateInfo.value = false;
        }

        const checkMatchDetails = (item) => {
            if (item.type == 0) {
                showMateInfo.value = true;
                empDetail.value = item
            } else if (item.type == 1) {
                CustomizeInfoRef.value.showModal(item)
            } else {
                pipelineInfo.value = Object.assign({}, item, item.pipelineDetailVO)
                pipelineVisible.value = true
            }
        };
        const closePipelineModal = () => {
            pipelineInfo.value = {}
            pipelineVisible.value = false
        }
        // 三个需求名称弹窗结束

        // 名称弹窗开始
        const empVisible = ref(false)
        const empId = ref()
        const openEmpModal = (row) => {
            empId.value = row.userId;
            empVisible.value = true;
        };
        const closeEmpModal = () => {
            empVisible.value = false;
        }

        // 名称弹窗结束

        // 批量修改弹窗开始

        const batchEditvisible = ref(false)
        const openBatchModal = () => {
            batchEditvisible.value = true
        }
        const closeBatchModalHandler = (type, obj) => {
            batchEditvisible.value = false
            // 批量编辑确定逻辑
            if (type === 1) {
                selectedRowKeys.value.forEach((item) => {
                    tableData.value.forEach((jtem) => {
                        if (item == jtem.preMatchingId) {
                            jtem.arveAssumptionStartDate = obj.arveAssumptionStartDate
                            jtem.arveAssumption = obj.arveAssumption
                        }
                    })
                })
            }
        }

        // 批量修改弹窗结束
        const formRef = ref()
        const confirmBatch = () => {
            formRef.value.validate().then(async () => {
                let arr = []
                tableData.value.forEach((item) => {
                    arr.push({
                        "preMatchingId": item.preMatchingId,
                        "arveAssumption": item.arveAssumption,
                        "arveAssumptionStartDate": item.arveAssumptionStartDate
                    })
                })
                await $api.batchChangeArve(arr)
                let preMatchingIdList = arr.map((item) => { return item.preMatchingId })
                let res = await $api.afterBatchChangeUpdate(preMatchingIdList)
                tableData.value.forEach((item) => {
                    res.data.forEach((jtem) => {
                        if (item.preMatchingId == jtem.preMatchingId) {
                            item.arveAssumption = jtem.arveAssumption == null ? jtem.consultantArveAssumption : jtem.arveAssumption
                            item.arveAssumptionStartDate = jtem.consultantArveAssumptionStartDate == null ? jtem.arveAssumptionStartDate : jtem.consultantArveAssumptionStartDate
                        }
                    })
                })
                message.success('修改成功')
                emit('refreshTableBtPage', res.data)
                closeModalHandler()
            })
        }

        const cancelHandler = () => {
            closeModalHandler()
        }

        return {
            closeModalHandler,
            tableData,
            selectedRowKeys,
            onSelectChange,
            columns,
            validateArve,
            showMateInfo, empDetail, closeMataInfoModal, checkMatchDetails, pipelineVisible,
            closePipelineModal, optType, CustomizeInfoRef, pipelineInfo, empVisible, empId,
            openEmpModal, closeEmpModal, batchEditvisible, closeBatchModalHandler, openBatchModal,
            confirmBatch, formRef, cancelHandler
        }
    },
});
</script>
  
<style scoped lang="less">
.batchChangeArve {
    .container {
        padding: 5px;

        .mainInfo {
            width: 100%;
        }
    }

    .btnContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right {
            display: flex;
        }
    }

    .underLine {
        text-decoration: underline;
        cursor: pointer;
    }

}
</style>
  