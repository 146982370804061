<template>
  <div ref="getRefsAff">
    <a-drawer
        :getContainer="() => $refs.getRefsAff"
        :visible="visible"
        @close="closeModal"
        width="83.3vw"
        class="restartAssignmentModal"
        :zIndex="99"
        title="Assignment 重启"
    >
      <div>
        <a-form :model="formData" ref="formRef">
          <a-row>
            <a-col :span="24" class="red-text">
              <a-form-item label="申请原因:" name="restartAssignmentReason">
                {{ formData.restartAssignmentReason }}
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-form
          :model="formData"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          layout="vertical"
      >
        <a-row>
          <a-col :span="6">
            <a-form-item label="Service Line:">
              {{ formData.serviceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Sub Service Line:">
              {{ formData.subServiceLine }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Name:" name="candidateName">
              {{formData.candidateName}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Contact:" name="candidateContact">
              {{formData.candidateContact}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Account:">
              {{ formData.dhrAccount }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Request Industry:">
              {{ formData.dhrIndustry }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Candidate Grade:">
              {{ formData.candidateGrade }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="ADRC/人员成本:">
              {{ formData.adrc }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Account:" name="account">
              {{formData.account}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Industry Line:" name="industryLine">
              {{formData.industryLine}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Engagement Code:" name="engagementCode">
              {{formData.engagementCode}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Description:"
                name="engagementDescription"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
            >
              {{formData.engagementDescription}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Code预计开出时间:"
                name="projectCommitDate"
            >
              {{!isFormalCodeShow? '仅适用于TBD Code' : formData.projectCommitDate}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Code有效期:">
              {{ formData.codeStartDate + '~' + (formData.projectStatus === 'Close'?formData.codeEndDate:formData.projectCompletionDate) }}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
                label="Engagement Manager:"
                name="engagementManager"
                class="selectEM"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
            >
              {{formData.engagementManager}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="PM:" name="pmInfo">
              {{formData.pmInfo}}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
                label="Leading CSM:"
                name="leadingCSM"
                class="selectEM"
            >
              {{!isFormalCodeShow? '仅适用于TBD Code' : formData.leadingCSM}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Assignment Term:" name="assignmentTerm">
              {{formData.onBoardDate + '~' + formData.actualDate}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Hours per Day:" name="hoursPerDay">
              {{formData.hoursPerDay}}
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formData.staffAssignmentParts?.length > 0">
            <a-table
                :columns="columns"
                :data-source="formData.staffAssignmentList"
                :pagination="false"
                class="segmentedClass"
                row-key="id"
                :scroll="{
                  x: 800,
                  scrollToFirstRowOnChange: true,
                }"
            >
              <template #AssignmentTerm>
                <div>
                  <span style="color: #1890FF">●</span>{{formData.onBoardDate + '~' + formData.actualDate}}
                </div>
                <div v-for="(item) in formData.staffAssignmentParts" :key="item.id">
                  <span style="color: #77CAED">●</span><span style="color: #999999">{{item.startDate + '~' + item.endDate }}</span>
                </div>
              </template>
              <template #hoursPerDay>
                <div>
                  {{formData.hoursPerDay}}
                </div>
                <div v-for="(item) in formData.staffAssignmentParts" :key="item.id">
                  <span style="color: #999999">{{item.hoursPerDay}}</span>
                </div>
              </template>
            </a-table>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Work Location:" name="workLocation">
              {{formData.workLocation}}
            </a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="Work City:" name="workCity">
              {{formData.workCity}}
            </a-form-item>
          </a-col>
          <a-col :span="24" class="tipsCol">
            <a-form-item
                label="Copy:"
                name="copyList"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 24 }"
            >
                <span v-for="item in formData.copyList" :key="item">
                  <a-tag>{{item}}</a-tag>
                </span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="btn-row bottom-btn footerbtn">
        <div class="left">
          <span style="margin-right: 8px;color: #505050">调令重启后是否发送 Assignment Update 邮件<span class="require">*</span> </span>
          <a-radio-group v-model:value="formData.isSendUpdateEmail" :disabled="true">
            <a-radio value="1" >是</a-radio>
            <a-radio value="0" >否</a-radio>
          </a-radio-group>
        </div>
        <div class="right">
          <a-space>
            <a-button @click="submitModal" type="primary">同意</a-button>
            <a-button class="btn-left" type="primary" danger @click="rejectModal">拒绝</a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  <revert-modal
      v-model:visible="rejectVisible"
      v-model:formState="formData"
      :title="rejectTitle"
      :comment-label="rejectCommentLabel"
      @close-modal="closerejectModal"
      @submit-modal="submitrejectModal"
  />
</template>

<script>
import ModalBox from "@/components/ModalBox.vue";
import {computed, defineComponent, ref, watch,} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import moment from "moment/moment";
import RevertModal from "@/views/home/ResourceManagement/Match/matchingList/components/RevertModal.vue";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "RestartAssignmentConfirm",
  components: {
    RevertModal,
    ModalBox,
  },
  props: {
    visible: Boolean,
    formState: {
      type: Object,
      default: () => ({
        serviceLine: "",
        subServiceLine: "",
        candidateName: "",
        candidateContact: "",
        candidateGrade: "",
        account: "",
        engagementCode: "",
        engagementDescription: "",
        workLocation: "",
        engagementManager: "",
        leadingCSM: "",
        copyList: [],
        assignmentTerm: [],
        estimateReleaseDate: "",
        hoursPerDay: "",
        adrc: "",
        industryLine: "",
        workCity: "",
        replaceReason: "",
        pmInfo: "",
        codeStartDate: "",
        codeEndDate: "",
        assEmailSendTime: "",
        staffAssignmentParts: [],
        applyReason: '',
        isSendUpdateEmail:"",
        staffAssignmentList:[],
      }),
    },
    isFormalCode: Boolean,
  },
  emits: ["close-modal","submit-modal"],
  setup(props,ctx) {
    const { $api } = useGlobalPropertyHook();
    const formData = computed(() => props.formState);
    const isFormalCodeShow = computed(() => props.isFormalCode)
    const columns = computed(() => {
      let cols = [
        {
          title: <div>分段Assignment Term<span class="require">*</span></div>,
          dataIndex: "startDate",
          ellipsis: true,
          width: `26%`,
          slots: { customRender: "AssignmentTerm" },
        },
        {
          title: <div>分段Hours Per Day<span class="require">*</span></div>,
          dataIndex: "hoursPerDay",
          ellipsis: true,
          width: `74%`,
          slots: { customRender: "hoursPerDay" },
        },
      ];
      return cols;
    })

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref('')
    const handleOk = () => {
      const params = {
        id: formData.value.id,
        isApprove: '1',
      }
      $api.approvalRestartAssignment(params).then((res) => {
        message.success(res.msg);
        ctx.emit("submit-modal");
      });
    }

    const rejectVisible = ref(false)
    const rejectTitle = '拒绝'
    const rejectCommentLabel = 'Reject Reason'
    const closerejectModal = () => {
      rejectVisible.value = false
    }
    const submitrejectModal = () => {
      const params = {
        id: formData.value.id,
        rejectReason: formData.value.rejectReason,
        isApprove: '0',
      }
      $api.approvalRestartAssignment(params).then((res) => {
        message.success(res.msg);
        rejectVisible.value = false
        ctx.emit("submit-modal");
      });
    }

    const closeModal = () => {
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      if(moment().isBefore(formData.value.actualDate) || moment(formData.value.actualDate).isSame(moment(), "day")){
        flag.value = 1
        content.value = "是否确认申请重启调令？"
      } else {
        flag.value = 2
        content.value = "您当前选择的下项目日期早于当日，审批通过后调令将不会重启，请确定是否修改调令时间？"
      }
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
    };
    const rejectModal = () => {
      rejectVisible.value = true
    };

    watch(
        () => props.visible,
        (val) => {
          if(val){
            if(formData.value.staffAssignmentParts?.length > 0){
              formData.value.staffAssignmentList = [{startDate:'',hoursPerDay:''}]
            }
          }
        }
    )

    return {
      labelCol: { span: 12 },
      wrapperCol: { span: 20 },
      formData,
      isFormalCodeShow,
      columns,
      closeModal,
      submitModal,
      rejectModal,
      modelValue,
      content,
      showIconCom,
      flag,
      handleOk,
      rejectVisible,
      rejectTitle,
      rejectCommentLabel,
      closerejectModal,
      submitrejectModal,
    };
  },
});
</script>

<style scoped lang="less">
.restartAssignmentModal {
  .red-text {
    :deep(.ant-form-item-label) {
      > label {
        color: #ff4d4f;
      }
    }
    :deep(.ant-form-item-control-input-content){
      color: #ff4d4f;
      font-size: 0.9vw;
    }
    :deep(.ant-form-item){
      margin-bottom: 0;
      align-items: center;
    }
  }
  .footerbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .segmentedClass {
    margin-right: 4vw;
    margin-bottom: 0.8vw;
    :deep(.ant-table .ant-table-thead > tr > th){
      padding-left: 0;
    }
    :deep(.ant-table .ant-table-tbody > tr > td) {
      padding: 0.5vw 0 ;
    }
    :deep(.ant-table-row-cell-ellipsis .ant-table-column-title){
      font-size: 16px;
    }
  }
}
.require {
  margin-left: 0.20833vw;
  color: #ff4d4f;
  font-size: 0.72917vw;
}
:deep(.ant-form-item-label > label){
  color: #999999;
  font-size: 16px;
}
:deep(.ant-form-item-control-input-content){
  font-size: 16px;
}
:deep(.ant-tag) {
  border: 0;
  background:#E8E8E8;
  color: #9F9F9F;
  font-size: 16px;
}
</style>
<style lang="less">
.restartAssignmentModal {
  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
  }
}
</style>
