import store from "@/store";
export function isshow(key) {
  const roles = store.state && store.state.app.perms;
  const isshow = roles.some((role) => {
    return key.includes(role);
  });
  return isshow
}

export function isShowTab(key) {
  const roles = store.state && store.state.app.pages;
  const isshow = roles.some((role) => {
    return key === role;
  });
  return isshow
}
