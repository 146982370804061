<template>
  <section class="top-filter">
    <FilterLayout @doReset="handleReset"
                  @doSearch="doSearch"
                  @doCollapseEffect="handleResize"
                  ref="filterLayoutRef"
                  @showMoreForm="showMoreForm"
                  :moreBtnVisible="moreBtnVisible"
                  :isShowMoreFlag="isShowMoreList">
      <template #customform>
<!--        <a-form
            class="form rms-form box"
            name="custom-validation"
            ref="formRef"
            :model="formState"
            layout="vertical"
        >
          <a-form-item label="Team" name="orgThreeTwoList" v-auth="['system:todoMarching:team']" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgThreeTwoList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
                @change="changeOrgThreeTwo"
            >
              <a-select-option
                  v-for="(item, index) in orgThreeTwoOps"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoMarching:offering']" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgFourOneOneList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
            >
              <a-select-option
                  v-for="(item, index) in orgFourOneOneOps"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Engagement Description" name="demandCondition" class="rms-form-item">
            <a-input
                v-model:value="formState.demandCondition"
                placeholder="项目名称"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
          <a-form-item label="需求编号" name="demandNumber" class="rms-form-item">
            <a-input
                v-model:value="formState.demandNumber"
                placeholder="需求编号"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
          <a-form-item label="客户名称" name="customerCondition" class="rms-form-item">
            <a-input
                v-model:value="formState.customerCondition"
                placeholder="客户名称/详细客户名称"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
          &lt;!&ndash;      匹配：只有PM显示Status &ndash;&gt;
          <a-form-item label="Status" name="status" v-auth="['system:todoMarching:status']" class="rms-form-item">
            <a-select
                v-model:value="formState.status"
                placeholder="请选择"
                :options="assignmentStatusOps"
                allowClear
                show-search
                :maxTagCount="1"
            />
          </a-form-item>
          <a-form-item label="搜索" name="nameCondition" class="rms-form-item">
            <a-input
                v-model:value="formState.nameCondition"
                placeholder="Name/GGID"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
        </a-form>-->
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <template v-for="(item,index) in queryList" :key="index">
            <template v-if="index<=4">
              <a-form-item :label="item.code" name="name" class="rms-form-item">
                <template v-if="item.type==='2'">
                  <a-select
                      v-model:value="formState[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                      :maxTagCount="1"
                  >
                  </a-select>
                </template>
                <template v-if="item.type==='3'">
                  <a-input
                      v-model:value="formState[item.inputDataType]"
                      :placeholder="getPlaceHoder(item.inputDataType)"
                      allowClear
                      autocomplete="off"
                  />
                </template>
                <template v-if="item.type==='5'">
                  <SelectWithAll
                      v-model:value="formState[item.inputDataType]"
                      placeholder="请选择"
                      mode="multiple"
                      showArrow
                      :maxTagCount="1"
                      allowClear
                      :options="getOptions(item.inputDataType)"
                      @change="getSelectChange(item.inputDataType)"
                  >
                  </SelectWithAll>
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index>4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                        :maxTagCount="1"
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <a-space>
            <Tooltip title="导出">
              <a-button class="" type="primary" ghost @click="handleExport" v-auth="['system:todoList:marching:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </a-space>
        </div>
      </template>
    </FilterLayout>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, onMounted, onActivated} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep, debounce} from "lodash";
import { ExportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'

export default defineComponent({
  name: "MatchingFilter",
  components: {Tooltip, ExportOutlined, MoreFormList, FormModalBox },
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    },
    isPM: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search", "refresh-table", "getSize", "exportClick", "getOrgList", "getPMOrgList"],
  setup(props, {emit}) {
    const {$api} = useGlobalPropertyHook();
    const formRef = ref();
    let initData = {
      demandCondition: "",
      engagementCode: "",
      nameCondition: "",
      status: null,
      demandNumber: "",
      customerCondition: "",
      orgThreeTwoList: [],
      orgFourOneOneList: [],
    }
    const formState = reactive({...initData});

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      console.log(formState, 'formState')
      currentParams = cloneDeep(formState);
    }

    // 点击收起展开计算table所占高度
    const handleResize = () => {
      emit("getSize");
    }

    const doSearch = () => {
      updateParams();
      emit("search", currentParams);
    };

    const handleReset = () => {
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams();
      emit("search", currentParams);
      // 重新获取team offering下拉
      if(props.isRM) {
        emit("getOrgList")
      }
      if(props.isPM) {
        emit("getPMOrgList")
      }
    };

    const assignmentStatusOps = ref([]);
    const getAssignmentTypes = () => {
      $api
          .getDictionaryList({
            params: {
              dictType: "todoListPreStatus",
            },
          })
          .then((res) => {
            assignmentStatusOps.value = res.data && res.data.todoListPreStatus ?
                res.data.todoListPreStatus.map(({dictCode, dictValue}) => ({
                  label: dictValue,
                  value: dictCode,
                })) : [];
          });
    };

    // team改变时更新offering下拉框内容
    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList);
    };

    const handleExport = () => {
      emit("exportClick", currentParams)
    }

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'todoList',
        secondMenu: 'preMatch',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'nameCondition':
          return 'Name/GGID'
        case 'demandNumber':
          return '需求编号'
        case 'customerCondition':
          return '客户名称/详细客户名称'
        case 'demandCondition':
          return '项目名称'
        default :
          ''  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return props.orgThreeTwoOps
        case 'Offering':
          return props.orgFourOneOneOps
        case 'status':
          return assignmentStatusOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      console.log(data)
      switch (data) {
        case 'orgThreeTwoList':
          return changeOrgThreeTwo()
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const moreSeachHanlder = () => {
          doSearch()
          closeMoreForm()
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getAssignmentTypes();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onActivated(() => {
      emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      assignmentStatusOps,
      updateParams,
      handleResize,
      changeOrgThreeTwo,
      handleExport,
      queryFormList,
      queryList,
      checkList,
      getPlaceHoder,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;

  .search {
    text-align: right;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
