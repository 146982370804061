<template>
  <a-drawer
      title="确认"
      :visible="visible"
      @close="pervClick"
      width="38.5vw"
      :getContainer="() => getContainerRef"
  >
    <div class="cards">
      <div class="card" v-for="item in list" :key="item.id">
        <div class="name">
          <span>{{ item.fullName }}</span>
        </div>
        <div class="item">
          <span class="label">GGID:</span>
          <span class="value">{{ item.ggid }}</span>
        </div>
        <div class="item" v-if="pageType === 'todoList'">
          <span class="label">Engagement Code:</span>
          <span class="value">{{ item.engagementCode }}</span>
        </div>
        <div class="item" v-if="pageType === 'todoList'">
          <span class="label">Engagement Description:</span>
          <span class="value">{{ item.engagementDescription }}</span>
        </div>
        <div class="item">
          <span>
            <span class="label"><span style="color: #1890FF" v-if="item.staffAssignmentParts.length > 0">●</span>Assignment Term:</span>
            <span class="value">{{ item.range }}</span>
          </span>
          <span>
            <span class="hourLabel">Hours Per Day:</span>
            <span class="value">{{ item.hoursPerDay }}小时</span>
          </span>
        </div>
        <div v-for="(item, index) in item.staffAssignmentParts" :key="index" class="item">
          <span>
          <span class="label"><span style="color: #77CAED">●</span>Assignment Term{{index + 1}}:</span>
          <span class="value">{{item.startDate + "~" + item.endDate }}</span>
          </span>
          <span>
          <span class="hourLabel">Hours Per Day:</span>
          <span class="value">{{item.hoursPerDay}}小时:</span>
          </span>
        </div>
        <div class="item">
          <span class="label"
          >{{ activeTab === "1" ? "Project Onboard Date:" : "Release Date:" }}</span
          >
          <span class="value" style="color: #12abdb">{{
              activeTab === "1"
                  ? item.newOnBoardDate
                  : item.newActualReleaseDate
            }}</span>
        </div>
        <div class="item">
          <span class="label">Code 预计开出时间:</span>
          <span class="value" v-if="isTBDCode">{{ item.projectCommitDate }}</span>
          <span class="value" v-else>仅适用于TBD Code</span>
        </div>
        <div class="item">
          <span class="label">备注:</span>
          <span class="value">{{
              actionType === "apply" ? item.reason : item.comments
            }}</span>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <a-space style="float: right">
        <a-button type="primary" @click="pervClick">上一步</a-button>
        <a-button type="primary" @click="saveClick">确定</a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import { defineComponent } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import moment from "moment/moment";

export default defineComponent({
  name: "BatchConfirmModal",
  props: {
    visible: Boolean,
    editData: {
      type: Object,
      default: () => {},
    },
    isTBDCode: {
      type: Boolean,
      default: false,
    },
    list: Array,
    idList: Array,
    role: String,
    activeTab: String,
    getContainerRef: Object,
    actionType: String,
    pageType: {
      type: String,
      default: '',
    },
  },
  emits: ["cancel-modal", "prev-click", "refresh-table"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const pervClick = () => {
      emit("prev-click");
    };

    const saveClick = () => {
      const params = {
        applyType: props.activeTab,
        assignmentList: props.list.map((item) => {
          let arr = []
          let newHoursPerDay = item.hoursPerDay
          if(item.staffAssignmentParts.length > 0){
            if(props.activeTab === '1') {
              arr = item.staffAssignmentParts.filter(i =>
                  moment(i.endDate).isAfter(item.newOnBoardDate) || moment(i.endDate).isSame(item.newOnBoardDate)
              ).map(i => {
                return {
                  id: i.id,
                  startDate: i.startDate,
                  endDate: i.endDate,
                  hoursPerDay: i.hoursPerDay
                }
              })
              arr[0].startDate = item.newOnBoardDate
            } else {
              arr = item.staffAssignmentParts.filter(i =>
                  moment(i.startDate).isBefore(item.newActualReleaseDate) || moment(i.startDate).isSame(item.newActualReleaseDate)
              ).map(i => {
                return {
                  id: i.id,
                  startDate: i.startDate,
                  endDate: i.endDate,
                  hoursPerDay: i.hoursPerDay
                }
              })
              arr[arr.length - 1].endDate = item.newActualReleaseDate
            }
            if(arr.length === 1) {
              newHoursPerDay = arr[0].hoursPerDay
              arr = []
            } else {
              newHoursPerDay = item.hoursPerDay
            }
          }
          return {
            ...item,
            assignmentId: item.id,
            // reason 去空格
            reason: item.reason?.trim(),
            // comments 去空格
            comments: item.comments?.trim(),
            staffAssignmentParts: arr,
            hoursPerDay: newHoursPerDay
          }
        })
      };
      const apiFn =
        props.actionType === "apply" ? $api.pmBatchApply : $api.rmBatchAudit;
      apiFn(params).then(() => {
        message.success("操作成功");
        emit("refresh-table");
      });
    };

    return {
      pervClick,
      saveClick,
    };
  },
});
</script>

<style lang="less" scoped>
.cards {
  .card {
    margin: 24px;
    padding: 12px;
    background: #f7fafc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    .name {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
      margin-bottom: 12px;
    }
    .item {
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 8px;
      display: flex;

      .label {
        color: #2d3748;
        width: 10vw;
        display: inline-block;
        flex-shrink: 0;
      }
      .hourLabel {
        color: #2d3748;
        width: 7vw;
        display: inline-block;
        flex-shrink: 0;
        margin-left: 2vw;
      }

      .value {
        color: #999999;
        width: 100%;
        word-break: break-all;
      }
    }
  }
}
</style>
