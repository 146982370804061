<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    width="40.6vw"
    @cancel="closeModal"
    class="release"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "异常处理" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      labelAlign="left"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item
            label="Release 方式"
            name="releaseMode"
            :label-col="{ span: 22 }"
            :wrapper-col="{ span: 22 }"
            :colon="false"
          >
            <a-radio-group v-model:value="formData.releaseMode" :columns="2">
              <div v-for="item in [1, 4, 5]" :key="item">
                <a-radio :value="item">
                  {{ showMode[item] }}
                </a-radio>
              </div>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Actual 时间"
            name="actualDate"
            :label-col="{ span: 22 }"
            :wrapper-col="{ span: 22 }"
            :colon="false"
          >
            <a-date-picker
              format="YYYY-MM-DD"
              v-model:value="formData.actualDate"
              disabled
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Release 原因"
            name="releaseReason"
            :label-col="{ span: 22 }"
            :wrapper-col="{ span: 24 }"
            :colon="false"
          >
            <a-textarea
              v-model:value="formData.releaseReason"
              placeholder="请输入 Release 原因"
              :rows="5"
              showCount
              :maxlength="65"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
const formRules = {
  releaseMode: [
    {
      required: true,
      message: "Please choose the Release Mode",
      trigger: "change",
      type: "number",
    },
  ],
  actualDate: [
    {
      required: true,
      message: "Please input Actual Date",
      trigger: "change",
      type: "date",
    },
  ],
  releaseReason: [
    {
      required: true,
      message: "Please input Release Reason",
      trigger: "change",
      type: "string",
    },
  ],
};

import { computed, defineComponent, ref } from "vue";
// import moment from "moment";
export default defineComponent({
  name: "Release",
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        releaseMode: "",
        disableDate: "",
        status: "",
        actualDate: null,
        releaseReason: "",
      }),
    },
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const formRef = ref();
    const showMode = {
      1: "RM Interrupt",
      2: "Normal Release",
      3: "Sudden Release",
      4: "Error Assignment Cancel",
      5: "Request Cancel",
    };

    // const disabledDateFunc = (current) => {
    //   return moment(current) && moment(current) > moment(props.formState.disableDate);
    // };
    const disableEstimateDate = computed(() => {
      if (props.formState.status == "1") {
        return true;
      }
      return false;
    });

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        ctx.emit("submit-modal");
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      formData,
      formRef,
      formRules,
      showMode,
      disableEstimateDate,
      // disabledDateFunc,
      getRefsAff,
      closeModal,
      submitModal,
    };
  },
});
</script>

<style lang="less">
.fill-appraises {
  //width: 50vw !important;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    position: relative;

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
    .ant-calendar-picker {
      width: 100%;
    }
  }
}
</style>
