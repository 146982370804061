<template>
  <div ref="restartAssignmentModalRef">
    <a-modal
        :getContainer="()=>$refs.restartAssignmentModalRef"
        :visible="visible"
        @cancel="closeModal"
        class="restartAssignmentModal"
        :zIndex="99"
        title="Assignment 重启"
        width="40.6vw"
        centered
    >
      <template #footer>
        <div class="footerBtn">
          <div>
            <span style="margin-right: 8px">调令重启后是否发送 Assignment Update 邮件<span class="require">*</span> </span>
            <a-radio-group v-model:value="isSendUpdateEmail">
              <a-radio value="1">是</a-radio>
              <a-radio value="0">否</a-radio>
            </a-radio-group>
          </div>
          <div>
            <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
            <a-button @click="submitModal" type="primary" class="applyBtn">确定</a-button>
          </div>
        </div>
      </template>
      <a-form
          :model="formData"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          labelAlign="left"
          layout="vertical"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
                label="Code 有效期："
                name=""
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 20 }"
                :colon="false"
                class="labelColor"
            >
              {{
                codeTerm.projectStartDate +
                " ~ " +
                codeTerm.projectCloseDate
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
                label="Assignment Term："
                name="originalOnboardDate"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 20 }"
                :colon="false"
                class="labelColor"
            >
              {{
                formData.originalOnboardDate +
                " ~ " +
                formData.originalActualDate
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Hours Per Day：" name="hoursPerDay" class="labelColor">
              {{formData.hoursPerDay}}
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="isSegmentedEditing">
            <a-form-item
                name="assignmentTerm"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
                :colon="false"
                :class="isShowBtn === true ? 'isShow' : ''"
            >
              <template v-slot:label>
                修改 Assignment Term<span class="require">*</span>
              </template>
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  v-model:value="formData.onBoardDate"
                  style="width: 47%;"
                  :disabled="true"
                  :allowClear="false"
              />
              <span> ~ </span>
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @change="actualDateChange"
                  v-model:value="formData.actualReleaseDate"
                  :disabledDate="estimateDisabledDate"
                  style="width: 47%;"
                  :allowClear="false"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="isSegmentedEditing" :wrapper-col="{ span: 24 }">
            <a-form-item label="修改Hours Per Day" name="hoursPerDay">
              <a-input
                  v-model:value="formData.hoursPerDay"
                  placeholder="请输入"
                  :disabled="true"
                  style="width: 55%;"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!isSegmentedEditing">
            <a-form :model="segmentedData" ref="segmenteFormRef" class="segmentedtaleclass">
              <a-table
                  :columns="columns"
                  :data-source="segmentedData"
                  :pagination="false"
                  class="segmentedClass"
                  row-key="id"
                  :scroll="{
                  x: 450,
                  scrollToFirstRowOnChange: true,
                }"
              >
                <template #startDate="{ index, text }">
                  <a-form-item :name="[index,'startDate']">
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        style="width: 92%"
                        :disabled="true"
                    />
                    <span class="tilde"> ~ </span>
                  </a-form-item>
                </template>
                <template #endDate="{ index, text, record }">
                  <a-form-item :name="[index,'endDate']">
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].endDate = val)"
                        style="width: 96%"
                        @change="endDateChange(index,record)"
                        :disabled-date="current => segmenteEstimateReleaseDate(current, record)"
                        :disabled="index !== segmentedData.length-1"
                    />
                  </a-form-item>
                </template>
                <template #hoursPerDay="{ index, text }">
                  <a-form-item :name="[index, 'hoursPerDay']">
                    <a-input style="width: 50%" :value="text" :disabled="true"/>
                  </a-form-item>
                </template>
              </a-table>
            </a-form>
          </a-col>
          <a-col :span="12" style="margin-top: 0.5vw">
            <a-form-item
                label="Code 预计开出时间"
                name="projectCommitDate"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
                :colon="false"
                :class="isShowBtn === true ? 'isShow' : ''"
            >
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  v-model:value="formData.projectCommitDate"
                  :placeholder="placeHolder"
                  style="width: 100%;"
                  :disabled="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-show="isShowBtn">
            <a-form-item
                label=""
                :colon="false"
                :wrapper-col="{ span: 15 }"
                style="margin-bottom: 0px"
            >
              <span style="color:#DC5E4F">Assignment Term与Code有效期冲突</span>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
                label="申请原因"
                name="applyReason"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :colon="false"
            >
              <a-textarea
                  v-model:value="formData.applyReason"
                  placeholder="请输入"
                  :rows="3"
                  showCount
                  :maxlength="65"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
                  @keyup.enter="(e) => { e.stopPropagation() }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import moment from "moment/moment";

export default defineComponent({
  name: "RestartAssignmentModal",
  components: {},
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        actualReleaseDate: "",
        applyReason: "",
        staffAssignmentParts: [],
      }),
    },
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isFormalCode: {
      type: Boolean,
      default: false,
    },
    orgInfo: Object,
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const codeTerm = computed(() => props.codeTermData);
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const isShowBtn = ref(false);
    const isSendUpdateEmail = ref('0')
    const isTBDCode = computed(() => props.isFormalCode);
    const placeHolder = computed(() =>  isTBDCode.value === false ? "仅适用于TBD Code" : "请选择");

    const formRules = {
      actualReleaseDate: [
        {
          required: true,
          message: "Please input Actual Date",
          trigger: "change",
          type: "date",
        },
      ],
      applyReason: [
        {
          required: true,
          message: "请输入申请原因",
          trigger: "blur",
        },
      ],
    };

    const getIsShow = () => {
      const params = {
        onBoardDate: formData.value.onBoardDate,
        estimateDate: segmentedData.value.length>0? segmentedData.value[segmentedData.value.length - 1].endDate : formData.value.actualReleaseDate,
        projectStartDate: codeTerm.value.projectStartDate,
        projectCloseDate:codeTerm.value.projectCloseDate,
      }
      $api.checkCodeAndAssignTerm([params]).then((res) => {
        if (res.data[0] === '1') {
          isShowBtn.value = false
        } else {
          isShowBtn.value = true
        }
      });
    }

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        ctx.emit("submit-modal", segmentedData.value,isSendUpdateEmail.value);
      });
    };


    const estimateDisabledDate = (current) => {
      return current < moment(formData.value.onBoardDate) || current > moment(formData.value.terminationDate)
    };

    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getCommitDateRange = async () => {
      if(isTBDCode.value === true) {
        const params = {
          onboardDate: formData.value.onBoardDate,
        }
        const { data } =await $api.getCommitDateRange(params)
        projectCommitEndDate.value = data.projectCommitEndDate
        projectCommitStartDate.value = data.projectCommitStartDate
      }
    }
    const actualDateChange = () => {
      formData.value.assignmentTerm[1] = formData.value.actualReleaseDate
      getIsShow();
    }

    //分段编辑
    const segmenteFormRef = ref()
    const isSegmentedEditing = ref(true)
    const segmentedData = ref([])
    const columns = computed(() => {
      let cols = [
        {
          title: <div>修改Assignment Term<span class="require">*</span></div>,
          dataIndex: "startDate",
          ellipsis: true,
          width: `26%`,
          slots: { customRender: "startDate" },
        },
        {
          title: " ",
          dataIndex: "endDate",
          ellipsis: true,
          width: `24%`,
          slots: { customRender: "endDate" },
        },
        {
          title: "修改Hours Per Day",
          dataIndex: "hoursPerDay",
          ellipsis: true,
          width: `50%`,
          slots: { customRender: "hoursPerDay" },
        },
      ];
      return cols;
    })

    const endDateChange = (index) => {
      formData.value.assignmentTerm[1] = segmentedData.value[index].endDate
      getIsShow()
    }

    const segmenteEstimateReleaseDate = (current,record) => {
      return current < moment(record.startDate) || current > moment(formData.value.terminationDate)
    };

    watch(
        () => formData.value.staffAssignmentParts,
        (val) => {
          if(val) {
            if (val.length > 0) {
              isSegmentedEditing.value = false
              segmentedData.value = formData.value.staffAssignmentParts.map(item => {
                return {
                  id: item.id,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  hoursPerDay: item.hoursPerDay
                }
              })
            } else {
              isSegmentedEditing.value = true
              segmentedData.value = []
            }
          }
        },
        {immediate:true,deep:true}
    )

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            getIsShow();
            getCommitDateRange();
            isSendUpdateEmail.value = '0'
          }}
    )

    return {
      labelCol: { span: 22 },
      wrapperCol: { span: 24 },
      formData,
      formRef,
      formRules,
      closeModal,
      submitModal,
      estimateDisabledDate,
      isShowBtn,
      getIsShow,
      codeTerm,
      placeHolder,
      isTBDCode,
      projectCommitEndDate,
      projectCommitStartDate,
      getCommitDateRange,
      actualDateChange,
      isSegmentedEditing,
      segmentedData,
      columns,
      endDateChange,
      segmenteFormRef,
      segmenteEstimateReleaseDate,
      isSendUpdateEmail,
    };
  },
})
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.restartAssignmentModal {
  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;
  }
  .footerBtn {
    display: flex;
    justify-content: space-between;
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
  }
  .ant-calendar-picker-input {
    border: 1px solid #cccccc !important;
  }

  .ant-select-arrow {
    color: #cccccc;
  }
  .ant-select-selector{
    border: 1px solid #cccccc !important;
  }
  .ant-input{
    border: 1px solid #cccccc !important;
  }
  .segmentedClass {
    :deep(.ant-table .ant-table-thead > tr > th){
      padding-left: 0;
    }
    :deep(.ant-table .ant-table-tbody > tr > td) {
      padding: 1vw 0 0;
    }
    .tilde{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
      margin: 0 -0.16vw
    }
    :deep(.ant-form-item) {
      flex-direction: row;
    }
    :deep(.ant-form-item-explain) {
      font-size: 0.67vw;
    }
    :deep(.ant-table-thead > tr > th .ant-table-header-column){
      display: flex;
    }
  }
  .segmentedtaleclass {
    padding-left: 0 !important;
    padding-right: 0 !important;

    :deep(.ant-form-item-has-error) {
      .ant-col {
        min-height: auto;
      }
    }
    :deep(.ant-table-row-cell-ellipsis .ant-table-column-title) {
      font-size: 0.791vw;
    }
  }
  .labelColor {
    :deep(.ant-form-item-label){
      padding: 0;
      > label {
        color: #999999;
      }
    }
  }
}
.isShow {
  margin-bottom: 0px;
}
.require {
  margin-left: 0.20833vw;
  color: #ff4d4f;
  font-size: 0.72917vw;
}
</style>
