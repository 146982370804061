<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    width="40.6vw"
    @cancel="closeModal"
    class="estimateReleaseConfirm"
    :zIndex="99"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "Assignment 时间确定" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="applyBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      layout="vertical"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item
              label="Code 有效期"
              name=""
              :label-col="{ span: 20 }"
              :wrapper-col="{ span: 20 }"
              :colon="false"
              :span="12"
          >
            <a-input
                :value="codeTerm.projectStartDate +' ~ ' + codeTerm.projectCloseDate"
                disabled
            />
          </a-form-item>
        </a-col>
<!--        <a-col :span="12">-->
<!--          <a-form-item-->
<!--            label="Assignment Term"-->
<!--            name="originalActualDate"-->
<!--            :label-col="{ span: 20 }"-->
<!--            :wrapper-col="{ span: 20 }"-->
<!--            :colon="false"-->
<!--            :span="12"-->
<!--          >-->
<!--            <a-input-->
<!--                :value="formData.originalOnboardDate + ' ~ '  + formData.originalActualDate"-->
<!--                disabled-->
<!--            />-->
<!--          </a-form-item>-->
<!--        </a-col>-->
        <a-col :span="24" :class="formData.staffAssignmentParts.length > 0 ? 'colColor':''">
          <a-row>
            <a-col :span="12">
              <a-form-item
                  name="originalActualDate"
                  :label-col="{ span: 20 }"
                  :wrapper-col="{ span: 20 }"
                  :colon="false"
                  class="labelColor"
              >
                <template v-slot:label>
                  <span style="color: #1890FF" class="status-icon" v-show="formData.staffAssignmentParts.length > 0">●</span>Assignment Term：
                </template>
                <span :style="`margin-left: ${ formData.staffAssignmentParts.length > 0 ? '0.6vw' : ''}`">
                {{
                  formData.originalOnboardDate +
                  " ~ " +
                  formData.originalActualDate
                }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                  label="Hours Per Day："
                  name="hoursPerDay"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :colon="false"
                  class="labelColor"
              >
                {{formData.hoursPerDay}}小时
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24" class="colColor" v-show="formData.staffAssignmentParts.length > 0">
          <div v-for="(item, index) in formData.staffAssignmentParts" :key="index">
            <a-row>
              <a-col :span="12">
                <a-form-item
                    name="originalOnboardDate"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                    :colon="false"
                    class="labelColor"
                >
                  <template v-slot:label>
                    <span style="color: #77CAED"><span class="status-icon">●</span></span>Assignment Term{{index + 1}}：
                  </template>
                  <span style="margin-left: 0.6vw;">
                  {{
                    item.startDate +
                    " ~ " +
                    item.endDate
                  }}
                  </span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                    label="Hours Per Day:"
                    name="hoursPerDay"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                    :colon="false"
                    class="labelColor"
                >
                  {{item.hoursPerDay}}小时
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-col>

        <a-col :span="24" :style="`margin-top: ${ formData.staffAssignmentParts.length > 0 ? '0.8vw' : ''}`">
          <a-form-item
            :label="isRm ? 'Actual Release Date' : 'Release Date'"
            name="actualDate"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 10 }"
            :colon="false"
            :class="isShowBtn === true ? 'isShow' : ''"
          >
            <a-date-picker
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              v-model:value="formData.actualDate"
              :disabledDate="disabledDate"
              style="width: 100%"
              @change="actualDateChange"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24" v-show="isShowBtn">
          <a-form-item
              label=" "
              :colon="false"
              :label-col="{ span: 20 }"
              :wrapper-col="{ span: 20 }"
              style="margin-bottom: 0px"
          >
            <span style="color:red">Actual Release Date与Code有效期冲突</span>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Release 方式"
            name="releaseMode"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 10 }"
            :colon="false"
            v-if="isRm"
          >
            <a-radio-group v-model:value="formData.releaseMode" :columns="2">
              <div v-for="item in releaseOps" :key="item.key">
                <a-radio :value="item.key">
                  {{ item.value }}
                </a-radio>
              </div>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="PM Comments"
            :label-col="{ span: 22 }"
            :wrapper-col="{ span: 22 }"
            :colon="false"
            v-if="isRm && formData.pmReason"
          >
            {{ formData.pmReason }}
          </a-form-item>
        </a-col>
        <a-col v-auth="['system:assignment:releaseEmailisSend']" :span="24">
          <a-form-item
            label="Release 邮件是否发送"
            name="releaseEmailSendMode"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 10 }"
            :colon="false"
          >
          <a-select v-model:value="formData.releaseEmailSendMode" placeholder="请选择">
            <a-select-option key="1" :value="1">是</a-select-option>
            <a-select-option key="2" :value="0">否</a-select-option>
          </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24" style="margin-bottom: 15px">
          <date-pickerselect v-auth="['system:assignment:releaseEmail']" ref="releasetimeref"  @toreleasetime="toreleasetime($event)" :actualDate="formData.actualDate" label="Release邮件发送时间" :isrequired="isrequired"></date-pickerselect>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Comments"
            name="applyReason"
            :label-col="{ span: 22 }"
            :wrapper-col="{ span: 22 }"
            :colon="false"
          >
            <a-textarea
              v-model:value="formData.applyReason"
              placeholder="请输入"
              :rows="3"
              showCount
              :maxlength="65"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <ModalBox v-model="modelValue" :ok="handleOk" :cancel="handleCancel" :content="content" :showIconCom="showIconCom" />
  <div ref="estimateRef">
  <a-modal
      :getContainer="() => $refs.estimateRef"
      v-model:visible="releaseVisible"
      width="40.6vw"
      title="提示"
      ok-text="确定"
      cancel-text="取消"
      @ok="releasehandleOk"
      @cancel="releasehandleCancel"
      centered
  >
    <div class="mb12">
      <p style="color: #5E5E5E"><ExclamationCircleOutlined style="color: #FDCD46;margin-right: 0.5vw"/>请确认是否执行Release操作？注意！如执行操作以下调令将终止：</p>
      <div class="assignment-infos">
        <div v-for="(item, index) in releaseData" :key="index">
          <div class="assignment">
          Assignment Term{{index + 1}}：<span class="info">{{item.startDate + " ~ " + item.endDate }}</span>
          Hours Per Day：<span class="info">{{item.hoursPerDay}}小时</span>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  </div>
</template>

<script>

import {computed, defineComponent, ref, watch} from "vue";
import moment from "moment";
import {useGlobalPropertyHook} from "@/hooks/common";
import DatePickerselect from '../components/DatePickerselect.vue'
import ModalBox from '@/components/ModalBox'
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "EstimateConfirm",
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        assignmentId: "",
        onBoardDate: null,
        actualDate: "",
        applyReason: "",
        originalDate: "",
        releaseMode: null,
        releaseEmailSendMode:1,
        staffAssignmentParts: [],
      }),
    },
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isRm: {
      type: Boolean,
      default: true,
    },
  },
  components:{DatePickerselect, ModalBox,ExclamationCircleOutlined},
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    let formData = computed(() => {
      let data={}
      data= props.formState
      data.releaseEmailSendMode=1
      return data
    });
    const codeTerm = computed(() => props.codeTermData);
    const formRef = ref();
    const validatorSpace = async (rule, value) => {
      if (value && value.replace(/\s/g, "").length === 0) {
        return Promise.reject("不能只输入空格");
      } else {
        return Promise.resolve();
      }
    };
    const forwardOver1Month = (value) => {
      return moment(value).subtract(30,'days').format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
    }
    const formRules = {
      actualDate: [
        {
          required: true,
          validator:async (rule, value) => {
            if(!value) {
              return Promise.reject("Please input Actual Date");
            }
            switch(formData.value.releaseMode) {
              case "1":
              case "3":
                if(forwardOver1Month(value)) {
                  return Promise.reject('当前 Release 方式请选择距当前时间30天之内的日期');
                } else {
                  return Promise.resolve();
                }
              case "6":
                if(moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
                  return Promise.reject('当前 Release 方式无法选择当天及之前的日期');
                } else {
                  return Promise.resolve();
                }
              default:
                return Promise.resolve();
            }
          },
          trigger: "change",
          type: "date"
        }
      ],
      releaseEmailSendMode:[
        {
          required: true,
          message: "",
          trigger: "blur",
          type:"number"
        },
      ],
      releaseMode: [
        {
          required: true,
          message: "Please input Release Mode",
          trigger: "change",
        },
      ],
      applyReason: [
        {
          required: true,
          message: "Please input Release Reason",
          trigger: "blur",
        },
        {
          validator: validatorSpace,
          message: "不能只输入空格",
          trigger: "blur"
        }
      ],
    };
    const showModeRadio = computed(() => {
      if (props.formState.status == "1") {
        return true;
      } else {
        return false;
      }
    });

    const releaseOps = [
      {
        key: "1",
        value: "RM Interrupt（强制终止）",
      },
      {
        key: "2",
        value: "Normal Release（正常释放）",
      },
      {
        key: "3",
        value: "Sudden Release（突然释放）",
      },
    ];
    const { $api } = useGlobalPropertyHook();
    const isShowBtn = ref(false);

    const getIsShow = () => {
      const params = {
        onBoardDate: formData.value.onBoardDate,
        estimateDate: formData.value.estimateReleaseDate,
        projectStartDate: codeTerm.value.projectStartDate,
        projectCloseDate:codeTerm.value.projectCloseDate,
        actualDate: formData.value.actualDate,
      }
      const checkApi = route.name === 'expirationInformation'? 'pmNoCheckCodeAndAssignTerm' : 'checkCodeAndAssignTerm'
      $api[checkApi]([params]).then((res) => {
        if (res.data[0] === '1') {
          isShowBtn.value = false
        } else {
          isShowBtn.value = true
        }
      });
    }

    const disabledDate = (current) => {
      return current < moment(formData.value.originalOnboardDate) ||
        current < moment(formData.value.staffOnboardDate) ||
        current > moment(formData.value.staffTerminationDate) ||
        ((formData.value.releaseMode === "1" || formData.value.releaseMode === "3") && current.format("YYYY-MM-DD") > moment().add(30,'days').format("YYYY-MM-DD")) ||
        (formData.value.releaseMode === "6" && current.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD"))
    };

    watch(
      () => formData.value.releaseMode,
      (val) => {
        if(val) {
          if(formData.value.actualDate) {
              getIsShow()
          }
        }
      }
    )
    const actualDateChange = () => {
      getIsShow()
    }

    // watch(
    //   () => formData.value.estimateDate,
    //   (val) => {
    //     // const result = moment(val).unix() && moment(val).unix() > moment(originalDate.value).unix();
    //     if (
    //       moment(val).unix() &&
    //       moment(val).unix() > moment(originalEstimateDate).unix()
    //     ) {
    //       formData.value.releaseMode = 1;
    //     } else if (
    //       moment(val).unix() &&
    //       moment(val).unix() < moment(originalEstimateDate).unix()
    //     ) {
    //       formData.value.releaseMode = 3;
    //     }
    //   }
    // );

    // const disableEstimateDate = computed(() => {
    //   if (props.formState.status == "1") {
    //     return true;
    //   }
    //   return false;
    // });

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const toreleasetime=(e)=>{
      // console.log('e>',e);
      // console.log('time', `${e.value.releasetime} ${e.value.selecttime}`);
      formData.value.releaseSendmailDate=`${e.value.releasetime} ${e.value.selecttime}`
      // console.log(formData.value);
    }
    const isrequired=ref(true)
    watch(
      ()=>formData.value.releaseEmailSendMode,
      (val)=>{
        isrequired.value=val===1?true:false
      }
    )
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      ctx.emit("submit-modal",formData.value);
    }
    const handleCancel = () => {
      formData.value.releaseEmailSendMode=0
    }
    const submitModal = () => {
      formRef.value.validate().then(() => {
        let i = formData.value.staffAssignmentParts.length
        if(i > 0) {
          if(moment(formData.value.actualDate).isAfter(formData.value.staffAssignmentParts[i-1].endDate) || moment(formData.value.actualDate).isSame(formData.value.staffAssignmentParts[i-1].endDate)) {
            submitFunc()
          } else {
            releaseData.value = formData.value.staffAssignmentParts.filter(item => moment(item.endDate).isAfter(formData.value.actualDate)).map(item => {
              return {
                id: item.id,
                startDate: item.startDate,
                endDate: item.endDate,
                hoursPerDay: item.hoursPerDay
              }
            })
            if(moment(releaseData.value[0].startDate).isBefore(formData.value.actualDate) || moment(releaseData.value[0].startDate).isSame(formData.value.actualDate)) {
              releaseData.value[0].startDate = moment(formData.value.actualDate).add(1,'days').format("YYYY-MM-DD")
            }
            releaseVisible.value = true
          }
        } else {
          submitFunc()
        }
      });
    };
    const submitFunc = () => {
      let arr=[]
      arr.push(formData.value.id)
      const checkApi = route.name === 'expirationInformation'? 'pmNoCheckReleaseEmailSendStatus' : 'checkReleaseEmailSendStatus'
      $api[checkApi](arr).then(res => {
        if (res?.data.length>0&&formData.value.releaseEmailSendMode===1) {
          content.value = '该调令已触发过邮件提醒，是否根据新 Release 邮件发送时间再次发送？'
          showIconCom.value = 'ExclamationCircleOutlined'
          releaseVisible.value = false
          modelValue.value = true
        }else{
          releaseVisible.value = false
          ctx.emit("submit-modal",formData.value);
          // formRef.value.resetFields();
        }
      })
    }
    const releaseVisible = ref(false)
    const releaseData = ref([])
    const releasehandleOk = () => {
      submitFunc()
    }
    const releasehandleCancel = () => {
      releaseVisible.value = false
    }

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const route = useRoute();

    watch(
        () => props.visible,
        (val) => {
          if(val){
            getIsShow();
          }
        }
    )

    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      formData,
      formRef,
      formRules,
      releaseOps,
      // disableEstimateDate,
      showModeRadio,
      getRefsAff,
      closeModal,
      submitModal,
      disabledDate,
      isShowBtn,
      getIsShow,
      codeTerm,
      toreleasetime,
      isrequired,
      modelValue,
      content,
      showIconCom,
      handleOk,
      handleCancel,
      releaseVisible,
      releasehandleOk,
      releasehandleCancel,
      releaseData,
      submitFunc,
      actualDateChange,
    };
  },
});
</script>

<style lang="less">
.estimateReleaseConfirm {
  //width: 50vw !important;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    text-align: right;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin: 10px 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
  }
  .ant-modal-footer button + button {
    margin-left: 10px !important;
  }
  .ant-calendar-picker-input {
    border: 1px solid #cccccc !important;
  }

  .ant-select-arrow {
    color: #cccccc;
  }
  .ant-select-selector{
    border: 1px solid #cccccc !important;
  }
  .ant-input{
    border: 1px solid #cccccc !important;
  }
}
</style>
<style lang="less" scoped>
.alert-p{
  margin: 3px 0;
  &.big{
    margin-bottom:10px;
    font-size: 16px;
  }
}
.isShow {
  margin-bottom: 0px
}
.colColor {
  background-color:#F7FAFC;
  :deep(.ant-form-item) {
    margin-bottom: 0;
  }
}
.labelColor {
  :deep(.ant-form-item-label > label){
    color: #999999;
  }
  .status-icon {
    //font-size: 1.4vw;
    margin-right: 0.2vw;
  }
}
.assignment-infos {
  background: #F7FAFC;
  padding: 1vw;
  .assignment {
    margin-bottom: 0.25vw;
  }
  .info {
    margin-right: 2vw;
    color: #999999;
  }
}
</style>
