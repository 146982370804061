<template>
  <!--  返回不需要权限：有菜单即有返回 v-auth="['system:assignmentDetails:back']"-->
  <div
    class="backE"
    v-if="!isTodoStatus"
  >
    <span class="goback underline" @click="goBack">
      <LeftOutlined class="icon" style="" />
      <span class="back">返回汇总列表</span>
    </span>
  </div>
  <div
      class="backE"
      v-if="isTodoStatus"
  >
    <span class="link underline" @click="goTodoBack">
      <LeftOutlined class="icon" style="" />
      <span class="back">返回待办列表</span>
    </span>
  </div>
  <section class="app-container">
    <Filter @search="handleSearch" :allDescOps="allDescOps" @getAllDescOps="getAllDescOps"></Filter>
    <!--    <AssignmentDetails ref="assDetailsRef"></AssignmentDetails>-->
    <component :is="componentName" ref="assDetailsRef" @goTodoBack="goTodoBack"/>
  </section>
</template>
<script>
import { computed, defineComponent, nextTick, ref, onMounted } from "vue";
import Filter from "./components/Filter.vue";
import AssignmentDetails from "./components/AssignmentDetails.vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PendingDetails from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/PendingDetails";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "assignmentDetails",
  components: {
    PendingDetails,
    AssignmentDetails,
    Filter,
    LeftOutlined,
  },
  emits: ['onBackTodoList'],
  setup(_, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const isPending = computed(
      () => store.state.page.assignmentCondition.isPending
    );
    const assDetailsRef = ref();
    const componentName = ref();
    const handleSearch = (tab, searchParams) => {
      if (!isTodoStatus.value && isPending.value) {
        componentName.value = "PendingDetails";
      } else {
        componentName.value = "AssignmentDetails";
      }
      // AssignmentDetails
      nextTick(() => {
        assDetailsRef.value.resetCurrent()
        assDetailsRef.value.searchClick(tab, searchParams);
      });
    };
    const goBack = () => {
      router.push("/assignment");
    };
    const goTodoBack = () => {
      emit("onBackTodoList")
    };
    const allDescOps = ref([])
    const { $api } = useGlobalPropertyHook();
    const getAllDescOps = async () => {
      const { data } = await $api.getDescItems()
      allDescOps.value = data.map((item) => {
        return {
          label: item,
          value: item,
        }
      })
    }
    onMounted(() => {
      getAllDescOps()
    })

    return {
      route,
      assDetailsRef,
      componentName,
      handleSearch,
      goBack,
      goTodoBack,
      isTodoStatus,
      isPending,
      allDescOps,
      getAllDescOps,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.backE + .app-container {
  height: calc(100% - 46px);
}

.backE {
  margin: 10px 30px 10px 20px;
  display: flex;
  justify-content: flex-end;

  .icon {
    font-size: 15px;
    cursor: pointer;
  }

  .back {
    font-size: 16px;
    margin-left: 3px;
    cursor: pointer;
  }
}
</style>
