<template>
  <div ref="editTimeModalRef">
    <a-modal
        :getContainer="()=>$refs.editTimeModalRef"
        :visible="visible"
        @cancel="closeModal"
        class="EditTimeModal"
        :zIndex="99"
        width="60.6vw"
        centered
    >
      <template #title>
        <strong class="modal-title">
          {{ title ? title : "Assignment 时间确定" }}
        </strong>
      </template>
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
          <a-button @click="submitModal" type="primary" class="applyBtn">
            确定
          </a-button>
        </div>
      </template>
      <a-form
          :model="formData"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          labelAlign="left"
          layout="vertical"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
                label="Code 有效期："
                name=""
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 20 }"
                :colon="false"
                class="labelColor"
            >
              {{
                codeTerm.projectStartDate +
                " ~ " +
                codeTerm.projectCloseDate
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
                label="Assignment Term："
                name="originalOnboardDate"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 20 }"
                :colon="false"
                class="labelColor"
            >
              {{
                formData.originalOnboardDate +
                " ~ " +
                formData.originalActualDate
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Hours per Day：" name="hoursPerDay" class="labelColor">
              {{formData.hoursPerDay}}
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label=" ">
              <div>
                <a-button type="primary" @click="segmentedEditingClick" v-if="isSegmentedEditing">分段编辑</a-button>
                <a-button type="primary" @click="cancelSegmentedEditingClick" v-else>取消分段编辑</a-button>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="isSegmentedEditing">
            <a-form-item
                label="修改 Assignment Term"
                name="assignmentTerm"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 24 }"
                :colon="false"
                :class="isShowBtn === true ? 'isShow' : ''"
            >
              <!--            <a-range-picker v-model:value="formData.assignmentTerm" />-->
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @change="onBoardDateChange"
                  v-model:value="formData.onBoardDate"
                  :disabledDate="onBoardDisabledDate"
                  style="width: 45%;"
                  :disabled="assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8'"
                  :allowClear="false"
              />
              <span> ~ </span>
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @change="actualDateChange"
                  v-model:value="formData.actualDate"
                  :disabledDate="estimateDisabledDate"
                  style="width: 45%;"
                  :allowClear="false"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="isSegmentedEditing" :wrapper-col="{ span: 12 }">
            <a-form-item label="修改Hours Per Day" name="newHoursPerDay" class="labelColor">
              <a-input
                  v-model:value="formData.newHoursPerDay"
                  placeholder="请输入"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!isSegmentedEditing">
            <a-form :model="segmentedData" ref="segmenteFormRef" class="segmentedtaleclass" :rules="segmentedFormRules">
              <a-table
                  :columns="columns"
                  :data-source="segmentedData"
                  :pagination="false"
                  class="segmentedClass"
                  row-key="id"
                  :scroll="{
                  x: 450,
                  scrollToFirstRowOnChange: true,
                }"
              >
                <template #startDate="{ index, text, record }">
                  <a-form-item :name="[index,'startDate']"
                               :rules="[
                               {
                                  validator: startValidatorSpace,
                                  startDateIndex: index,
                                  trigger: 'change',
                               }
                               ]"
                  >
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].startDate = val)"
                        style="width: 92%"
                        @change="startDateChange(index,record)"
                        :disabled="index===0? (assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8') : false"
                        :disabled-date="current => segmenteOnBoardDisabledDate(current, record,index)"
                    />
                    <span class="tilde"> ~ </span>
                  </a-form-item>
                </template>
                <template #endDate="{ index, text, record }">
                  <a-form-item :name="[index,'endDate']"
                               :rules="[
                               {
                                  validator: endValidatorSpace,
                                  endDateIndex: index,
                                  trigger: 'change',
                               }]"
                  >
                    <a-date-picker
                        :value="text"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].endDate = val)"
                        style="width: 96%"
                        @change="endDateChange(index,record)"
                        :disabled-date="current => segmenteEstimateReleaseDate(current, record, index)"
                    />
                  </a-form-item>
                </template>

                <template #hoursPerDay="{ index, text }">
                  <a-form-item :name="[index, 'hoursPerDay']"
                               :rules="[
                                 {
                                  required: false,
                                  pattern: /^[0-7]\.[0-9]$|^[0-8]$/,
                                  message: 'Please input the correct format Hours Per Day (>=0&<=8)',
                                  trigger: 'blur',
                                  },
                                  {
                                  validator: hoursValidatorSpace,
                                  hoursIndex: index,
                                  trigger: 'blur',
                               }
                             ]"
                  >
                    <a-input
                        style="width: 96%"
                        :value="text"
                        @update:value="(val) => (segmentedData[index].hoursPerDay = val)"
                        placeholder="请输入"
                        @change="hoursPerDayChange"
                    />
                  </a-form-item>
                </template>

                <template #action="{ index,record }">
                  <a-form-item :name="[index,'action']">
                  <div v-if="index === segmentedData.length - 1" style="font-size: 1vw">
                  <span @click="segmentedAdd(record)">
                    <PlusOutlined style="color: #3182CE;cursor: pointer;"/>
                  </span>
                    <a-divider type="vertical" style="background-color: #EBEBEB" />
                    <span @click="segmentedDel(index,record)" :class="segmentedData.length > 2 ? 'operationLink underline' : 'operationLink disabled underline'">
                    <DeleteOutlined :style="`color: ${ segmentedData.length > 2 ? '#DC5E4F' : '#999999'};cursor: pointer`"/>
                  </span>
                  </div>
                  </a-form-item>
                </template>
              </a-table>
            </a-form>
          </a-col>

          <a-col :span="12" style="margin-top: 0.6vw">
            <a-form-item
                label="Code 预计开出时间"
                name="projectCommitDate"
                :label-col="{ span: 20 }"
                :wrapper-col="{ span: 20 }"
                :colon="false"
                :class="isShowBtn === true ? 'isShow' : ''"
            >
              <a-date-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  v-model:value="formData.projectCommitDate"
                  :disabledDate="disabledCommitDate"
                  allow-clear
                  :placeholder="placeHolder"
                  :disabled="isDisableCommitDate"
                  style="width: 100%;"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-show="isShowBtn">
            <a-form-item
                label=""
                :colon="false"
                :wrapper-col="{ span: 15 }"
                style="margin-bottom: 0px"
            >
              <span style="color:#DC5E4F">Assignment Term与Code有效期冲突</span>
            </a-form-item>
          </a-col>
<!--          <a-col :span="24">-->
<!--            <a-form-item-->
<!--                label="Comments"-->
<!--                name="applyReason"-->
<!--                :label-col="{ span: 22 }"-->
<!--                :wrapper-col="{ span: 22 }"-->
<!--                :colon="false"-->
<!--            >-->
<!--              <a-textarea-->
<!--                  v-model:value="formData.applyReason"-->
<!--                  placeholder="请输入"-->
<!--                  :rows="5"-->
<!--                  showCount-->
<!--                  :maxlength="65"-->
<!--                  :auto-size="{ minRows: 3, maxRows: 3 }"-->
<!--              />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";

const formRules = {
  onBoardDate: [
    {
      required: true,
      message: "Please input Project Onboard Date",
      trigger: "change",
      type: "date",
    },
  ],
  actualDate: [
    {
      required: true,
      message: "Please input Actual Date",
      trigger: "change",
      type: "date",
    },
  ],
  projectCommitDate: [
    {
      required: true,
      message: "Please input Code预计开出时间",
      trigger: "change",
      type: "date",
    },
  ],
  // applyReason: [
  //   {
  //     required: true,
  //     message: "Please input Apply Reason",
  //     trigger: "blur",
  //   },
  // ],
  newHoursPerDay: [
    {
      required: true,
      message: "Please input Hours Per Day",
      trigger: "blur",
    },
    {
      required: false,
      pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
      message: "Please input the correct format Hours Per Day (>0 & <=8)",
      trigger: ["blur"],
    },
  ],
};

import {computed, defineComponent, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons-vue";

export default defineComponent({
  name: "EditTimeModal",
  components: {PlusOutlined, DeleteOutlined},
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        assignmentId: "",
        onBoardDate: null,
        actualDate: "",
        applyReason: "",
        staffAssignmentParts: [],
      }),
    },
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isFormalCode: {
      type: Boolean,
      default: false,
    },
    orgInfo: Object,
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const codeTerm = computed(() => props.codeTermData);
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const isShowBtn = ref(false);

    const isTBDCode = computed(() => props.isFormalCode);
    const isDisableCommitDate = ref(false)
    const placeHolder = computed(() =>  isTBDCode.value === false ? "仅适用于TBD Code" : "请选择");
    const assignmentStatus = computed(() => props.orgInfo.status);
    const handleIsFormalCode = () => {
      if(isTBDCode.value === false) {
        isDisableCommitDate.value = true
        formRules.projectCommitDate[0].required = false
        formData.value.projectCommitDate = ''
      } else {
        isDisableCommitDate.value = false
        formRules.projectCommitDate[0].required = true
      }
    }

    const getIsShow = () => {
      const params = {
        onBoardDate: segmentedData.value.length>0? segmentedData.value[0].startDate :
            formData.value.onBoardDate === null ? formData.value.originalOnboardDate : formData.value.onBoardDate,
        estimateDate: segmentedData.value.length>0? segmentedData.value[segmentedData.value.length - 1].endDate :
            formData.value.estimateReleaseDate  === null ? formData.value.originalActualDate : formData.value.actualDate,
        projectStartDate: codeTerm.value.projectStartDate,
        projectCloseDate:codeTerm.value.projectCloseDate,
      }
      $api.checkCodeAndAssignTerm([params]).then((res) => {
        if (res.data[0] === '1') {
          isShowBtn.value = false
        } else {
          isShowBtn.value = true
        }
      });
    }

    const closeModal = () => {
      formRef.value.resetFields();
      if(segmentedData.value.length > 0) segmenteFormRef.value.resetFields();
      segmentedData.value = []
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        if(segmentedData.value.length === 0) {
          ctx.emit("submit-modal", segmentedData.value);
        } else {
          segmenteFormRef.value.validate().then(() => {
            ctx.emit("submit-modal", segmentedData.value);
          })
        }
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const onBoardDisabledDate = (current) => {
      return current > moment(formData.value.actualDate) ||
        current < moment(formData.value.staffOnboardDate) ||
        current > moment(formData.value.staffTerminationDate);
    };

    const estimateDisabledDate = (current) => {
      return current < moment(formData.value.onBoardDate) ||
        current < moment() ||
        current < moment(formData.value.staffOnboardDate) ||
        current > moment(formData.value.staffTerminationDate);
    };
    const disabledCommitDate = (current) => {
      if(moment().diff(formData.value.onBoardDate,'day') <= 0){
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))
            && (moment(current.format("YYYY-MM-DD")) >= moment(projectCommitStartDate.value))) return false
        return true;
      } else {
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))) return false
        return true;
      }
    }
    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getCommitDateRange = async () => {
      if(isTBDCode.value === true) {
        const params = {
          onboardDate: segmentedData.value.length>0? segmentedData.value[0].startDate :
              formData.value.onBoardDate === null ? formData.value.originalOnboardDate : formData.value.onBoardDate,
        }
        const { data } =await $api.getCommitDateRange(params)
        projectCommitEndDate.value = data.projectCommitEndDate
        projectCommitStartDate.value = data.projectCommitStartDate
      }
    }
    const onBoardDateChange = () => {
      getIsShow();
      getCommitDateRange();
      handleIsFormalCode();
      formData.value.projectCommitDate = ''
    }
    const actualDateChange = () => {
      getIsShow();
    }

    //分段编辑
    const segmenteFormRef = ref()
    const isSegmentedEditing = ref(true)
    const segmentedData = ref([])
    const columns = [
      {
        title: "修改分段Assignment Term",
        dataIndex: "startDate",
        ellipsis: true,
        width: `26%`,
        slots: { customRender: "startDate" },
      },
      {
        title: " ",
        dataIndex: "endDate",
        ellipsis: true,
        width: `24%`,
        slots: { customRender: "endDate" },
      },
      {
        title: "修改分段Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: `33%`,
        slots: { customRender: "hoursPerDay" },
      },
      {
        title: "",
        ellipsis: true,
        width: `17%`,
        slots: { customRender: "action" },
      },
    ];

    const segmentedFormRules = {
      startDate: [
        {
          required: true,
          message: 'Please input Assignment Term',
          trigger: 'change',
        }
      ],
      endDate: [
        {
          required: true,
          message: 'Please input Assignment Term',
          trigger: 'change',
        }
      ],
      hoursPerDay: [
        {
          required: true,
          message: 'Please input Hours Per Day',
          trigger: 'change',
        },
        {
          required: false,
          pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
          message: 'Please input the correct format Hours Per Day (>0 & <=8)',
          trigger: 'blur',
        },
      ],
    };

    const segmentedAdd = () => {
      if(segmentedData.value[segmentedData.value.length-1].endDate) {
        console.log(111)
        let newRow = {
          hoursPerDay: undefined,
          startDate: moment(segmentedData.value[segmentedData.value.length-1].endDate).add(1, 'days').format('YYYY-MM-DD'),
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      } else {
        let newRow = {
          hoursPerDay: undefined,
          startDate: undefined,
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      }
    }
    const segmentedDel = (index) => {
      segmentedData.value.splice(index,1)
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.newHoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      formData.value.assignmentTerm[1] = segmentedData.value[index-1].endDate
    }

    const segmentedEditingClick = () => {
      isSegmentedEditing.value = false
      if(segmentedData.value.length === 0){
        if(assignmentStatus.value === '5' || assignmentStatus.value === '6' || assignmentStatus.value === '7' || assignmentStatus.value === '8'){
          segmentedData.value = [
            {
              id:'',
              hoursPerDay: '',
              startDate: formData.value.onBoardDate,
              endDate: '',
              assignmentId: '',
            },
            {
              id:'',
              hoursPerDay: '',
              startDate: '',
              endDate: '',
              assignmentId: '',
            },
          ]
        } else {
          segmentedData.value = [
            {
              id:'',
              hoursPerDay: '',
              startDate: '',
              endDate: '',
              assignmentId: '',
            },
            {
              id:'',
              hoursPerDay: '',
              startDate: '',
              endDate: '',
              assignmentId: '',
            },
          ]
        }
      }
    }
    const cancelSegmentedEditingClick = () => {
      isSegmentedEditing.value = true
      segmentedData.value = []
    }
    const startDateChange = (index,record) => {
      console.log(index,record)
      if(index > 0 && record.startDate && !segmentedData.value[index - 1].endDate){
        segmentedData.value[index - 1].endDate = moment(record.startDate).subtract(1, 'days').format("YYYY-MM-DD")
      }
      if(segmentedData.value[0].startDate) {
        formData.value.assignmentTerm[0] = segmentedData.value[0].startDate
        if(segmentedData.value[segmentedData.value.length-1].endDate) {
          getIsShow();
          getCommitDateRange();
          handleIsFormalCode();
          formData.value.projectCommitDate = ''
        }
      }
    }

    const endDateChange = (index,record) => {
      if(index < segmentedData.value.length-1 && record.endDate) {
        segmentedData.value[index + 1].startDate = moment(record.endDate).add(1, 'days').format("YYYY-MM-DD")
      } else {
        formData.value.assignmentTerm[1] = segmentedData.value[segmentedData.value.length-1].endDate
        if(segmentedData.value[0].startDate) {
          getIsShow()
        }
      }
    }
    const hoursPerDayChange = () => {
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.newHoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      //formData.value.newHoursPerDay = segmentedData.value.map(item => item.hoursPerDay).reduce((prev,curr)=> (Number(prev)*10+Number(curr)*10)/10)
    }
    const segmenteOnBoardDisabledDate = (current,record,index) => {
      if(segmentedData.value[index].endDate) {
        return current > moment(record.endDate)
      }
    };
    const segmenteEstimateReleaseDate = (current,record,index) => {
      if(index === segmentedData.value.length - 1) {
        return current < moment()
      } else if(segmentedData.value[index].startDate) {
        return current < moment(record.startDate)
      }
    };

    const startValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.startDateIndex > 0 && segmentedData.value[rule.startDateIndex - 1].endDate) {
          let endDate = moment(segmentedData.value[rule.startDateIndex - 1].endDate).add(1, 'days')
          if (!moment(value).isSame(endDate)) {
            return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
          }
          // if(segmentedData.value[rule.startDateIndex].endDate) {
          //   if (!moment(value).isBefore(moment(segmentedData.value[rule.startDateIndex].endDate).add(1, 'days'))) {
          //     return Promise.reject("您所选择的时间不符合规定");
          //   } else {
          //     return Promise.resolve();
          //   }
          // }
        } else {
          return Promise.resolve();
        }
      }
    };
    const endValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.endDateIndex < segmentedData.value.length - 1) {
          if (segmentedData.value[rule.endDateIndex + 1].startDate) {
            let startDate = moment(segmentedData.value[rule.endDateIndex + 1].startDate).subtract(1, 'days')
            if (!moment(value).isSame(startDate)) {
              return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
            }
          } else {
            return Promise.resolve();
          }
        }
        if(segmentedData.value[rule.endDateIndex].startDate) {
          if (!moment(value).isAfter(moment(segmentedData.value[rule.endDateIndex].startDate).subtract(1, 'days'))) {
            return Promise.reject("您所选择的时间不符合规定");
          } else {
            return Promise.resolve();
          }
        }
      }
    };
    const hoursValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.hoursIndex > 0) {
          if (value === segmentedData.value[rule.hoursIndex - 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else if (rule.hoursIndex < segmentedData.value.length - 1) {
            if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
              return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
            }
          } else {
            return Promise.resolve();
          }
        } else {
          if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else {
            return Promise.resolve();
          }
        }
      }
    }

    watch(
        () => formData.value.staffAssignmentParts,
        (val) => {
          if(val) {
            if (val.length > 0) {
              isSegmentedEditing.value = false
              segmentedData.value = formData.value.staffAssignmentParts.map(item => {
                return {
                  id: item.id,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  hoursPerDay: item.hoursPerDay
                }
              })
            } else {
              isSegmentedEditing.value = true
              segmentedData.value = formData.value.staffAssignmentParts.map(item => {
                return {
                  id: item.id,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  hoursPerDay: item.hoursPerDay
                }
              })
            }
          }
        },
    )

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            console.log(props.formState)
            getIsShow();
            getCommitDateRange();
            handleIsFormalCode();
          }}
    )

    return {
      labelCol: { span: 22 },
      wrapperCol: { span: 23 },
      formData,
      formRef,
      formRules,
      getRefsAff,
      closeModal,
      submitModal,
      onBoardDisabledDate,
      estimateDisabledDate,
      isShowBtn,
      getIsShow,
      codeTerm,
      placeHolder,
      isDisableCommitDate,
      isTBDCode,
      handleIsFormalCode,
      disabledCommitDate,
      projectCommitEndDate,
      projectCommitStartDate,
      getCommitDateRange,
      onBoardDateChange,
      actualDateChange,
      assignmentStatus,
      isSegmentedEditing,
      segmentedData,
      columns,
      segmentedFormRules,
      segmentedAdd,
      segmentedDel,
      segmentedEditingClick,
      cancelSegmentedEditingClick,
      endDateChange,
      startDateChange,
      hoursPerDayChange,
      segmenteFormRef,
      segmenteOnBoardDisabledDate,
      segmenteEstimateReleaseDate,
      startValidatorSpace,
      endValidatorSpace,
      hoursValidatorSpace,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/aModal.less";
.EditTimeModal {
  //width: 50vw !important;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    text-align: right;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin: 10px 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
  }
  .ant-modal-footer button + button {
    margin-left: 10px !important;
  }
  .ant-calendar-picker-input {
    border: 1px solid #cccccc !important;
  }

  .ant-select-arrow {
    color: #cccccc;
  }
  .ant-select-selector{
    border: 1px solid #cccccc !important;
  }
  .ant-input{
    border: 1px solid #cccccc !important;
  }
  .segmentedClass {
    :deep(.ant-table .ant-table-thead > tr > th){
      padding-left: 0;
    }
    :deep(.ant-table .ant-table-tbody > tr > td) {
      //padding-left: 0;
      //padding-right: 0;
      padding: 1vw 0 0;
    }
    .tilde{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
      margin: 0 -0.16vw
    }
    :deep(.ant-form-item) {
      flex-direction: row;
    }
    :deep(.ant-form-item-explain) {
      font-size: 0.67vw;
    }
  }
  .segmentedtaleclass {
    padding-left: 0 !important;
    padding-right: 0 !important;

    :deep(.ant-form-item-has-error) {
      .ant-col {
        min-height: auto;
      }
    }
    :deep(.ant-table-row-cell-ellipsis .ant-table-column-title) {
      font-size: 0.791vw;
    }
  }
}
.isShow {
  margin-bottom: 0px;
}
</style>
