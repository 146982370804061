<template>
  <div ref="fcstDateModalRef" class="fcstDateModal">
    <a-modal
        :getContainer="()=>$refs.fcstDateModalRef"
        :visible="visible"
        @cancel="closeModal"
        width="30vw"
        centered
        title="提示"
    >
      <div class="titleTip">Assignment Term不能超出FCST Extension Assumption Date，请更新或清空FCST Extension Assumption Date: </div>
      <a-form layout='vertical' :model="formData" :rules="formRules" ref="formRef" class="fcstForm">
        <a-form-item name="extensionAssumptionDate" class="fcstLabel">
          <template v-slot:label>
            <span>FCST Extension Assumption Date</span>
            <span class="tipLabel">不会更新调令的结束时间</span>
          </template>
          <a-date-picker class="item" allow-clear type="date" placeholder="请选择" valueFormat="YYYY-MM-DD"
                         :inputReadOnly="true" :showToday="false" v-model:value="formData.extensionAssumptionDate"
                         :disabledDate="disabledFcstDate" style="width: 72%"/>
        </a-form-item>
        <a-form-item name="certainty" class="fcstLabel">
          <template v-slot:label>
            <span :class="formData.extensionAssumptionDate ? 'cerLabel' : ''">确定性</span>
          </template>
          <a-radio-group v-model:value="formData.certainty" :disabled="certaintyDisabled">
            <a-radio value="Firm">Firm</a-radio>
            <a-radio value="Proposal">Proposal</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">关闭</a-button>
          <a-button @click="submitModal" type="primary">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import moment from "moment/moment";

export default defineComponent({
  name: "FcstDateModal",
  components: {},
  props: {
    visible: Boolean,
    infos: {
      type: Object,
      default: () => {
        return {
          certainty: '',
          extensionAssumptionDate: '',
          terminationDate: '',
        }
      },
    },
  },
  emits: ["close-modal","submit-modal"],
  setup(props,{ emit }) {
    const formData = ref()
    const formRules = {
      certainty: [
        {
          required: false,
          message: "Please select 确定性",
          trigger: "change",
        },
      ],
      extensionAssumptionDate: [
        {
          required: false,
          trigger: "change",
          validator: async (rule, value) => {
            if(value && formData.value.onBoardDate && formData.value.actualDate) {
              const diffStart = moment(formData.value.onBoardDate).diff(moment(value), 'days') < 0;
              const diffEnd = moment(formData.value.actualDate).diff(moment(value), 'days') > 0;
              const diffDtate = diffStart && diffEnd;
              if (diffDtate) {
                return Promise.reject("该日期不能在Assignment Term范围内，请重新选择");
              } else {
                return Promise.resolve();
              }
            }
          },
        },
      ],
    }
    const formRef = ref()
    const closeModal = () => {
      emit("close-modal");
    }
    const submitModal = () => {
      formRef.value.validate().then(() => {
        emit("submit-modal", formData.value);
      })
    }

    const disabledFcstDate = (current) => {
      return current < moment(formData.value.actualDate) || current > moment(formData.value.terminationDate || null)
    };

    const certaintyDisabled = ref(true)
    watch(
        () => formData.value?.extensionAssumptionDate,
        (val) => {
          if(val) {
            certaintyDisabled.value = false
            formRules.certainty[0].required = false
          } else {
            certaintyDisabled.value = true
            formData.value.certainty = null
            formRules.certainty[0].required = false
          }
        }
    )
    watch(
        () => props.visible,
        (val) => {
          if(val){
            formData.value = JSON.parse(JSON.stringify(props.infos))
          }
        }
    )


    return {
      closeModal,
      submitModal,
      formData,
      formRules,
      formRef,
      disabledFcstDate,
      certaintyDisabled,
    }
  }
})
</script>

<style scoped lang="less">
.fcstDateModal {
  .cerLabel {
    &::after {
      display: inline-block;
      margin-left: 0.20833vw;
      color: #ff4d4f;
      font-size: 0.72917vw;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  .titleTip {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #000000A8;
  }
  .fcstLabel {
    font-size: 0.9vw;
    .tipLabel {
      color: #3182ce;
      margin-left: 0.5vw;
      font-size: 12px;
    }
  }
  .fcstForm {
    margin-top: 20px;
    :deep(.ant-form-item-label > label) {
      font-size: 16px;
    }
  }
}
</style>
