<template>
  <div ref="uploadAnnex">
    <a-modal
        :getContainer="()=>$refs.uploadAnnex"
        :title="titleName"
        placement="right"
        :width="560"
        :visible="drawerVisible"
        @cancel="onClose"
        @ok="submitFiles"
        cancelText="取消"
        okText="确定"
        centered
    >
      <a-upload-dragger
          v-model:fileList="fileList"
          name="file"
          :multiple="true"
          accept=".png,.jpg,.jpeg,.bmp,.pdf"
          :beforeUpload="handleBeforeUpload"
      >
        <!-- :customRequest="handleCustomRequest"-->
        <p class="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
        <div class="ant-upload-text-alert">
          <p>1、仅支持上传jpg、jpeg、png、pdf、bmp格式文件</p>
          <div v-if="!isReplace">
            <p>2、单个文件大小最大不能超过5M</p>
            <p>3、单次最多只允许上传4个文件</p>
            <p v-show="isSecondUpload">4、总文件数不能超过8个</p>
          </div>
          <p v-if="isReplace">2、文件大小最大不能超过5M，且只能上传一个进行替换</p>
        </div>
      </a-upload-dragger>

<!--      <div class="annex-alert-info">
        <a-alert
            message="提示:"
            type="warning"
            showIcon
        >
          <template #icon><smile-outlined /></template>
          <template #description>
            <p>1、仅支持上传jpg、jpeg、png、pdf、bmp格式文件</p>
            <template v-if="!isReplace">
              <p>2、单个文件大小最大不能超过5M</p>
              <p>3、单次最多只允许上传4个文件</p>
              <p v-show="isSecondUpload">4、总文件数不能超过8个</p>
            </template>
            <template v-if="isReplace">
              <p>2、文件大小最大不能超过5M，且只能上传一个进行替换</p>
            </template>
          </template>
        </a-alert>
      </div>-->
<!--      <div class="operation-btn">
        <a-button @click="onClose">取消</a-button>
        <a-button @click="submitFiles" type="primary">确定</a-button>
      </div>-->
    </a-modal>
<!--    <a-drawer
        :title="titleName"
        placement="right"
        width="520"
        :closable="false"
        :visible="drawerVisible"
        @close="onClose"
    >
      <a-upload-dragger
          v-model:fileList="fileList"
          name="file"
          :multiple="true"
          accept=".png,.jpg,.jpeg,.bmp,.pdf"
          :beforeUpload="handleBeforeUpload"
      >
        &lt;!&ndash; :customRequest="handleCustomRequest"&ndash;&gt;
        <p class="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p class="ant-upload-text">上传文件</p>
      </a-upload-dragger>

      <div class="annex-alert-info">
        <a-alert
            message="提示:"
            type="warning"
            showIcon
        >
          <template #icon><smile-outlined /></template>
          <template #description>
            <p>1、仅支持上传jpg、jpeg、png、pdf、bmp格式文件</p>
            <template v-if="!isReplace">
              <p>2、单个文件大小最大不能超过5M</p>
              <p>3、单次最多只允许上传4个文件</p>
              <p v-show="isSecondUpload">4、总文件数不能超过8个</p>
            </template>
            <template v-if="isReplace">
              <p>2、文件大小最大不能超过5M，且只能上传一个进行替换</p>
            </template>
          </template>
        </a-alert>
      </div>
      <div class="operation-btn">
        <a-button @click="onClose">取消</a-button>
        <a-button @click="submitFiles" type="primary">确定</a-button>
      </div>
    </a-drawer>-->
  </div>
</template>

<script>
import { defineComponent, ref } from "@vue/runtime-core";
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: "uploadAnnex",
  props: {
    drawerVisible: Boolean,
    titleName: String,
    isReplace: {
      type: Boolean,
      default: false
    },
    isSecondUpload: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UploadOutlined,
  },
  emits: ["onCloseDrawer","onUpdateFileList"],
  setup(props,{emit}) {
    const onClose = () => {
      emit("onCloseDrawer")
    };
    const fileList = ref([])
    let messageTimer = null;
    const handleBeforeUpload = (file, list) => {
      return new Promise(() => {
        for(let i = 0; i < fileList.value.length; i++) {
          if(fileList.value[i]) {
            if(fileList.value[i].name === file.name) {
              // settimeout 防止弹出多个消息提示
              clearTimeout(messageTimer);
              messageTimer = setTimeout(() => {
                message.error("请不要选择重复的文件！");
              });
              return false;
            }
          }
        }

        const num = props.isReplace ? 1 : 4
        const info = props.isReplace ? "只能替换一个文件。" : "单次上传附件数量上限为4个，请合理安排分批上传。"
        if (list.length > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }
        if (fileList.value.length + 1 > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }

        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("当前上传附件中，有超出文件大小限制5MB，请检查后再次上传!");
          });
          return false;
        }

        if( !file.type.includes("image/png") &&
            !file.type.includes("image/jpg") &&
            !file.type.includes("image/jpeg") &&
            !file.type.includes("image/bmp") &&
            !file.type.includes("pdf")
        ) {
          fileList.value = [];
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("只可以上传jpg、jpeg、png、pdf、bmp格式的文件！");
          });
          return false;
        }
        fileList.value = [...fileList.value, file];
        return false;
      })
    };
    const resetFileList = () => {
      fileList.value = []
    }
    const submitFiles = () => {
      emit("onUpdateFileList", fileList.value)
    }
    return {
      onClose,
      fileList,
      handleBeforeUpload,
      submitFiles,
      resetFileList,
    }
  }
})
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 35px;
}
.ant-upload-text {
  color: #333 !important;
  font-weight: 400;
}
.ant-upload-text-alert {
  color: #999;
}
.operation-btn {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100px;
  text-align: right;

  .ant-btn {
    margin-left: 10px;
    margin-bottom: 20px;
  }
}
.annex-alert-info {
  margin-top: 20px;
  .ant-alert-description {
    p {
      margin-bottom: 0;
    }
  }
  :deep(.ant-alert-with-description .ant-alert-icon){
    margin-top: -3px;
  }
}
:deep(.ant-upload-list-item-card-actions .anticon){
  color: #DC5E4F;
}
</style>