<template>
  <section class="org-list">
    <a-table ref="todoListRef" :columns="columns" :data-source="todoList" :rowKey="(record) => record.id"
      :customRow="tabId === '1' ? matchCustomRowFunc : tabId === '2' ? assignmentCustomRowFunc : WelcomeLetterCustomRowFunc"
      :scroll="{
        x: 1200,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }" :row-selection="(tabId==='2'||tabId==='3')?rowSelection:false" :pagination="pagination" @change="handleTableChange">
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  nextTick,
  toRefs,
  watch,
  onUnmounted,
  computed
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  todoListAssStatus
} from "@/utils/formatStatus";
import { formatTableCol, useTableHeight } from "@/utils/common";
import { isshow } from '@/utils/isshow.js'
import {useRoute} from "vue-router";
import store from "@/store";

export default defineComponent({
  name: "TodoList",
  components: {},
  props: {
    tabId: String,
  },
  emits: ['onSwitchDetail', 'changerowlist', 'WelcomeLetteronDetail'],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute();
    const todoListRef = ref();
    const columns = props.tabId === '2' ? [
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        customRender: formatTableCol(todoListAssStatus),
        ellipsis: true,
        width: 150,
        sorter: true,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Candidate Name",
        dataIndex: "candidateName",
        key: "candidateName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
        sorter: true,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 150,
        sorter: true,
      },
      {
        title: "Project Onboard Date",
        dataIndex: "onBoardDate",
        key: "onBoardDate",
        ellipsis: true,
        width: 180,
        sorter: true,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimateReleaseDate",
        key: "estimateReleaseDate",
        ellipsis: true,
        width: 180,
        sorter: true,
      },
    ] : props.tabId === '1' ?
      [
        {
          title: "需求编号",
          dataIndex: "demandNumber",
          key: "demandNumber",
          ellipsis: true,
          width: 150,
        },
        {
          title: "需求名称",
          dataIndex: "demandName",
          key: "demandName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          key: "projectName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "客户名称",
          dataIndex: "customerName",
          key: "customerName",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
        {
          title: "详细客户名称",
          dataIndex: "customerNameDetail",
          key: "customerNameDetail",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
        {
          title: "员工姓名",
          dataIndex: "fullName",
          key: "fullName",
          ellipsis: true,
          width: 150,
        },

        {
          title: "GGID",
          dataIndex: "ggid",
          key: "ggid",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Team",
          dataIndex: "orgThreeTwo",
          key: "orgThreeTwo",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
        {
          title: "Offering",
          dataIndex: "orgFourOneOne",
          key: "orgFourOneOne",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
        {
          title: "状态",
          dataIndex: "preStatus",
          key: "preStatus",
          ellipsis: true,
          sorter: true,
          width: 150,
        }
      ] :
      [
        {
          title: "Team",
          dataIndex: "orgThreeTwo",
          key: "orgThreeTwo",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Offering",
          dataIndex: "orgFourOneOne",
          key: "orgFourOneOne",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "GGID",
          dataIndex: "ggid",
          key: "ggid",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Capgemini Start Date",
          dataIndex: "onboardDate",
          key: "onboardDate",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Role",
          dataIndex: "position",
          key: "position",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
        {
          title: "Welcome Letter发送时间",
          dataIndex: "sendmailDate",
          key: "sendmailDate",
          ellipsis: true,
          width: 200,
          sorter: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          ellipsis: true,
          width: 150,
          sorter: true,
        },
      ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40','100'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        // doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1
        pagination.pageSize = size
      },
    });

    const sort = reactive({
      order: '',
      field: ''
    });

    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = ""
        sorter.field = ""
      }
      Object.assign(sort, sorter)
      doSearch();
    };

    const todoList = ref([]);
    let searchCondition = {};
    const tableHeight = ref(0)

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight() - 24;
      });
    };
    const doSearch = (searchParams = searchCondition) => {
      const listUrl = props.tabId === '1' ? `/todolists/prematchings` : props.tabId === '2' ? `/todolists/assignments` : `/todolists/welcomeLetter`
      const params = {
        ...searchParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        sort,
        dashboardStatus:sessionStorage.getItem('dashboardStatus')?sessionStorage.getItem('dashboardStatus')-0:'',
      };
      $api
        .getNewTodoAssignmentList(listUrl, params)
        .then((res) => {
          pagination.current = res.data?.pageIndex + 1;
          pagination.pageSize = res.data?.pageSize;
          pagination.total = res.data?.total;
          todoList.value = res.data.resource;
          if(props.tabId === '2' && isRM.value) {
            const dom = document.getElementsByClassName("ant-checkbox-inner")[0]
            const dom1 = document.querySelector("input[type='checkbox'].ant-checkbox-input")
            if(state.selectedRowKeys.length > 0) {
              let status = state.selectedRows[0].status
              todoList.value = todoList.value.map((item) => {
                return {
                  ...item,
                  disabled: ['2','3'].includes(status)?(item.status === '6'||item.status === '7') : (item.status === '2'||item.status === '3'),
                }
              })
              if(dom && todoList.value.every(item => item.disabled === true)) {
                dom.style.backgroundColor = '#d9d9d9'
                dom1.style.cursor = 'not-allowed';
              } else {
                dom.style.backgroundColor = null
                dom1.style.cursor = null;
              }
            } else {
              todoList.value = todoList.value.map((item) => {
                return {
                  ...item,
                  disabled: false,
                }
              })
              let onBoard = todoList.value.every(item => ['2','3'].includes(item.status))
              let estimate = todoList.value.every(item => ['6','7'].includes(item.status))
              if(dom && !onBoard && !estimate) {
                dom.style.backgroundColor = '#d9d9d9'
                dom1.style.cursor = 'not-allowed';
              } else {
                dom.style.backgroundColor = null
                dom1.style.cursor = null;
              }
            }
          }
        });
    };

    const matchCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          const query = {
            preMatchingId: record.preMatchingId,
            source: 'MatchingList'// 与组件名称对应
          };

          emit('onSwitchDetail', query)
        },
      };
    };
    const WelcomeLetterCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          if(isshow('system:staffInfo:todolistWelcomeletter'))
          {
            emit('WelcomeLetteronDetail', record)
          }
        },
      };
    }

    const assignmentCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          let status;
          switch (record.status) {
            case "1":
            case "2":
            case "3":
            case "4":
              status = "1";
              break;
            case "5":
            case "6":
            case "7":
            case "8":
              status = "2";
              break;
            case "9":
            case "13":
              status = "3";
              break;
            case "14":
              status = "3";
              break;
          }
          const query = {
            assignmentData: record.engagementCode,
            assignmentId: record.id,
            status,
            source: 'AssignmentDetails'// 与组件名称对应
          };
          emit('onSwitchDetail', query)
        },
      };
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };
    watch(
        () => route.query,
        (val) => {
          let record = sessionStorage.getItem('itemData')?JSON.parse(sessionStorage.getItem('itemData')):''
          if(val.flag==='1'&&record){
            const query = {
              preMatchingId: record.preMatchingId,
              source: 'MatchingList'// 与组件名称对应
            };
            emit('onSwitchDetail', query)
          }else if(val.flag==='2'&&record){
            let status;
            switch (record.status) {
              case "1":
              case "2":
              case "3":
              case "4":
                status = "1";
                break;
              case "5":
              case "6":
              case "7":
              case "8":
                status = "2";
                break;
              case "9":
              case "13":
                status = "3";
                break;
            }
            const query = {
              assignmentData: record.engagementCode,
              assignmentId: record.id,
              status,
              source: 'AssignmentDetails'// 与组件名称对应
            };
            emit('onSwitchDetail', query)
          }else if(val.flag==='3'&&record){
            if(isshow('system:staffInfo:todolistWelcomeletter'))
            {
              emit('WelcomeLetteronDetail', record)
            }
          }
        },
        {immediate: true}
    )
    onMounted(() => {
      doSearch();
      getSize();
    });

    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
    });
    window.addEventListener("resize", getSize);
    const rowSelection = {
      selectedRowKeys: toRefs(state).selectedRowKeys,
      selectedRows: toRefs(state).selectedRows,
      onSelect: (record, selected) => {
        if (selected) {
          state.selectedRows.push(record)
        } else {
          const delIndex = state.selectedRows.findIndex((val) => {
            return val.id === record.id
          })
          state.selectedRows.splice(delIndex, 1)
        }
        emit('changerowlist', state.selectedRows)
      },
      onSelectAll: (selected,selectedRowKeys,selectedchange) => {
        //const dom = document.getElementsByClassName("ant-checkbox-inner")[0]
        if (selected) {
          let onBoard = todoList.value.every(item => ['2', '3'].includes(item.status))
          let estimate = todoList.value.every(item => ['6', '7'].includes(item.status))
          if (props.tabId === '2' && isRM.value && state.selectedRows.length === 0&&!onBoard && !estimate) {
            state.selectedRowKeys = []
            //state.selectedRows = []
          } else {
            selectedRowKeys.forEach(item => {
              if (!state.selectedRows.some(jtem => jtem.id === item.id)) {
                state.selectedRows.push(item)
              }
            })
          }
        } else {
          state.selectedRows = state.selectedRows.filter(item => !selectedchange.some(jtem => jtem.id === item.id))
        }
        emit('changerowlist', state.selectedRows)
      },
      onChange: (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys;
        if(props.tabId === '2' && isRM.value) {
          const dom = document.getElementsByClassName("ant-checkbox-inner")[0]
          const dom1 = document.querySelector("input[type='checkbox'].ant-checkbox-input")
          if(state.selectedRowKeys.length === 1) {
            let status = todoList.value.find((item) => item.id === state.selectedRowKeys[0]).status
            todoList.value = todoList.value.map((item) => {
              return {
                ...item,
                disabled: ['2','3'].includes(status)?(item.status === '6'||item.status === '7') : (item.status === '2'||item.status === '3'),
              }
            })
            dom.style.backgroundColor = null;
            dom1.style.cursor = null;
          } else if (state.selectedRowKeys.length === 0) {
            todoList.value = todoList.value.map((item) => {
              return {
                ...item,
                disabled: false,
              }
            })
            let onBoard = todoList.value.every(item => ['2','3'].includes(item.status))
            let estimate = todoList.value.every(item => ['6','7'].includes(item.status))
            if(dom && !onBoard && !estimate) {
              dom.style.backgroundColor = '#d9d9d9'
              dom1.style.cursor = 'not-allowed';
            } else {
              dom.style.backgroundColor = null
              dom1.style.cursor = null;
            }
          }
        }
      },
      getCheckboxProps: (record) => {
        return {
          disabled: record.disabled
        }
      },
    };

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const clearSelectedRows = () => {
      state.selectedRowKeys = []
      state.selectedRows = []
      emit('changerowlist', state.selectedRows)
    }

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      todoListRef,
      columns,
      pagination,
      todoList,
      searchClick,
      doSearch,
      handleTableChange,
      sort,
      matchCustomRowFunc,
      assignmentCustomRowFunc,
      WelcomeLetterCustomRowFunc,
      getSize,
      ...toRefs(state),
      tableHeight,
      rowSelection,
      clearSelectedRows,
      isRM,
    };
  },
});
</script>
<style scoped lang="less">
.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);
  margin-top: 12px;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    background: #f4f4f4;
  }
}
</style>
