<template>
<div>
  <div class="annex-upload-btn" v-show="isCanUpload" v-auth="['system:annexUpload:upload']">
    <span style="color: #ff0000">{{ annexRightMsg }}</span>
    <a-button @click="showDrawer" type="primary" style="margin-left: 10px">上传附件</a-button>
  </div>
  <div class="annex-table">
    <a-table
        :data-source="annexDataList"
        :columns="annexColumns"
        rowKey="id"
        :pagination="false">
      <template #operation="{ record }">
        <a-space class="flex-box">
          <!--  第一次上传  -->
          <template v-if="!isSecondUpload">
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">删除</span>
              </template>
              <a
                  danger
                  class="operationLinkdel icon-btn"
                  @click="delAnnexClick(record)"
                  v-show="isCanUpload"
                  v-auth="['system:annexUpload:delete']">
                <DeleteOutlined :style="{display:'block'}" />
              </a>
            </a-tooltip>
          </template>
<!--          <a-tooltip class="tooltip-list" placement="top" color="#fff">
            <template #title>
              <span class="font-color">上传</span>
            </template>
            <span v-if="!isPreview" class="operationLinklist icon-btn" @click="uploadClick()"
                  v-auth="['system:roleMaintain:preview']"><UploadOutlined :style="{display:'block'}" /></span>
          </a-tooltip>
          <a-tooltip class="tooltip-list" placement="top" color="#fff">
            <template #title>
              <span class="font-color">删除</span>
            </template>
            <span v-if="!isPreview" class="operationLinkdel icon-btn" @click="deleteClick(record)"
                  v-auth="['system:roleMaintain:preview']"><DeleteOutlined :style="{display:'block'}" /></span>
          </a-tooltip>-->
          <!--  第二次补传  -->
          <template v-if="isSecondUpload">
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">下载</span>
              </template>
              <a danger class="operationLinkdel icon-btn" @click="downloadAnnexClick(record)" v-auth="['system:annexUpload:download']">
                <DownloadOutlined :style="{display:'block'}" />
              </a>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">替换</span>
              </template>
              <a danger
                 class="operationLinkdel icon-btn"
                 @click="replaceAnnexClick(record)"
                 v-show="isCanUpload && roleId === record.fileUserId"
                 v-auth="['system:annexUpload:replace']">
                <UploadOutlined :style="{display:'block'}" />
              </a>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">删除</span>
              </template>
              <a danger
                 class="operationLinkdel icon-btn"
                 @click="delAnnexClick(record)"
                 v-show="isCanUpload && roleId === record.fileUserId"
                 v-auth="['system:annexUpload:delete']">
                <DeleteOutlined :style="{display:'block'}" />
              </a>
            </a-tooltip>
<!--            <a danger @click="downloadAnnexClick(record)" v-auth="['system:annexUpload:download']">下载</a>
            <a danger @click="replaceAnnexClick(record)"
               v-show="isCanUpload && roleId === record.fileUserId"
               v-auth="['system:annexUpload:replace']">替换</a>
            <a danger
               @click="delAnnexClick(record)"
               v-show="isCanUpload && roleId === record.fileUserId"
               v-auth="['system:annexUpload:delete']">删除</a>-->
          </template>

        </a-space>
      </template>
    </a-table>
  </div>
</div>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined
} from '@ant-design/icons-vue';
import { useStore } from "vuex";

export default defineComponent({
  name: "AnnexDataTable",
  components: {
    DownloadOutlined,
    DeleteOutlined,
    UploadOutlined,
  },
  props:{
    annexColumns: {
      type: Array,
      default:() => {
        return []
      }
    },
    annexDataList: {
      type: Array,
      default:() => {
        return []
      }
    },
    isSecondUpload: {
      type: Boolean,
      default: false
    },
    isCanUpload: {
      type: Boolean,
      default: true
    },
    annexRightMsg: {
      type: String,
      default: '',
    }
  },
  emits:["del-annexClick", "show-drawer", "download-annexClick", "replace-AnnexClick"],
  setup(_, { emit }) {
    const store = useStore();
    const roleId = ref(store.state.app.user.id)
    const showDrawer = () => {
      emit("show-drawer")
    }
    const downloadAnnexClick = (record) => {
      emit("download-annexClick",record)
    }
    const delAnnexClick = (record) => {
      emit("del-annexClick", record)
    }
    const replaceAnnexClick = (record) => {
      emit("replace-AnnexClick", record)
    }
    return {
      showDrawer,
      replaceAnnexClick,
      downloadAnnexClick,
      delAnnexClick,
      roleId
    }
  }
})
</script>

<style scoped lang="less">

:deep(.ant-empty) {
  display: block !important;
}
.annex-table {
  margin-top: 10px;
}
.annex-upload-btn {
  text-align: right;
  margin-top: -27px
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}
.font-color{
  color:#4d4d4d;
}
:deep(.anticon-delete){
  svg{
    color: #DC5E4F;
  }
}
</style>
