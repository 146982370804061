<template>
  <div ref="getRefsAff">
    <a-drawer :getContainer="() => $refs.getRefsAff" :visible="visible" @close="closeModal" width="83.3vw"
      class="fill-assignment" :zIndex="99" style="transform: translateX(0px)!important">
      <template #title>
        <strong class="modal-title">
          {{ title ? (isShowDemand? "请选择北森需求" : title) : "填写调令信息" }}
        </strong>
      </template>
     <div v-show="!isShowDemand">
      <!-- 当buttonType为PM填写时候是先选PM -->
      <template v-if="buttonType == 'PM填写'">
        <div style="padding:10px;padding-top:20px">
          <a-form ref="pmForm" :model="pmData" :rules="pmRules">
            <a-form-item name="selectPM" label="选择PM">
              <a-select :dropdownAlign="{ overflow: { adjustY: false } }" :dropdownStyle="{ maxHeight: '300px' }"
                style="width:300px" v-model:value="pmData.selectPM" show-search placeholder="选择PM" :show-arrow="false"
                :filter-option="filterOption" :options="pmOption">
              </a-select>
            </a-form-item>
          </a-form>
        </div>
        <div class="headerBtn bottom-btn">
          <a-button @click="sendPMButton(-1)">取消</a-button>
          <a-button type="primary" @click="sendPMButton(1)">下一步</a-button>
        </div>
      </template>
      <template v-else>
        <div v-show="formData.rejectReason">
          <a-form :model="formData" :rules="formRules" ref="formRef">
            <a-row>
              <a-col :span="24" class="red-text">
                <a-form-item label="Reject Reason:" name="rejectReason">
                  <span style="margin-top: 3px; display: inline-block;">
                    {{ formData.rejectReason }}
                  </span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <a-form :layout="'vertical'" :model="formData" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol"
          ref="formRef">
          <a-row>
            <!--          <a-col :span="24" v-show="formData.rejectReason" class="red-text">-->
            <!--            <a-form-item-->
            <!--                label="Reject Reason"-->
            <!--                name="rejectReason"-->
            <!--                :label-col="{ span: 22 }"-->
            <!--                :wrapper-col="{ span: 24 }"-->
            <!--            >-->
            <!--              {{ formData.rejectReason }}-->
            <!--            </a-form-item>-->
            <!--          </a-col>-->
            <!--          <a-col :span="24" v-show="isCanReject" class="red-text">
            <a-form-item
                label="想要驳回？"
                name="reject"
                :label-col="{ span: 22 }"
                :wrapper-col="{ span: 24 }"
                v-auth="['system:prematching:rmReject']"
                v-if="rejectBtn"
            >
              <a-button type="primary" danger @click="rejectModal"> 驳回 </a-button>
            </a-form-item>
          </a-col>-->
            <a-col :span="6">
              <a-form-item label="Service Line：" class="labelColor">
                {{ formData.serviceLine }}
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Sub Service Line：" class="labelColor">
                {{ formData.subServiceLine }}
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Candidate Name" name="candidateName">
                <a-input v-model:value="formData.candidateName" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Candidate Contact" name="candidateContact">
                <a-input v-model:value="formData.candidateContact" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Request Account：" class="labelColor">
                {{ formData.matchType === '2'? formData.pipelineDetailVO.account : formData.demandClientName }}
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Request Industry：" class="labelColor">
                {{ formData.matchType === '2'? formData.pipelineDetailVO.industryLine : formData.demandIndustryLine }}
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="source === 'match' || source === 'assignmentDetail'">
              <a-form-item label="Candidate Grade：" class="labelColor">
                {{ formData.candidateGrade }}
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="source === 'match' || source === 'assignmentDetail'">
              <a-form-item label="ADRC/人员成本：" class="labelColor">
                {{ formData.adrc }}
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Account" name="account">
                <a-select ref="selectCityRef" v-model:value="formData.account" :options="accountOpts" placeholder="请选择"
                  allowClear show-search :getPopupContainer="getPopupContainer" @change="handleAccountChange()">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="18">
              <a-form-item label="Industry Line" name="industryLine">
                <a-input v-model:value="formData.industryLine" disabled style="width: 33.3%" />
              </a-form-item>
            </a-col>
            <a-col :span="6" class="engagementCodeCol">
              <a-form-item name="engagementCode" :label-col="{ span: 24 }">
                <template v-slot:label>
                  <span class="codeLabel">Engagement Code</span>
                  <a-popover placement="topLeft">
                    <template #content>
                      <div>如果查询不到，请<a href="https://teams.microsoft.com/l/chat/0/0?users=siyi.chen@capgemini.com"
                          style="text-decoration:underline;color: #3182CE">联系管理员</a></div>
                    </template>
                    <span>
                      <QuestionCircleOutlined :style="{ color: '#3182ce' }" v-if="title === 'PM 确定匹配'" />
                    </span>
                  </a-popover>
                </template>
                <a-select class="item" allow-clear placeholder="请选择" show-search optionFilterProp="label"
                  :options="engagementCodeOpts" v-model:value="formData.engagementCode" @change="handleCodeChange()"
                  :filter-option="(input,option)=>option.value.indexOf(input.trim()) >= 0"
                  @popupScroll="handlePopupScroll" @search="handleSelectCode" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Engagement Description" name="engagementDescription">
                <a-input v-model:value="formData.engagementDescription" placeholder="请输入" :maxlength="40" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Code预计开出时间" name="projectCommitDate">
                <a-date-picker class="item" allow-clear type="date" :placeholder="placeHolderTip" valueFormat="YYYY-MM-DD"
                  :disabledDate="disabledCommitDate" :inputReadOnly="true" :showToday="false"
                  :disabled="isDisableProjectCommitDate" v-model:value="formData.projectCommitDate" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Code有效期：" class="labelColor">
                {{ formData.codeTerm }}
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Engagement Manager" name="engagementManager" class="selectEM">
                <a-select ref="selectEMRef" v-model:value="formData.engagementManager" placeholder="请选择" show-search
                  allow-clear :getPopupContainer="getPopupContainer"
                  :disabled="isFormalCode === true ? !isAbleEm : false">
                  <a-select-option v-for="em in EMOps" :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email">
                    {{ em.fullName + " " + em.email }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="PM" name="pmInfo">
                <a-select
                    v-model:value="formData.pmInfo"
                    show-search
                    placeholder="选择PM"
                    :show-arrow="false"
                    :filter-option="filterOption"
                    :options="PMOps"
                    v-if="tabStatus === '0'"
                >
                </a-select>
                <a-input
                    v-model:value="formData.pmInfo"
                    placeholder="请输入"
                    disabled
                    v-else
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Leading CSM" name="leadingCSM" class="selectEM">
                <a-select ref="selectEMRef" v-model:value="formData.leadingCSM" :placeholder="placeHolderTip" show-search
                  :show-arrow="false" :getPopupContainer="getPopupContainer"
                  :disabled="!isAbleEm || isDisableProjectCommitDate" style="width: 50%">
                  <a-select-option v-for="em in EMOps" :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email">
                    {{ em.fullName + " " + em.email }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Assignment Term" name="assignmentTerm" class="assignmentTermClass" :rules="assignmentTermRules"
                :wrapper-col="{ span: 24 }">
                <!--              <a-range-picker-->
                <!--                  v-model:value="formData.assignmentTerm"-->
                <!--                  :disabled="modifyDisabled"-->
                <!--                  :disabled-date="disabledDate"-->
                <!--                  @change="handleAssChange"-->
                <!--              />-->
                <a-date-picker v-model:value="formData.assignmentTerm[0]" :disabledDate="modifyDisabled" placeholder="请选择"
                  style="width: 48%" @change="handleAssChange" :disabled-date="onBoardDisabledDate"
                  :disabled="modifyDisabled || !isSegmentedEditing" requied="true"/>
                <div class="content">~</div>
                <a-date-picker v-model:value="formData.assignmentTerm[1]" :disabledDate="modifyDisabled" placeholder="请选择"
                  style="width: 48%" @change="handleAssChange" :disabled-date="estimateReleaseDisabledDate"
                  :disabled="modifyDisabled || !isSegmentedEditing" />

                </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Hours per Day" :name="isSegmentedEditing ? 'hoursPerDay' : 'hoursPerDays'">
                <a-input v-model:value="formData.hoursPerDay" placeholder="请输入"
                  :disabled="modifyDisabled || !isSegmentedEditing" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label=" ">
                <div v-if="source === 'assignmentDetail'" style="margin-top: 0.2vw">
                  <a-button type="primary" @click="rmEditTime"
                    v-auth="['system:assignmentDetails:editTime']">修改时间</a-button>
                </div>
                <div v-else style="margin-top: 0.2vw">
                  <a-button type="primary" @click="segmentedEditingClick" v-if="isSegmentedEditing">分段编辑</a-button>
                  <a-button type="primary" @click="cancelSegmentedEditingClick" v-else>取消分段编辑</a-button>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="!isSegmentedEditing">
              <a-form :model="segmentedData" ref="segmentFormRef" class="segmentedtaleclass" :rules="segmentedFormRules">
                <a-table :columns="columns" :data-source="segmentedData" :pagination="false" class="segmentedClass"
                  row-key="id" :scroll="{
                    x: 1000,
                    scrollToFirstRowOnChange: true,
                  }">
                  <template #startDate="{ index, text, record }">
                    <a-form-item :name="[index, 'startDate']" :rules="[
                      {
                        validator: startValidatorSpace,
                        startDateIndex: index,
                        trigger: 'change',
                      }
                    ]">
                      <a-date-picker :value="text" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].startDate = val)" style="width: 96%"
                        @change="startDateChange(index, record)"
                        :disabled-date="current => segmenteOnBoardDisabledDate(current, record, index)"
                        :disabled="modifyDisabled" />
                      <span class="tilde"> ~ </span>
                    </a-form-item>
                  </template>
                  <template #endDate="{ index, text, record }">
                    <a-form-item :name="[index, 'endDate']" :rules="[
                      {
                        validator: endValidatorSpace,
                        endDateIndex: index,
                        trigger: 'change',
                      }]">
                      <a-date-picker :value="text" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                        @update:value="(val) => (segmentedData[index].endDate = val)" style="width: 96%"
                        @change="endDateChange(index, record)"
                        :disabled-date="current => segmenteEstimateReleaseDate(current, record, index)"
                        :disabled="modifyDisabled" />
                    </a-form-item>
                  </template>

                  <template #hoursPerDay="{ index, text }">
                    <a-form-item :name="[index, 'hoursPerDay']" :rules="[
                      {
                        pattern: /^[0-7]\.[0-9]$|^[0-8]$/,
                        message: 'Please input the correct format Hours Per Day (>=0 & <=8)',
                        trigger: 'blur',
                      },
                      {
                        validator: hoursValidatorSpace,
                        hoursIndex: index,
                        trigger: 'blur',
                      }
                    ]">
                      <a-input style="width: 96%" :value="text"
                        @update:value="(val) => (segmentedData[index].hoursPerDay = val)" placeholder="请输入"
                        @change="hoursPerDayChange" :disabled="modifyDisabled" />
                    </a-form-item>
                  </template>

                  <template #action="{ index, record }">
                    <a-form-item :name="[index, 'action']">
                      <div v-if="index === segmentedData.length - 1 && source !== 'assignmentDetail'"
                        style="font-size: 1vw">
                        <span @click.stop="segmentedAdd(record)"
                          :class="modifyDisabled ? 'operationLink disabled underline' : ' '">
                          <PlusOutlined :style="`color:${modifyDisabled ? '#999999' : '#3182CE'};cursor: pointer;`" />
                        </span>
                        <a-divider type="vertical" style="background-color: #EBEBEB" />
                        <span @click.stop="segmentedDel(index, record)"
                          :class="modifyDisabled ? 'operationLink disabled underline' : segmentedData.length > 2 ? 'operationLink underline' : 'operationLink disabled underline'">
                          <DeleteOutlined
                            :style="`color: ${modifyDisabled ? '#999999' : segmentedData.length > 2 ? '#DC5E4F' : '#999999'};cursor: pointer`" />
                        </span>
                      </div>
                    </a-form-item>
                  </template>
                </a-table>
              </a-form>
            </a-col>
            <a-col :span="6" v-if="isShowSendEmail">
              <a-form-item label="FCST Extension Assumption Date" name="extensionAssumptionDate">
                <a-date-picker class="item" allow-clear type="date" placeholder="请选择" valueFormat="YYYY-MM-DD"
                               :inputReadOnly="true" :showToday="false" v-model:value="formData.extensionAssumptionDate"
                               :disabledDate="disabledFcstDate"/>
              </a-form-item>
            </a-col>
            <a-col :span="18" v-if="isShowSendEmail">
              <a-form-item name="certainty" class="fcstLabel">
                <template v-slot:label>
                  <span :class="formData.extensionAssumptionDate? 'cerLabel' : ''">确定性</span>
                  <span class="tipLabel">不会更新调令的结束时间</span>
                </template>
                <a-radio-group v-model:value="formData.certainty" :disabled="certaintyDisabled">
                  <a-radio value="Firm">Firm</a-radio>
                  <a-radio value="Proposal">Proposal</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Work Location" name="workLocation" :label-col="{ span: 8 }" :wrapper-col="{ span: 24 }">
                <a-textarea v-model:value="formData.workLocation" placeholder="请输入" showCount :maxlength="50" :rows="1"
                  :auto-size="{ minRows: 1, maxRows: 1 }" style="width: 98%"
                  @keyup.enter="(e) => { e.stopPropagation() }" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Work City" name="workCity">
                <a-select ref="selectCityRef" v-model:value="formData.workCity" :options="workCityOps" placeholder="请选择"
                  allowClear show-search :getPopupContainer="getPopupContainer" style="width: 50%">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" :class="modifyDisabled ? 'tipsCol labelColor' : 'tipsCol'">
              <a-form-item :label="modifyDisabled ? 'Copy：' : 'Copy'" name="copyList" :label-col="{ span: 8 }"
                :wrapper-col="{ span: 24 }">
                <a-select ref="selectEMRef" v-model:value="formData.copyList" placeholder="请选择"
                  :getPopupContainer="getPopupContainer" showArrow allowClear mode="multiple" v-if="!modifyDisabled"
                  style="width: 50%" :maxTagCount="1">
                  <a-select-option v-for="em in EMOps" :value="em.fullName + ' ' + em.email"
                    :key="em.fullName + ' ' + em.email" :disabled="formData.copyList &&
                      formData.copyList.length == 10 &&
                      formData.copyList.findIndex(
                        (i) => i === em.fullName + ' ' + em.email
                      ) === -1
                      ">
                    {{ em.fullName + " " + em.email }}
                  </a-select-option>
                </a-select>
                <div v-if="!modifyDisabled" class="tip-text-primary">请选择其他除LM、EM、PM、RM、HRBP以外需要抄送邮件的人员。</div>
                <div v-else class="copy-person">
                  <span v-if="formData.copyList ? formData.copyList.length > 0 : false">
                    <span v-for="(item, index) in formData.copyList" :key="item">
                      {{ item }}
                      <span v-show="formData.copyList.length - 1 > index">/</span>
                    </span>
                  </span>
                  <span v-else> 无 </span>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="source === 'assignmentDetail'">
              <a-form-item label="Comments" name="applyReason" :label-col="{ span: 22 }" :wrapper-col="{ span: 24 }"
                :colon="false" v-if="assignmentStatus === '1' ||
                  assignmentStatus === '2' ||
                  assignmentStatus === '4' ||
                  assignmentStatus === '5' ||
                  assignmentStatus === '6' ||
                  assignmentStatus === '8'
                  " style="width: 98%">
                <a-textarea v-model:value="formData.applyReason" placeholder="请输入" :rows="5" showCount :maxlength="65"
                  :auto-size="{ minRows: 3, maxRows: 3 }" />
              </a-form-item>
            </a-col>
            <a-col :span="24" v-show="isShowComment">
              <a-form-item :label-col="{ span: 22 }" :wrapper-col="{ span: 24 }" label="RM Comments" name="replaceReason">
                <a-textarea v-model:value="formData.replaceReason" placeholder="请输入" showCount :maxlength="200"
                  :auto-size="{ minRows: 2, maxRows: 2 }" @keyup.enter="(e) => { e.stopPropagation() }" />
              </a-form-item>
            </a-col>
            <a-col :span="6" v-show="showSendAssignmentLetter" class="mandatory-asterisk">
              <a-form-item label="是否立即发送Assignment Letter" name="selectAssEmailSendTime">
                <a-radio-group v-model:value="sendAssignmentLetter" @change="selectIsSend">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="2">否</a-radio>
                  <a-radio value="3">不触发邮件</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="18" v-show="showSendAssignmentLetter">
              <a-form-item label="发送日期" name="assEmailSendTime">
                <a-date-picker style="width: 33.3%" allow-clear placeholder="请选择" format="YYYY-MM-DD HH:00"
                  valueFormat="YYYY-MM-DD HH:00" :show-time="{ format: 'HH' }" v-model:value="formData.assEmailSendTime"
                  :disabled="isDisableDate" @change="sendAssignmentLetterDataChange" :disabledDate="disabledDateSendEmail"
                  :disabledTime="disabledTimeSendEmail" :showToday="false" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <!-- @todo rmleader 看不到该模块        -->
        <div class="annex-container" v-show="isShowUploadAnnex">
          <AnnexDataTable :annexDataList="annexDataList" :annexColumns="annexColumns" :annexRightMsg="annexRightMsg"
            @show-drawer="showDrawer" @del-annexClick="delAnnexClick"></AnnexDataTable>
        </div>
        <div class="headerBtn bottom-btn">
          <!--        <a-button v-if="showRollback" @click="rollBackModal">-->
          <!--          <template #icon>-->
          <!--            <RollbackOutlined/>-->
          <!--          </template>-->
          <!--          恢复上次填写-->
          <!--        </a-button>-->
          <span class="btn-left is-email" v-show="isShowSendEmail">
            <a-form :model="isSendEmail">
              <a-form-item label="是否发送邮件" name="isSendEmail" class="mandatory-asterisk email">
                <!--          <span style="margin-right: 8px">是否发送邮件</span>-->
                <a-radio-group v-model:value="isSendEmail">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="2">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-form>
          </span>
          <a-button class="btn-left" v-if="isCanReject && rejectBtn" type="primary" danger
            v-auth="['system:prematching:rmReject']" @click="rejectModal"> 驳回 </a-button>
          <a-button @click="closeModal">取消</a-button>
          <a-button @click="saveModal" v-show="showSaveBtn" v-auth="['system:matchingList:save']">保存</a-button>
          <a-button  v-if="['1','2'].includes(rowType) && !isPm" @click="nextToDemand" type="primary">下一步</a-button>
          <a-button  v-else @click="submitModal" type="primary">确定</a-button>

        </div>
        <upload-annex ref="uploadAnnexRef" v-model:drawerVisible="drawerVisible" @onUpdateFileList="onUpdateFileList"
          :titleName="titleName" @onCloseDrawer="onCloseDrawer"></upload-annex>
      </template>
     </div>
     <div class="drawerDemand" v-show="isShowDemand">
       <drawerDemandFilter ref="demandFilter" :showNuber="3" @search="handleDemandSearch"/>
       <drawerDemandList ref="damandListRef" :consultObj="[]" :isContainConsult="false" @changeDemadnd="changeDemadnd"/>
       <div class="headerBtn drawerDemandBtn">
          <a-button @click="demandButton('取消')">取消</a-button>
          <a-button type="primary" @click="demandButton('上一步')">上一步</a-button>
          <a-button type="primary" @click="demandButton('跳过')">未建立北森需求，跳过</a-button>
          <a-button type="primary" :disabled="!selectDemand.length" @click="demandButton('确定')">确定</a-button>
        </div>
     </div>

    </a-drawer>
    <upload-annex
      ref="uploadAnnexRef"
      v-model:drawerVisible="drawerVisible"
      @onUpdateFileList="onUpdateFileList"
      :titleName="titleName"
      @onCloseDrawer="onCloseDrawer"
    ></upload-annex>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :cancel="handleCancel" :content="content" :showIconCom="showIconCom" />
  <!-- 关联的ModalBox -->
  <ModalBox v-model="demandModelValue" :ok="demandHandleOk" :modalWidth="500" title="正在跳过关联至北森"
            :content="demandContent" :showIconCom="demandShowIconCom" />
  <div v-if="buttonType == 'PM填写'">
    <ModalBox v-model="pmModelValue" title="填写调令" :ok="pmHandleOk">
      <template #content>
         <div>请选择操作流程</div>
         <a-radio-group v-model:value="noLoginValue">
            <a-radio style="display: block;margin-top:5px" :value="0">常规流程</a-radio>
            <a-radio style="display: block;padding:5px 0px" :value="1">免登录链接流程</a-radio>
          </a-radio-group>
      </template>
    </ModalBox>
  </div>

</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  reactive,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";
import moment from "moment";
import UploadAnnex from "@/views/home/ResourceManagement/Match/matchingList/components/uploadAnnex";
import AnnexDataTable from "@/components/AnnexDataTable";
import { message } from "ant-design-vue";
import { formatCodeTerm } from "@/utils/common";
import ModalBox from '@/components/ModalBox'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { debounce } from "lodash";
import drawerDemandFilter from "@/views/home/ResourceManagement/Match/newMatch/components/TopFilter"
import drawerDemandList from "@/views/home/ResourceManagement/Match/newMatch/components/DemandList"
export default defineComponent({
  name: "fillAssignment",
  components: {
    QuestionCircleOutlined,
    AnnexDataTable,
    UploadAnnex,
    ModalBox,
    PlusOutlined,
    DeleteOutlined,
    drawerDemandFilter,
    drawerDemandList
  },
  props: {
    showRollback: Boolean,
    rejectBtn: Boolean,
    visible: Boolean,
    title: String,
    modifyDisabled: Boolean,
    source: String,
    isShowSendEmail: Boolean,
    showSendAssignmentLetter: Boolean,
    showSaveBtn: Boolean,
    formState: {
      type: Object,
      default: () => ({
        serviceLine: "",
        subServiceLine: "",
        candidateName: "",
        candidateContact: "",
        candidateGrade: "",
        account: "",
        engagementCode: "",
        engagementDescription: "",
        workLocation: "",
        engagementManager: "",
        leadingCSM: "",
        copyList: [],
        assignmentTerm: [],
        estimateReleaseDate: "",
        hoursPerDay: "",
        adrc: "",
        industryLine: "",
        workCity: "",
        replaceReason: "",
        pmInfo: "",
        codeStartDate: "",
        codeEndDate: "",
        assEmailSendTime: "",
        staffAssignmentParts: [],
        applyReason: '',
      }),
    },
    fillAssignmentType: String,
    orgInfo: Object,
    isCanReject: {
      type: Boolean,
      default: true,
    },
    accountNameOps: {
      type: Array,
      default: () => [],
    },
    industryLineOps: {
      type: Array,
      default: () => [],
    },
    accountIndustryRelaction: {
      type: Array,
      default: () => [],
    },
    originalCodeAndDateObj: {
      type: Object,
      default: () => { },
    },
    codeRelationList: {
      type: Object,
      default: () => { },
    },
    tabStatus: String,
    // 以下属性均为 JIRA - 4102 内容
    activeTab: String,
    pmOptions: Array,
    buttonType: String,
    isPM: {
      type: Boolean,
      default: true,
    },
    rowType: String,
    preMatchingId:String
  },
  emits: ["close-modal", "submit-modal", "open-reject-modal", "get-annexList","sendCloseModal","rm-edit-time","relevanceAndUpdata","upDemandId"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const formData = computed(() => props.formState);
    const accountOpts = ref([]);
    watch(
      () => props.accountNameOps,
      (val) => {
        accountOpts.value = val;
      },
      { immediate: true, deep: true }
    );
    const isPm = store.state.app?.user?.roles.includes('3')
    const formRef = ref();
    const workCityOps = ref([]);
    const EMOps = ref([]);
    const selectCityRef = ref();
    const selectEMRef = ref();
    const isShowComment = ref(false);
    const isSendEmail = ref('2')
    //接收code状态
    const projectStatus = ref()
    const resData = ref()
    const PMOps = ref([])

    // 附件上传相关 start
    const isShowUploadAnnex = ref(false);
    const drawerVisible = ref(false);
    const titleName = ref("");
    const showDrawer = () => {
      uploadAnnexRef.value.fileList = annexDataList.value;
      drawerVisible.value = true;
      titleName.value = "上传附件";
    };
    const onCloseDrawer = () => {
      drawerVisible.value = false;
    };
    const uploadAnnexRef = ref(null);
    const annexDataList = ref([]);
    const annexColumns = [
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
    ];
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref()
    const modalData = ref()
    const handleOk = () => {
      if (flag.value === 1) {
        annexDataList.value.splice(modalData.value, 1);
      } else if (flag.value === 2) {
        setDataFromCode(modalData.value);
      }
    }
    const handleCancel = () => {
      if (flag.value === 2) {
        formData.value.engagementCode = "";
      }
    }
    const delAnnexClick = (record, index) => {
      content.value = '确定删除这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 1
      modalData.value = index
      modelValue.value = true

      /*Modal.confirm({
        title: "确定删除这条数据吗?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          annexDataList.value.splice(index, 1);
        },
      });*/
    };
    const onUpdateFileList = (fileList) => {
      if (fileList.length < 1) {
        message.error("请选择文件。");
        return false;
      }
      annexDataList.value = fileList.map((item) => {
        if (!item.id) item.id = item.uid;
        return item;
      });
      onCloseDrawer();
    };
    // 附件上传相关 end

    const formRules = reactive({
      engagementCode: [
        {
          required: true,
          message: "Please select Engagement Code",
          trigger: "blur",
        },
      ],
      candidateContact: [
        {
          required: true,
          message: "Please input Candidate Contact",
          trigger: "blur",
        },
        {
          required: true,
          pattern:
            /^([a-zA-Z.-])+@capgemini.com(\/(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})?$/,
          // /^(([a-zA-Z.-])+@capgemini.com\/(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/,
          message: "Please input the correct format（Email/mobile phone number",
          trigger: "blur",
        },
      ],
      engagementManager: [
        {
          required: true,
          message: "Please select Engagement Manager",
          trigger: "blur",
        },
      ],
      leadingCSM: [
        {
          required: true,
          message: "Please select Leading CSM",
          trigger: "blur",
        },
      ],
      assignmentTerm: [
        {
          required: true,
          message: "Please input Assignment Term",
          trigger: ["change", "blur"],
          type: "array",
        },
      ],
      candidateName: [
        {
          required: true,
          message: "Please input Candidate Name",
          trigger: "blur",
        },
      ],
      account: [
        {
          required: true,
          message: "Please input Account",
          trigger: "blur",
        },
      ],
      engagementDescription: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (value && value.trim()) {
              if (/^[\u4e00-\u9fa5'’‘]{1,}$/.test(value)) {
                return Promise.reject("不支持中文、引号");
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.reject("Please input Engagement Description");
            }
          },
        },
      ],
      projectCommitDate: [
        {
          required: true,
          message: "Please input Code预计开出时间",
          trigger: "change",
        },
      ],
      workCity: [
        {
          required: true,
          message: "Please select Work City",
          trigger: "blur",
        },
      ],
      workLocation: [
        {
          required: true,
          message: "Please input Work Location",
          trigger: "blur",
        },
      ],
      hoursPerDay: [
        {
          required: true,
          message: "Please input Hours Per Day",
          trigger: "blur",
        },
        {
          required: false,
          pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
          message: "Please input the correct format Hours Per Day (>0 & <=8)",
          trigger: ["blur"],
        },
      ],
      replaceReason: [],
      assEmailSendTime: [
        {
          required: true,
          message: "Please select Assignment Letter Date",
          trigger: "change",
        },
      ],
      applyReason: [
        {
          required: true,
          message: "Please input Apply Reason",
          trigger: "blur",
        },
      ],
      pmInfo: [
        {
          required: false,
          message: "Please input PM",
          trigger: "blur",
        },
      ],
      certainty: [
        {
          required: false,
          message: "Please select 确定性",
          trigger: "change",
        },
      ],
      extensionAssumptionDate: [
        {
          required: false,
          trigger: "change",
          validator: async (rule, value) => {
            if(value && formData.value.assignmentTerm[0] && formData.value.assignmentTerm[1]) {
              const diffStart = moment(formData.value.assignmentTerm[0]).diff(moment(value), 'days') < 0;
              const diffEnd = moment(formData.value.assignmentTerm[1]).diff(moment(value), 'days') > 0;
              const diffDtate = diffStart && diffEnd;
              if (diffDtate) {
                return Promise.reject("该日期不能在Assignment Term范围内，请重新选择");
              } else {
                return Promise.resolve();
              }
            }
          },
        },
      ],
    });

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: { dictType: "city" },
        })
        .then((res) => {
          workCityOps.value = createSelectOptionData(res.data.city);
          store.commit("SET_DICTIONARY", res.data);
        });
    };

    const engagementCodeOpts = ref([]);
    // 正式code为true
    const isFormalCode = ref(true);

    const isDisableProjectCommitDate = ref(true);

    const placeHolderTip = computed(() =>
      isDisableProjectCommitDate.value ? "仅适用于TBD Code" : "请选择"
    );

    const codeRelationList = computed(() => props.codeRelationList);

    watch(
      () => codeRelationList.value,
      (val) => {
        if (formData.value.account) {
          getFilterredCodeOpt()
        } else {
          engagementCodeOpts.value = val.allCodeOptionsList
        }
      },
      { immediate: true, deep: true }
    );

    const codeTerm = computed(() => {
      return {
        codeStartDate: formData.value.codeStartDate,
        codeEndDate: formData.value.codeEndDate,
      };
    });
    watch(
      () => codeTerm.value,
      (val) => {
        formData.value.codeTerm = formatCodeTerm(
          val.codeStartDate,
          val.codeEndDate
        );
      },
      {
        immediate: true,
      }
    );

    const getSelectedCodeInfo = (engagementCode, type) => {
      const params = {
        pagelndex: type === 'scoll' ? codeFormData.pagelndex : 1,
        pageSize: 20,
        code: selectCodeScoll.value ? selectCodeScoll.value : engagementCode,
        account: type === 'select' ? formData.value.account : undefined
      }
      $api.getCodeDataOps(params).then((res) => {
        if (type === 'code') {
          accountOpts.value = res.data.resource.map((item) => {
            return {
              ...item,
              label: item.account,
              value: item.account,
            };
          });
          if (res.data.resource?.length > 0) {
            const selectedCodeInfo = res.data.resource[0];
            isFormalCode.value = selectedCodeInfo.type === "code";
            initStateWithCodeType(isFormalCode.value);
            handleDisableEm(selectedCodeInfo.codeType);
          }
        }
        if (type === 'scoll') {
          const arr = res.data.resource.map((item) => {
            return {
              ...item,
              label: item.codeAndDescription,
              value: item.codeAndDescription,
              type: item.type,
            }
          })
          engagementCodeOpts.value.push(...arr)
          codeFormData.hasMoreItems = res.data.resource.length === 20
        }
        if (type === 'select') {
          engagementCodeOpts.value = res.data.resource.map((item) => {
            return {
              ...item,
              label: item.codeAndDescription,
              value: item.codeAndDescription,
              type: item.type,
            }
          })
        }
      });
    };
    const handleCodeChange = async () => {
      // 获取最新的关系数据，获取最新的下拉列表
      formData.value.engagementCode = formData.value.engagementCode
        ?.split("(")[0]
        .trim();
      //为空则初始化account和code下拉框
      if (!formData.value.engagementCode) {
        accountOpts.value = props.accountNameOps;
        engagementCodeOpts.value = codeRelationList.value.allCodeOptionsList;
        formData.value.account = undefined;
      }
      const param = {
        engagementCode: formData.value.engagementCode
      }
      const { data } = await $api.getCodeDataByCode(param)
      const selectedCodeInfo = data
      resData.value = data
      //接受code的状态
      projectStatus.value = data.projectStatus
      //将模糊查询中code置空
      selectCodeScoll.value = ''
      // 如果Engagement Code填写的类型为IDC, BDC 或 SFC时，输入后则弹窗提示：当前填写Code非DC Code，是否继续填写？
      // 点击确定，则回到调令填写页面，code字段为已选择的code；点击取消，则选择框置空
      if (!selectedCodeInfo) return;
      if (selectedCodeInfo && selectedCodeInfo.codeType !== "DC") {
        content.value = '当前填写 Code 为 Non-Billable Code，是否继续填写?'
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 2
        modalData.value = selectedCodeInfo
        modelValue.value = true

        //  提示弹窗
        /*Modal.confirm({
          title: () => "提示",
          content: "当前填写Code为Non-Billable Code，是否继续填写？",
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          onOk() {
            setDataFromCode(selectedCodeInfo);
          },
          onCancel() {
            formData.value.engagementCode = "";
          },
        });*/
      } else {
        setDataFromCode(selectedCodeInfo);
      }
      // 为正式code时 设置engagementDescription默认值
      if (
        props.source === "assignmentDetail" ||
        props.fillAssignmentType === "RMConfirm"
      ) {
        formData.value.projectCommitDate = getDefaultCommitDate();
      }
    };
    const handleAccountChange = () => {
      if (!formData.value.account) {
        // 筛选code下拉列表
        engagementCodeOpts.value = codeRelationList.value.allCodeOptionsList;
        accountOpts.value = props.accountNameOps;
        formData.value.engagementCode = undefined;
      }
    };
    // 设置跟code变化的数据
    const setDataFromCode = (selectedCodeInfo) => {
      // Engagement Code填写后，自动查询EM值，Engagement Manager需在code主数据中自动带出，不可编辑
      formData.value.engagementManager = selectedCodeInfo.engagementManager;
      formData.value.leadingCSM = selectedCodeInfo.leadingCSM;
      handleDisableEm(selectedCodeInfo.codeType);
      // 获取Code有效期
      if (projectStatus.value == 'Close') {
        formData.value.codeEndDate = selectedCodeInfo.codeEndDate
      } else {
        formData.value.codeEndDate = selectedCodeInfo.projectCompletionDate
      }
      formData.value.codeStartDate = selectedCodeInfo.codeStartDate;

      // 获取account默认值
      formData.value.account = selectedCodeInfo.account
        ? selectedCodeInfo.account
        : undefined;

      isFormalCode.value = selectedCodeInfo.type === "code";
      formData.value.engagementDescription = isFormalCode.value
        ? selectedCodeInfo.engagementDescription
        : "";
      // projectCommitDate 校验
      if (isFormalCode.value) formData.value.projectCommitDate = "";
      // engagementDescription默认值
      initStateWithCodeType(isFormalCode.value);
      handleDisableCommitDate();
    };

    const initStateWithCodeType = (isFormalCode) => {
      formRules.projectCommitDate[0].required = !isFormalCode;
      formRules.leadingCSM[0].required = !isFormalCode
      // 上传附件提示信息
      annexRightMsg.value = !isFormalCode ? annexMessage : "";
    };

    watch(
      () => formData.value.engagementCode,
      (val) => {
        if (val) {
          val = val?.split("(")[0].trim();
          console.log("val",val)
          getSelectedCodeInfo(val, 'code');
          // if (selectDataList.value?.length > 0) {
          //   const selectedCodeInfo = selectDataList.value[0];
          //   isFormalCode.value = selectedCodeInfo.type === "code";
          //   initStateWithCodeType(isFormalCode.value);
          //   handleDisableEm(selectedCodeInfo.codeType);
          // }
        } else {
          isFormalCode.value = true;
          formData.value.engagementManager = "";
        }
      },
      { deep: true }
    );

    watch(
      () => formData.value.account,
      (val) => {
        if (val) {
          const relatedData = props.accountIndustryRelaction.find(
            (item) => item.account === val
          );
          // 获取account对应的industryLine
          formData.value.industryLine = relatedData?.industryLine;
          getFilterredCodeOpt()
          //engagementCodeOpts.value = getFilterredCodeOpt();
        }
      }
    );
    // 根据account查询code
    const getFilterredCodeOpt = () => {
      $api.relationsByAccount({ account: formData.value.account }).then((res) => {
        engagementCodeOpts.value = res.data.map((item) => {
          return {
            ...item,
            label: item.codeAndDescription,
            value: item.codeAndDescription,
            type: item.type,
          }
        })
      });
    };
    const disabledCommitDate = (current) => {
      if (moment().diff(formData.value.assignmentTerm[0], "day") <= 0) {
        if (
          current &&
          moment(current.format("YYYY-MM-DD")) <=
          moment(projectCommitEndDate.value) &&
          moment(current.format("YYYY-MM-DD")) >=
          moment(projectCommitStartDate.value)
        )
          return false;
        return true;
      } else {
        if (
          current &&
          moment(current.format("YYYY-MM-DD")) <=
          moment(projectCommitEndDate.value)
        )
          return false;
        return true;
      }
    };

    const annexRightMsg = ref("");
    let annexMessage = "当前 Code 为 TBD Code，请上传相关附件，谢谢";

    watch(
      () => annexDataList.value,
      (val) => {
        annexRightMsg.value =
          !isFormalCode.value && val.length < 1 ? annexMessage : "";
      },
      {
        deep: true,
      }
    );
    const handleDisableCommitDate = () => {
      if (isFormalCode.value) {
        isDisableProjectCommitDate.value = true;
      } else {
        if (
          formData.value.assignmentTerm &&
          formData.value.assignmentTerm.length < 1
        ) {
          isDisableProjectCommitDate.value = true;
          return;
        } else {
          if (
            props.source === "assignmentDetail" ||
            props.fillAssignmentType === "RMConfirm"
          ) {
            //
            isDisableProjectCommitDate.value =
              formData.value.engagementCode ===
              props.originalCodeAndDateObj.engagementCode &&
              props.originalCodeAndDateObj.onBoardDate ===
              moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD");
          } else {
            isDisableProjectCommitDate.value = false;
          }
        }
      }
    };
    const isAbleEm = ref(false);
    const handleDisableEm = (codeType) => {
      // 可填写
      isAbleEm.value = isFormalCode.value && codeType !== "DC";
    };
    const assignmentTermRules = [
      {
        validator: (rule, value) => {
          const startDate = value[0];
          const endDate = value[1];
          if (startDate && !endDate) {
            // 开始日期有值但结束日期没有值，则返回错误消息
            return Promise.reject('Please input Assignment Term');
          }
          // 其他情况下，返回通过的 Promise
          return Promise.resolve();
        },
        trigger: ['blur', 'change'], // 触发校验的事件
      },
    ];
    const handleAssChange = () => {
      if (formData.value.assignmentTerm[0] && formData.value.assignmentTerm[1]) {
        formData.value.projectCommitDate = getDefaultCommitDate()
        // tbd 类型则
        handleDisableCommitDate()
      }
    };
    const getDefaultCommitDate = () => {
      const originalOnBoardDate = props.originalCodeAndDateObj.onBoardDate;
      const originalCode = props.originalCodeAndDateObj.engagementCode;
      const isSameCode =
        originalCode && originalCode === formData.value.engagementCode;
      const isSameOnBoardDate =
        originalOnBoardDate &&
        originalOnBoardDate ===
        moment(formData.value.assignmentTerm[0]).format("YYYY-MM-DD");
      if (isSameCode && isSameOnBoardDate) {
        return props.originalCodeAndDateObj.projectCommitDate;
      } else {
        return "";
      }
    };
    watch(
      () => formData.value.assignmentTerm,
      (val) => {
        if (val) {
          if (val[0] && val[1]) {
            // 如果开始日期和结束日期都有值
            getCommitDateRange(); // 执行其他操作
            if (!isFormalCode.value) {
              isDisableProjectCommitDate.value = false;
            }
            // 清除验证消息（如果需要）
            formRef.value?.clearValidate('assignmentTerm');
          } else if (val[0] && !val[1]) {
            // 如果只有开始日期有值而结束日期没有值
            // 显示验证消息
            // formRef.value.validateField('assignmentTerm');
            formRef.value.validate('assignmentTerm')
          } else {
            isDisableProjectCommitDate.value = true
          }
        }
    },
    { immediate: true, deep: true }
    );
    const onBoardDisabledDate = (current) => {
      if (formData.value.assignmentTerm[1]) {
        return moment(formData.value.assignmentTerm[1]) < current
      }
    };
    const estimateReleaseDisabledDate = (current) => {
      if (formData.value.assignmentTerm[0]) {
        return current < moment(formData.value.assignmentTerm[0])
      }
    };
    const projectCommitEndDate = ref("");
    const projectCommitStartDate = ref("");
    var getCommitDateRange = async () => {
      if (formData.value.assignmentTerm.length > 0) {
        const params = {
          onboardDate: moment(formData.value.assignmentTerm[0]).format(
            "YYYY-MM-DD"
          ),
        };
        const { data } = await $api.getCommitDateRange(params);
        projectCommitEndDate.value = data.projectCommitEndDate;
        projectCommitStartDate.value = data.projectCommitStartDate;
      }
    };

    // 构建select筛选options
    const createSelectOptionData = (data) => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: item.dictValue,
          key: item.dictId,
        };
      });
      return options;
    };

    const getEMOptions = () => {
      $api.findAllStaffs().then((res) => {
        EMOps.value = res.data;
      });
      if(!props.isPM) {
        $api.findMatchingPM().then((res) => {
          PMOps.value = res.data.map(item => {
            return {
              ...item,
              value: item.fullName + ' ' + item.email
            }
          })
        });
      }
    };
    // 设置RM COMMENTS校验规则
    const getFormRulesOptions = () => {
      formRules.replaceReason.splice(0);
      const commentRules = {
        required: true,
        message: "Please input RM Comments",
        trigger: "blur",
      };
      formRules.replaceReason.push(commentRules);
      return formRules.replaceReason;
    };
    watch(
      () => props.fillAssignmentType,
      (val) => {
        isShowComment.value = val === "RMFillAssignment";
        formRules.replaceReason =
          val === "RMFillAssignment" ? getFormRulesOptions() : [];
        isShowUploadAnnex.value = val === "RMFillAssignment";
      },
      { immediate: true }
    );

    watch(
      () => props.visible,
      (val) => {
        pmData.value = {
          selectPM: undefined
        }
        selectIsSend();
        if (val) {
          formRef.value?.clearValidate();
          isSendEmail.value = "2";
        }
        if (!val) isFormalCode.value = true;
        if (val && props.rejectBtn === true) {
          $api
            .checkFormalCode({ engagementCode: formData.value.engagementCode })
            .then((res) => {
              isFormalCode.value = !res.data;
            });
        }
        if(props.tabStatus === '0') {
          formRules.pmInfo[0].required = true;
          formData.value.pmInfo = formData.value.pmInfo === "暂无PM"? undefined : formData.value.pmInfo;
        }
      }
    );
    watch(
      () => props.formState.assEmailSendTime,
      (val) => {
        if (val) {
          sendAssignmentLetter.value = "2";
          isDisableDate.value = false;
          formRules.assEmailSendTime[0].required = true;
        }
      },
      { deep: true }
    );

    onMounted(() => {
      getSelectOptions();
      getEMOptions();
    });

    const rejectModal = () => {
      ctx.emit("open-reject-modal");
    };

    const closeModal = () => {
      ctx.emit("close-modal");
      damandListRef.value.removeAdded()
      // formRef.value.resetFields();
      segmentedData.value = []
      annexDataList.value = [];
      sendAssignmentLetter.value = "1";
      formData.value.assEmailSendTime = "";
      isSegmentedEditing.value = true
    };
    const submitModal = () => {
      if (isFormalCode.value === true) {
        formRules.leadingCSM[0].required = false;
        formRules.projectCommitDate[0].required =false;
      }
      if (formData.value.assignmentTerm.filter((item) => { return item !== null }).length != 2) {
        message.error("Assignment Term 的开始时间和结束时间均是必填项")
        return
      }
      if (segmentedData.value.length > 0) {
        segmentFormRef.value.validate().then(() => {
          formRef.value.validate().then(() => {
            const data = {
              code: formData.value.engagementCode,
              onboardDate: moment(formData.value.assignmentTerm[0]).format(
                "YYYY-MM-DD"
              ),
              estimateReleaseDate: moment(formData.value.assignmentTerm[1]).format(
                "YYYY-MM-DD"
              ),
            };
            if (props.isShowSendEmail === true) {
              formData.value.isEmail = isSendEmail.value;
            }
            if (
              isShowUploadAnnex.value &&
              !isFormalCode.value &&
              annexDataList.value.length < 1
            ) {
              return;
            }
            if(props.tabStatus === '0' && formData.value.pmInfo){
              formData.value.pmId = PMOps.value.find(
                  (item) =>
                      item.fullName + " " + item.email === formData.value.pmInfo
              )?.id;
            }
            ctx.emit("submit-modal", data, segmentedData.value);
            ctx.emit("get-annexList", annexDataList.value);
          });
        })
      } else {
        formRef.value.validate().then(() => {
          const data = {
            clientName:formData.value.account,
            code: formData.value.engagementCode,
            description:formData.value.engagementDescription,
            onboardDate: moment(formData.value.assignmentTerm[0]).format(
              "YYYY-MM-DD"
            ),
            estimateReleaseDate: moment(formData.value.assignmentTerm[1]).format(
              "YYYY-MM-DD"
            ),
          };
          if (props.isShowSendEmail === true) {
            formData.value.isEmail = isSendEmail.value;
          }
          if (
            isShowUploadAnnex.value &&
            !isFormalCode.value &&
            annexDataList.value.length < 1
          ) {
            return;
          }
          if(props.tabStatus === '0' && formData.value.pmInfo){
            formData.value.pmId = PMOps.value.find(
                (item) =>
                    item.fullName + " " + item.email === formData.value.pmInfo
            )?.id;
          }
          ctx.emit("submit-modal", data, segmentedData.value);
          ctx.emit("get-annexList", annexDataList.value);
        });
      }
    };

    // const rollBackModal = () => {
    //   formRef.value.resetFields();
    //   ctx.emit("rollback-modal");
    // };

    const getRefsAff = ref();

    const disabledDate = (current) => {
      if (
        current &&
        props.formState.staffTerminationDate &&
        moment(current.format("YYYY-MM-DD")) >
        moment(props.formState.staffTerminationDate)
      )
        return true;
      if (
        current &&
        props.formState.staffOnboardDate &&
        moment(current.format("YYYY-MM-DD")) <
        moment(props.formState.staffOnboardDate)
      )
        return true;
      return false;
    };
    const getPopupContainer = (triggerNode) => {
      return triggerNode.parentNode || document.body;
    };

    const sendAssignmentLetter = ref("1");
    const isDisableDate = ref(true);
    const selectIsSend = () => {
      // 0发送邮件， 1不发送
      formData.value.isSendEmail = sendAssignmentLetter.value === '3' ? '1' : '0'
      isDisableDate.value = sendAssignmentLetter.value !== '2';
      formRules.assEmailSendTime[0].required = sendAssignmentLetter.value === '2';
      if (sendAssignmentLetter.value === "1" || sendAssignmentLetter.value === "3") {
        formData.value.assEmailSendTime = "";
      }
    };
    const sendAssignmentLetterDataChange = () => {
      formRef.value.validate(["assEmailSendTime"]);
    };
    const disabledDateSendEmail = (current) => {
      return current && current < moment().subtract(1, "days").endOf("day");
    };
    const disabledTimeSendEmail = (now) => {
      if (now) {
        const disabledTime = moment();
        const disabledDay = moment(disabledTime).date();
        const nowDay = moment(now).date();
        if (disabledDay === nowDay) {
          return {
            disabledHours: () => {
              let hours = [];
              let time = moment();
              let hour = moment(time).hour() + 1;
              for (let i = 0; i < hour; i++) {
                hours.push(i);
              }
              return hours;
            },
          };
        }
      }
      return {};
    };
    const saveModal = () => {
      if(props.tabStatus === '0' && formData.value.pmInfo){
        formData.value.pmId = PMOps.value.find(
            (item) =>
                item.fullName + " " + item.email === formData.value.pmInfo
        )?.id;
      }
      ctx.emit("save-modal",segmentedData.value);
    };

    //分段编辑
    const segmentFormRef = ref()
    const isSegmentedEditing = ref(true)
    const segmentedData = ref([])
    const columns = [
      {
        title: "分段Assignment Term",
        dataIndex: "startDate",
        ellipsis: true,
        width: `25%`,
        slots: { customRender: "startDate" },
      },
      {
        title: " ",
        dataIndex: "endDate",
        ellipsis: true,
        width: `25%`,
        slots: { customRender: "endDate" },
      },
      {
        title: "分段Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: `25%`,
        slots: { customRender: "hoursPerDay" },
      },
      {
        title: "",
        ellipsis: true,
        width: `25%`,
        slots: { customRender: "action" },
      },
    ];

    const segmentedFormRules = ref({});
    watch(
      () => props.formState.staffAssignmentParts,
      (val) => {
        if (val) {
          isSegmentedEditing.value = val.length > 0 ? false : true
          segmentedData.value = props.formState.staffAssignmentParts
        }
      },
    )
    const segmentedAdd = () => {
      if (segmentedData.value[segmentedData.value.length - 1].endDate) {
        let newRow = {
          hoursPerDay: undefined,
          startDate: moment(segmentedData.value[segmentedData.value.length - 1].endDate).add(1, 'days').format('YYYY-MM-DD'),
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      } else {
        let newRow = {
          hoursPerDay: undefined,
          startDate: undefined,
          endDate: undefined,
        }
        segmentedData.value.push(newRow)
      }
    }
    const segmentedDel = (index) => {
      segmentedData.value.splice(index, 1)
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.hoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      formData.value.assignmentTerm[1] = segmentedData.value[index - 1].endDate
    }

    const segmentedEditingClick = () => {
      isSegmentedEditing.value = false
      if (segmentedData.value.length === 0) {
        segmentedData.value = [
          {
            id: '',
            hoursPerDay: '',
            startDate: '',
            endDate: '',
            assignmentId: '',
          },
          {
            id: '',
            hoursPerDay: '',
            startDate: '',
            endDate: '',
            assignmentId: '',
          },
        ]
      }
    }
    const cancelSegmentedEditingClick = () => {
      isSegmentedEditing.value = true
      segmentedData.value = []
    }

    const startDateChange = (index, record) => {
      if (index > 0 && record.startDate && !segmentedData.value[index - 1].endDate) {
        segmentedData.value[index - 1].endDate = moment(record.startDate).subtract(1, 'days').format("YYYY-MM-DD")
      }
      if (segmentedData.value[0].startDate) {
        formData.value.assignmentTerm[0] = segmentedData.value[0].startDate
        if (segmentedData.value[segmentedData.value.length - 1].endDate) {
          formData.value.projectCommitDate = getDefaultCommitDate()
          handleDisableCommitDate()
        }
      }
    }

    const endDateChange = (index, record) => {
      if (index < segmentedData.value.length - 1 && record.endDate) {
        segmentedData.value[index + 1].startDate = moment(record.endDate).add(1, 'days').format("YYYY-MM-DD")
      } else {
        formData.value.assignmentTerm[1] = segmentedData.value[segmentedData.value.length - 1].endDate
        if (segmentedData.value[0].startDate) {
          formData.value.projectCommitDate = getDefaultCommitDate()
          handleDisableCommitDate()
        }
      }
    }
    const hoursPerDayChange = () => {
      let arr = segmentedData.value.map(item => item.hoursPerDay).filter(item => item)
      formData.value.hoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      //formData.value.hoursPerDay = segmentedData.value.map(item => item.hoursPerDay).reduce((prev,curr)=> (Number(prev)*10+Number(curr)*10)/10)
    }

    const segmenteOnBoardDisabledDate = (current, record, index) => {
      if (segmentedData.value[index].endDate) {
        return current > moment(record.endDate)
      }
    };
    const segmenteEstimateReleaseDate = (current, record, index) => {
      if (segmentedData.value[index].startDate) {
        return current < moment(record.startDate)
      }
    };

    const startValidatorSpace = async (rule, value) => {
      if (!value) {
        return Promise.reject("Please input Assignment Term");
      } else {
        if (rule.startDateIndex > 0 && segmentedData.value[rule.startDateIndex - 1].endDate) {
          let endDate = moment(segmentedData.value[rule.startDateIndex - 1].endDate).add(1, 'days')
          if (!moment(value).isSame(endDate)) {
            return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
          }
          // if(segmentedData.value[rule.startDateIndex].endDate) {
          //   if (!moment(value).isBefore(moment(segmentedData.value[rule.startDateIndex].endDate).add(1, 'days'))) {
          //     return Promise.reject("您所选择的时间不符合规定");
          //   } else {
          //     return Promise.resolve();
          //   }
          // }
        } else {
          return Promise.resolve();
        }
      }
    };
    const endValidatorSpace = async (rule, value) => {
      if (!value) {
        return Promise.reject("Please input Assignment Term");
      } else {
        if (rule.endDateIndex < segmentedData.value.length - 1) {
          if (segmentedData.value[rule.endDateIndex + 1].startDate) {
            let startDate = moment(segmentedData.value[rule.endDateIndex + 1].startDate).subtract(1, 'days')
            if (!moment(value).isSame(startDate)) {
              return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
            }
          }
        }
        if (segmentedData.value[rule.endDateIndex].startDate) {
          if (!moment(value).isAfter(moment(segmentedData.value[rule.endDateIndex].startDate).subtract(1, 'days'))) {
            return Promise.reject("您所选择的时间不符合规定");
          } else {
            return Promise.resolve();
          }
        }
      }
    };
    const hoursValidatorSpace = async (rule, value) => {
      if (!value) {
        return Promise.reject("Please input Hours Per Day");
      } else {
        if (rule.hoursIndex > 0) {
          if (value === segmentedData.value[rule.hoursIndex - 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else if (rule.hoursIndex < segmentedData.value.length - 1) {
            if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
              return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
            }
          } else {
            return Promise.resolve();
          }
        } else {
          if (value === segmentedData.value[rule.hoursIndex + 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else {
            return Promise.resolve();
          }
        }
      }
    }


    //修改时间按钮
    const rmEditTime = () => {
      ctx.emit("rm-edit-time", formData.value);
    };
    const assignmentStatus = computed(() => props.orgInfo.status);

    const codeFormData = reactive({
      pagelndex: 1,
      pageSize: 100,
      hasMoreItems: true
    })
    const handlePopupScroll = (e) => {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      //下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        codeFormData.scrollPage = 1
      } else {
        //当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 1) {
          if (codeFormData.hasMoreItems) {
            codeFormData.pagelndex = codeFormData.pagelndex + 1
            getSelectedCodeInfo('', 'scoll');
          }
        }
      }
    }
    const selectCodeScoll = ref('')
    const handleSelectCode = debounce((code) => {
      selectCodeScoll.value = code
      getSelectedCodeInfo(code, 'select');
    }, 500)


    //pipeline 获取Request account对应的Request industryLine
    watch(
        () => formData.value.pipelineDetailVO?.account,
        (val) => {
          if (val) {
            const relatedData = props.accountIndustryRelaction.find(
                (item) => item.account === val
            );
            formData.value.pipelineDetailVO.industryLine = relatedData?.industryLine;
            if(formData.value.matchType && formData.value.matchType === '2') {
              formData.value.account = val
              formData.value.industryLine = formData.value.pipelineDetailVO.industryLine
            }
          }
        },{ immediate: true, deep: true }
    );


    // JIRA-4102 逻辑
    const pmData = ref({
      selectPM: undefined
    })

    let pmForm = ref()

    const pmOption = computed(() => { return props.pmOptions })

    const pmRules = {
      selectPM: [
        {
          required: true,
          message: "请选择PM",
          trigger: ["change", "blur"],
        },
      ],
    }

    const filterOption = (input, option) => {
      if (!input) return false;
      let reg = /[\s|/,|/，]*/g
      return option.value.replace(reg, '').toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0 || option.ggid.toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0;
    }

    const isShowDemand = ref(false)

    const sendPMButton = (type) => {
      if (type == -1) {
        ctx.emit("sendCloseModal");
      } else {
        pmForm.value.validate().then(()=>{
          isShowDemand.value = true
          demandFilter.value.handleFormChange()
        }).catch(()=>{
          isShowDemand.value = false
        })
      }
    }

    const nextToDemand = () => {
      if (formData.value.assignmentTerm.filter((item) => { return item !== null }).length != 2) {
        message.error("Assignment Term 的开始时间和结束时间均是必填项")
        return
      }
      formRef.value.validate().then(()=>{
        isShowDemand.value = true
        demandFilter.value.handleFormChange()
      }).catch(()=>{
        isShowDemand.value = false
      })
    }
    // 代PM填写调令/填写调令 跳过北森
    const replacePMHandler = () =>{
      submitModal()
    }
    const demandModelValue = ref(false)
    const demandHandleOk = () => {
      if(props.buttonType == 'PM填写'){
        pmModelValue.value = true
      } else {
        replacePMHandler()
      }
    }

    const demandContent = ref('')
    const demandShowIconCom = ref('')

    const demandButton = (type) => {
      if(type == '取消'){
        closeModal()
      } else if(type == '上一步'){
        isShowDemand.value = false
      } else if(type == '跳过'){
          let text = props.rowType == 2 ? 'PipeLine需求' : '自定义需求'
          demandContent.value = `跳过关联至北森, 将会基于该${text}进行匹配, 是否确认?`
          demandShowIconCom.value = 'ExclamationCircleOutlined'
          demandModelValue.value = true
      } else if(type == '确定'){
        if(props.buttonType == 'PM填写'){
          pmModelValue.value = true
        } else {
          let pmId = PMOps.value.filter((item)=>{return item.value == formData.value.pmInfo})[0].id
          ctx.emit("upDemandId", selectDemand.value[0].id,pmId)
          submitModal()
        }
      }
    }

    const demandFilter = ref()
    const damandListRef = ref()

    const handleDemandSearch = (data) => {
      data.reset = true;
      damandListRef.value.resetCurrent(data);
      damandListRef.value.getDemandListData(data);
    }

    const selectDemand = ref([])
    const changeDemadnd = (val) => {
       selectDemand.value = val
    }

    const noLoginValue = ref(0)

    const pmModelValue = ref(false)
    const pmHandleOk = async() => {
      let obj = {}
      obj.pmId = pmOption.value.filter((item)=>{return item.value == pmData.value.selectPM})[0].id
      obj.preMatchingId = props.preMatchingId
      obj.type = String(noLoginValue.value)
      if(selectDemand.value.length){
        obj.demandId = selectDemand.value[0].id
      }
      $api.pipeLineToDemand(obj)
      message.success('更新成功,该需求匹配已经发送至PM,请至"处理中"查看')
      closeModal()
      ctx.emit("relevanceAndUpdata");
    }
    const pmHandleCancel = () => {
      noLoginValue.value = 1
    }

    //调令增加延期预设
    const disabledFcstDate = (current) => {
      return current < moment(formData.value.assignmentTerm[1]) || current > moment(formData.value.terminationDate || null)
    };
    const fcstDateValidator = () => {
      formRef.value.validate('extensionAssumptionDate');
    }

    const certaintyDisabled = ref(true)
    watch(
        () => formData.value.extensionAssumptionDate,
        (val) => {
          if(val) {
            certaintyDisabled.value = false
            formRules.certainty[0].required = true
          } else {
            certaintyDisabled.value = true
            formData.value.certainty = null
            formRules.certainty[0].required = false
          }
        }
    )


    return {
      labelCol: { span: 22 },
      wrapperCol: { span: 23 },
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      getRefsAff,
      workCityOps,
      getSelectOptions,
      getFormRulesOptions,
      EMOps,
      PMOps,
      getEMOptions,
      selectCityRef,
      selectEMRef,
      rejectModal,
      //rollBackModal,
      disabledDate,
      isShowComment,
      showDrawer,
      drawerVisible,
      annexDataList,
      annexColumns,
      delAnnexClick,
      onCloseDrawer,
      titleName,
      uploadAnnexRef,
      onUpdateFileList,
      isShowUploadAnnex,
      getPopupContainer,
      engagementCodeOpts,
      isFormalCode,
      placeHolderTip,
      isDisableProjectCommitDate,
      disabledCommitDate,
      handleAssChange,
      annexRightMsg,
      projectCommitEndDate,
      projectCommitStartDate,
      accountOpts,
      codeTerm,
      handleCodeChange,
      isAbleEm,
      handleAccountChange,
      getFilterredCodeOpt,
      isSendEmail,
      sendAssignmentLetter,
      isDisableDate,
      selectIsSend,
      saveModal,
      sendAssignmentLetterDataChange,
      disabledDateSendEmail,
      disabledTimeSendEmail,
      modelValue,
      content,
      showIconCom,
      handleOk,
      handleCancel,
      onBoardDisabledDate,
      estimateReleaseDisabledDate,
      isSegmentedEditing,
      columns,
      segmentedData,
      segmentedAdd,
      segmentedDel,
      segmentedEditingClick,
      cancelSegmentedEditingClick,
      rmEditTime,
      assignmentStatus,
      segmentedFormRules,
      startDateChange,
      endDateChange,
      hoursPerDayChange,
      segmenteOnBoardDisabledDate,
      segmenteEstimateReleaseDate,
      startValidatorSpace,
      endValidatorSpace,
      hoursValidatorSpace,
      segmentFormRef,
      handlePopupScroll,
      codeFormData,
      handleSelectCode,
      getSelectedCodeInfo,
      selectCodeScoll,
      pmData,
      pmRules,
      pmForm,
      filterOption,
      pmOption,
      sendPMButton,
      nextToDemand,
      isShowDemand,
      demandButton,
      damandListRef,
      handleDemandSearch,
      demandFilter,
      changeDemadnd,
      selectDemand,
      demandModelValue,
      demandHandleOk,
      demandContent,
      demandShowIconCom,
      pmModelValue,
      pmHandleOk,
      pmHandleCancel,
      noLoginValue,
      isPm,
      disabledFcstDate,
      certaintyDisabled,
      fcstDateValidator,
      assignmentTermRules
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/theme.less";
.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

.ant-calendar-picker {
  width: 100%;
}

.red-text {
  :deep(.ant-form-item-label) {
    >label {
      color: #ff4d4f;
    }
  }

  :deep(.ant-form) {
    padding: 0.625vw 1.25vw 0;
  }

  :deep(.ant-form-item-control-input-content) {
    color: #ff4d4f;
    font-size: 0.9vw;
  }

  :deep(.ant-form-item) {
    margin-bottom: 0;
  }
}

:deep(.ant-btn-dangerous:not(.ant-btn-sm)) {
  padding: 4px 16px;

  &:hover,
  &:focus,
  &:active {
    background: #ff4d4f;
    border-color: #ff4d4f;
  }
}

:deep .ant-radio {
  vertical-align: middle;
}

.headerBtn {
  text-align: right;

  .ant-btn {
    margin-left: 10px;
  }
}

.tip-text-primary {
  color: @primary-color;
}

.copy-person {
  margin-top: 5px;

  span {
    margin-right: 5px;
  }
}

.tipsCol .ant-form-item {
  :deep(.ant-select-selection-overflow) {
    flex-wrap: nowrap;
  }
}

.annex-container {
  text-align: initial;
  padding: 10px 24px;
}

.is-email {
  .ant-form {
    padding: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .ant-radio-group {
    display: flex;
    align-items: center;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
}

.assignmentTermClass {
  :deep(.ant-form-item-control-input-content) {
    display: flex;
    align-items: center;
  }

  .content {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    margin: 0 0.07vw
  }

  :deep(.ant-form-item-label::after) {
    content: '*'; /* 添加 * */
    color: #ff4d4f; /* 设置颜色为红色 */
    margin-left: 4px; /* 设置 * 和 label 之间的间距 */
  }

}

:deep(.ant-form-item-label > label) {
  color: #505050;
  font-size: 0.9vw;
}

.labelColor {
  :deep(.ant-form-item-label > label) {
    color: #999999;
  }
}

:deep(.ant-table-row-cell-ellipsis .ant-table-column-title) {
  font-size: 0.7vw;
  color: #999999;
}

.segmentedClass {
  :deep(.ant-table .ant-table-thead > tr > th) {
    padding-left: 0;
  }

  :deep(.ant-table .ant-table-tbody > tr > td) {
    //padding-left: 0;
    //padding-right: 0;
    padding: 1vw 0 0;
  }

  .tilde {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    margin: 0 -0.16vw
  }

  :deep(.ant-form-item) {
    flex-direction: row;
  }
}

.segmentedtaleclass {
  padding-left: 0 !important;
  padding-right: 0 !important;

  :deep(.ant-form-item-has-error) {
    .ant-col {
      min-height: auto;
    }
  }
}

.engagementCodeCol {
  :deep .ant-form-item-label>label::after {
    content: "";
  }

  .codeLabel {
    margin-right: 0.3vw;

    &::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.mandatory-asterisk {
  :deep(.ant-form-item-label > label) {
    &::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

:deep(.ant-form-vertical .ant-form-item-label) {
  font-family: PingFang SC;
}

.email {
  :deep(.ant-form-item-label > label) {
    font-size: 0.72917vw;
  }
}

.drawerDemand {
  :deep(.ant-form){
    padding:0px !important
  }
  .drawerDemandBtn {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 12px 24px;
    width: 100%;
    height: 64px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #ebebeb;
    z-index: 1;
    .btn-left{
      float: left;
    }
    .is-email{
      display: inline-block;
      line-height: 35px;
    }
    .ant-btn {
      margin-left: 10px;
    }
    .ant-btn-background-ghost{
      color: #999;
      border-color: #999;
    }


}
}
.fcstLabel {
  font-size: 0.9vw;
  .tipLabel {
    color: #3182ce;
    margin-left: 0.5vw;
    font-size: 16px;
  }
  .cerLabel {
    &::after {
      display: inline-block;
      margin-left: 0.20833vw;
      color: #ff4d4f;
      font-size: 0.72917vw;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  :deep(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
    content: ' ';
  }
}
</style>
