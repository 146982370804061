<template>
<!--  <section class="app-container" v-show="contentShowType === 'todoMain'">-->
    <!-- :destroyInactiveTabPane="true" 避免两个tab页table数据相互污染 -->
    <div class="titleTabs" v-show="contentShowType === 'todoMain'">
      <a-tabs v-model:activeKey="activeKey" :destroyInactiveTabPane="true" @change="initSelectData">
        <a-tab-pane key="1" tab="需求匹配" v-if="matchShow">
          <MatchingFilter ref="marchingFilterRef" :tabId="activeKey" :orgThreeTwoOps="orgThreeTwoOps"
            :orgFourOneOneOps="orgFourOneOneOps" :isRM="isRM" :isPM="isPM"  @getPMOrgList="getPMOrgList" @getSize="getSize" @search="handleSearch"
            @exportClick="exportClick" @getOrgList="getOrgList" @handleOrgChange="handleOrgChange"></MatchingFilter>
          <TodoList ref="matchingListRef" :tabId="activeKey" @onSwitchDetail="onSwitchDetail"></TodoList>
        </a-tab-pane>
        <a-tab-pane key="2" tab="调令管理" id="tabContent" v-if="assignmentShow">
          <AssignmentFilter ref="assignmentFilterRef" :orgThreeTwoOps="orgThreeTwoOps"
                            :orgFourOneOneOps="orgFourOneOneOps" :accountParamList="accountParamList" :isRM="isRM" :isPM="isPM"  @getPMOrgList="getPMOrgList" @search="handleSearch"
                            @getSize="getSize" @handleOrgChange="handleOrgChange" @exportClick="exportClick" @getOrgList="getOrgList" :rowlist="rowlist" @clearSelectedRows="clearSelectedRowsClick">
          </AssignmentFilter>
          <TodoList ref="assListRef" :tabId="activeKey" @onSwitchDetail="onSwitchDetail" @changerowlist="changerowlist($event)"></TodoList>
        </a-tab-pane>
        <a-tab-pane v-if="show" key="3" tab="Welcome Letter">
          <WelcomeLetterFilter ref="WelcomeLetterRef" :tabId="activeKey" :orgThreeTwoOps="orgThreeTwoOps"
            :orgFourOneOneOps="orgFourOneOneOps" :isRM="isRM" @getSize="getSize" @search="handleSearch"
            @towelcome="towelcome" @getOrgList="getOrgList"  @handleOrgChange="handleOrgChange" :disabled="welcomedisabled"></WelcomeLetterFilter>
          <TodoList ref="WelcomeRef" :tabId="activeKey" @WelcomeLetteronDetail="WelcomeLetteronDetail($event)"
            @changerowlist="changerowlist($event)"></TodoList>
        </a-tab-pane>
      </a-tabs>
    </div>
<!--  </section>-->
  <WelcomeLetter v-if="welcomeVisible" title="Welcome Letter" :rowlist="rowlist"
    @handleCancel="handleWelcomeCancel" @update="doSearch" v-model:visible="welcomeVisible"  />
  <component :is="contentShowType" v-if="contentShowType !== 'todoMain'" @onBackTodoList="onBackTodoList" />
</template>

<script>
import {
  defineComponent,
  ref,
  provide,
  computed,
  onActivated,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import AssignmentFilter from "@/views/home/TodoList/components/AssignmentFilter";
import MatchingFilter from "@/views/home/TodoList/components/MatchingFilter";
import WelcomeLetterFilter from "@/views/home/TodoList/components/WelcomeLetterFilter";
import MatchingList from "@/views/home/ResourceManagement/Match/matchingList/index";
import TodoList from "@/views/home/TodoList/components/TodoList";
import AssignmentDetails
  from "@/views/home/ResourceManagement/Assignment/assignmentDetails/index";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import {dictionaryOpt, downloadFile} from "@/utils/common";
import WelcomeLetter from '@/components/WelcomeLetter.vue';
import {isShowTab} from '@/utils/isshow.js'
export default defineComponent({
  name: "Assignment",
  components: { AssignmentFilter, TodoList, AssignmentDetails, MatchingList, MatchingFilter, WelcomeLetterFilter, WelcomeLetter },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const assListRef = ref();
    const matchingListRef = ref();
    const WelcomeRef = ref();
    const assignmentFilterRef = ref();
    const marchingFilterRef = ref();
    const route = useRoute()
    const activeKey = ref('1');
    route.query.flag?activeKey.value=route.query.flag:''
    const contentShowType = ref("todoMain");
    const todoQueryDetail = ref({});
    const store = useStore();
    const listRef = computed(() => activeKey.value === '1' ? matchingListRef.value : activeKey.value === '2' ? assListRef.value :WelcomeRef.value);

    provide("query", computed(() => todoQueryDetail));

    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    // 跳转详情
    const onSwitchDetail = (query) => {
      store.commit("SET_IS_TODO_STATUS", true);
      contentShowType.value = query.source;
      todoQueryDetail.value = query;
    };

    // 返回待办列表时间
    const onBackTodoList = () => {
      route.query.flag?activeKey.value=route.query.flag:''
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      listRef.value.doSearch()
      contentShowType.value = "todoMain";
    };

    // 重新计算table所占高度
    const getSize = () => {
      listRef.value.getSize();
    };

    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);

    //查询org3.2/org4.2
    const getOrgList = () => {
      $api
        .getOrgThree_FourList()
        .then((res) => {
          const { orgThreeTwo, orgFourOneOne } = res.data;
          orgThreeTwoOps.value = dictionaryOpt(orgThreeTwo);
          orgFourOneOneOps.value = dictionaryOpt(orgFourOneOne);
        });
    };
    const getPMOrgList = () => {
      $api
          .queryComboBox()
          .then((res) => {
            const { orgThreeTwoList, orgFourOneOneList, accountList } = res.data;
            orgThreeTwoOps.value = dictionaryOpt(orgThreeTwoList);
            orgFourOneOneOps.value = dictionaryOpt(orgFourOneOneList);
            accountParamList.value = dictionaryOpt(accountList);
          });
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.queryComboBoxRelation().then((res) => {
        orgAllList.value = res.data
      })
    }

    // team改变时更新offering下拉框内容
    const handleOrgChange = (val) => {
      if(isPM.value){
        if (val?.length) {
          orgFourOneOneOps.value = dictionaryOpt(orgAllList.value.filter(item => val.includes(item.orgThreeTwo)).map(item => item.orgFourOneOne))
        } else {
          getPMOrgList()
        }
      } else {
        if (val?.length) {
          $api
              .getOrgFour({condition: val.join()})
              .then((res) => {
                orgFourOneOneOps.value = dictionaryOpt(res.data.orgFourOneOne);
              });
        } else {
          getOrgList();
        }
      }
    };

    const accountParamList = ref([]);

    // 获取account下拉框内容
    const getAccountOptions = () => {
      $api.getTodoAccount().then((res) => {
        if (res.code === 200) {
          accountParamList.value = res.data.map((item) => {
            return {
              label: item.account,
              value: item.account_code,
            };
          });
        } else {
          message.error(res.msg);
        }
      });
    };
    const roles = computed(() => store.state.app.user.roles);
    const isRM = ref(roles.value.indexOf("2") !== -1);
    const isPM = ref(roles.value.indexOf("3") !== -1);
    const isRmLeader = ref(roles.value.indexOf("11") !== -1);

    //切换tab页重新获取org下拉列表内容
    const initSelectData = () => {
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      if (isRM.value || isRmLeader.value) getOrgList();
      if (isPM.value) {
        getPMOrgList();
        getOrgRelatedList();
      }
    }

    const exportClick = (filterData) => {
      const exportFuc = activeKey.value === '1' ? 'doMarchingExport' : 'doAssExport'
      $api[exportFuc](filterData).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const welcomeVisible = ref(false)
    const towelcome = () => {
      welcomeVisible.value = true
    }
    const rowlist = ref([])
    const welcomedisabled=ref(true)
    const changerowlist = (list) => {
      welcomedisabled.value=list.length===0?true:false
      rowlist.value = list
    }
    const WelcomeLetteronDetail = (record) => {
      rowlist.value = []
      rowlist.value[0] = record
      welcomeVisible.value = true
    }

    const clearSelectedRowsClick = () => {
      listRef.value.clearSelectedRows();
    }

    onMounted(() => {
      activeKey.value = matchShow? '1': assignmentShow? '2':'3'
      if (isRM.value || isRmLeader.value) {
        getOrgList();
        getAccountOptions()
      }
      if (isPM.value) {
        getPMOrgList();
        getOrgRelatedList();
      }
    })

    onActivated(() => {
      route.query.flag?activeKey.value=route.query.flag:''
      //contentShowType.value = "todoMain";
      // 页面切换回待办列表时设置为true
      store.commit("SET_IS_TODO_STATUS", true);
      setTimeout(()=>{
        listRef.value.doSearch()
      },500)
    })

    onBeforeRouteLeave(() => {
      contentShowType.value = "todoMain";
      // 页面离开待办列表时设置为false
      store.commit("SET_IS_TODO_STATUS", false);
      //删除dashboard 的传参
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
    });
    const handleWelcomeCancel=()=>{
      //删除dashboard 的传参
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      welcomeVisible.value=false
      listRef.value.doSearch()
    }
    //welcome letter,调令管理,需求匹配 tab标签页权限配置
    const show=isShowTab('/system/staffInfo/welcomelettertab')
    const assignmentShow = isShowTab('/system/staffInfo/dispatch')
    const matchShow = isShowTab('/system/staffInfo/requirementmatching')

    return {
      activeKey,
      assListRef,
      matchingListRef,
      WelcomeRef,
      handleSearch,
      onSwitchDetail,
      contentShowType,
      todoQueryDetail,
      onBackTodoList,
      assignmentFilterRef,
      marchingFilterRef,
      getSize,
      orgThreeTwoOps,
      orgFourOneOneOps,
      getOrgList,
      handleOrgChange,
      accountParamList,
      getAccountOptions,
      initSelectData,
      exportClick,
      isRM,
      welcomeVisible,
      towelcome,
      changerowlist,
      rowlist,
      WelcomeLetteronDetail,
      handleWelcomeCancel,
      show,
      welcomedisabled,
      isPM,
      getPMOrgList,
      getOrgRelatedList,
      assignmentShow,
      matchShow,
      isRmLeader,
      clearSelectedRowsClick,
    };
  },
});
</script>

<style scoped lang="less">
//.app-container {
//  height: 100%;
//  overflow: auto;

  .titleTabs {
    // 大标题的tab样式
    @import "~@/style/newTitleTab.less";
    :deep(.ant-tabs) {
      margin: 0 16px 0;

      .ant-tabs-tabpane {
        background-color: #fff;
        padding: 10px 20px;
        min-height: 75vh;
      }
    }
  }


//}
</style>
