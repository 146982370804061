<template>
  <a-drawer :getContainer="getRefsAff" :visible="showMateInfo" @close="cancel" :footer="null"
    :bodyStyle="{ padding: '0px' }" width="38.5vw" class="mateInfo">
    <template #title>
      <strong class="nameD"> 匹配详情 </strong>
    </template>
    <div class="cusTitle" v-if="isConsult">
      <div class="demandName">
        <div class="circle"></div>
        <span style="max-width: 90%;font-weight: 600;">{{ empDetail.demandName }}</span>
      </div>
      <div style="display: flex;min-width: 30%;">
        <div style="margin-right: 1vw">
          <div class="info1">已有匹配</div>
          <div class="info2">
            <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ empDetail.mateNumber }}</span>
            <span style="font-size: 0.83vw">人</span>
          </div>
        </div>
        <div class="info3"></div>
        <div style="margin-left: 1vw">
          <div class="info1">已有调令</div>
          <div class="info2">
            <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ empDetail.transferOrderNumber }}</span>
            <span style="font-size: 0.83vw">项</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalContentLeft">
        <div class="demandInfo">
          <CardTitle class="card">需求信息</CardTitle>
          <a-row class="demandInfoRow">
            <a-col :span="12">
              <div>
                需求编号:
                <p class="text">{{ empDetail.demandNumber }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                需求名称:
                <p class="text">{{ empDetail.demandName }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                项目名称:
                <p class="text">{{ empDetail.projectName }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                优先级:<p class="text">{{ empDetail.priority }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                工作地点:<p class="text">{{ empDetail.demandWorkLocation }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div v-if="!currentRole.includes('4')">
                人员级别:<p class="text">{{ empDetail.staffGrade }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                期望到岗时间:<p class="text">{{ empDetail.expectArrivalTime }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                排序到岗时间:<p class="text">{{ empDetail.sortingArrivalTime }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                行业线:<p class="text">{{ empDetail.industryLine }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                预计用人时长:<p class="text">{{ empDetail.useStaffLength }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                客户名称:<p class="text">{{ empDetail.customerName }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                详细客户名称:<p class="text">{{ empDetail.customerNameDetail }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                客户服务经理:<p class="text">{{
                  empDetail.csm?.substring(0, empDetail.csm?.indexOf('(')) + ' ' +
                  empDetail.csm?.substring(empDetail.csm?.indexOf('('))
                }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                项目经理:<p class="text">{{ empDetail.demandPm }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div v-if="empDetail.originalDemand">
                原需求渠道:
                 <p class="text">{{ empDetail.originalDemand}}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div v-if="empDetail.originalDemandId">
                需求ID:
                <p class="text">{{empDetail.originalDemandId}}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                项目补充说明:<p class="text" :title="empDetail.projectSupplement">
                  {{ empDetail.projectSupplement ? empDetail.projectSupplement : '-' }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="modalContentRight">
        <div class="empInfo">
          <CardTitle class="card">顾问人员信息</CardTitle>
          <a-row class="demandInfoRow">
            <a-col :span="12">
              <div>
                姓名:<p class="text">{{ empDetail.fullName }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                邮箱:<p class="text">{{ empDetail.email }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                职级:<p class="text">{{ empDetail.psGroup }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                角色:<p class="text">{{ empDetail.position }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                工作地点:<p class="text">{{ empDetail.staffWorkLocation }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                人员状态:<p class="text">{{ empDetail.status }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                service line:<p class="text">{{ empDetail.serviceLine }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                sub service line:<p class="text">{{
                  empDetail.subServiceLine
                }}</p>
              </div>
            </a-col>
            <a-col :span="24">
              <div>
                PM:<p class="text">{{ empDetail.pmName + ' (' + empDetail.pmEmail + ')' }}</p>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                ARVE Assumption %:
                <div class="arveChange" v-show="arveValueChange">
                  <p class="text arveText">{{ empDetail.arveAssumption }}</p>
                  <FormOutlined class="changeIcon" v-if="canChangeARVE" @click="changeArve('num', empDetail.arveAssumption)" />
                </div>
                <div class="positionAnother" v-show="!arveValueChange">
                  <a-form ref="formRef" :model="arveFormValue">
                    <a-form-item name="value" :rules="[
                      {
                        validator: () => validateArve(arveFormValue.value),
                        trigger: ['change', 'blur'],
                      }]">
                      <a-input autocomplete='off' style="width:70px" v-model:value="arveFormValue.value"
                        placeholder="请输入" />
                      <CheckOutlined class="changeIcon confirmIcon" @click="confirmArve('num')" />
                    </a-form-item>
                  </a-form>
                </div>
              </div>
            </a-col>
            <a-col :span="12">
              <div>
                ARVE Assumption Start Date:
                <div class="arveChange" v-show="arveTimeChange">
                  <p class="text arveText">{{ convertDateFormat(empDetail.arveAssumptionStartDate) }}</p>
                  <FormOutlined class="changeIcon" v-if="canChangeARVE"
                    @click="changeArve('date', convertDateFormat(empDetail.arveAssumptionStartDate))" />
                </div>
                <div class="positionAnother" v-show="!arveTimeChange">
                  <a-form ref="timeRef" :model="arveFormTime">
                    <a-form-item name="value" :rules="[
                      {
                        validator: () => validateTime(arveFormTime.value),
                        trigger: ['change', 'blur'],
                      }]">
                      <a-date-picker class="item" style="width: 150px" v-model:value="arveFormTime.value" type="date"
                        placeholder="选择日期" valueFormat="YYYY-MM-DD"/>
                      <CheckOutlined class="changeIcon" @click="confirmArve('date')" />
                    </a-form-item>
                  </a-form>

                </div>

              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { message } from "ant-design-vue";
import { defineComponent, computed, ref,watch } from "vue";
import { useStore } from "vuex";
import CardTitle from "@/components/CardTitle.vue";
import { FormOutlined, CheckOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
export default defineComponent({
  components: { CardTitle, FormOutlined, CheckOutlined },
  props: {
    showMateInfo: Boolean,
    empDetail: Object,
    // 来区分是匹配流程中(Process)的弹窗还是匹配列表(List)的弹窗，匹配列表的弹窗有额外的ARVE数据
    type: {
      type: String,
      default: 'Process'
    },
    // canChangeARVE 为true 可以弹窗内修改ARVE
    canChangeARVE: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close-mata-info-modal","updateArve",'refreshTable'],
  setup(props, { emit }) {
    const isConsult = computed(() => {
      const roles = store.state.app.user.roles;
      console.log('roles',roles)
      return !roles.includes('4')
      // return !(roles.indexOf("4") !== -1)
    });
    const { $api } = useGlobalPropertyHook()
    const cancel = () => {
      emit("close-mata-info-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    // 当前账号的角色类型数组
    const store = useStore();
    const currentRole = computed(() => {
      return store.state.app.user.roles
    })
    //console.warn(store.state.app.user.roles);

    const convertDateFormat = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }


    const arveValueChange = ref(true)
    const arveTimeChange = ref(true)
    const formRef = ref()
    const timeRef = ref()
    const arveFormValue = ref({})
    const arveFormTime = ref({})

    watch(()=>props.showMateInfo,(val)=>{
      if(val == true){
        arveValueChange.value = true
        arveTimeChange.value = true
      }
    },{immediate:true})

    // type 是num 是值 是date 是日期
    const changeArve = (type, value) => {
      if (type == 'num') {
        arveFormValue.value = { value }
        arveValueChange.value = !arveValueChange.value
      } else {
        arveFormTime.value = { value }
        arveTimeChange.value = !arveTimeChange.value
      }
    }

    const validateArve = (value) => {
      const isValidNumber = (input) => {
        const regex = /^(0$|[1-9][0-9]?|100)$/;
        return regex.test(input);
      }
      let flag = isValidNumber(value)
      if (!flag) {
        return Promise.reject('请输入0-100之间的数字')
      } else {
        return Promise.resolve()
      }
    }
    const validateTime = (value) => {
      if(value == null){
        return Promise.reject('ARVE Assumption Start Date 为必填项')
      } else {
        return Promise.resolve()
      }
    }

    const confirmArve = (type) => {
      if (type == 'num') {
        formRef.value.validate().then(async () => {
          let arr = []
          arr[0] = {
            "preMatchingId": props.empDetail.preMatchingId,
            "arveAssumption": arveFormValue.value.value,
            "arveAssumptionStartDate": convertDateFormat(props.empDetail.arveAssumptionStartDate)
          }
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([props.empDetail.preMatchingId])
          arveValueChange.value = !arveValueChange.value
          emit("updateArve", res.data)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption % 填写的不符合规则");
        })
      } else {
        timeRef.value.validate().then(async () => {
          let arr = []
          arr[0] = {
            "preMatchingId": props.empDetail.preMatchingId,
            "arveAssumption": props.empDetail.arveAssumption,
            "arveAssumptionStartDate": arveFormTime.value.value
          }
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([props.empDetail.preMatchingId])
          arveTimeChange.value = !arveTimeChange.value
          emit("updateArve", res.data)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption % 填写的不符合规则");
        })
      }
    }

    return {
      cancel,
      getRefsAff,
      currentRole,
      convertDateFormat,
      changeArve,
      arveValueChange,
      arveTimeChange,
      arveFormValue,
      arveFormTime,
      validateArve,
      validateTime,
      confirmArve,
      formRef,
      timeRef,
      isConsult
    };
  },
});
</script>

<style lang="less" scoped>
.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;

  .modalContentLeft {
    width: 100%;
    overflow: hidden;

    .demandInfo {
      margin: 10px 0px 10px 0.5vw;

      .card {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #4D4D4D;

        &::before {
          top: 0.1vw;
        }
      }

      .demandInfoRow {
        margin-top: 1vw;
        color: rgba(77, 77, 77, 0.6);
        white-space: pre;
        margin-bottom: 10px !important;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .modalContentRight {
    width: 100%;

    .empInfo {
      margin: 10px 0px 10px 0.5vw;

      .card {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #4D4D4D;

        &::before {
          top: 0.1vw;
        }
      }

      .demandInfoRow {
        margin-top: 1vw;
        color: rgba(77, 77, 77, 0.6);
        white-space: pre;
        margin-bottom: 10px !important;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.addDetail {
  padding: 10px 20px;

  :deep(.ant-collapse) {
    background-color: #fff;
  }
}

.text {
  //margin-left: 16px;
  color: #4D4D4D;
  white-space: initial;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0.2vw 0 0.83vw 0.2vw !important;
}

.nameD {
  font-size: 20px;
}

.cusTitle {
  border: 1px solid #CCCCCC99;
  margin: 1vw;
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(24, 144, 255);
    margin-right: 15px;
  }

  .demandName {
    font-size: 1vw;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    max-width: 70%;
  }

  .info1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.22em;
    color: #999999;
  }

  .info2 {
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  .info3 {
    height: 40px;
    border: 1px solid #CCCCCC
  }
}

.arveChange {
  display: flex;
  align-items: center;

  .arveText {
    margin: 0.2vw 0.5vw 0vw 0.2vw !important;
  }

  .changeIcon {
    display: 'block';
    margin-top: 0.2vw;
    cursor: pointer;
    color: rgb(49, 130, 206)
  }


  .ant-form {
    padding: 0.2vw !important;

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
.positionAnother {
  .arveText {
    margin: 0.2vw 0.5vw 0vw 0.2vw !important;
  }

  .changeIcon {
    display: 'block';
    margin-top: 0.2vw;
    margin-left: 10px;
    cursor: pointer;
    color: rgb(49, 130, 206)
  }

  .ant-form {
    padding: 0.2vw !important;
    position: relative;

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
</style>
