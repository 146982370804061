<template>
  <section class="top-filter">
    <div class="filterContainer" v-if="!isTodoStatus">
      <div class="filterLeft">
        <filter-layout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef">
          <template #customform>
            <a-form ref="formRef" class="form rms-form" :model="formState" layout="vertical">
              <a-form-item label="搜索" name="condition" class="rms-form-item" v-if="!isTodoStatus && activeTab !== '0'">
                <a-input v-model:value="formState.condition" placeholder="项目名称/顾问姓名" enter-button @keyup.enter="doSearch"
                  allowClear autocomplete="off" />
              </a-form-item>
              <a-form-item label="需求编号" name="demandNumber" class="rms-form-item" v-if="!isTodoStatus && activeTab !== '0'">
                <a-input v-model:value="formState.demandNumber" placeholder="需求编号" enter-button @keyup.enter="doSearch"
                  allowClear autocomplete="off" />
              </a-form-item>
              <a-form-item label="需求渠道" name="source" class="rms-form-item" v-if="!isTodoStatus && activeTab !== '0'">
                <a-select v-model:value="formState.source" :options="sourceOptions" @change="changeSourceOptions"
                  placeholder="请选择" allowClear show-search>
                </a-select>
              </a-form-item>
              <a-form-item name="completeChannel" v-if="!isTodoStatus && activeTab !== '0'" class="rms-form-item">
                <template v-slot:label>
                  <span>北森完成渠道</span>
                  <a-popover placement="topLeft">
                    <QuestionCircleOutlined :style="{ color: '#3182ce', 'margin-bottom': '0.15vw' }" />
                    <template #content>
                      TA 渠道的需求只允许匹配一个顾问
                    </template>
                  </a-popover>
                </template>
                <a-select :disabled="formState.source == undefined || formState.source == '' || formState.source !== '0'"
                  v-model:value="formState.completeChannel" :options="completeChannelOps" @change="doSearch"
                  placeholder="请选择" allowClear show-search>
                </a-select>
              </a-form-item>
              <a-form-item label="搜索" name="pipelineInfo" class="rms-form-item" v-if="!isTodoStatus && activeTab === '0'">
                <a-input v-model:value="formState.pipelineInfo" placeholder="Opportunity Name/THOR ID/PPL ID" enter-button
                  @keyup.enter="doSearch" allowClear autocomplete="off" />
              </a-form-item>
              <a-form-item label="顾问" name="consultant" class="rms-form-item" v-if="!isTodoStatus && activeTab === '0'">
                <a-input v-model:value="formState.consultant" placeholder="Name/GGID" enter-button @keyup.enter="doSearch"
                  allowClear autocomplete="off" />
              </a-form-item>
              <a-form-item label="ARVE Assumption Start Date" name="arveDate" class="rms-form-item"
                v-if="!isTodoStatus && activeTab === '0'">
                <a-range-picker v-model:value="formState.arveDate" :placeholder="['Start date', 'End date']"
                  format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>
          </template>
        </filter-layout>
      </div>
      <div class="filterRight">
        <a-button v-auth="['system:matching:new']" type="primary" @click="newMatch" v-show="!isTodoStatus">
          <PlusOutlined />
          新建匹配
        </a-button>
      </div>
    </div>
    <div class="filter">
      <div class="left">
        <a-tabs v-model:activeKey="activeTab" defaultActiveKey="1" class="left" @change="tabChange">
          <a-tab-pane key="0" tab="Pipeline 预匹配" v-if="isRM && !isTodoStatus"></a-tab-pane>
          <a-tab-pane key="1" tab="处理中 In Progress"></a-tab-pane>
          <a-tab-pane key="2" tab="已完成 Completed" v-if="!isTodoStatus"></a-tab-pane>
          <a-tab-pane key="3" tab="已失效 Invalid" v-if="!isTodoStatus"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="right">
        <a-space>
          <a-button type="primary" @click="batchChangeARVE" ghost v-show="activeTab === '1' || activeTab === '0'"
            :disabled="paramsSelectedData.selectData.length === 0" v-auth="['system:matching:arve']">
            <img src="@/assets/images/arve.png" class="filtericon" />修改ARVE
          </a-button>
          <a-button type="primary" ghost v-show="activeTab === '1' || activeTab === '0'" @click="rmBatchRevertClick"
            v-auth="['system:matching:revert']" :disabled="paramsSelectedData.selectData.length === 0">
            <img src="@/assets/images/withdraw.png" class="filtericon" v-if="paramsSelectedData.selectData.length > 0" />
            <img src="@/assets/images/withDrawAll.svg" class="filtericon" v-else />
            撤回
          </a-button>
          <a-button type="primary" v-if="(activeTab === '1' || activeTab === '0') && isSelectAll" @click="removeSelectedAll"
            v-auth="['system:matching:select']">
            <img src="@/assets/images/selectall.png" class="filtericon" />全选
          </a-button>
          <a-button type="primary" ghost v-if="(activeTab === '1' || activeTab === '0') && !isSelectAll"
            @click="selectAllClick" v-auth="['system:matching:select']">
            <img src="@/assets/images/selectallBlue.png" class="filtericon" />全选
          </a-button>
        </a-space>
      </div>
    </div>
    <revert-modal v-model:visible="revertVisible" v-model:formState="revertFormState" :title="revertTitle"
      :comment-label="revertCommentLabel" @close-modal="closeRevertModal" @submit-modal="submitRevertModal" />
    <ModalBox v-model="errorVisible" :ok="handleOk" :content="content" :showIconCom="showIconCom" :modalWidth="650">
      <template #content>
        <div v-for="(item, index) in errorContent" :key="index">
          <div>{{ item }}</div>
        </div>
      </template>
    </ModalBox>
  </section>
  <batchChangeArveModal ref="batchChangeArveModal" :visible="batchVisible" :list="batchList" @closeModal="closeBatchModal"
    @refreshTableBtPage="refreshTableBtPage" />
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  computed,
  onActivated,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useGlobalPropertyHook } from "@/hooks/common";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import RevertModal from "@/views/home/ResourceManagement/Match/matchingList/components/RevertModal.vue";
import { message } from "ant-design-vue";
import ModalBox from "@/components/ModalBox.vue";
import batchChangeArveModal from "./batchChangeArve.vue";
export default defineComponent({
  name: "Filter",
  components: { ModalBox, RevertModal, QuestionCircleOutlined, PlusOutlined, batchChangeArveModal },
  props: {
    paramsSelectedData: {
      type: Object,
      default: () => {
        return {
          matchList: [],
          selectData: [],
        }
      },
    },
  },
  setup(props, ctx) {
    const formRef = ref();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const newMatch = () => {
      router.push({ path: "/newMatch" });
    };
    let formState = reactive({
      demandNumber: "",
      condition: "",
      type: "project",
      source: undefined,
      completeChannel: undefined,
      arveDate: [],
      pipelineInfo: "",
      consultant: "",
      beginDate: "",
      endDate: "",
    });
    let originState = reactive({
      demandNumber: "",
      condition: "",
      type: "project",
      source: undefined,
      completeChannel: undefined,
      arveDate: [],
      pipelineInfo: "",
      consultant: "",
      beginDate: "",
      endDate: "",
    })

    const activeTab = ref("1");
    const doSearch = () => {
      if(JSON.stringify(formState) != JSON.stringify(originState)){
        originState = JSON.parse(JSON.stringify(formState))
        ctx.emit("clearSelect");
      }
      ctx.emit("search", activeTab.value, formState);
    };

    const doSearchByPage = () => {
      ctx.emit("doSearchByPage", activeTab.value, formState)
    }

    const tabChange = () => {
      originState = {
        demandNumber: "",
        condition: "",
        type: "project",
        source: undefined,
        completeChannel: undefined,
        arveDate: [],
        pipelineInfo: "",
        consultant: "",
        beginDate: "",
        endDate: "",
      }
      resetForm()
    }

    const isTodoStatus = computed(() => store.state.app.isTodoStatus);

    const completeChannelOps = ref([]);
    const sourceOptions = ref([])

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "completeChannel",
          },
        })
        .then((res) => {
          completeChannelOps.value = createSelectOptionData(
            res.data.completeChannel
          )
        })
      $api.getDictionaryList({
        params: {
          dictType: "source",
        },
      })
        .then((result) => {
          sourceOptions.value = createSelectOptionData(
            result.data.source
          )
        })
    };
    // 构建select筛选options
    const createSelectOptionData = (data) => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: item.dictCode,
          key: item.dictId,
        };
      });
      return options;
    };
    watch(
      () => route.query.id,
      (val) => {
        if (val) {
          $EventBus.on("getActiveTab", (getActiveTab) => {
            activeTab.value = getActiveTab;
          });
        }
      }
    );
    const filterLayoutRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      formState.type = "project";
      formState.beginDate = ''
      formState.endDate = ''
      formState.source = undefined
      formState.completeChannel = undefined
      ctx.emit("search", activeTab.value, formState);
      ctx.emit("clearSelect");
    };

    onMounted(() => {
      getSelectOptions();
      doSearch();
    });

    onActivated(() => {
      if (route.query.id) {
        $EventBus.on("getActiveTab", (getActiveTab) => {
          activeTab.value = getActiveTab;
        });
      }
      ctx.emit("refresh-table");
    });

    const changeSourceOptions = () => {
      formState.completeChannel = undefined
    }

    const isSelectAll = computed(() => {
      if (props.paramsSelectedData.selectData.length > 0) {
        return props.paramsSelectedData.matchList.every(item =>
          props.paramsSelectedData.selectData.map(jtem => jtem.preMatchingId).includes(item.preMatchingId)
        )
      } else {
        return false
      }
    })

    const selectAllClick = () => {
      ctx.emit("select-all");
    }

    const removeSelectedAll = () => {
      ctx.emit("select-remove");
    }

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const rmBatchRevertClick = () => {
      revertFormState.preMatchingId = props.paramsSelectedData.selectData.map(item => item.preMatchingId);
      revertVisible.value = true;
      revertTitle.value = "撤回";
      revertCommentLabel.value = "Revert Reason";
    }
    const revertVisible = ref(false);
    const revertTitle = ref("");
    const revertCommentLabel = ref("");
    const revertFormState = reactive({
      preMatchingId: [],
      rejectReason: "",
    });
    const closeRevertModal = () => {
      Object.assign(revertFormState, {
        preMatchingId: [],
        rejectReason: "",
      });
      revertVisible.value = false;
      revertTitle.value = "";
      revertCommentLabel.value = "";
    };
    const submitRevertModal = () => {
      const params = {
        preMatchingIdList: revertFormState.preMatchingId,
        revertComments: revertFormState.rejectReason,
      };
      $api.rmRevert(params).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeRevertModal();
        doSearch();
        ctx.emit("clearSelect");
        if (res.data && res.data.length > 0) {
          errorContent.value = res.data
          errorVisible.value = true
        } else {
          message.success(res.msg);
        }
      });
    };

    const errorVisible = ref(false)
    const content = ref('')
    let showIconCom = ''
    const errorContent = ref([])
    const handleOk = () => {
      errorVisible.value = false
    }
    const batchChangeArveModal = ref()
    const batchVisible = ref(false)
    const batchList = ref([])
    const batchChangeARVE = () => {
      batchVisible.value = true
      batchChangeArveModal.value.selectedRowKeys = props.paramsSelectedData.selectData.map((item) => { return item.preMatchingId })
      batchList.value = props.paramsSelectedData.selectData
    }
    const closeBatchModal = () => {
      batchVisible.value = false
    }

    const refreshTableBtPage = (updata) => {
      ctx.emit("updateSelectList", updata);
      doSearchByPage()
    }


    return {
      store,
      formRef,
      formState,
      newMatch,
      activeTab,
      doSearch,
      tabChange,
      isTodoStatus,
      completeChannelOps,
      filterLayoutRef,
      resetForm,
      sourceOptions,
      changeSourceOptions,
      selectAllClick,
      isRM,
      isSelectAll,
      removeSelectedAll,
      rmBatchRevertClick,
      revertVisible,
      revertTitle,
      revertCommentLabel,
      revertFormState,
      closeRevertModal,
      submitRevertModal,
      errorVisible,
      content,
      handleOk,
      showIconCom,
      errorContent,
      batchChangeARVE,
      batchVisible,
      closeBatchModal,
      batchList,
      doSearchByPage,
      refreshTableBtPage,
      batchChangeArveModal
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/newTitleTab.less";

.filterContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .filterRight {
    display: flex;
    align-items: flex-end;
  }
}

::v-deep {
  .ant-tabs-bar {
    border: none;
  }
}

.top-filter {
  padding: 10px 20px 0;
  background: #f6f9fc;

  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
    }

    .right {
      display: flex;
    }
  }
}

:deep(.ant-form-item-label > label > .anticon) {
  vertical-align: middle;
  margin-left: 0.2vw;
}

.filtericon {
  width: 24px;
  height: 24px;
  margin: 0 0.4vw 0.2vw 0;
}</style>
