<template>
    <a-form :style="{ 'margin-top': '15px', 'width': '17vw', 'display': 'inline-block' }" :model="formData"
        :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
        <a-form-item :label="label" name="date" :label-col="{ span: 1 }" :wrapper-col="{ span: 15 }" :colon="false">
            <a-date-picker @change="seltime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model:value="formData.date"
                :disabledDate="disabledDate" />
            <a-select @change="changeselecttime" :style="{ 'margin-left': '20px' }" v-model:value="formData.selecttime"
                placeholder="请选择">
                <a-select-option v-for="item in selecttimeops" :key="item" :value="item.value"><span :title="item.title">{{
                    item.title
                }}</span>
                </a-select-option>
            </a-select>
        </a-form-item>
    </a-form>
</template>
<script>
import moment from "moment";
import { defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
    name: "DatePickerselect",
    props: {
        visible: Boolean,
        time: String
    },
    emits: ['resetdate', 'setdate'],
    setup(props, ctx) {
        const selecttimeops = ref([])
        const formData = ref({ date: '', selecttime: '' })
        const disabledDate = (current) => {
            return current < moment().subtract(1, 'day').endOf('day');
        }
        //选择日期触发函数
        const seltime = () => {
            formData.value.selecttime = ''
            if (formData.value.date === moment().format("YYYY-MM-DD")) {
                hour.value = moment().hour() + 1
                getslecttimeops()
            } else {
                hour.value = 0
                getslecttimeops()
            }
            ctx.emit('resetdate')
        }
        //选择时间(小时触发)
        const changeselecttime = () => {
            ctx.emit('setdate', formData)
        }
        const hour = ref(0)
        //设置小时选择范围
        const getslecttimeops = () => {
            let ops = []
            for (let i = hour.value; i < 24; i++) {
                if (i < 10) {
                    ops.push({ value: `0${i}:00:00`, title: `${i}:00` })
                } else {
                    ops.push({ value: `${i}:00:00`, title: `${i}:00` })
                }
            }
            selecttimeops.value = ops
        }
        onMounted(() => {
            getslecttimeops()
        })
        watch(
            () => props.time,
            () => {
                console.log(props.time);
                const strtime = props.time
                const date = strtime.slice(0, 10)
                const selecttime = strtime.slice(11, 19)
                formData.value = { date, selecttime }
                ctx.emit('setdate', formData)
            },

        )
        return {
            selecttimeops,
            seltime,
            hour,
            getslecttimeops,
            disabledDate,
            formData,
            changeselecttime,
        };
    },
});
</script>
  
<style lang="less" scoped>
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
    width: 6vw;
}

.ant-row {
    width: 31.83333vw !important;
}
</style>
  