export function assignmentStatus(statusKey) {
  switch (statusKey) {
    case "1":
      return "HardBook";
    case "2":
      return "上项目待确认";
    case "3":
      return "上项目待RM审批";
    case "4":
      return "上项目已确认";
    case "5":
      return "项目进行中";
    case "6":
      return "下项目待确认";
    case "7":
      return "下项目待RM审批";
    case "8":
      return "下项目已确认";
    case "9":
      return "已完成";
    case "12":
      return "已失效";
    case "13":
      return "待评价";
    case "14":
      return "重启调令待审批";
  }
  return "";
}

export function preMatchingNextStatus(statusKey) {
  switch (statusKey) {
    case "proposeToProject":
      return "PM待筛选简历";
    case "pmCVInterview":
      return "PM面试结果待反馈";
    case "rmReject":
      return "RM驳回待查看";
    case "pmSoftBook":
      return "RM待确定调令";
  }
  return "";
}

export function releaseModeStatus(statusKey) {
  switch (statusKey) {
    case "1":
      return "Interrupt";
    case "2":
      return "Normal Release";
    case "3":
      return "Sudden Release";
    case "4":
      return "Error Assignment Cancel";
    case "5":
      return "Request Cancel";
    case "6":
      return "Exceed";
  }
  return "";
}

export function allDataSourceStatus(statusKey) {
  switch (statusKey) {
    case "1":
      return "System Data";
    case "2":
      return "Import Data";
  }
  return "";
}
export function taskType(statusKey) {
  switch (statusKey) {
    case "1":
      return "Shadow";
    case "2":
      return "On Project Support";
    case "3":
      return "Transition";
    case "4":
      return "COE";
    case "5":
      return "Management";
    case "6":
      return "No Pay Leave";
    case "7":
      return "Maternity Leave";
    case "8":
      return "BD";
    case "9":
      return "Booster";
    case "10":
      return "Training";
    case "11":
      return "Recruitment";
    case "12":
      return "Annual Leave";
    case "13":
      return "Other Leave";
  }
  return "";
}
export function taskStatus(statusKey) {
  switch (statusKey) {
    case "0":
      return "未开始";
    case "1":
      return "进行中";
    case "2":
      return "已完成";
    case "3":
      return "已失效";
  }
  return "";
}
export function applyStatus(statusKey) {
  switch (statusKey) {
    case "0":
      return "待审批";
    case "1":
      return "生效";
    case "2":
      return "已审批";
    case "3":
      return "PM发起调令";
    case "4":
      return "RM确认调令";
    case "5":
      return "RM代PM操作";
    case "7":
      return "未通过";

  }
  return "";
}
export function todoListAssStatus(statusKey) {
  switch (statusKey) {
    case "2":
      return "上项目待确认";
    case "3":
      return "上项目待审批";
    case "6":
      return "下项目待确认";
    case "7":
      return "下项目待审批";
    case "13":
      return "调令待评价";
    case "14":
      return "待审批重启调令";
  }
  return "";
}
export function getStatusInAccount(val) {
  switch (val) {
    case 1:
      return "进行中";
    case 2:
      return "已完成";
    case 3:
      return "已失败";
    case 4:
      return "请保持当前页面";
    default:
      return "未进行导入";
  }
}
export function menuBtnStatus(statusKey) {
  switch (statusKey) {
    case "0":
      return "已禁用";
    case "1":
      return "已启用";
  }
  return "";
}
export function seatUserNameStatus(statusKey) {
  switch (statusKey) {
    case "0":
      return "待审批";
    case "1":
      return "已通过";
    case "2":
      return "已驳回";
    case "3":
      return "已过期";
    case "4":
      return "已取消";
    case "6":
      return "改期待审批"
  }
  return "";
}
export function seatMyUserNameStatus(statusKey) {
  switch (statusKey) {
    case "0":
      return "待审批";
    case "1":
      return "已通过";
    case "2":
      return "待操作";
    case "3":
      return "已过期";
    case "4":
      return "已取消";
    case "6":
      return "改期待审批"
  }
  return "";
}
export function seatFloor(statusKey) {
  switch (statusKey) {
    case "56F":
      return "floor01";
    case "3F":
      return "floor02";
    case "4F":
      return "floor03";
    case "5F":
      return "floor04";
  }
  return "";
}
