<template>
  <section class="match-list" ref="matchListRef">
    <a-list size="small" bordered :data-source="matchList" :pagination="matchList.length > 0 ? pagination : false"
            id="match-list-content" :class="isTodoStatus ? '' : 'match-list-content'">
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item ant-list-item-no-flex" @click.stop="">
          <a-row class="padding-container">
            <a-col :span="7" style="padding-right: 3vw">
              <p class="first-line match-name">
                <span class="num" @click="checkMatchDetails(index, item)">{{ item.demandType === '2' ?
                    item.pipelineDetailVO.pipelineId : item.demandNumber }}</span>
                <Tooltip :title="item.demandType === '2' ? item.pipelineDetailVO.opportunityName : item.demandName">
                  <span class="match-name-text" @click="checkMatchDetails(index, item)">{{ item.demandType === '2' ?
                      item.pipelineDetailVO.opportunityName : item.demandName }}</span>
                </Tooltip>
                <a-tag color="blue" v-show="item.completeChannel" class="completetag">
                  {{ item.completeChannel }}
                </a-tag>
                <a-tag color="yellow" v-show="item.type === '1'" class="completetag tagColor2">
                  自定义
                </a-tag>
                <a-tag color="green" v-show="item.type === '2'" class="completetag tagColor3">
                  PPL
                </a-tag>
              </p>
              <div v-if="item.demandType === '2'">
                <ul class="key-msg-list sec-line">
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="CST">
                      <img src="@/assets/images/customize-ind.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.cst>
                      <span class="key-msg-span">{{ item.pipelineDetailVO.cst }}</span>
                    </Tooltip>
                  </li>
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="Offering">
                      <img src="@/assets/images/pip-offering.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.offering>
                      <span class="key-msg-span">{{ item.pipelineDetailVO.offering }}</span>
                    </Tooltip>
                  </li>
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="SPOC">
                      <img src="@/assets/images/customize-csm.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.spoc>
                      <span class="key-msg-span">{{ item.pipelineDetailVO.spoc }}</span>
                    </Tooltip>
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul class="key-msg-list sec-line">
                  <li class="key-msg-item">
                    <Tooltip title="人员级别">
                      <img src="@/assets/iconfont/level.png" class="icon img" />
                    </Tooltip>
                    {{ item.staffGrade }}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="预计用人时长">
                      <ClockCircleOutlined class="icon" />
                    </Tooltip>
                    {{ item.type === '1'&&item.useStaffLength?item.useStaffLength+'个月' : item.useStaffLength}}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="到岗时间">
                      <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img" />
                    </Tooltip>
                    {{ item.expectArrivalTime }}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="工作地点">
                      <EnvironmentOutlined class="icon" />
                    </Tooltip>
                    {{ item.demandWorkLocation }}
                  </li>
                </ul>
              </div>
              <!-- 1042-需求匹配增加需求编号
              <p class="key-msg-item">
                需求编号：{{ item.demandNumber }}
              </p>
            -->
            </a-col>
            <a-col :span="4" style="padding-right: 3vw">
              <p>
                <span @click="openEmpModal(item)" class="cName">{{ item.fullName }}</span>
                <!--                <span class="first-line consultant-name">-->
                <!--                  {{ item.job }}-->
                <!--                </span>-->
              </p>
              <ul class="key-msg-list sec-line" style="color: #3182CE">
                <li class="key-msg-item">
                  <Tooltip title="职级">
                    <img src="@/assets/images/levelBlue.png" class="icon levelimg" />
                  </Tooltip>
                  {{ item.psGroup }}
                </li>
                <li class="key-msg-item">
                  <Tooltip title="岗位">
                    <TeamOutlined class="icon" />
                  </Tooltip>
                  <Tooltip :title=item.job>
                    <span>{{ item.job }}</span>
                  </Tooltip>
                </li>
              </ul>
            </a-col>
            <a-col :span="9" class="match-status">
              <a-steps v-show="item.preStatus !== 'pmUnmatch' && tabStatus !== '0'" progress-dot
                       :current="getStepCurrent(item.preStatus)"
                       :class="tabStatus === '3' ? (item.preStatus === 'invalid' ? 'astep1' : 'steps3') : tabStatus === '2' ? 'steps2' : 'steps'">
                <a-step title="预匹配完成">
                  <template #description>
                    <!--                    <a-tooltip placement="topLeft">-->
                    <!--                      <template #title>-->
                    <!--                        RM驳回： <span>{{ item.rejectReason }}</span>-->
                    <!--                        <span>{{ item.revertComments }}</span>-->
                    <!--                      </template>-->
                    <!--                      <ExclamationCircleOutlined-->
                    <!--                          class="reject-icon"-->
                    <!--                          v-auth="['system:matching:rejectReason']"-->
                    <!--                          v-show="(item.rejectReason || item.revertComments) && tabStatus !== '3'"-->
                    <!--                          @click.stop=""-->
                    <!--                      />-->
                    <!--                    </a-tooltip>-->
                    <Tooltip :title="'RM驳回：' + item.rejectReason">
                      <ExclamationCircleOutlined class="reject-icon" v-auth="['system:matching:rejectReason']"
                                                 v-show="item.rejectReason && tabStatus !== '3'" @click.stop="" />
                    </Tooltip>
                    <span>{{ item.preMatchDate }}</span>
                  </template>
                </a-step>
                <a-step title="PM筛选简历">
                  <template #description>
                    <Tooltip :title="'拒绝原因：' + item.rejectReason">
                      <span v-if="tabStatus === '3' && item.rejectReason && item.preStatus === 'pmCVReject'"
                            style="color: #DC5E4F; cursor: pointer;">
                        PM拒绝
                      </span>
                    </Tooltip>
                    <Tooltip :title="'撤回原因：' + item.revertComments">
                      <span v-if="tabStatus === '3' && item.revertComments && item.preStatus === 'rmProposeToProject'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM撤回
                      </span>
                    </Tooltip>
                    <span>{{ item.filterCvDate }}</span>
                  </template>
                </a-step>
                <a-step title="PM填写调令">
                  <template #description>
                    <Tooltip :title="'驳回原因：' + item.rejectReason">
                      <span v-if="tabStatus === '3' && item.rejectReason && item.preStatus === 'pmInterviewFail'"
                            style="color: #DC5E4F; cursor: pointer;">
                        PM驳回
                      </span>
                    </Tooltip>
                    <span>{{ item.fillAssignmentDate }}</span>
                    <Tooltip :title="'撤回原因：' + item.revertComments">
                      <span v-if="tabStatus === '3' && item.revertComments && item.preStatus === 'rmPmCVInterview'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM撤回
                      </span>
                    </Tooltip>
                  </template>
                </a-step>
                <a-step title="RM确认调令">
                  <template #description>
                    <Tooltip :title="'驳回原因：' + item.rejectReason">
                      <span v-if="tabStatus === '3' && item.rejectReason && item.preStatus === 'rmPmSoftBook'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM驳回
                      </span>
                    </Tooltip>
                    <span>{{ item.confirmAssignmentDate }}</span>
                  </template>
                </a-step>
                <a-step title="匹配完成">
                  <template #description>
                    <span>{{ item.confirmAssignmentDate }}</span>
                  </template>
                </a-step>
              </a-steps>
              <div v-if="tabStatus === '0'">
                <p>
                  <Tooltip title="ARVE Assumption %">
                    <img src="@/assets/images/Probability.png" class="icon img" />
                  </Tooltip>
                  <span class="arve">ARVE Assumption % ：{{ item.arveAssumption }}</span>
                </p>
                <p>
                  <Tooltip title="ARVE Assumption Start Date">
                    <img src="@/assets/images/Bidding.png" class="icon img" />
                  </Tooltip>
                  <span class="arve">ARVE Assumption Start Date ：{{ item.arveAssumptionStartDate }}</span>
                </p>
              </div>
            </a-col>
            <a-col :span="4" class="match-btn">
              <a-space class="buttons">
                <div v-show="item.preStatus === 'pmUnmatch'" class="statustip">
                  本需求PM已有选定人员
                </div>
                <div v-if="tabStatus === '2'">
                  <div v-if="item.replacePerson" class="statustip">RM ( {{ item.replacePerson }} ) 代PM操作</div>
                  <div v-else-if="tabStatus === '2'" class="statustip">
                    PM ( {{ item.pmName }} ) 操作
                  </div>
                </div>
                <div v-if="tabStatus === '3'">
                  <div v-if="['rmPmCVInterview', 'rmProposeToProject', 'rmPmSoftBook'].includes(item.preStatus)"
                       class="statustip">
                    RM ( {{ item.replacePerson }} )
                    <span
                        v-show="item.preStatus === 'rmPmCVInterview' || item.preStatus === 'rmProposeToProject'">撤回</span>
                    <span v-show="item.preStatus === 'rmPmSoftBook'">驳回</span>
                  </div>
                  <div v-else class="statustip">
                    <span v-show="item.preStatus === 'pmCVReject' || item.preStatus === 'pmInterviewFail'">PM (
                      {{ item.pmName }} )</span>
                    <span v-show="item.preStatus === 'pmCVReject'">拒绝</span>
                    <span v-show="item.preStatus === 'pmInterviewFail'">驳回</span>
                  </div>
                </div>
                <a-button type="primary" @click="sendPMHandler(item)" v-auth="['system:prematching:sentPM']" v-if="tabStatus === '0'">
                  发送至PM
                </a-button>
                <a-button type="primary" @click="pmFillAssignmentClick(item, 'RMFillAssignment')"
                          v-auth="['system:replacepm:rm']" v-if="item.preStatus === 'proposeToProject' ||
                    item.preStatus === 'pmCVInterview' ||
                    item.preStatus === 'rmReject' ||
                    item.preStatus === 'pmTransferOrderToBeFilledOut' ||
                    tabStatus === '0'
                    ">
                  代PM填写调令
                </a-button>
                <!--PM-->
                <!--                <a-button-->
                <!--                  type="primary"-->
                <!--                  @click="openEmpModal(item)"-->
                <!--                  v-auth="['system:prematching:consultantInfo']"-->
                <!--                >-->
                <!--                  查看顾问信息-->
                <!--                </a-button>-->
                <a-button type="primary" @click="pmCvAccpectClick(item)" v-auth="['system:prematching:pmAccept']"
                          v-if="item.preStatus === 'proposeToProject'">
                  接受
                </a-button>
                <a-button type="primary" @click="pmCvRejectClick(item)" v-auth="['system:prematching:pmRefuse']"
                          v-if="item.preStatus === 'proposeToProject'">
                  拒绝
                </a-button>
                <a-button type="primary" @click="pmFillAssignmentClick(item, 'PMFillAssignment')"
                          v-auth="['system:prematching:pm']" v-if="item.preStatus === 'pmCVInterview' ||
                    item.preStatus === 'rmReject' ||
                    item.preStatus === 'pmTransferOrderToBeFilledOut'
                    ">
                  填写调令
                </a-button>
                <a-button type="primary" @click="pmInterviewRejectClick(item)" v-auth="['system:prematching:pmReject']"
                          v-if="item.preStatus === 'pmCVInterview' ||
                    item.preStatus === 'rmReject' ||
                    item.preStatus === 'pmTransferOrderToBeFilledOut'">
                  驳回
                </a-button>
                <!--RM-->
                <a-button type="primary" @click="rmConfirmClick(item)" v-auth="['system:prematching:rm']"
                          v-if="item.preStatus === 'pmSoftBook'">
                  确认调令
                </a-button>
                <!--                <a-button-->
                <!--                  type="primary"-->
                <!--                  @click="rmRevertClick(item)"-->
                <!--                  v-auth="['system:prematching:rmRevert']"-->
                <!--                  v-if="-->
                <!--                    item.preStatus === 'pmCVInterview' ||-->
                <!--                    item.preStatus === 'rmReject' ||-->
                <!--                    item.preStatus === 'proposeToProject' ||-->
                <!--                    item.preStatus === 'pmTransferOrderToBeFilledOut' ||-->
                <!--                    (item.completeChannel === 'TA_Team' &&-->
                <!--                      item.preStatus === 'pmCVInterview')-->
                <!--                  "-->
                <!--                >-->
                <!--                  撤回-->
                <!--                </a-button>-->
                <div class="selectProgress" v-if="(tabStatus === '0' || tabStatus === '1') && isRM"></div>
                <div v-if="tabStatus === '0' || tabStatus === '1'">
                  <div class="select-btn" @click="select(item)" v-auth="['system:matching:select']"
                       v-if="!selectData.some(jtem => jtem.preMatchingId === item.preMatchingId) && isRM">
                    <CheckOutlined style="color: white; font-size: 14px; margin-top: 0.2vw;" />
                  </div>
                  <div class="selected-btn" @click="removeSelected(item)" v-auth="['system:matching:select']"
                       v-if="selectData.some(jtem => jtem.preMatchingId === item.preMatchingId) && isRM">
                    <CheckOutlined style="color: white; font-size: 14px; margin-top: 0.2vw;" />
                  </div>
                </div>
              </a-space>
            </a-col>
          </a-row>
        </a-list-item>
      </template>
    </a-list>

    <!-- pipeline：0，处理中1 的 才可以弹窗内修改ARVE -->
    <mate-info v-if="showMateInfo" :showMateInfo="showMateInfo" :empDetail="empDetail"
               @closeMataInfoModal="closeMataInfoModal" type="List" :canChangeARVE="['0', '1'].includes(activeTab)"
               @updateArve="updateArveValue"
               @refreshTable="refreshTable"
    />
    <CustomizeInfo ref="CustomizeInfoRef"
                   :nowStep="0" type="List"
                   :canChangeARVE="['0', '1'].includes(activeTab)"
                   @refreshTable="refreshTable"
                   title="匹配详情"
    />
    <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo"
                   @close-modal="closePipelineModal" :canChangeARVE="['0', '1'].includes(activeTab)" :nowStep="0"
                   @refreshTable="refreshTable" title="匹配详情"
    />

    <emp-info v-if="empVisible" :isShowingEmpInfo="empVisible" :getId="empId" @close-emp-info="closeEmpModal"
              title="查看顾问信息" :isHaveAdd="false" />




    <!--  v-if="assignmentVisible"-->
    <fill-assignment ref="fillAssignmentModal" v-model:visible="assignmentVisible" v-model:formState="formState"
                     :title="assignmentTitle" :rejectBtn="rejectHidden" :showRollback="showRollback" :accountNameOps="clientNameOps"
                     :industryLineOps="industryLineOps" :accountIndustryRelaction="accountIndustryRelaction" source="match"
                     @close-modal="closeAssignmentModal" @submit-modal="submitAssignmentModal" @rollback-modal="rollbackAssignmentModal"
                     @open-reject-modal="openRejectModal" @get-annexList="getAnnexList" :fillAssignmentType="fillAssignmentType"
                     :originalCodeAndDateObj="originalCodeAndDateObj" :codeRelationList="codeRelationList"
                     :showSendAssignmentLetter="showSendAssignmentLetter" :showSaveBtn="showSaveBtn" @save-modal="saveAssignmentModal" :tabStatus="tabStatus"
                     :isPM="isPM" :activeTab="activeTab" :pmOptions="pmOptions" :buttonType="buttonType" @sendCloseModal="sendCloseModal"
                     :rowType="rowType" :preMatchingId="preMatchingId"  @relevanceAndUpdata="relevanceAndUpdata"
                     @upDemandId="upDemandIdHandler"
    />

    <reject-modal v-model:visible="rejectVisible" v-model:formState="formState" title="驳回" @close-modal="closeRejectModal"
                  @submit-modal="submitRejectModal" @open-confirm-modal="rmConfirmClick" :rejectNoLoginState="rejectNoLoginState"
                  @nologin-submit-modal="submitNoLoginRejectModal" />

    <revert-modal v-model:visible="revertVisible" v-model:formState="revertFormState" :title="revertTitle"
                  :comment-label="revertCommentLabel" @close-modal="closeRevertModal" @submit-modal="submitRevertModal" />
    <OverlapModal :data="overlapData" @confirm="submitAssignment" v-model:visible="overlapModalVisible" />

    <a-modal :getContainer="() => $refs.matchListRef" v-model:visible="replaceTipModalVisible" width="20.8vw" title="提示"
             ok-text="确定" cancel-text="取消" @ok="confrimReplaceTipModal" centered>
      <div v-if="isEarlierThanToday">
        确定后直接生成调令！填写的上项目时间
        <span class="dayTipStyle">{{ onBoardDayTip }}</span>
        早于或等于当天，调令将直接变为进行中，
        <span class="dayTipStyle">上项目时间不可再更改</span>
        ,请确定已和PM沟通过调令内容。
      </div>
      <div v-else>
        <span>确定后直接生成调令，如需要修改调令信息，可以到【需求资源管理】-【调令管理】进行修改。</span>
      </div>
    </a-modal>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </section>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  onMounted,
  inject,
  watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  EnvironmentOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons-vue";
import MateInfo from "./MateInfo.vue";
import EmpInfo from "@/components/EmpInfo.vue";
import { message } from "ant-design-vue";
import FillAssignment from "@/views/home/ResourceManagement/Match/matchingList/components/FillAssignment";
import moment from "moment";
import RejectModal from "@/views/home/ResourceManagement/Match/matchingList/components/RejectModal";
import RevertModal from "@/views/home/ResourceManagement/Match/matchingList/components/RevertModal";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useStore } from "vuex";
import OverlapModal from "@/components/OverlapModal";
import ModalBox from '@/components/ModalBox'
import Tooltip from "@/components/Tooltip.vue";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
let queryCache = null;
export default defineComponent({
  name: "MatchList",
  components: {
    PipelineModal,
    CheckOutlined,
    ClockCircleOutlined,
    Tooltip,
    OverlapModal,
    RevertModal,
    RejectModal,
    FillAssignment,
    EmpInfo,
    EnvironmentOutlined,
    TeamOutlined,
    MateInfo,
    ModalBox,
    ExclamationCircleOutlined,
    CustomizeInfo
  },
  props: {
    activeTabStatus: String,
    isTodoStatus: Boolean,
  },
  emits: ["goTodoBack", "getSelectedData", 'refreshTab'],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute();
    const { $EventBus } = useGlobalPropertyHook();
    const store = useStore();
    const fillAssignmentModal = ref(null);
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const storePerms = computed(() => store.state.app.perms);
    const matchInfoRef = ref();
    const matchList = ref([]);
    let activeTab = ref();
    const getActiveTab = ref("");
    const searchCondition = ref({});
    const replaceTipModalVisible = ref(false);
    const annexDataList = ref([]);
    const tabStatus = computed(() => props.activeTabStatus);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        // 回到页面顶端
        document.getElementById(
            "match-list-content"
        ).parentElement.scrollTop = 0;
        doSearch();
        // getMatchListData(queryCache);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        // 回到页面顶端
        document.getElementById(
            "match-list-content"
        ).parentElement.scrollTop = 0;
        doSearch();
        // getMatchListData(queryCache);
      },
    });
    const showMateInfo = ref(false);
    const empDetail = reactive({});

    const rejectHidden = ref(false);

    // 是否显示恢复上次输入按钮
    const showRollback = ref(false);

    //是否显示"是否立即发送Assignment Letter"
    const showSendAssignmentLetter = ref(false);

    //是否显示保存按钮
    const showSaveBtn = ref(false);

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.value.current = 1;
      }
    };
    let CustomizeInfoRef = ref()
    // 打开项目详情弹窗
    const checkMatchDetails = (index, item) => {
      if (item.type == 0) {
        showMateInfo.value = true;
      } else if (item.type == 1) {
        CustomizeInfoRef.value.showModal(item)
      } else {
        pipelineInfo.value = Object.assign({}, item, item.pipelineDetailVO)
        pipelineVisible.value = true
      }
      Object.assign(empDetail, matchList.value[index]);
      // matchInfoRef.value.showModal(details);
    };

    const closeMataInfoModal = () => {
      showMateInfo.value = false;
    };
    const query = inject("query");
    const doSearch = () => {
      let beginDate, endDate;
      if (searchCondition.value.arveDate && searchCondition.value.arveDate.length === 2) {
        beginDate = searchCondition.value.arveDate[0].format("YYYY-MM-DD");
        endDate = searchCondition.value.arveDate[1].format("YYYY-MM-DD");
      }
      const params = {
        status: activeTab.value,
        demandNumber: searchCondition.value.demandNumber,
        condition: searchCondition.value.condition,
        source: searchCondition.value.source,
        type: searchCondition.value.type,
        completeChannel: searchCondition.value.completeChannel,
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize,
        pipelineInfo: searchCondition.value.pipelineInfo,
        consultant: searchCondition.value.consultant,
        beginDate,
        endDate,
      };
      if (isTodoStatus.value) {
        // params.preMatchingId = route.query.preMatchingId;
        params.preMatchingId = query.value.value.preMatchingId;
      }
      if (noticePreMatchingId.value) {
        if (getActiveTab.value === "") {
          params.status = "";
          params.preMatchingId = noticePreMatchingId.value;
        }
      }
      $api.searchMatchByCondition(params).then((res) => {
        matchList.value = res.data.resource;
        matchList.value = matchList.value.map(item => {
          return {
            ...item,
            arveAssumptionStartDate: moment(item.arveAssumptionStartDate).format("YYYY-MM-DD"),
            consultantArveAssumptionStartDate: moment(item.arveAssumptionStartDate).format("YYYY-MM-DD"),
            consultantArveAssumption: item.arveAssumption,
            consultantArveAssumptionHc: item.arveAssumptionHc,
            pmInfo: item.pmInfo !== 'null' ? item.pmInfo : '暂无PM',
          }
        })
        pagination.value.total = res.data.total;
        if (matchList.value?.length === 0 && isTodoStatus.value) {
          // 无数据时跳回待办列表
          emit("goTodoBack");
        }
        emit("getSelectedData", matchList.value, selectData.value);
        if (noticePreMatchingId.value) {
          handlegetActiveTab();
        }
      });
    };

    const noticePreMatchingId = computed(() => route.query.id);
    const handlegetActiveTab = () => {
      if (matchList.value.length > 0) {
        let inProgress = [
          "proposeToProject",
          "pmCVInterview",
          "pmSoftBook",
          "rmReject",
          "pmUnmatch",
        ];
        let Completed = ["rmHardBook"];
        let Invalid = ["pmCVReject", "invalid", "pmInterviewFail"];
        if (inProgress.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = "1";
        } else if (Completed.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = "2";
        } else if (Invalid.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = "3";
        }
        activeTab.value = getActiveTab.value;
        $EventBus.emit("getActiveTab", getActiveTab.value);
      }
    };

    const searchClick = (tab, searchParams) => {
      activeTab.value = tab;
      searchCondition.value = searchParams;
      pagination.value.current = 1;
      doSearch();
    };

    const searchByPange = (tab, searchParams) => {
      activeTab.value = tab;
      searchCondition.value = searchParams;
      doSearch();
    };



    const empVisible = ref(false);
    // const empInfo = reactive({});
    const empId = ref();
    const openEmpModal = (row) => {
      empId.value = row.userId;
      empVisible.value = true;
      // $api.getOneUser(row.userId).then((res) => {
      //   if (res.code == "200") {
      //     empVisible.value = true;
      //     Object.assign(empInfo, res.data);
      //   }
      // });
    };
    const closeEmpModal = () => {
      empVisible.value = false;
    };
    const preMatchingId = ref('')
    const assignmentVisible = ref(false);
    const assignmentTitle = ref("");
    let assignmentType = "";
    const formState = reactive({
      rejectReason: "",
      serviceLine: "",
      subServiceLine: "",
      candidateName: "",
      candidateContact: "",
      candidateGrade: "",
      account: "",
      codeStartDate: "",
      codeEndDate: "",
      clientName: "",
      engagementCode: "",
      engagementDescription: "",
      workLocation: "",
      engagementManager: "",
      copyList: [],
      assignmentTerm: [],
      actualReleaseDate: "",
      hoursPerDay: "8",
      adrc: "",
      industryLine: "",
      workCity: undefined,
      replaceReason: "", // RM代PM填写原因
      pmInfo: "",
      projectCommitDate: "",
    });
    let fillAssignmentType = ref(""); // 区别PM填写调令还是RM代PM填写
    const activeRow = {};

    // 检查是否存在匹配当前调令preMatchingId的缓存
    const checkRollbackData = (row) => {
      if (sessionStorage["matching-list-assignment-modal-data-temp"]) {
        const data = JSON.parse(
            sessionStorage["matching-list-assignment-modal-data-temp"]
        );
        if (
            data.preMatchingId === row.preMatchingId &&
            data.updateTime === row.updateTime
        ) {
          showRollback.value = true;
        } else {
          delete sessionStorage["matching-list-assignment-modal-data-temp"];
          showRollback.value = false;
        }
      } else {
        delete sessionStorage["matching-list-assignment-modal-data-temp"];
        showRollback.value = false;
      }
    };

    // 恢复上次未填完的数据
    const rollbackAssignmentModal = () => {
      Object.assign(
          formState,
          JSON.parse(sessionStorage["matching-list-assignment-modal-data-temp"])
      );
      if (formState.assignmentTerm && formState.assignmentTerm.length > 1) {
        formState.assignmentTerm = [
          moment(formState.assignmentTerm[0]),
          moment(formState.assignmentTerm[1]),
        ];
      }
      delete sessionStorage["matching-list-assignment-modal-data-temp"];
      showRollback.value = false;
      $api.getADRC({ crc: activeRow.CRC }).then((res) => {
        formState.adrc = res.data;
      });
    };

    // 非提交触发的关闭弹窗用户希望保留数据
    const dealWithRollbackTemp = (obj) => {
      if (obj && obj.type === "clear-temp") {
        delete sessionStorage["matching-list-assignment-modal-data-temp"];
      } else {
        const obj = {
          ...formState,
        };
        if (formState.assignmentTerm && formState.assignmentTerm.length > 1) {
          obj.assignmentTerm = [
            formState.assignmentTerm[0].format("YYYY-MM-DD"),
            formState.assignmentTerm[1].format("YYYY-MM-DD"),
          ];
        }
        sessionStorage["matching-list-assignment-modal-data-temp"] =
            JSON.stringify(obj);
      }
    };
    const clientNameOps = ref([]);
    const industryLineOps = ref([]);
    const accountIndustryRelaction = ref([]);

    const getAccountOptions = async () => {
      const params = {
        pagelndex: 1,
        pageSize: 20,
      }
      const result = await Promise.all([
        $api.getAccountIndustryLineOpts(),
        $api.getAccountIndustryRelations(),
        $api.getCodeDataOps(params)
      ]);
      clientNameOps.value = result[0].data.account.map((item) => {
        return {
          value: item,
          label: item,
          key: item,
        };
      });
      accountIndustryRelaction.value = result[1].data;
      codeRelationList.allCodeOptionsList = [...new Set(result[2].data.resource.map((item) => {
        return {
          ...item,
          label: item.codeAndDescription,
          value: item.codeAndDescription,
          type: item.type,
        }
      }))]
    };

    const codeRelationList = reactive({
      relationsList: [],
      allCodeOptionsList: [],
    });

    // const getCodeOptions = async () => {
    //   const { data } = await $api.getCodeRelations();
    //   codeRelationList.relationsList = data;
    //   codeRelationList.allCodeOptionsList = [
    //     ...new Set(
    //       codeRelationList.relationsList.map((item) => {
    //         return {
    //           ...item,
    //           label: item.codeAndDescription,
    //           value: item.codeAndDescription,
    //           type: item.type,
    //         };
    //       })
    //     ),
    //   ];
    // };
    const savedId = ref("");
    const buttonType = ref('')
    const rowType = ref('')
    // 代PM填写调令 / 填写调令
    const pmFillAssignmentClick = async (row, type) => {
      buttonType.value = type == 'RMFillAssignment' ? '代填写' : ''
      rowType.value = row.type
      if (type === "RMFillAssignment") {
        showSendAssignmentLetter.value = true;
      }
      showSaveBtn.value = true;
      checkRollbackData(row);
      resetForm();
      await getAccountOptions();
      //await getCodeOptions();
      formState.preMatchingId = row.preMatchingId;
      formState.updateTime = row.updateTime;
      assignmentType = type === "RMFillAssignment" ? "rm" : "pm";
      assignmentTitle.value =
          type === "RMFillAssignment" ? "RM 代 PM 填写调令" : "PM 确定匹配";
      fillAssignmentType.value = type;

      // RM代PM填写调令不显示驳回 并且 comment必填
      if (type === "RMFillAssignment") rejectHidden.value = false;
      formState.serviceLine = row.serviceLine;
      formState.subServiceLine = row.subServiceLine;
      formState.candidateName = row.fullName;
      formState.candidateGrade = row.psGroup;
      formState.replaceReason = row.replaceReason;
      formState.candidateContact = row.phoneNo
          ? (row.email ? row.email : "") + "/" + (row.phoneNo ? row.phoneNo : "")
          : row.email
              ? row.email
              : "";
      formState.demandClientName = row.customerName;
      formState.demandIndustryLine = row.industryLine;
      formState.pmInfo = row.pmInfo;
      formState.staffTerminationDate = row.staffTerminationDate;
      formState.staffOnboardDate = row.staffOnboardDate;
      formState.pipelineDetailVO = row.pipelineDetailVO;
      formState.matchType = row.type;
      const clientName = clientNameOps.value.find(
          (item) => item.value === row.customerName
      );
      if (!clientName) {
        formState.account = undefined;
        formState.industryLine = undefined;
      } else {
        formState.account = row.customerName;
      }

      Object.assign(activeRow, row);
      if (row.preMatchingId) {
        const paramsId = {
          preMatchingId: row.preMatchingId,
        };
        $api.preMatchingIdTemporary(paramsId).then((res) => {
          const resData = ref([]);
          resData.value.push(res.data);
          if (res.data) {
            Object.assign(formState, res.data);
            formState.assignmentTerm =
                !res.data.onBoardDate && !res.data.actualReleaseDate
                    ? []
                    : [
                      res.data.onBoardDate ? moment(res.data.onBoardDate) : "",
                      res.data.actualReleaseDate
                          ? moment(res.data.actualReleaseDate)
                          : "",
                    ];
            formState.copyList = formState.copyList
                ? formState.copyList.split(";")
                : [];
            formState.hoursPerDay = formState.hoursPerDay + "";
            formState.leadingCSM = formState.leadingCsm;
            savedId.value = res.data.id;
          } else {
            $api.getAssignmentInfo(row.preMatchingId).then((res) => {
              Object.assign(formState, res.data);
              if (res.data) {
                formState.assignmentTerm = [
                  moment(res.data.onBoardDate),
                  moment(res.data.actualReleaseDate),
                ];
              }
              formState.hoursPerDay = formState.hoursPerDay + "";
              savedId.value = ""
            });
          }
        });
      }
      $api.getADRC({ crc: row.CRC }).then((res) => {
        formState.adrc = res.data;
      });
      preMatchingId.value = row.preMatchingId
      assignmentVisible.value = true;
    };
    const sendPMHandler = (row) => {
      rowType.value = row.type
      buttonType.value = 'PM填写'
      assignmentTitle.value = '请选择PM'
      preMatchingId.value = row.preMatchingId
      assignmentVisible.value = true
    }
    const originalCodeAndDateObj = ref({});
    const rmConfirmClick = async (row) => {
      showSendAssignmentLetter.value = true;
      showSaveBtn.value = false;
      rowType.value = row.type
      buttonType.value ='代填写'
      preMatchingId.value = row.preMatchingId
      if (row != undefined) {
        checkRollbackData(row);
        formState.preMatchingId = row.preMatchingId;
        formState.updateTime = row.updateTime;
        formState.demandClientName = row.customerName;
        formState.demandIndustryLine = row.industryLine;
        formState.pmInfo = row.pmInfo;
        Object.assign(activeRow, row);
      }
      await getAccountOptions();
      formState.pipelineDetailVO = activeRow?.pipelineDetailVO;
      formState.matchType = activeRow?.type;
      //await getCodeOptions();
      fillAssignmentType.value = "RMConfirm";
      assignmentType = "rm";
      assignmentTitle.value = "RM 确定匹配";
      rejectHidden.value = true;
      await $api.getAssignmentInfo(activeRow.preMatchingId).then((res) => {
        Object.assign(originalCodeAndDateObj.value, {
          engagementCode: res.data.engagementCode,
          projectCommitDate: res.data.projectCommitDate,
          onBoardDate: res.data.onBoardDate,
        });
        Object.assign(formState, res.data);
        formState.assignmentTerm = [
          moment(res.data.onBoardDate),
          moment(res.data.actualReleaseDate),
        ];
        formState.candidateGrade = res.data.psGroup;
        formState.hoursPerDay = formState.hoursPerDay + "";
        const clientName = clientNameOps.value.find(
            (item) => item.dictCode === formState.clientName
        );
        if (!clientName) {
          formState.clientName = undefined;
          formState.industryLine = undefined;
        }
        if (rejectVisible.value === true) {
          formState.industryLine = res.data.industryLine;
        }
        if (res.data.projectStatus && res.data.projectStatus !== 'Close') {
          formState.codeEndDate = res.data.projectCompletionDate;
        }
        rejectVisible.value = false;
      });
      $api.getADRC({ crc: activeRow.CRC }).then((res) => {
        formState.adrc = res.data;
      });
      assignmentVisible.value = true;
    };

    const closeAssignmentModal = (obj) => {
      assignmentVisible.value = false;
      fillAssignmentModal.value.isShowDemand = false
      fillAssignmentModal.value.selectDemand = []
      dealWithRollbackTemp(obj);
      resetForm();
      annexDataList.value = [];
      originalCodeAndDateObj.value = {};
      buttonType.value = ''
      Object.assign(activeRow, {});
    };

    const resetForm = () => {
      Object.assign(formState, {
        id: undefined,
        rejectReason: "",
        serviceLine: "",
        subServiceLine: "",
        candidateName: "",
        candidateContact: "",
        candidateGrade: "",
        account: "",
        codeStartDate: "",
        codeEndDate: "",
        codeTerm: "",
        projectCommitDate: "",
        clientName: "",
        engagementCode: "",
        engagementDescription: "",
        workLocation: "",
        engagementManager: "",
        copyList: [],
        assignmentTerm: [],
        actualReleaseDate: "",
        hoursPerDay: "8",
        adrc: "",
        industryLine: "",
        workCity: undefined,
        replaceReason: "",
        onBoardDate: "",
        staffOnboardDate: "",
      });
    };
    const pmSubmit = () => {
      const data = {
        // staffAssignment: {
        id: formState.id,
        account: formState.account,
        projectCommitDate: formState.projectCommitDate,
        codeStartDate: formState.codeStartDate,
        codeEndDate: formState.codeEndDate,
        candidateContact: formState.candidateContact,
        candidateGrade: formState.candidateGrade,
        candidateName: formState.candidateName,
        clientName: formState.clientName,
        costCenter: activeRow.costCenter,
        costCtr: activeRow.costCtr,
        demandId: activeRow.demandId,
        engagementCode: formState.engagementCode,
        copyList: formState.copyList,
        engagementDescription: formState.engagementDescription,
        engagementManager: formState.engagementManager,
        actualReleaseDate: moment(formState.assignmentTerm[1]).format("YYYY-MM-DD"),
        hoursPerDay: formState.hoursPerDay,
        onBoardDate: moment(formState.assignmentTerm[0]).format("YYYY-MM-DD"),
        personCost: formState.adrc,
        pmId: activeRow.pmId,
        preMatchingId: activeRow.preMatchingId,
        rmId: activeRow.rmId,
        serviceLine: formState.serviceLine,
        subServiceLine: formState.subServiceLine,
        industryLine: formState.industryLine,
        workCity: formState.workCity,
        userId: activeRow.userId,
        workLocation: formState.workLocation,
        // }
      };
      data.staffAssignmentParts = segmenteData.value
      $api.pmAccept(data).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeAssignmentModal({ type: "clear-temp" });
        doSearch();
        message.success(res.msg);
      });
    };
    const rmSubmit = () => {
      formState.onBoardDate = moment(formState.assignmentTerm[0]).format("YYYY-MM-DD");
      formState.actualReleaseDate =
          moment(formState.assignmentTerm[1]).format("YYYY-MM-DD");
      if (formState.assEmailSendTime)
        formState.assEmailSendTime = moment(formState.assEmailSendTime).format(
            "YYYY-MM-DD HH:00:00"
        );
      const url =
          fillAssignmentType.value === "RMFillAssignment"
              ? formState.assEmailSendTime
                  ? "/assignments/replaceTime"
                  : "/assignments/replace"
              : formState.assEmailSendTime
                  ? "/assignments/rmByTime"
                  : "/assignments/rm";
      formState.staffAssignmentParts = segmenteData.value
      if(drawerDemandId.value != ''){
        formState.demandId = drawerDemandId.value
        formState.demandType = rowType.value
        formState.pmId = drawerPmId.value
      }
      $api.rmConfirm(url, formState).then((res) => {
        // 如果为rm代pm操作，并且上传附件有数据则调用上传接口
        drawerDemandId.value = ''
        drawerPmId.value = ''
        if (
            fillAssignmentType.value === "RMFillAssignment" &&
            annexDataList.value?.length > 0
        ) {
          handleUploadAnnex(res.data.id);
        } else {
          message.success(res.msg);
        }
        closeAssignmentModal({ type: "clear-temp" });
        doSearch();
        // 获取待办数量
        store.dispatch("getTodoAllNum");
      });
    };
    // 上传附件
    const handleUploadAnnex = (id) => {
      if (annexDataList.value.length > 4) {
        message.error("单次上传附件数量上限为4个，请合理安排分批上传。");
        return false;
      }
      const formData = new FormData();
      annexDataList.value.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileRole", "RM");
      formData.append("id", id);

      $api.uploadAnnex(formData).then((res) => {
        if (res.code === 200) {
          message.success(res.msg);
        }
      });
    };
    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      // demandList: [],
    });
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      handleTimeJudgment();
    }
    const segmenteData = ref([])
    const submitAssignmentModal = async (params, segmente) => {
      segmenteData.value = segmente
      const { data } = await $api.checkCodeTerm(params);
      // 时间校验,不通过强提示
      if (data) {
        content.value = 'Assignment Term 与 Code 有效期冲突,请确定是否继续'
        showIconCom.value = 'ExclamationCircleOutlined'
        modelValue.value = true

        /*Modal.confirm({
          title: () => "提示",
          content: "Assignment Term与Code有效期冲突,请确定是否继续",
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          onOk() {
            handleTimeJudgment();
          },
        });*/
      } else {
        handleTimeJudgment();
      }
    };
    const handleTimeJudgment = () => {
      const staffAssignment = {
        id: formState.id,
        hoursPerDay: formState.hoursPerDay,
        actualReleaseDate: moment(formState.assignmentTerm[1]).format("YYYY-MM-DD"),
        onBoardDate: moment(formState.assignmentTerm[0]).format("YYYY-MM-DD"),
        userId: activeRow.userId,
        // demandId: activeRow.demandId,
      };
      staffAssignment.staffAssignmentParts = segmenteData.value
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([staffAssignment.onBoardDate, staffAssignment.actualReleaseDate])
      )
      // localStorage.setItem('FcstEightTimeLine', '')
      $api.isTimeJudgments(staffAssignment).then((res) => {
        if (res.code !== 200) {
          message.error(res.msg);
          return;
        }
        if (res.data.gatherFlag === "false") {
          // 时间不重叠
          submitAssignment();
        } else {
          let currentOverlapData = {
            clientName: formState.account,
            engagementCode: formState.engagementCode,
            engagementDescription: formState.engagementDescription,
          }
          overlapData.value = {
            status: res.data.gatherFlag,
            assignmentList: res.data.assignmentQueryPartCommandList || [],
            workTaskList: res.data.workTaskList || [],
            overlapCommandList: res.data.overlapCommandList || [],
            currentformData: currentOverlapData,
            // demandList: res.data.demandList || [],
          };
          overlapModalVisible.value = true;
        }
      });
    };
    const confrimReplaceTipModal = () => {
      replaceTipModalVisible.value = false;
      submitAssignmentContent();
    };

    const drawerDemandId = ref('')
    const drawerPmId = ref('')

    const isEarlierThanToday = ref(false);
    const onBoardDayTip = ref("");
    const getAnnexList = (val) => {
      annexDataList.value = val;
    };
    const submitAssignment = () => {
      overlapModalVisible.value = false;
      // RM代PM操作时才有确定弹框
      if (fillAssignmentType.value === "RMFillAssignment") {
        replaceTipModalVisible.value = true;
        onBoardDayTip.value = moment(formState.assignmentTerm[0]).format(
            "YYYY-MM-DD"
        );
        // 判断上项目时间是否早于当天 区别显示提示语
        isEarlierThanToday.value =
            moment().endOf("day") > moment(formState.assignmentTerm[0]);
      } else {
        submitAssignmentContent();
      }
    };
    const submitAssignmentContent = () => {
      if (assignmentType === "pm") {
        pmSubmit();
      } else {
        rmSubmit();
      }
    };
    const rejectVisible = ref(false);
    const rejectNoLoginState = ref('')
    const openRejectModal = (row) => {
      if (row != undefined) {
        Object.assign(activeRow, row);
        $api.getADRC({ crc: row.CRC }).then((res) => {
          formState.adrc = res.data;
        });
      }
      formState.pipelineDetailVO = activeRow?.pipelineDetailVO;
      formState.matchType = activeRow?.type;
      $api.getAssignmentInfo(activeRow.preMatchingId).then((res) => {
        Object.assign(formState, res.data);
        formState.assignmentTerm = [
          moment(res.data.onBoardDate),
          moment(res.data.actualReleaseDate),
        ];
        // formState.adrc = res.data.personCost;
        formState.hoursPerDay = formState.hoursPerDay + "";
        formState.candidateGrade = res.data.psGroup;
        assignmentVisible.value = false;
        rejectVisible.value = true;
      });
      // $api.queryNoLoginLinkStatus(activeRow.preMatchingId).then((res) => {
      //   console.log('11111111',res.data)
      //   rejectNoLoginState.value = res.data
      // })
    };
    const closeRejectModal = () => {
      resetForm();
      Object.assign(activeRow, {});
      rejectVisible.value = false;
    };
    const submitRejectModal = () => {
      const params = {
        rejectWay: formState.rejectWay,
        staffAssignment: formState,
      };
      $api.rmReject(params).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeRejectModal({ type: "clear-temp" });
        doSearch();
        message.success(res.msg);
      });
    };
    const submitNoLoginRejectModal = () => {
      const staffAssignment = {
        preMatchingId: formState.preMatchingId,
        rejectReason: formState.rejectReason,
      };
      const data = {
        staffAssignment: staffAssignment
      }
      $api.rejectSendingNoLoginLinkStatus(data).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeRejectModal({ type: "clear-temp" });
        doSearch();
        message.success(res.msg);
      });
    }

    const getStepCurrent = (preStatus) => {
      switch (preStatus) {
        case "proposeToProject":
        case "pmCVReject":
        case "rmProposeToProject":
          return 1;
        case "rmReject":
        case "pmCVInterview":
        case "pmTransferOrderToBeFilledOut":
        case "pmInterviewFail":
        case "rmPmCVInterview":
          return 2;
        case "pmSoftBook":
        case "rmPmSoftBook":
          return 3;
        case "rmHardBook":
          return 4;
        default:
          return 0;
      }
    };

    const pmCvAccpectClick = (row) => {
      $api.pmCVInterview({ preMatchingId: row.preMatchingId }).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        doSearch();
        message.success(res.msg);
      });
    };

    const revertVisible = ref(false);
    const revertTitle = ref("");
    const revertCommentLabel = ref("");
    const revertFormState = reactive({
      preMatchingId: "",
      rejectReason: "",
    });
    let rejectType = "";
    const pmCvRejectFunc = () => {
      // const params = {
      //   preMatchingId: revertFormState.preMatchingId,
      //   rejectReason: revertFormState.rejectReason
      // }
      $api.pmCVReject(revertFormState).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeRevertModal();
        doSearch();
        message.success(res.msg);
      });
    };
    const pmInterviewRejectFunc = () => {
      $api.pmInterviewFail(revertFormState).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeRevertModal();
        doSearch();
        message.success(res.msg);
      });
    };
    const pmCvRejectClick = (row) => {
      rejectType = "cv";
      revertFormState.preMatchingId = row.preMatchingId;
      revertVisible.value = true;
      revertTitle.value = "驳回";
      revertCommentLabel.value = "Reject Reason";
    };
    const pmInterviewRejectClick = (row) => {
      rejectType = "interview";
      revertFormState.preMatchingId = row.preMatchingId;
      revertVisible.value = true;
      revertTitle.value = "驳回";
      revertCommentLabel.value = "Reject Reason";
    };
    const closeRevertModal = () => {
      Object.assign(revertFormState, {
        preMatchingId: "",
        rejectReason: "",
      });
      revertVisible.value = false;
      revertTitle.value = "";
      revertCommentLabel.value = "";
    };
    const submitRevertModal = () => {
      if (rejectType === "cv") {
        pmCvRejectFunc();
      } else if (rejectType === "interview") {
        pmInterviewRejectFunc();
      }
    };

    watch(
        () => route.query.id,
        (val) => {
          if (val) {
            getActiveTab.value = "";
            noticePreMatchingId.value = route.query.id;
            doSearch();
          }
        }
    );
    onBeforeRouteLeave(() => {
      if (isTodoStatus.value) {
        store.commit("SET_IS_TODO_STATUS", false);
      }
      getActiveTab.value = "";
    });
    const isOperationTipShow = ref(false);
    const roles = store.state.app.user.roles;
    const getOperationTipShow = () => {
      // 如果为RM 为true
      isOperationTipShow.value = roles.indexOf("2") !== -1;
    };
    const saveAssignmentModal = (segmente) => {
      assignmentVisible.value = false;
      formState.onBoardDate = formState.assignmentTerm[0] ? moment(formState.assignmentTerm[0])
          .add(0, "days")
          .format("YYYY-MM-DD") : '';
      formState.actualReleaseDate = formState.assignmentTerm[1] ? moment(formState.assignmentTerm[1])
          .add(0, "days")
          .format("YYYY-MM-DD") : '';
      formState.copyList = formState.copyList.join(";");
      formState.id = savedId.value ? savedId.value : "";
      formState.leadingCsm = formState.leadingCSM;
      formState.staffAssignmentParts = segmente
      $api.saveAssignment(formState).then((res) => {
        if (res.code === 200) message.success("保存成功");
      });
    };

    //勾选逻辑
    const selectData = ref([]);

    const select = (data) => {
      selectData.value.push(data)
      //用来判断此时状态是否是全选
      emit("getSelectedData", matchList.value, selectData.value);
    }

    const removeSelected = (data) => {
      selectData.value = selectData.value.filter(item => item.preMatchingId !== data.preMatchingId)
      emit("getSelectedData", matchList.value, selectData.value);
    }

    const selectAll = () => {
      matchList.value.forEach(item => {
        if (!selectData.value.some(jtem => jtem.preMatchingId === item.preMatchingId)) {
          selectData.value.push(item)
        }
      })
      emit("getSelectedData", matchList.value, selectData.value);
    }

    const removeSelectedAll = () => {
      selectData.value = selectData.value.filter(item => !matchList.value.some(jtem => jtem.preMatchingId === item.preMatchingId))
      emit("getSelectedData", matchList.value, selectData.value);
    }

    const clearSelectedAll = () => {
      selectData.value = []
      emit("getSelectedData", matchList.value, selectData.value);
    }

    //pipeline需求信息弹窗
    const pipelineVisible = ref(false)
    let optType = 'match'
    const pipelineInfo = ref({})
    const closePipelineModal = () => {
      pipelineInfo.value = {}
      pipelineVisible.value = false
    }
    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })
    const isPM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("3") !== -1
    })

    onMounted(() => {
      getOperationTipShow();
      if(!isPM.value) getPMoptions()
    })

    let pmOptions = ref([])
    const getPMoptions = async () => {
      let result = await $api.findMatchingPM()
      pmOptions.value = result.data.map(item => {
        return {
          ...item,
          value: item.fullName + '/' + item.email
        }
      })
    }

    const updateArveValue = async (val) => {
      let obj = {
        "arveAssumption": val[0].arveAssumption,
        "arveAssumptionStartDate": val[0].arveAssumptionStartDate
      }
      empDetail.value = Object.assign(empDetail, obj)
    }

    const refreshTable = (data) => {
      emit('refreshTab', data)
    }
    const sendCloseModal = () => {
      assignmentVisible.value = false
    }
    const relevanceAndUpdata = () => {
      doSearch()
    }
    const upDemandIdHandler = (val,pm) =>{
      drawerDemandId.value = val
      drawerPmId.value = pm
    }

    return {
      searchCondition,
      matchList,
      pagination,
      matchInfoRef,
      resetCurrent,
      checkMatchDetails,
      empDetail,
      closeMataInfoModal,
      showMateInfo,
      searchClick,
      doSearch,
      empVisible,
      empId,
      openEmpModal,
      closeEmpModal,
      assignmentVisible,
      assignmentTitle,
      formState,
      pmFillAssignmentClick,
      closeAssignmentModal,
      submitAssignmentModal,
      submitAssignmentContent,
      rmConfirmClick,
      rejectVisible,
      openRejectModal,
      closeRejectModal,
      submitRejectModal,
      getStepCurrent,
      pmCvAccpectClick,
      pmCvRejectClick,
      pmInterviewRejectClick,
      revertVisible,
      revertTitle,
      revertCommentLabel,
      revertFormState,
      closeRevertModal,
      submitRevertModal,
      rejectHidden,
      storePerms,
      showRollback,
      rollbackAssignmentModal,
      submitAssignment,
      overlapModalVisible,
      overlapData,
      fillAssignmentModal,
      fillAssignmentType,
      isOperationTipShow,
      replaceTipModalVisible,
      confrimReplaceTipModal,
      isEarlierThanToday,
      onBoardDayTip,
      clientNameOps,
      industryLineOps,
      handleUploadAnnex,
      getAnnexList,
      annexDataList,
      getAccountOptions,
      accountIndustryRelaction,
      originalCodeAndDateObj,
      codeRelationList,
      noticePreMatchingId,
      handlegetActiveTab,
      getActiveTab,
      showSendAssignmentLetter,
      showSaveBtn,
      saveAssignmentModal,
      savedId,
      modelValue,
      content,
      showIconCom,
      handleOk,
      rejectNoLoginState,
      submitNoLoginRejectModal,
      segmenteData,
      tabStatus,
      CustomizeInfoRef,
      selectData,
      select,
      removeSelected,
      selectAll,
      removeSelectedAll,
      pipelineVisible,
      optType,
      pipelineInfo,
      closePipelineModal,
      clearSelectedAll,
      isRM,
      isPM,
      activeTab,
      updateArveValue,
      refreshTable,
      searchByPange,
      pmOptions,
      sendPMHandler,
      buttonType,
      sendCloseModal,
      rowType,
      preMatchingId,
      relevanceAndUpdata,
      upDemandIdHandler
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";

// 覆盖ant-design样式
::v-deep .ant-list-items {
  //&::-webkit-scrollbar {
  //  width: 10px;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  width: 10px;
  //  background: #d0e8ff;
  //  border: 1px solid #d0e8ff;
  //  border-radius: 5px;
  //}
}

:deep(.ant-list-empty-text) {
  height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  width: 100%;
  display: table;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.match-list {
  flex: 1;

  //overflow: scroll;
  .match-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 15.5vw);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    margin: 0 1vw 0 1.25vw;
    padding: 1vw 0 0;
    position: relative;

    .steps {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #77CAED;
        top: 0;
      }
    }

    .steps2 {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #3182CE;
        top: 0;
      }
    }

    .steps3 {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #DC5E4F;
        top: 0;

        &::after {
          content: 'x';
          top: -0.22vw;
          left: -1.28vw;
        }
      }
    }

    .astep1 {
      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #3182CE;
        top: 0;

        &::after {
          content: '';
          top: -0.22vw;
          left: -1.28vw;
        }
      }
    }

    .buttons {
      padding-bottom: 24px;
    }
  }

  :deep(.ant-list-bordered) {
    border: 0px;
  }

  .first-line {
    font-size: 18px;
    color: #1890ff;
    margin-bottom: 12px;
  }

  .match-name {
    cursor: pointer;
    position: relative;
    display: flex;

    .match-name-text {
      //display: block;
      //overflow: hidden;
      //text-overflow: ellipsis;

      cursor: pointer;
      display: inline-block;
      margin-left: 0.41667vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //line-height: 1.30208vw;
      font-size: .9375vw;
      color: #4d4d4d;
      font-weight: 600;
      border-bottom: 1px solid #4d4d4d;
    }

    .completetag {
      padding-top: 0.15vw;
      font-size: 0.73vw;
    }

    .tagColor2 {
      color: #FDCD46;
      background: #FFFCB8;
      border-color: #FFFCB8;
    }

    .tagColor3 {
      color: #FFFFFF;
      background: #58B99E;
      border-color: #58B99E;
    }

    .reject-icon {
      position: absolute;
      top: 5px;
      padding-left: 10px;
      left: -35px;
      color: #ff4d4f;
      cursor: pointer;
    }

    .ant-tag {
      margin-left: 0.6vw;
      border-radius: 1vw;
      line-height: inherit;
    }

    .num {
      display: inline-block;
      padding: 0 4px;
      //line-height: 20px;
      color: #3182CE;
      font-weight: 600;
      border: 1px solid #77CAED;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.8vw;
      margin-top: 0.2vw;
    }

    :deep(.ant-tag-blue) {
      color: #3182CE;
      background: #E9F6FE;
      border-color: #E9F6FE;
    }
  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .more-info {
    padding: 0 32px 24px;
    display: flex;
    align-items: center;
    width: 200px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .suplement {
    line-height: 32px;
    background: #d0e8ff;
    padding: 0 32px;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: all 2s linear;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      .key-msg-span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 0.05vw;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }

      .img {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
      }

      .levelimg {
        width: 0.8vw;
        height: 0.8vw;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .match-status {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding-bottom: 24px;
      color: rgba(77, 77, 77, 0.6);
    }

    .reject-icon {
      position: absolute;
      top: -0.2vw;
      padding-left: 10px;
      left: 1.8vw;
      color: #ff4d4f;
      cursor: pointer;
    }

    .img {
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-bottom: 0.15vw;
    }

    .arve {
      color: #3182CE;
      font-size: 14px
    }
  }

  .match-btn {
    position: absolute;
    //bottom: -10px;
    right: 0;

    .select-btn {
      width: 35px;
      height: 35px;
      border: 1px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: 2px;
      color: #3182CE;
      text-align: center;
      line-height: 32px;
      top: 50%;
      left: 50%;
      cursor: pointer;
    }

    .selected-btn {
      background-color: #3182CE;
      width: 35px;
      height: 35px;
      border: 1px solid #3182CE;
      box-sizing: border-box;
      border-radius: 2px;
      color: #3182CE;
      text-align: center;
      line-height: 32px;
      top: 50%;
      left: 50%;
      cursor: pointer;
    }

    .selectProgress {
      content: "";
      display: inline-block;
      height: 60px;
      width: 0.05vw;
      background-color: #CCCCCC;
      margin: 0 0 0 10px;
    }
  }

  :deep(.ant-pagination) {
    justify-content: flex-end;
  }

  .statustip {
    color: #999999;
    //margin-top: 1.2vw;
  }
}

.cName {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .9375vw;
  color: #4d4d4d;
  font-weight: 600;
  border-bottom: 1px solid #4d4d4d;
}

.dayTipStyle {
  color: red;
  font-size: 18px;
  font-weight: bold;
}

:deep(.ant-steps-dot .ant-steps-item-icon) {
  width: 0.6vw;
  height: 0.6vw;
}

:deep(.ant-spin-nested-loading) {
  //height: calc(100vh - 17.5vw);
  //overflow-y: auto;
}

:deep(.ant-list-bordered .ant-list-pagination) {
  margin: 0.2vw 0 0 0;
}
</style>
