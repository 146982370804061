<template>
  <section class="top-filter">
    <FilterLayout @doReset="handleReset" @doSearch="doSearch" @doCollapseEffect="handleResize" ref="filterLayoutRef">
      <template #customform>
        <a-form class="form rms-form" name="custom-validation" ref="formRef" :model="formState" layout="vertical">
          <a-form-item label="搜索" name="nameCondition" class="rms-form-item">
            <a-input v-model:value="formState.nameCondition" placeholder="Name/GGID" allowClear
                     autocomplete="off" />
          </a-form-item>
          <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgThreeTwoList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
                :options="orgThreeTwoOps"
                @change="changeOrgThreeTwo"
            >
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoAssignment:offering']" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgFourOneOneList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
                :options="orgFourOneOneOps"
            >
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Status" name="statusList" class="rms-form-item">
            <SelectWithAll v-model:value="formState.statusList" placeholder="请选择" mode="multiple" showArrow :maxTagCount="1"
                           allowClear :options="statusOps">
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Capgemini Start Date" name="capgeminiStratDate" class="rms-form-item">
            <a-range-picker v-model:value="formState.capgeminiStratDate" format="YYYY-MM-DD"
                            valueFormat="YYYY-MM-DD" @change="handlecapgeminiStratDateChange" />
          </a-form-item>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <a-button class="nopadding" :disabled="disabled" v-auth="['system:staffInfo:todolistWelcomeletter']" type="primary" ghost @click="towelcome">
            发送 Welcome Letter
          </a-button>
        </div>
      </template>
    </FilterLayout>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { cloneDeep } from "lodash";
import { dictionaryOptions } from "@/utils/common";

export default defineComponent({
  name: "WelcomeLetterFilter",
  components: {},
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    },
    disabled:{
      type: Boolean,
      default: true,
    }
  },
  emits: ["search", "refresh-table", "getSize", "exportClick", "getOrgList"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const formState = reactive({
      nameCondition: "",
      status: null,
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      statusList: [],
      capgeminiStratDate: []
    });

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = cloneDeep(formState);
    }

    // 点击收起展开计算table所占高度
    const handleResize = () => {
      emit("getSize");
    }

    const doSearch = () => {
      updateParams();
      emit("search", currentParams);
    };

    const handleReset = () => {
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      formRef.value.resetFields();
      formState.onBoardBegin=''
      formState.onBoardEnd=''
      updateParams();
      emit("search", currentParams);
      // 重新获取team offering下拉
      if (props.isRM) {
        emit("getOrgList")
      }
    };
    const handlecapgeminiStratDateChange = () => {
      formState.onBoardBegin = formState.capgeminiStratDate[0]
      formState.onBoardEnd = formState.capgeminiStratDate[1]
    }
    const statusOps = ref([]);
    const getAssignmentTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "todoListPreStatus,staffStatus",
          },
        })
        .then((res) => {
          statusOps.value = dictionaryOptions(res.data.staffStatus);
        });
    };

    // team改变时更新offering下拉框内容
    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList);
    };
    onMounted(() => {
      getAssignmentTypes();
    });

    onActivated(() => {
      emit("refresh-table");
    });
    const towelcome = () => {
      emit("towelcome")
    }
    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      updateParams,
      handleResize,
      changeOrgThreeTwo,
      statusOps,
      towelcome,
      handlecapgeminiStratDateChange
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;

  .search {
    text-align: right;
  }
}
:deep(.rms-filter-btn-container){
  width: 110px;
}
.nopadding{
  padding-left: 5px;
  padding-right: 5px;
}
</style>
