<template>
  <div ref="updateIdcModal">
  <a-modal
      :getContainer="() => $refs.updateIdcModal"
      :visible="visible"
      title="操作"
      @cancel="closeModal"
      width="29.2vw"
      :z-index="10000"
      centered
  >
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
        layout="vertical"
        :labelCol="{span:22}"
        :wrapperCol="{span:22}"
        :model="formData"
        :rules="formRules"
        ref="formRef"
        class="form rms-form"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="Entity" name="entity">
            <a-select v-model:value="formData.entity" placeholder="请选择"
                      :options="entityList" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Offering" name="orgFourOneOneList">
            <a-select v-model:value="formData.orgFourOneOneList" placeholder="请选择" showArrow
                      allowClear :maxTagCount="1" show-search>
              <a-select-option v-for="(item, index) in orgFourOneOneOps" :key="index" :value="item"><span
                  :title="item">{{
                  item }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {message} from "ant-design-vue";
import {useGlobalPropertyHook} from "@/hooks/common";

export default {
  name: "UpdateIdc",
  props: {
    visible: Boolean,
    updateIdcOffering: {
      type: Array,
      default:() => {
        return []
      }
    },
  },
  emits: ['close-modal','submitModal'],
  setup(props, { emit }) {
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const formData = ref({
      entity: '',
      orgFourOneOneList: ''
    })

    const formRules = {
      entity: [
        {
          required: true,
          message: "Please select entity",
          trigger: "change",
        },
      ],
      orgFourOneOneList: [
        {
          required: true,
          message: "Please select Offering",
          trigger: "change",
        },
      ],
    };

    const entityList = [
      { label: "PRC", value: "PRC", key: "1" },
      { label: "PRK", value: "PRK", key: "2" },
      { label: "PRJ", value: "PRJ", key: "3" }
    ]
    const orgFourOneOneOps = computed(() => props.updateIdcOffering)

    const closeModal = () => {
      formRef.value.resetFields();
      emit("close-modal");
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          entity: formData.value.entity,
          offering: formData.value.orgFourOneOneList,
        };
        $api.getIdcData(params).then((res) => {
          emit("submitModal",res.data);
          closeModal();
          message.success("更改成功");
        });
      });
    };

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            console.log(val)
            //formData.value.orgFourOneOneList = props.updateIdcOffering
          }
        }
    )

    return {
      formRef,
      closeModal,
      submitModal,
      formData,
      formRules,
      entityList,
      orgFourOneOneOps,
    };
  },
}
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.rms-form{
  display: block;
  padding: 0 !important;
  border-bottom: none;
}
</style>
