<template>
  <div class="org-info" v-show="visible">
    <CloseOutlined class="close-icon" @click="cancel" />
    <div class="title">Assignment 信息</div>
    <div class="btn-part" v-if="hasOperateBtn">
      <div class="left">
        <a-space>
          <a-button
            class="eachBtn"
            @click="pmConfirm"
            v-auth="['system:assignmentDetails:confirmPM']"
            v-if="assignmentStatus === '2' || assignmentStatus === '6'"
          >
            <!--PM上项目确定、PM下项目确定-->
            {{assignmentStatus === "2" ? "确定" : "Release" }}
          </a-button>
          <a-button
            class="eachBtn"
            @click="pmOnBoardConfirm"
            v-auth="['system:assignmentDetails:apply']"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '3' ||
              assignmentStatus === '4'
            "
          >
            <!--PM上项目申请-->
            申请
          </a-button>
          <a-button
            class="eachBtn"
            @click="pmEstimateConfirm"
            v-auth="['system:assignmentDetails:downApply']"
            v-if="
              assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8'
            "
          >
            <!--PM下项目申请-->
            申请
          </a-button>
          <a-button
            class="eachBtn"
            @click="openAppraises"
            v-auth="['system:assignmentDetails:evaluate']"
            v-if="assignmentStatus === '9' || assignmentStatus === '13'"
          >
            <!--PM已完成项目评价-->
            评价
          </a-button>

          <!-- 免登录特殊按钮评价开始 -->
          <a-button v-if="nonLoginEvalute" class="eachBtn"  @click="openAppraises">评价</a-button>
          <a-button v-if="noReleaseDate" class="eachBtn"  @click="pmEstimateConfirm">改期</a-button>
          <!-- 免登录特殊按钮评价结束 -->

          <a-button
            class="eachBtn"
            @click="openEmpInfo"
            v-auth="['system:assignmentDetails:empInfo']"
          >
            <!--PM已完成项目评价-->
            个人信息
          </a-button>

          <a-button
            class="eachBtn"
            @click="rmOnBoardConfirm"
            v-auth="[
              'system:assignmentDetails:confirmRM',
              'system:assignmentDetails:approve',
            ]"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '3' ||
              assignmentStatus === '4'
            "
          >
            <!--RM上项目确定、上项目审批-->
            {{
              assignmentStatus === "1" ||
              assignmentStatus === "2" ||
              assignmentStatus === "4"
                ? "确认"
                : "审批"
            }}
          </a-button>
          <a-button
            class="eachBtn"
            @click="rmEstimateConfirm"
            v-auth="[
              'system:assignmentDetails:downRelease',
              'system:assignmentDetails:downConfirmRM',
              'system:assignmentDetails:downApprove',
            ]"
            v-if="
              assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8'
            "
          >
            <!--RM下项目Release、下项目确定、下项目审批-->
            {{
              assignmentStatus === "5" || assignmentStatus === "8" ||assignmentStatus === "6"
                ? "Release"
                : "审批"
            }}
          </a-button>
          <a-button
            class="eachBtn"
            @click="modifyInfo"
            v-auth="['system:assignmentDetails:update']"
            v-if="
              assignmentStatus !== '13' &&
              assignmentStatus !== '9' &&
              assignmentStatus !== '12' &&
              assignmentStatus !== '14'
            "
          >
            修改调令
          </a-button>
<!--          <a-button-->
<!--            class="eachBtn"-->
<!--            @click="rmEditTime"-->
<!--            v-auth="['system:assignmentDetails:editTime']"-->
<!--            v-if="-->
<!--              assignmentStatus === '1' ||-->
<!--              assignmentStatus === '2' ||-->
<!--              assignmentStatus === '4' ||-->
<!--              assignmentStatus === '5' ||-->
<!--              assignmentStatus === '6' ||-->
<!--              assignmentStatus === '8'-->
<!--            "-->
<!--          >-->
<!--            修改时间-->
<!--          </a-button>-->
          <a-button
            class="eachBtn"
            @click="openRelease"
            v-auth="['system:assignmentDetails:release']"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '4'
            "
          >
            异常处理
          </a-button>
          <Tooltip title="仅支持对实际下项目时间（Actual release date）距当日一个月内的顾问进行调令重启">
            <a-button
                class="eachBtn"
                @click="restartAssignment"
                v-auth="['system:assignmentDetails:restartAssignment']"
                v-if="activeTabVal === '3'"
                :disabled="isRestart"
            >
              重启调令
            </a-button>
          </Tooltip>
          <a-button
              class="eachBtn"
              @click="restartAssignmentConfirm"
              v-auth="['system:assignmentDetails:restartAssignmentConfirm']"
              v-if="activeTabVal === '3' && roleEmail === 'tingting.jiang@capgemini.com' && assignmentStatus === '14'"
          >
            审批
          </a-button>
        </a-space>
      </div>
      <a-space class="right">
        <a-button @click="emailClick" v-if="activeTabVal === '2' || activeTabVal === '3'" style="margin-left: 10px">
          邮件记录
        </a-button>
        <a-button
          @click="modifyRecord"
          v-auth="['system:assignmentDetails:modifyRecord']"
        >
          调令记录
        </a-button>
        <a-button
          class="eachBtn"
          @click="recordClick"
          v-auth="['system:assignmentDetails:applyRecord']"
        >
          申请记录
        </a-button>
      </a-space>
    </div>
    <div class="scroll-wrap">
      <a-row>
        <a-col :span="4" class="avatar-wrap">
          <a-avatar :size="60" :src="orgInfo.photoUrl" class="avatar">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </a-col>
        <a-col :span="20" class="list-col">
          <a-row>
            <a-col :span="13">
              <a-col :span="24" class="label"> Candidate Name</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.fullName }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.fullName }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11">
              <a-col :span="24" class="label"> Grade</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.psGroup }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.psGroup }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="24">
              <a-col :span="24" class="label"> Engagement Description</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.engagementDescription }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.engagementDescription }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="13">
              <a-col :span="24" class="label"> Project Onboard Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.onBoardDate }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.onBoardDate }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11">
              <a-col :span="24" class="label">Estimate Release Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.estimateReleaseDate }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.estimateReleaseDate }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="13">
              <a-col :span="24" class="label"> Hours Per Day</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.hoursPerDay }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.hoursPerDay }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11" v-show="activeTabVal === '2' || activeTabVal === '3' || activeTabVal === '4'">
              <a-col :span="24" class="label">{{ 'Actual Release Date' }}</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{
                        assignmentStatus !== '8' &&
                        assignmentStatus !=='9' &&
                        assignmentStatus !=='13' &&
                        assignmentStatus !=='14'
                            ? '-' : orgInfo.actualReleaseDate }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ assignmentStatus !== '8' &&
                  assignmentStatus !=='9' &&
                  assignmentStatus !=='13'&&
                  assignmentStatus !=='14'
                      ? '-' : orgInfo.actualReleaseDate }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="13" v-show="isRM || isRMLeader">
              <a-col :span="24" class="label"> FCST Extension Assumption Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.extensionAssumptionDate }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.extensionAssumptionDate? orgInfo.extensionAssumptionDate : '-' }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11" v-show="isRM || isRMLeader">
              <a-col :span="24" class="label">确定性</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.certainty }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.certainty? orgInfo.certainty : '-' }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <div class="staffAssignmentClass" v-if="staffAssignmentList.length > 0">
        <a-table
            :columns="staffAssignmentColumns"
            :data-source="staffAssignmentList"
            rowKey="arveNO"
            :pagination="false"
        >
          <template #index="{ index }">
            <span>{{ index + 1}}</span>
          </template>
        </a-table>
      </div>
      <div class="line" v-else></div>

      <a-row>
        <a-col :span="12">
          <a-col :span="24" class="label"> Service Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.serviceLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.serviceLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Sub Service Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.subServiceLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.subServiceLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Candidate Name</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.fullName }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.fullName }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Candidate Contact</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.candidateContact }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.candidateContact }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Grade</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.psGroup }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.psGroup }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> ADRC</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.personCost }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.personCost }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Engagement Code</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.engagementCode }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.engagementCode }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Code有效期</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ codeTerm }}</span>
              </template>
              <div class="overflow-text">{{ codeTerm }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Account</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.account
                  }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.account }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Industry Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.industryLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.industryLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Code预计开出时间</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.projectCommitDate }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.projectCommitDate }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Engagement Manager</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.engagementManager }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.engagementManager }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12" v-show="isFormalCode">
          <a-col :span="24" class="label">Leading CSM</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.leadingCSM }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.leadingCSM }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Work City</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.workCity }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.workCity }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label">PM</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.pmInfo }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.pmInfo }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Work Location</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.workLocation }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.workLocation }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Hours Per Day</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.hoursPerDay }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.hoursPerDay }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Assignment Type</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.assignmentType }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.assignmentType }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Copy</a-col>
          <a-col :span="24" class="value-normal">
          <a-tooltip
              placement="topLeft"
              :overlayStyle="{ maxWidth: 'unset' }"
          >
            <template #title>
              <div class="copy-person">
                <div v-for="item in orgInfo.copyList" :key="item">
                  {{ item }}
                </div>
              </div>
            </template>
            <div class="overflow-text">
              {{ orgInfo.copyPersonName }}
            </div>
          </a-tooltip>
        </a-col>
        </a-col>
      </a-row>
      <div class="annex-table-cantainer" v-show="isShowAnnexRelated">
        <AnnexDataTable :annexDataList="annexDataList"
                        @show-drawer="showDrawer"
                        :isCanUpload="activeTabVal === '1' || activeTabVal === '2'"
                        :annexColumns="annexColumns"
                        @del-annexClick="delAnnexClick"
                        :isSecondUpload="true"
                        @download-annexClick="downloadAnnexClick"
                        @replace-AnnexClick="replaceAnnexes"
        ></AnnexDataTable>
      </div>
      <div>
        <upload-annex ref="uploadAnnexRef"
                      v-model:drawerVisible="drawerVisible"
                      @onUpdateFileList="onUpdateFileList"
                      :titleName="titleName"
                      @onCloseDrawer="onCloseDrawer"
                      :isSecondUpload="true"
                      :isReplace="isReplace"></upload-annex>
      </div>
    </div>
  </div>
  <record-modal
    v-model:visible="recordVisible"
    title="查看记录"
    @close-modal="closeRecordModal"
    :assignment-info="orgInfo"
  />
  <email-modal
    v-model:visible="emailVisible"
    title="邮件发送记录"
    @close-modal="closeEmailModal"
    :assignment-info="orgInfo" />
<!--  <change-record-modal-->
<!--    v-model:visible="modifyVisible"-->
<!--    title="查看调令变更记录"-->
<!--    @close-modal="closeModifyModal"-->
<!--    :info="orgInfo"-->
<!--  />-->
  <EmpInfo
    v-if="empVisible"
    :is-showing-emp-info="empVisible"
    :get-id="orgInfo.userId"
    title="个人信息"
    :showwork-experience-col="false"
    :is-have-add="false"
    @close-emp-info="closeEmpInfo"
  />
  <AssignmentChangeRecord
      v-model:visible="modifyVisible"
      title="查看调令变更记录"
      @close-modal="closeModifyModal"
      :info="orgInfo"
  />
  <div ref="modalDialog">
    <a-modal :visible="modelValue" title="提示" width="20vw" @cancel="modelValue=false" :footer="null" centered
             :z-index="10002" :getContainer="()=>$refs.modalDialog">
      <p style="font-size: 0.83vw"><ExclamationCircleOutlined class="icon-position"/>重启调令正在审批中</p>
    </a-modal>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import { CloseOutlined, UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import RecordModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/recordModal";
import EmailModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/EmailModal";
import EmpInfo from "@/components/EmpInfo";
import AnnexDataTable from "@/components/AnnexDataTable";
import UploadAnnex from "@/views/home/ResourceManagement/Match/matchingList/components/uploadAnnex";
import {message} from "ant-design-vue";
import { formatCodeTerm } from "@/utils/common"
import AssignmentChangeRecord from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/AssignmentChangeRecord.vue";
import Tooltip from "@/components/Tooltip.vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "AssignmentInfo",
  components: {
    Tooltip,
    AssignmentChangeRecord,
    RecordModal,
    CloseOutlined,
    UserOutlined,
    EmpInfo,
    AnnexDataTable,
    UploadAnnex,
    EmailModal,
    ExclamationCircleOutlined,
  },
  props: {
    visible: Boolean,
    orgInfo: Object,
    hasOperateBtn: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: String,
    },
    annexDataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    annexColumns: {
      type: Array,
      default: () => {
        return []
      }
    },
    isShowAnnexRelated: {
      type: Boolean,
      default: false,
    },
    isPmOrConsultant: Boolean,
    isFormalCode: {
      type: Boolean,
      default: false,
    },
    nonLoginEvalute:{
      type: Boolean,
      default: false,
    },
    noReleaseDate:{
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "close-info",
    "pm-confirm",
    "pm-on-board-confirm",
    "modify-info",
    "open-release",
    "open-appraises",
    "pm-estimate-confirm",
    "rm-on-board-confirm",
    "rm-estimate-confirm",
    "rm-edit-time",
    "download-annexes",
    "delete-annexes",
    "update-file-list",
    "replace-annexes",
    "rm-restart-assignment",
    "rmleader-assignment-Confirm",
  ],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    //activeTabVal之前取activeTab，在消息跳转第一次跳转时，获取不到activeTab，在这里冲判断一下当前在那个tab页
    const activeTabVal = computed(()=>{
      let getActiveTab = ''
      let hardBook = ['1', '2', '3', '4']
      let inProgress = ["5", "6", "7", "8"]
      let Completed = ["9", "13", "14"]
      let Invalid = ["12"]
      if (hardBook.includes(props.orgInfo.status)) {
        getActiveTab = '1'
      } else if (inProgress.includes(props.orgInfo.status)) {
        getActiveTab = '2'
      } else if (Completed.includes(props.orgInfo.status)) {
        getActiveTab = '3'
      } else if (Invalid.includes(props.orgInfo.status)) {
        getActiveTab = '4'
      }
      return getActiveTab
    })
    const cancel = () => {
      ctx.emit("close-info");
    };
    const pmConfirm = () => {
      ctx.emit("pm-confirm", props.orgInfo);
    };
    const pmOnBoardConfirm = () => {
      ctx.emit("pm-on-board-confirm", props.orgInfo);
    };
    const modifyInfo = () => {
      ctx.emit("modify-info", props.orgInfo);
    };
    const openRelease = () => {
      ctx.emit("open-release", props.orgInfo);
    };
    const openAppraises = () => {
      ctx.emit("open-appraises", props.orgInfo);
    };
    const pmEstimateConfirm = () => {
      ctx.emit("pm-estimate-confirm", props.orgInfo);
    };
    const rmOnBoardConfirm = () => {
      ctx.emit("rm-on-board-confirm", props.orgInfo);
    };
    const rmEstimateConfirm = () => {
      ctx.emit("rm-estimate-confirm", props.orgInfo);
    };
    // const rmEditTime = () => {
    //   ctx.emit("rm-edit-time", props.orgInfo);
    // };
    const restartAssignment = async () => {
      const { msg } = await $api.isRestartAssignment({id:props.orgInfo.id})
      if (msg === 'success'){
        ctx.emit("rm-restart-assignment", props.orgInfo);
      } else {
        modelValue.value = true
      }
    }
    const modelValue = ref(false)
    //rmleader(Jiang tingting)审批
    const restartAssignmentConfirm = () => {
      ctx.emit("rmleader-assignment-Confirm", props.orgInfo);
    }

    const recordVisible = ref(false);
    const recordClick = () => {
      recordVisible.value = true;
    };
    const modifyVisible = ref(false);
    const modifyRecord = () => {
      modifyVisible.value = true;
    };
    const closeRecordModal = () => {
      recordVisible.value = false;
    };
    const emailVisible = ref(false)
    const emailClick = () => {
      emailVisible.value = true;
    };
    const closeEmailModal = () => {
      emailVisible.value = false;
    };
    const closeModifyModal = () => {
      modifyVisible.value = false;
    };
    const codeTerm = ref("")
    watch(
        () => props.orgInfo,
        (val) => {
          val.codeEndDate = val.projectStatus === 'Close' ? val.codeEndDate : val.projectCompletionDate
          codeTerm.value = formatCodeTerm(val.codeStartDate, val.codeEndDate)
        }
    )
    const assignmentStatus = computed(() => props.orgInfo.status);
    const empVisible = ref(false);
    const openEmpInfo = () => {
      empVisible.value = true;
    };
    const closeEmpInfo = () => {
      empVisible.value = false;
    };

    // 附件上传相关 start
    const uploadAnnexRef = ref(null)
    const drawerVisible = ref(false)
    const titleName = ref('')
    const isReplace = ref(false)
    const theColumsData = ref({})
    const showDrawer = () => {
      uploadAnnexRef.value.resetFileList()
      drawerVisible.value = true;
      titleName.value = '上传附件'
    };
    const delAnnexClick = (record) => {
      ctx.emit('delete-annexes',record)
    }
    const downloadAnnexClick = (record) => {
      ctx.emit('download-annexes',record)
    }
    const onUpdateFileList = (fileList) => {
      if(fileList.length < 1) {
        message.error("请选择文件。")
        return false;
      }
      if(isReplace.value) {
        ctx.emit('replace-annexes',fileList, theColumsData.value)
      }else {
        ctx.emit('update-file-list',fileList)
      }
      onCloseDrawer()
    }
    const replaceAnnexes = (record) => {
      theColumsData.value = record
      showDrawer()
      isReplace.value = true
    }
    const onCloseDrawer = () => {
      drawerVisible.value = false;
      isReplace.value = false;
    }
    // 附件上传相关 end

    //分段调令
    const staffAssignmentColumns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        width: 45,
        ellipsis: true,
        slots: { customRender: "index" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "startDate",
        key: "startDate",
        width: 140,
        ellipsis: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 145,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 95,
      },
    ]
    const staffAssignmentList = computed(() => props?.orgInfo?.staffAssignmentParts? props.orgInfo.staffAssignmentParts : [])

    //判断重启调令是否置灰
    const isRestart = ref(false)
    watch(
        () => props.orgInfo.actualReleaseDate,
        (val) => {
          if(val){
            let day = moment(val).add(1, 'months').format("YYYY-MM-DD")
            isRestart.value = moment(day).isBefore(moment(), "day")
          }
        }
    )
    //获取当前角色de邮箱
    const roleEmail = computed(() => store.state.app.user.email);

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const isRMLeader = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("11") !== -1
    })

    return {
      cancel,
      pmConfirm,
      pmOnBoardConfirm,
      modifyInfo,
      openRelease,
      openAppraises,
      pmEstimateConfirm,
      rmOnBoardConfirm,
      rmEstimateConfirm,
      recordVisible,
      modifyRecord,
      modifyVisible,
      recordClick,
      closeRecordModal,
      closeModifyModal,
      //rmEditTime,
      assignmentStatus,
      empVisible,
      openEmpInfo,
      closeEmpInfo,
      activeTabVal,
      drawerVisible,
      showDrawer,
      titleName,
      delAnnexClick,
      downloadAnnexClick,
      onUpdateFileList,
      onCloseDrawer,
      uploadAnnexRef,
      replaceAnnexes,
      isReplace,
      theColumsData,
      codeTerm,
      closeEmailModal,
      emailVisible,
      emailClick,
      staffAssignmentColumns,
      staffAssignmentList,
      restartAssignment,
      modelValue,
      isRestart,
      restartAssignmentConfirm,
      roleEmail,
      isRM,
      isRMLeader,
    };
  },
});
</script>

<style lang="less" scoped>
.org-info {
  position: relative;
  padding: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 22px;
    line-height: 22px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .btn-part {
    margin-bottom: 5px;
    display: flex;
    margin-right: 20px;

    .left {
      flex: 1;
    }
  }

  .ant-row {
    margin-top: 5px;
    margin-right: 20px;
  }

  .label {
    font-size: 16px;
    line-height: 20px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .value-high {
    //font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    vertical-align: middle;
    color: #000000;
    margin-bottom: 10px;
  }

  .value-normal {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505050;
    margin-bottom: 10px;
  }

  .value-low {
    font-size: 16px;
    line-height: 20px;
    color: #505050;
    margin-bottom: 10px;
  }

  .overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .line {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 5px 0;
  }
  .staffAssignmentClass {
    margin-right: 1.04167vw
  }

  .avatar-wrap {
    display: flex;
    //justify-content: center;
    //align-items: center;
    margin-top: 0.26vw;
  }

  .scroll-wrap {
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
}

.copy-person {
  padding: 5px;
  white-space: nowrap;
}
.annex-table-cantainer{
  margin-right: 15px;
  margin-top: 25px;
  ::v-deep .ant-table-body {
    &:last-child {
      height: initial !important;
    }
  }
}
.icon-position {
  color: #fdcd46;
  margin-right: 8px;
}
</style>
