<template>
  <section class="top-filter">
    <div v-show="!isPM">
      <filter-layout
        @doReset="resetForm"
        @doSearch="doSearch"
        ref="filterLayoutRef"
      >
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item
                name="selectPM"
                label="PM"
                v-auth="['system:assignmentDetails:selectPM']"
                v-if="!isTodoStatus && isPending"
                class="rms-form-item"
            >
              <a-select
                  v-model:value="formState.selectPM"
                  show-search
                  placeholder="选择PM"
                  :show-arrow="false"
                  allowClear
                  :filter-option="filterOption"
                  @change="doSearch"
              >
                <a-select-option
                    v-for="pm in pmOptions"
                    :value="pm.id"
                    :key="pm.id"
                >
                  {{ pm.fullName + "/" + pm.email }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
                label="搜索"
                name="nameCondition"
                v-auth="['system:assignmentDetails:search']"
                v-if="!isTodoStatus"
                class="rms-form-item"
            >
              <a-input
                  name="defaultName"
                  v-model:value="formState.nameCondition"
                  placeholder="Name/GGID"
                  enter-button
                  @search="doSearch"
                  allowClear
                  autocomplete="off"
              />
            </a-form-item>
            <a-form-item
                label="Engagement Description"
                name="engagementDescription"
                v-auth="['system:assignmentDetails:search']"
                class="rms-form-item"
            >
              <SelectWithAll
                  mode="multiple"
                  v-model:value="formState.engagementDescription"
                  show-search
                  placeholder="请选择"
                  allowClear
                  :options="allDescOps"
                  @keyup.enter="doSearch"
              ></SelectWithAll>
            </a-form-item>
          </a-form>
        </template>
      </filter-layout>
    </div>
    <div class="left">
      <a-tabs
        v-model:activeKey="activeTab"
        defaultActiveKey="1"
        class="left"
        @change="doSearch"
      >
        <a-tab-pane
          key="1"
          tab="未上项目 Hard Book"
          v-if="
            !isTodoStatus ||
            route.query.status === '1' ||
            todoListQuery?.status === '1'
          "
        ></a-tab-pane>
        <a-tab-pane
          key="2"
          tab="进行中 In Progress"
          v-if="
            !isTodoStatus ||
            route.query.status === '2' ||
            todoListQuery?.status === '2'
          "
        ></a-tab-pane>
        <a-tab-pane
          key="3"
          tab="已完成 Completed"
          v-if="
            (!isTodoStatus ||
              route.query.status === '3' ||
              todoListQuery?.status === '3') &&
            !isPending
          "
        ></a-tab-pane>
        <a-tab-pane
          key="4"
          tab="已失效 Invalid"
          v-if="
            (!isTodoStatus ||
              route.query.status === '4' ||
              todoListQuery?.status === '4') &&
            !isPending
          "
        ></a-tab-pane>
      </a-tabs>
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onActivated,
  onMounted,
  inject,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "Filter",
  emits: ["search"],
  props: {
    allDescOps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(_, ctx) {
    const route = useRoute();
    const store = useStore();
    const { $EventBus } = useGlobalPropertyHook();
    const { $api } = useGlobalPropertyHook();
    const todoListQuery = ref({});
    todoListQuery.value = inject("query")?.value?.value;
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const title = computed(() => store.state.app.user.id);
    const isPending = computed(
      () => store.state.page.assignmentCondition.isPending
    );
    const assignmentCondition = computed(
      () => store.state.page.assignmentCondition
    );

    const formRef = ref();
    const formState = reactive({
      nameCondition: assignmentCondition.value.condition,
      selectPM: undefined,
      engagementDescription: [],
    });
    const activeTab = ref(route.query.status ? route.query.status : "1");

    const doSearch = () => {
      ctx.emit("search", activeTab.value, formState);
    };

    const pmOptions = ref([]);
    const getPMOptions = () => {
      if (!store.state.page.assignmentCondition.engagementCode) return;
      const params = {
        engagementCode: isTodoStatus.value
          ? todoListQuery.value.assignmentData
          : store.state.page.assignmentCondition.engagementCode,
      };
      $api.rmBatchApplyPM(params).then((res) => {
        pmOptions.value = res.data;
      });
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    watch(
      () => route.query.id,
      () => {
        $EventBus.on("getActiveTab", (getActiveTab) => {
          activeTab.value = getActiveTab;
        });
      }
    );

    const perms = computed(() => store.state.app.perms);

    const isPM = computed(() => {
      const roles = store.state.app.user.roles;
      console.log(roles,'roles')
      return roles.indexOf("3") !== -1||roles.indexOf("14") !== -1;
    });
    console.log("isPm", isPM.value);
    const filterLayoutRef = ref();
    const resetForm = () => {
      formState.nameCondition = assignmentCondition.value.condition;
      formState.selectPM = undefined;
      formState.engagementDescription = [];
      ctx.emit("search", activeTab.value, formState);
    };

    onMounted(() => {
      if (isTodoStatus.value) {
        activeTab.value = todoListQuery.value.status;
        doSearch();
      }
    });
    onActivated(() => {
      if (route.query.id) {
        $EventBus.on("getActiveTab", (getActiveTab) => {
          activeTab.value = getActiveTab;
        });
        assignmentCondition.value.condition = "";
      } else {
        activeTab.value = route.query.status
          ? route.query.status
          : activeTab.value;
      }
      if (perms.value.indexOf("system:assignmentDetails:selectPM") !== -1) {
        getPMOptions();
      }
      //避免query参数不变时，组件不重新渲染
      formState.nameCondition = assignmentCondition.value.condition;
      formState.engagementDescription.splice(0);
      doSearch();
    });

    return {
      store,
      route,
      formRef,
      formState,
      activeTab,
      doSearch,
      title,
      isTodoStatus,
      isPending,
      pmOptions,
      filterOption,
      todoListQuery,
      filterLayoutRef,
      resetForm,
      isPM,
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/newTitleTab.less";
::v-deep {
  .ant-tabs-bar {
    border: none;
  }
}

.top-filter {
  .title {
    font-weight: bold;
  }
}
</style>
