<template>
  <div ref="pipelineModalRef" class="pipelineModal">
    <a-drawer :getContainer="getRefsAff" width="38.5vw" :visible="visible" @close="closeModal" :title="title ? title : '需求详情'">
      <div class="container">
        <div class="btn-row bottom-btn">
          <div class="left"></div>
          <div class="right">
            <a-space>
              <a-button type="primary" ghost @click="closeModal">取消</a-button>
              <a-button type="primary" @click="modifyModal" v-if="plType === 'check'"
                v-auth="['system:pipeline:modify']">修改</a-button>
              <a-button type="primary" @click="submitModal" v-if="plType === 'edit' || plType === 'add'">确定</a-button>
              <a-button type="primary" @click="addMatch" v-if="plType === 'newMatch' && nowStep === 2">添加至匹配器</a-button>
            </a-space>
          </div>
        </div>
        <div v-if="plType === 'newMatch' || plType === 'match'" class="pipe">
          <div class="cusTitle" v-if="isConsult">
            <div class="demandName">
              <div class="circle"></div>
              <span style="max-width: 90%;font-weight: 600;">{{ formState.opportunityName }}</span>
            </div>
            <div style="display: flex;min-width: 30%;">
              <div style="margin-right: 1vw">
                <div class="info1">已有匹配</div>
                <div class="info2">
                  <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ plType === 'newMatch'? formState.currentValidMatchingNum : formState.mateNumber }}</span>
                  <span style="font-size: 0.83vw">人</span>
                </div>
              </div>
              <div class="info3"></div>
              <div style="margin-left: 1vw">
                <div class="info1">已有调令</div>
                <div class="info2">
                  <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ plType === 'newMatch'? formState.currentValidAssignmentNum : formState.transferOrderNumber }}</span>
                  <span style="font-size: 0.83vw">项</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-form ref="formRef" layout="vertical" :model="formState">
          <a-row>
            <div v-for="(item, index) in demandInfo" :key="index" style="display: contents">
              <a-col :span="24"
                v-show="item.type === '0' && (plType === 'check' || plType === 'newMatch' || plType === 'match')">
                <div class="check-info">
                  <a-collapse v-model:activeKey="activeKey" @change="fold(item)" :bordered="false"
                    expandIconPosition="right" style="background-color: #ffffff">
                    <template #expandIcon>
                      <div>
                        <CaretDownOutlined :rotate="item.isFold ? 180 : 0" class="icon" />
                      </div>
                    </template>
                    <a-collapse-panel :key="item.title" v-if="plType === 'match' ? item.match : item.newMatch">
                      <template #header>
                        <CardTitle class="card"> {{ item.title }} </CardTitle>
                      </template>
                      <a-row>
                        <div v-for="(jtem, jndex) in item.children" :key="jndex" style="display: contents;">
                          <a-col :span="jtem.span">
                            <hr v-if="jtem.type === '1'" class="hr-class" style="margin-bottom: 1vw;" />
                            <div
                              v-else-if="jtem.label != 'ARVE Assumption %: ' && jtem.label != 'ARVE Assumption Start Date: '">
                              {{ jtem.label }}<p class="text">{{ formState[jtem.value] ? formState[jtem.value] : '-' }}
                              </p>
                            </div>
                            <div class="changeValue" v-else>
                              <template v-if="['ARVE Assumption %: '].includes(jtem.label)">
                                <div>
                                  ARVE Assumption %:
                                  <div class="arveChange" v-show="arveValueChange">
                                    <p class="text arveText">{{ formState[jtem.value] }}</p>
                                    <FormOutlined class="changeIcon" v-if="canChangeARVE" @click="changeArve('num', formState[jtem.value])" />
                                  </div>
                                  <div class="positionAnother" v-show="!arveValueChange">
                                    <a-form ref="formRefArve" :model="arveFormValue">
                                      <a-form-item name="value" :rules="[
                                        {
                                          validator: () => validateArve(arveFormValue.value),
                                          trigger: ['change', 'blur'],
                                        }]">
                                        <a-input autocomplete='off' style="width:70px" v-model:value="arveFormValue.value"
                                          placeholder="请输入" />
                                        <CheckOutlined class="changeIcon" @click="confirmArve('num')" />
                                      </a-form-item>
                                    </a-form>
                                  </div>
                                </div>
                              </template>
                              <template v-else-if="['ARVE Assumption Start Date: '].includes(jtem.label)">
                                <div>
                                  ARVE Assumption Start Date:
                                  <div class="arveChange" v-show="arveTimeChange">
                                    <p class="text arveText">{{ formState[jtem.value] }}</p>
                                    <FormOutlined class="changeIcon" v-if="canChangeARVE" @click="changeArve('date', formState[jtem.value])" />
                                  </div>
                                  <div class="positionAnother" v-show="!arveTimeChange">
                                    <a-form ref="timeRef" :model="arveFormTime">
                                      <a-form-item name="value" :rules="[
                                        {
                                          validator: () => validateTime(arveFormTime.value),
                                          trigger: ['change', 'blur'],
                                        }]">
                                        <a-date-picker class="item" style="width: 150px"
                                          v-model:value="arveFormTime.value" type="date" placeholder="选择日期"
                                          valueFormat="YYYY-MM-DD" />
                                        <CheckOutlined class="changeIcon" @click="confirmArve('date')" />
                                      </a-form-item>
                                    </a-form>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </a-col>
                        </div>
                      </a-row>
                    </a-collapse-panel>
                  </a-collapse>
                </div>
              </a-col>
              <a-col :span="item.span" v-show="item.type === '1' && (plType === 'add' || plType === 'edit')">
                <div v-if="item.title">
                  <div style="margin: 10px 0">
                    <CardTitle class="card"> {{ item.title }} </CardTitle>
                  </div>
                </div>
                <a-form-item :label="item.label" :name="item.name" :rules="formRules(item)" v-else
                  :class="item.status === '0' ? 'labelColor' : item.status === '5' ? 'hr' : ''">
                  <!--text-->
                  <span v-show="item.status === '0'">{{ formState[item.value] ? formState[item.value] : '-' }}</span>
                  <!--input-->
                  <a-input v-model:value="formState[item.value]" :placeholder="item.placeholder" allowClear
                    v-show="item.status === '1'" @change="(val) => optChange(val, item)" :maxlength="item.maxlength"
                    style="width: 90%" />
                  <!--textarea-->
                  <a-textarea v-model:value="formState[item.value]" :placeholder="item.placeholder"
                    v-show="item.status === '2'" :rows="item.rows" :maxlength="item.maxlength" allowClear
                    @keyup.enter="(e) => { e.stopPropagation() }" @change="(val) => optChange(val, item)"
                    :auto-size="{ maxRows: item.rows }" style="width: 95%" />
                  <!--select-->
                  <a-select v-model:value="formState[item.value]" :placeholder="item.placeholder"
                    v-show="item.status === '3'" :options="formOps[item.formOps]" allowClear showSearch
                    @change="(val) => optChange(val, item)" @select="(val) => optSelectChange(val, item)"
                    :filter-option="filterOption"
                    :style="`width: ${['salesNameAndEmail', 'csmNameAndEmail', 'spocNameAndEmail', 'somSalesNameAndEmail', 'somCsmNameAndEmail'].includes(item.value) ? '95%' : '90%'}`">
                  </a-select>
                  <!--date-->
                  <a-date-picker v-model:value="formState[item.value]" :placeholder="item.placeholder"
                    v-show="item.status === '4'" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                    @change="(val) => optChange(val, item)" style="width: 90%" />
                  <!--横线-->
                  <hr v-show="item.status === '5'" class="hr-class" />
                </a-form-item>
              </a-col>

            </div>
          </a-row>
        </a-form>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CardTitle from "@/components/CardTitle.vue";
import { dictionaryOpt } from "@/utils/common";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import { FormOutlined, CheckOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PipelineModal",
  components: { CardTitle, CaretDownOutlined, FormOutlined, CheckOutlined },
  props: {
    title: String,
    visible: Boolean,
    pipelineInfo: {
      type: Object,
      default: () => ({
        pipelineId: '',
        opportunityId: '',
        opportunityName: '',
        subPl: undefined,
        account: undefined,
        clientName: '',
        sales: '',
        salesEmail: '',
        salesNameAndEmail: undefined,
        csm: '',
        csmEmail: '',
        csmNameAndEmail: undefined,
        contractType: undefined,
        marginNumber: '',
        riskBufferNumber: '',
        probability: '',
        contractDuration: '',
        startDate: '',
        tcv: '',
        cst: '',
        offering: undefined,
        solution: '',
        spoc: '',
        spocEmail: '',
        spocNameAndEmail: undefined,
        mustWin: undefined,
        isFollowUp: undefined,//是否重点跟进(1:Y 0:N)
        drbStage: undefined,
        drbStatus: undefined,
        deliveryNature: undefined,
        biddingStatus: undefined,
        projectStatus: '',//项目进展情况
        totalHc: '',
        resourcePlanType: undefined,//原有加人
        dlsyPlanned: undefined,
        fulfillmentChannel: '',
        resourcePlanStatus: '',//资源计划 (EM/BA/Archi等) 有无
        remarks: '',
        thorId: '',
        somSales: '',
        somSalesEmail: '',
        somSalesNameAndEmail: undefined,
        somCsm: '',
        somCsmEmail: '',
        somCsmNameAndEmail: undefined,
        projectEntryTime: '', //项目入场时间
        expectedContractReturnDate: '',//合同预计签回日期
        somProbability: '',
        latestBusinessProgress: '',
        remark: '',
        infoUpdateDate: '',
        arveAssumptionHc: '',
        arveAssumption: '',
        arveAssumptionStartDate: '',
        createOperator: '',
        createTime: '',
        operator: '',
        operationTime: '',
      })
    },
    optType: {
      type: String,
      default: "",
    },
    paramListObj: {
      type: Object,
      default: () => { },
    },
    allList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    nowStep: {
      type: Number,
      default: 0,
    },
    // canChangeARVE 为true 可以弹窗内修改ARVE
    canChangeARVE: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close-modal", "submit-modal", 'refreshTable'],
  setup(props, { emit }) {
    const store = useStore();
    const isConsult = computed(() => {
      const roles = store.state.app.user.roles;
      console.log('roles',roles)
      return !roles.includes('4')
      // return !(roles.indexOf("4") !== -1)
    });
    const { $api } = useGlobalPropertyHook();
    const formRef = ref()
    const plType = ref('')
    const formState = ref({
      pipelineId: '',
      opportunityId: '',
      opportunityName: '',
      subPl: undefined,
      account: undefined,
      clientName: '',
      sales: '',
      salesEmail: '',
      salesNameAndEmail: undefined,
      csm: '',
      csmEmail: '',
      csmNameAndEmail: undefined,
      contractType: undefined,
      marginNumber: '',
      riskBufferNumber: '',
      probability: '',
      contractDuration: '',
      startDate: '',
      tcv: '',
      cst: '',
      offering: undefined,
      solution: '',
      spoc: '',
      spocEmail: '',
      spocNameAndEmail: undefined,
      mustWin: undefined,
      isFollowUp: undefined,//是否重点跟进(1:Y 0:N)
      drbStage: undefined,
      drbStatus: undefined,
      deliveryNature: undefined,
      biddingStatus: undefined,
      projectStatus: '',//项目进展情况
      totalHc: '',
      resourcePlanType: undefined,//原有加人
      dlsyPlanned: undefined,
      fulfillmentChannel: '',
      resourcePlanStatus: '',//资源计划 (EM/BA/Archi等) 有无
      remarks: '',
      thorId: '',
      somSales: '',
      somSalesEmail: '',
      somSalesNameAndEmail: undefined,
      somCsm: '',
      somCsmEmail: '',
      somCsmNameAndEmail: undefined,
      projectEntryTime: '', //项目入场时间
      expectedContractReturnDate: '',//合同预计签回日期
      somProbability: '',
      latestBusinessProgress: '',
      remark: '',
      infoUpdateDate: '',
      arveAssumptionHc: '',
      arveAssumption: '',
      arveAssumptionStartDate: '',
      createOperator: '',
      createTime: '',
      operator: '',
      operationTime: '',
      fullName: '',
      email: '',
      psGroup: '',
      position: '',
      staffWorkLocation: '',
      status: '',
      subServiceLineserviceLine: '',
      pmInfo: '',
      consultantArveAssumption: '',
      consultantArveAssumptionStartDate: '',
    })
    const formOps = ref({
      subPlOps: [],
      accountOps: [],
      salesEmailOps: [],
      contractTypeOps: [],
      offeringOps: [],
      csmEmailOps: [],
      spocEmailOps: [],
      mustWinOps: dictionaryOpt(['Y', 'N']),
      isFollowUpOps: dictionaryOpt(['Y', 'N']),
      drbStageOps: [],
      drbStatusOps: [],
      deliveryNatureOps: [],
      biddingStatusOps: [],
      resourcePlanTypeOps: [],
      dlsyPlannedOps: [],
      somSalesEmailOps: [],
      somCsmEmailOps: [],
    })
    const demandInfo = ref([
      {
        type: '0', title: '基本信息', span: '', isFold: true, match: true, newMatch: true,
        children: [
          { span: '12', label: 'Pipeline ID:', value: 'pipelineId' },
          { span: '12', label: 'Opportunity ID:', value: 'opportunityId' },
          { span: '24', label: '需求名称:', value: 'opportunityName' },
          { span: '12', label: 'Sub P&L:', value: 'subPl' },
          { span: '12', label: 'Account:', value: 'account' },
          { span: '24', label: 'Client Name:', value: 'clientName' },
          { span: '12', label: 'Sales:', value: 'sales' },
          { span: '12', label: 'Sales Email:', value: 'salesEmail' },
          { span: '12', label: 'CSM:', value: 'csm' },
          { span: '12', label: 'CSM Email:', value: 'csmEmail' },
          { span: '24', label: 'Contract Type:', value: 'contractType' },
          { span: '12', label: 'Margin %:', value: 'marginNumber' },
          { span: '12', label: 'Risk Buffer:', value: 'riskBufferNumber' },
          { span: '12', label: 'Probability %:', value: 'probability' },
          { span: '12', label: 'TCV:', value: 'tcv' },
          { span: '12', label: 'Start Date:', value: 'startDate' },
          { span: '12', label: 'Contract Duration (month）:', value: 'contractDuration' },
          { span: '24', label: '', value: '', type: '1' },
          { span: '12', label: 'CST:', value: 'cst' },
          { span: '12', label: 'Offering:', value: 'offering' },
          { span: '24', label: 'Solution:', value: 'solution' },
          { span: '12', label: 'SPOC:', value: 'spoc' },
          { span: '12', label: 'SPOC Email:', value: 'spocEmail' },
          { span: '12', label: 'Must Win (Y/N)', value: 'mustWin' },
          { span: '12', label: 'Bidding Status:', value: 'biddingStatus' },
          { span: '24', label: '是否重点跟进(Y/N)', value: 'isFollowUp' },
          { span: '12', label: 'DRB Status:', value: 'drbStatus' },
          { span: '12', label: 'DRB Stage:', value: 'drbStage' },
          { span: '24', label: 'Delivery Nature:', value: 'deliveryNature' },
          { span: '24', label: '项目进展情况:', value: 'projectStatus' },
          { span: '24', label: '', value: '', type: '1' },
        ]
      },
      {
        type: '0', title: 'Resource Plan', span: '', isFold: true, match: true, newMatch: true,
        children: [
          { span: '12', label: 'Total HC:', value: 'totalHc' },
          { span: '12', label: '原有/加人:', value: 'resourcePlanType' },
          { span: '12', label: 'DL/SY planned(Y/N):', value: 'dlsyPlanned' },
          { span: '12', label: 'Fulfillment Channel:', value: 'fulfillmentChannel' },
          { span: '24', label: '资源计划（EM/BA/Archi等）有无:', value: 'resourcePlanStatus' },
          { span: '24', label: 'Remarks:', value: 'remarks' },
          { span: '24', label: '', value: '', type: '1' },
        ]
      },
      {
        type: '0', title: 'SOM feedback', span: '', isFold: true, match: true, newMatch: true,
        children: [
          { span: '24', label: 'THOR ID:', value: 'thorId' },
          { span: '12', label: 'SOM Sales:', value: 'somSales' },
          { span: '12', label: 'SOM Sales Email:', value: 'somSalesEmail' },
          { span: '12', label: 'SOM CSM:', value: 'somCsm' },
          { span: '12', label: 'SOM CSM Email:', value: 'somCsmEmail' },
          { span: '12', label: '项目入场时间:', value: 'projectEntryTime' },
          { span: '12', label: '合同预计签回日期:', value: 'expectedContractReturnDate' },
          { span: '24', label: 'SOM Probability %:', value: 'somProbability' },
          { span: '24', label: '最新商务进展:', value: 'latestBusinessProgress' },
          { span: '24', label: '备注', value: 'remark' },
          { span: '12', label: '信息更新日期:', value: 'infoUpdateDate' },
          { span: '24', label: '', value: '', type: '1' },
        ]
      },
      {
        type: '0', title: 'ARVE Assumption', span: '', isFold: true, match: true, newMatch: true,
        children: [
          { span: '12', label: 'ARVE Assumption HC', value: 'arveAssumptionHc' },
          { span: '12', label: 'ARVE Assumption%', value: 'arveAssumption' },
          { span: '12', label: 'ARVE Assumption Start Date', value: 'arveAssumptionStartDate' },
          { span: '24', label: '', value: '', type: '1' },
        ]
      },
      {
        type: '0', title: '操作信息', span: '', isFold: true, match: false, newMatch: true,
        children: [
          { span: '12', label: '创建人:', value: 'createOperator' },
          { span: '12', label: '创建时间:', value: 'createTime' },
          { span: '12', label: '更新人:', value: 'operator' },
          { span: '12', label: '更新时间:', value: 'operationTime' },
        ]
      },
      {
        type: '0', title: '顾问人员信息', span: '', isFold: false, match: true, newMatch: false,
        children: [
          { span: '12', label: '姓名:', value: 'fullName' },
          { span: '12', label: '邮箱:', value: 'email' },
          { span: '12', label: '职级:', value: 'psGroup' },
          { span: '12', label: '角色:', value: 'position' },
          { span: '12', label: '工作地点:', value: 'staffWorkLocation' },
          { span: '12', label: '人员状态:', value: 'status' },
          { span: '12', label: 'Service Line：', value: 'serviceLine' },
          { span: '12', label: 'Sub Service Line:', value: 'subServiceLine' },
          { span: '24', label: 'PM:', value: 'pmInfo' },
          { span: '12', label: 'ARVE Assumption %: ', value: 'consultantArveAssumption' },
          { span: '12', label: 'ARVE Assumption Start Date: ', value: 'consultantArveAssumptionStartDate' },
        ]
      },

      { type: '1', title: '基本信息', span: '24', status: '' },

      {
        type: '1', title: '', span: '12', status: '0', label: 'Pipeline ID:', name: 'pipelineId', value: 'pipelineId',
        placeholder: '', required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Opportunity ID', name: 'opportunityId', value: 'opportunityId',
        placeholder: '请输入', maxlength: 20, required: false, message: '请输入Opportunity ID', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '2', label: 'Opportunity Name', name: 'opportunityName', value: 'opportunityName',
        placeholder: '请输入', rows: '1', maxlength: 100, required: true, message: '请输入Opportunity Name', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Sub P&L:', name: 'subPl', value: 'subPl',
        placeholder: '请选择', formOps: 'subPlOps', required: true, message: '请选择Sub P&L', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Account:', name: 'account', value: 'account',
        placeholder: '请选择', formOps: 'accountOps', required: true, message: '请选择Account', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '2', label: 'Client Name:', name: 'clientName', value: 'clientName',
        placeholder: '请输入', rows: '1', maxlength: 60, required: true, message: '请输入Client Name', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '3', label: 'Sales:', name: 'salesNameAndEmail', value: 'salesNameAndEmail',
        placeholder: 'Name/GGID', formOps: 'salesEmailOps', required: true, message: '请选择Sales', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '3', label: 'CSM:', name: 'csmNameAndEmail', value: 'csmNameAndEmail',
        placeholder: 'Name/GGID', formOps: 'csmEmailOps', required: true, message: '请选择CSM', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Contract Type:', name: 'contractType', value: 'contractType',
        placeholder: '请选择', formOps: 'contractTypeOps', required: false, message: '请选择Contract Type', trigger: 'change'
      },

      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Margin %:', name: 'marginNumber', value: 'marginNumber',
        placeholder: '请输入', required: false, message: '请输入Margin', trigger: 'change', validator: 'true'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Risk Buffer:', name: 'riskBufferNumber', value: 'riskBufferNumber',
        placeholder: '请输入', required: false, message: '请输入Risk Buffer', trigger: 'change', validator: 'true'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Probability %:', name: 'probability', value: 'probability',
        placeholder: '请输入', required: true, message: '请输入Probability', trigger: 'change', validator: 'true'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'TCV:', name: 'tcv', value: 'tcv',
        placeholder: '请输入', required: false, message: '请输入TCV', trigger: 'change', validator: 'true'
      },

      {
        type: '1', title: '', span: '12', status: '4', label: 'Start Date:', name: 'startDate', value: 'startDate',
        placeholder: '请选择日期', required: true, message: '请选择Start Date', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Contract duration (month）:', name: 'contractDuration', value: 'contractDuration',
        placeholder: '请输入', required: false, message: '请输入Contract duration (month）', trigger: 'change', validator: 'true'
      },

      { type: '1', title: '', span: '24', status: '5' },

      {
        type: '1', title: '', span: '12', status: '0', label: 'CST:', name: 'cst', value: 'cst',
        placeholder: '', required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Offering:', name: 'offering', value: 'offering',
        placeholder: '请选择', formOps: 'offeringOps', required: true, message: '请选择Offering', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '2', label: 'Solution:', name: 'solution', value: 'solution',
        placeholder: '请输入', rows: '2', maxlength: 180, required: false, message: '请输入Solution', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '3', label: 'SPOC:', name: 'spocNameAndEmail', value: 'spocNameAndEmail',
        placeholder: 'Name/GGID', formOps: 'spocEmailOps', required: true, message: '请选择SPOC', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Must Win (Y/N):', name: 'mustWin', value: 'mustWin',
        placeholder: '请选择', formOps: 'mustWinOps', required: true, message: '请选择Must Win (Y/N)', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Bidding Status:', name: 'biddingStatus', value: 'biddingStatus',
        placeholder: '请选择', formOps: 'biddingStatusOps', required: true, message: '请选择Bidding Status', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: '是否重点跟进(Y/N):', name: 'isFollowUp', value: 'isFollowUp',
        placeholder: '请选择', formOps: 'isFollowUpOps', required: true, message: '请选择是否重点跟进(Y/N)', trigger: 'change'
      },

      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '12', status: '3', label: 'DRB Status:', name: 'drbStatus', value: 'drbStatus',
        placeholder: '请选择', formOps: 'drbStatusOps', required: true, message: '请选择DRB Status', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'DRB Stage:', name: 'drbStage', value: 'drbStage',
        placeholder: '请选择', formOps: 'drbStageOps', required: true, message: '请选择DRB Stage', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'Delivery Nature:', name: 'deliveryNature', value: 'deliveryNature',
        placeholder: '请选择', formOps: 'deliveryNatureOps', required: false, message: '请选择Delivery Nature', trigger: 'change'
      },

      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '24', status: '2', label: '项目进展情况:', name: 'projectStatus', value: 'projectStatus',
        placeholder: '请输入', rows: '1', maxlength: 100, required: true, message: '请输入项目进展情况', trigger: 'change'
      },

      { type: '1', title: '', span: '24', status: '5' },

      { type: '1', title: 'Resource Plan', span: '24' },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Total HC:', name: 'totalHc', value: 'totalHc',
        placeholder: '请输入', required: false, message: '', trigger: 'change', validator: 'true'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: '原有/加人:', name: 'resourcePlanType', value: 'resourcePlanType',
        placeholder: '请选择', formOps: 'resourcePlanTypeOps', required: false, message: '请选择Delivery Nature', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '3', label: 'DL/SY planned(Y/N):', name: 'dlsyPlanned', value: 'dlsyPlanned',
        placeholder: '请选择', formOps: 'dlsyPlannedOps', required: false, message: '请选择Delivery Nature', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Fulfillment Channel:', name: 'fulfillmentChannel', value: 'fulfillmentChannel',
        placeholder: '请输入', maxlength: 25, required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: '资源计划 (EM/BA/Archi等) 有无:', name: 'resourcePlanStatus', value: 'resourcePlanStatus',
        placeholder: '请输入', maxlength: 40, required: false, message: '', trigger: 'change'
      },
      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '12', status: '1', label: 'Remarks:', name: 'remarks', value: 'remarks',
        placeholder: '请输入', maxlength: 100, required: false, message: '', trigger: 'change'
      },
      { type: '1', title: '', span: '12', status: '' },

      { type: '1', title: '', span: '24', status: '5' },

      { type: '1', title: 'SOM feedback', span: '24' },

      {
        type: '1', title: '', span: '12', status: '2', label: 'THOR ID:', name: 'thorId', value: 'thorId',
        placeholder: '请输入', maxlength: 25, required: false, message: '', trigger: 'change'
      },
      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '24', status: '3', label: 'SOM Sales:', name: 'somSalesNameAndEmail', value: 'somSalesNameAndEmail',
        placeholder: 'Name/GGID', formOps: 'salesEmailOps', required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '3', label: 'SOM CSM:', name: 'somCsmNameAndEmail', value: 'somCsmNameAndEmail',
        placeholder: 'Name/GGID', formOps: 'somCsmEmailOps', required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: '项目入场时间:', name: 'projectEntryTime', value: 'projectEntryTime',
        placeholder: '请输入', maxlength: 40, required: false, message: '请输入项目入场时间', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: '合同预计签回日期:', name: 'expectedContractReturnDate', value: 'expectedContractReturnDate',
        placeholder: '请输入', maxlength: 40, required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '1', label: 'SOM Probability %:', name: 'somProbability', value: 'somProbability',
        placeholder: '请输入', required: false, message: '', trigger: 'change', validator: 'true'
      },
      { type: '1', title: '', span: '12', status: '' },

      {
        type: '1', title: '', span: '24', status: '2', label: '最新商务进展:', name: 'latestBusinessProgress', value: 'latestBusinessProgress',
        placeholder: '请输入', rows: '1', maxlength: 100, required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '24', status: '2', label: '备注:', name: 'remark', value: 'remark',
        placeholder: '请输入', rows: '1', maxlength: 100, required: false, message: '', trigger: 'change'
      },

      {
        type: '1', title: '', span: '12', status: '4', label: '信息更新日期:', name: 'infoUpdateDate', value: 'infoUpdateDate',
        placeholder: '请选择日期', required: false, message: '', trigger: 'change'
      },
    ])

    const formRules = (item) => {
      let rules = []
      let arr = ['marginNumber', 'probability', 'somProbability']
      let arr2 = ['contractDuration', 'tcv', 'totalHc']
      let pattern = arr.includes(item.value) ? /^((\d|[1-9]\d)(\.\d+)?|100)$/ : arr2.includes(item.value) ? /^[0-9]+([.]{1}[0-9]+){0,1}$/ : /^(100|[1-9]?\d(\.\d+)?)%$/
      let message = arr.includes(item.value) ? '只能输入0~100以内的数字' : arr2.includes(item.value) ? '只能输入数字' : '只能输入0~100以内的数字百分比'
      if (item.validator) {
        rules = [
          {
            required: item.required,
            message: item.message,
            trigger: item.trigger,
          },
          {
            pattern: pattern,
            message: message,
            trigger: 'change',
          },
        ]
      } else {
        rules = [{
          required: item.required,
          message: item.message,
          trigger: item.trigger,
        }]
      }
      return rules
    }
    const optChange = (val, item) => {
      formRef.value.validate(item.name)
    }

    const optSelectChange = (val, item) => {
      if (item.label === 'Offering:') {
        getReturnEnum('offering', val)
      }
    }

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    watch(
      () => formState.value.subPl,
      (val) => {
        if (val) {
          if (props.optType !== 'newMatch' && props.optType !== 'match') getReturnEnum('sub', formState.value.subPl)
        } else {
          formOps.value.accountOps = props.paramListObj.accountOps
        }
      }
    )
    watch(
      () => formState.value.account,
      (val) => {
        if (val) {
          if (props.optType !== 'newMatch' && props.optType !== 'match') getReturnEnum('account', formState.value.account)
        } else {
          formOps.value.subPlOps = props.paramListObj.subPlOps
        }
      }
    )

    const activeKey = ref(['基本信息', 'Resource Plan', 'SOM feedback', 'ARVE Assumption', '操作信息']);

    const filterOption = (input, option) => {
      if (!input) return false;
      let reg = /[\s|/,|/，]*/g
      if (option.ggid) {
        return option.value.replace(reg, '').toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0 || option.ggid.toUpperCase().indexOf(input.replace(' ', '').toUpperCase()) >= 0;
      } else {
        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
    };

    const fold = (data) => {
      demandInfo.value = demandInfo.value.map(item => {
        if (item.type === '0' && item.title === data.title) {
          item.isFold = !item.isFold
        }
        return item
      })
    }

    const orgAllList = computed(() => props.allList)
    const getOpsList = () => {
      $api.returnEmai().then((res) => {
        const ops = res.data.map(item => {
          return {
            ...item,
            value: item.fullName + '（' + item.email + '）'
          }
        })
        formOps.value.csmEmailOps = ops;
        formOps.value.salesEmailOps = ops;
        formOps.value.somCsmEmailOps = ops;
        formOps.value.somSalesEmailOps = ops;
        formOps.value.spocEmailOps = ops;
      });
      getReturnEnum('all', '')
    };

    const getReturnEnum = (type, value) => {
      let tag = type === 'all' ? ['BiddingStatus', 'ContractType', 'DeliveryNature', 'DLSYPlanned', 'DrbStatus', 'MustWin', 'ResourcePlanType', 'SubPI', 'Offering', 'DrbStage'] :
        type === 'offering' ? ['cst'] : type === 'sub' ? ['Account'] : type === 'account' ? ['SubPI'] : []
      const params = {
        tag: tag,
        sub: type === 'sub' ? value : '',
        offering: type === 'offering' ? value : '',
        account: type === 'account' ? value : '',
        subDouble: [],
        offeringDouble: [],
        accountDouble: [],
        cstDouble: [],
      }
      $api.returnEnum(params).then((res) => {
        if (type === 'all') {
          formOps.value.biddingStatusOps = dictionaryOpt(res.data.BiddingStatus);
          formOps.value.contractTypeOps = dictionaryOpt(res.data.contractType);
          formOps.value.deliveryNatureOps = dictionaryOpt(res.data.DeliveryNature);
          formOps.value.drbStatusOps = dictionaryOpt(res.data.DrbStatus);
          formOps.value.resourcePlanTypeOps = dictionaryOpt(res.data.ResourcePlanType);
          formOps.value.dlsyPlannedOps = dictionaryOpt(res.data.DLSPYlanned);
          formOps.value.drbStageOps = dictionaryOpt(res.data.DrbStage);
          formOps.value.offeringOps = dictionaryOpt(res.data.offering);
        } else if (type === 'offering') {
          formState.value.cst = String(res.data?.cst)
        } else if (type === 'sub') {
          formOps.value.accountOps = dictionaryOpt(res.data?.accountBySub)
        } else if (type === 'account') {
          formOps.value.subPlOps = dictionaryOpt(res.data?.SubPLByAccount)
        }
      });
    }

    const closeModal = () => {
      formRef.value.resetFields();
      emit("close-modal");
    };

    const modifyModal = () => {
      plType.value = 'edit'
      formState.value.salesNameAndEmail = formState.value.sales + '（' + formState.value.salesEmail + '）'
      formState.value.csmNameAndEmail = formState.value.csm + '（' + formState.value.csmEmail + '）'
      formState.value.spocNameAndEmail = formState.value.spoc + '（' + formState.value.spocEmail + '）'
      formState.value.somSalesNameAndEmail = formState.value.somSales ? formState.value.somSales + '（' + formState.value.somSalesEmail + '）' : undefined
      formState.value.somCsmNameAndEmail = formState.value.somCsm ? formState.value.somCsm + '（' + formState.value.somCsmEmail + '）' : undefined
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          ...formState.value,
          salesGgid: formOps.value.csmEmailOps.find(item => item.value === formState.value.salesNameAndEmail).ggid,
          csmGgid: formOps.value.csmEmailOps.find(item => item.value === formState.value.csmNameAndEmail).ggid,
          spocGgid: formOps.value.csmEmailOps.find(item => item.value === formState.value.spocNameAndEmail).ggid,
          somSalesGgid: formState.value.somSalesNameAndEmail ? formOps.value.csmEmailOps.find(item => item.value === formState.value.somSalesNameAndEmail)?.ggid : null,
          somCsmGgid: formState.value.somCsmNameAndEmail ? formOps.value.csmEmailOps.find(item => item.value === formState.value.somCsmNameAndEmail)?.ggid : null,
        }
        const submitFuc = plType.value === 'add' ? 'savePipeline' : 'updatePipLine'
        $api[submitFuc](params).then((res) => {
          if (res.msg === 'success') {
            message.success('成功！')
            closeModal()
            emit("submit-modal");
          } else {
            message.error(res.msg)
          }
        });
      })
    };
    const { $EventBus } = useGlobalPropertyHook();
    const addMatch = () => {
      $EventBus.emit("matchPipeline", formState.value);
      closeModal()
    }

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          arveValueChange.value = true
          arveTimeChange.value = true
          plType.value = props.optType
          if (props.optType !== 'newMatch' && props.optType !== 'match') {
            if (!props.pipelineInfo?.subPl) formOps.value.accountOps = props.paramListObj.accountOps
            if (!props.pipelineInfo?.account) formOps.value.subPlOps = props.paramListObj.subPlOps
          }
          formState.value = props.pipelineInfo
          formState.value.salesNameAndEmail = formState.value.sales ? formState.value.sales + '（' + formState.value.salesEmail + '）' : undefined
          formState.value.csmNameAndEmail = formState.value.csm ? formState.value.csm + '（' + formState.value.csmEmail + '）' : undefined
          formState.value.spocNameAndEmail = formState.value.spoc ? formState.value.spoc + '（' + formState.value.spocEmail + '）' : undefined
          formState.value.somSalesNameAndEmail = formState.value.somSales ? formState.value.somSales + '（' + formState.value.somSalesEmail + '）' : undefined
          formState.value.somCsmNameAndEmail = formState.value.somCsm ? formState.value.somCsm + '（' + formState.value.somCsmEmail + '）' : undefined
          if (props.optType === 'match') {
            activeKey.value = ["顾问人员信息"]
          } else {
            activeKey.value = ['基本信息', 'Resource Plan', 'SOM feedback', 'ARVE Assumption', '操作信息']
          }
        }
      }
    )

    onMounted(() => {
      if (props.optType !== 'newMatch' && props.optType !== 'match') getOpsList()
    })


    const arveValueChange = ref(true)
    const arveTimeChange = ref(true)
    const formRefArve = ref()
    const timeRef = ref()
    const arveFormValue = ref({})
    const arveFormTime = ref({})

    // type 是num 是值 是date 是日期
    const changeArve = (type, value) => {
      if (type == 'num') {
        arveFormValue.value = { value }
        arveValueChange.value = !arveValueChange.value
      } else {
        arveFormTime.value = { value }
        arveTimeChange.value = !arveTimeChange.value
      }
    }

    const validateArve = (value) => {
      const isValidNumber = (input) => {
        const regex = /^(0$|[1-9][0-9]?|100)$/;
        return regex.test(input);
      }
      let flag = isValidNumber(value)
      if (!flag) {
        return Promise.reject('请输入0-100之间的数字')
      } else {
        return Promise.resolve()
      }
    }
    const validateTime = (value) => {
      if (value == null) {
        return Promise.reject('ARVE Assumption Start Date 为必填项')
      } else {
        return Promise.resolve()
      }
    }

    const confirmArve = (type) => {
      if (type == 'num') {
        formRefArve.value[0].validate().then(async () => {
          let arr = []
          console.log('arr', arr, formState.value)
          arr[0] = {
            "preMatchingId": formState.value.preMatchingId,
            "arveAssumption": arveFormValue.value.value,
            "arveAssumptionStartDate": formState.value.consultantArveAssumptionStartDate
          }
          formState.value.consultantArveAssumptionStartDate
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([formState.value.preMatchingId])
          arveValueChange.value = !arveValueChange.value
          let obj = {
            consultantArveAssumption: res.data[0].consultantArveAssumption,
            consultantArveAssumptionStartDate: res.data[0].consultantArveAssumptionStartDate
          }
          formState.value = Object.assign(formState.value, obj)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption % 填写的不符合规则");
        })
      } else {
        timeRef.value[0].validate().then(async () => {
          let arr = []
          arr[0] = {
            "preMatchingId": formState.value.preMatchingId,
            "arveAssumption": formState.value.consultantArveAssumption,
            "arveAssumptionStartDate": arveFormTime.value.value
          }
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([formState.value.preMatchingId])
          arveTimeChange.value = !arveTimeChange.value
          let obj = {
            consultantArveAssumption: res.data[0].consultantArveAssumption,
            consultantArveAssumptionStartDate: res.data[0].consultantArveAssumptionStartDate
          }
          formState.value = Object.assign(formState.value, obj)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption Start Date 填写的不符合规则");
        })
      }
    }

    return {
      formRef,
      formState,
      demandInfo,
      closeModal,
      modifyModal,
      submitModal,
      plType,
      formOps,
      activeKey,
      formRules,
      optChange,
      filterOption,
      fold,
      orgAllList,
      getOpsList,
      optSelectChange,
      getReturnEnum,
      addMatch,

      changeArve,
      arveValueChange,
      arveTimeChange,
      arveFormValue,
      arveFormTime,
      validateArve,
      validateTime,
      confirmArve,
      formRefArve,
      timeRef,
      getRefsAff,
      isConsult
    }
  }
})
</script>

<style scoped lang="less">
.container {

  .check-info {
    :deep(.ant-collapse-borderless > .ant-collapse-item) {
      border-bottom: 0;
    }

    :deep(.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header) {
      padding: 0;
      margin: 10px 0 20px 0;
    }

    :deep(.ant-collapse-content > .ant-collapse-content-box) {
      padding: 0;
    }

    .demandInfoRow {
      color: rgba(77, 77, 77, 0.6);
      white-space: pre;
      margin-bottom: 10px !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

      .text {
        color: #4D4D4D;
        white-space: initial;
        margin: 0.2vw 0 0.83vw 0 !important;
      }
    }


    .icon {
      color: #3182CE;
    }
  }

  .card {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin: 10px 0 10px 0;

    &::before {
      top: 0.1vw;
    }
  }

  .labelColor {
    :deep(.ant-form-item-label > label) {
      color: rgba(77, 77, 77, 0.6);
    }
  }

  :deep(.ant-form-item-label > label) {
    white-space: pre;
    margin-bottom: 10px !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .hr {
    margin-bottom: 0;
  }

  .hr-class {
    height: 1px;
    border: none;
    border-top: 1px solid #f2f2f2;
    margin-bottom: 0.5vw;
  }

  .pipe {
    position: relative;
    background: #ffffff;
    margin: 1.25vw 0px 10px 1.25vw;

    .cusTitle {
      border: 1px solid #CCCCCC99;
      margin: 0 1vw 1vw 0;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;

      .demandName {
        font-size: 1vw;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        align-items: center;
        max-width: 70%;
      }

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #58B99E;
        margin-right: 15px;
      }

      .info1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.22em;
        color: #999999;
      }

      .info2 {
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
      }

      .info3 {
        height: 40px;
        border: 1px solid #CCCCCC
      }
    }
  }

  .arveChange {
    display: flex;
    align-items: center;

    .arveText {
      margin: 0.2vw 0.5vw 0vw 0.2vw !important;
    }

    .changeIcon {
      display: block;
      margin-top: 0.2vw;
      cursor: pointer;
      color: rgb(49, 130, 206)
    }


    .ant-form {
      padding: 0.2vw !important;

      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
  }

  .positionAnother {
    .arveText {
      margin: 0.2vw 0.5vw 0vw 0.2vw !important;
    }

    .changeIcon {
      display: block;
      margin-top: 0.2vw;
      margin-left: 10px;
      cursor: pointer;
      color: rgb(49, 130, 206)
    }

    .ant-form {
      padding: 0.2vw !important;
      position: relative;

      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
  }

  .changeValue {
    height: 80px;
  }
}
</style>
