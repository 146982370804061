<template>
  <section class="app-container">
    <div class="backE" v-if="isTodoStatus">
      <span class="link underline" @click="goTodoBack">
        <LeftOutlined class="icon" style="" />
        <span class="back">返回待办列表</span>
      </span>
    </div>
    <Filter ref="filter" @search="handleSearch" @doSearchByPage="doSearchByPage" @refresh-table="handleResetTable"
      v-show="!isTodoStatus" @select-all="selectAllClick" @select-remove="removeSelectedClick"
      :paramsSelectedData="paramsSelectedData" @clearSelect="clearSelect" @updateSelectList="updateSelectList"></Filter>
    <MatchList ref="matchListRef" @goTodoBack="goTodoBack" :activeTabStatus='activeTabStatus' :isTodoStatus="isTodoStatus"
      @getSelectedData="getSelectedData" @refreshTab="refreshTab"></MatchList>
  </section>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import Filter from "../matchingList/components/Filter.vue";
import MatchList from "./components/MatchList";
import { useStore } from "vuex";
import { LeftOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "matchingList",
  components: {
    MatchList,
    Filter,
    LeftOutlined
  },
  setup(_, { emit }) {
    const matchListRef = ref();
    const store = useStore();
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const handleSearch = (tab, searchParams) => {
      activeTabStatus.value = tab
      matchListRef.value?.searchClick(tab, searchParams);
    };
    const doSearchByPage = (tab, searchParams) => {
      activeTabStatus.value = tab
      matchListRef.value?.searchByPange(tab, searchParams);
    }

    const handleResetTable = () => {
      matchListRef.value.doSearch();
    };
    // 待办列表得返回
    const goTodoBack = () => {
      emit("onBackTodoList")
    };
    const activeTabStatus = ref('')

    const selectAllClick = () => {
      matchListRef.value?.selectAll();
    }

    const removeSelectedClick = () => {
      matchListRef.value?.removeSelectedAll();
    }

    const paramsSelectedData = ref({
      matchList: [],
      selectData: [],
    })
    const getSelectedData = (data1, data2) => {
      paramsSelectedData.value.matchList = data1
      paramsSelectedData.value.selectData = data2
    }

    const clearSelect = () => {
      matchListRef.value?.clearSelectedAll();
    }

    const filter = ref()
    // 更新勾选的行的值
    const updateSelectListHandler = (data) => {
      paramsSelectedData.value.selectData.forEach((item) => {
        data.forEach((jtem) => {
          if (item.preMatchingId == jtem.preMatchingId) {
            item.arveAssumption = jtem.arveAssumption == null ? jtem.consultantArveAssumption : jtem.arveAssumption
            item.arveAssumptionStartDate = jtem.consultantArveAssumptionStartDate == null ? jtem.arveAssumptionStartDate : jtem.consultantArveAssumptionStartDate
          }
        })
      })
    }
    const refreshTab = (data) => {
      updateSelectListHandler(data)
      filter.value.doSearchByPage()
    }
    const updateSelectList = (updata) => {
      updateSelectListHandler(updata)
    }

    return {
      matchListRef,
      isTodoStatus,
      handleSearch,
      handleResetTable,
      goTodoBack,
      activeTabStatus,
      selectAllClick,
      removeSelectedClick,
      paramsSelectedData,
      getSelectedData,
      clearSelect,
      filter,
      refreshTab,
      doSearchByPage,
      updateSelectList
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 0 !important;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .bottom-content {
    height: calc(100% - 88px);

    .list-box {
      border-right: 3px solid rgba(0, 0, 0, 0.05);
    }
  }
}

//.backE + .app-container {
//  height: calc(100% - 56px);
//}

.backE {
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: flex-end;
  background: #f6f9fc;

  .icon {
    font-size: 15px;
    cursor: pointer;
  }

  .back {
    font-size: 16px;
    margin-left: 3px;
    cursor: pointer;
  }
}
</style>
