<template>
  <a-page-header
    class="header"
    v-auth="['system:assignmentDetails:info']"
    v-if="!isTodoStatus"
  >
    <template #title>
      <span>Account: {{ assignmentCondition.account }}</span>
      <span>Code: {{ assignmentCondition.engagementCode }}</span>
      <span>
        Engagement Description:
        <a-tooltip
          placement="top"
          :title="assignmentCondition.engagementDescription"
          >{{ assignmentCondition.engagementDescription }}</a-tooltip
        >
      </span>
    </template>
  </a-page-header>
  <div class="service-line">
    <span v-show="serviceLine"> Service Line: {{ serviceLine }} </span>
    <span v-show="subServiceLine">
      Sub-Service Line: {{ subServiceLine }}
    </span>
  </div>
  <section class="assignment-list">
    <a-table
      :columns="columns"
      :data-source="assDetails"
      :pagination="pagination"
      row-key="id"
      :scroll="{
        x: tableWidth,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :row-selection="{
        selectedRowKeys: selectedAssignmentIdList,
        selectedRows: selectedAssignmentList,
        onChange: selectedChange,
      }"
    >
      <template #action="{ record }">
<!--        <span
          class="operationLink underline"
          @click="recordClick(record)"
          v-auth="['system:assignmentDetails:pendingApplyRecord']"
        >
          申请记录
      </span>-->
      <ActionBtn v-bind="record" :btnList="btnList" @HistoryOutlined="recordClick(record)" />
      </template>
    </a-table>
    <div class="batch-btn">
      <a-button
        type="primary"
        :disabled="selectedAssignmentIdList.length === 0"
        v-auth="['system:assignmentDetails:batchApproval']"
        @click="batchAuditClick"
      >
        批量审批
      </a-button>
    </div>
  </section>
  <BatchEditTimeDrawer
    actionType="audit"
    v-model:visible="batchEditTimeVisible"
    v-model:isFormalCode="isFormalCode"
    :list="selectedAssignmentList"
    :id-list="selectedAssignmentIdList"
    role="rm"
    :active-tab="activeTab"
    @close-modal="closeBatchModal"
    @refresh-table="refreshTable"
  />
  <NoticeModal
    v-model:modalVisible="noticeVisible"
    :modalWidth="510"
    :closable="true"
    @close-modal="closeNoticeModal"
    @sumbit-modal="submitNoticeModal"
    sumbitBtn="确定"
    cancelBtn="取消"
  >
    <div class="save-content">
      <div class="title">
        {{
          `请注意PM申请${
            activeTab === "1" ? "上" : "下"
          }项目时间不一致，需要逐条进行审批操作`
        }}
      </div>
    </div>
  </NoticeModal>
  <RecordModal
    v-model:visible="recordVisible"
    title="查看记录"
    @close-modal="closeRecordModal"
    :assignment-info="assignmentInfo"
  />
</template>
<script>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { formatTableCol, useTableHeight } from "@/utils/common";
import { assignmentStatus } from "@/utils/formatStatus";
import { useStore } from "vuex";
import BatchEditTimeDrawer from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/BatchEditTimeDrawer";
import NoticeModal from "@/components/NoticeModal";
import RecordModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/recordModal";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '申请记录',
    icon: 'HistoryOutlined',
    auth: ['system:assignmentDetails:pendingApplyRecord']
  },
]
export default defineComponent({
  name: "PendingDetails",
  components: {
    RecordModal,
    NoticeModal,
    BatchEditTimeDrawer,
    ActionBtn
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();

    const assignmentCondition = computed(
      () => store.state.page.assignmentCondition
    );

    const serviceLine = assignmentCondition.value.serviceLineList
      ? assignmentCondition.value.serviceLineList.join(",")
      : undefined;
    const subServiceLine = assignmentCondition.value.subServiceLineList
      ? assignmentCondition.value.subServiceLineList.join(",")
      : undefined;
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const columns = [
      {
        title: "顾问姓名",
        dataIndex: "fullName",
        key: "fullName",
        width: 105,
        fixed: "left",
        ellipsis: true,
      },
      {
        title: "职级",
        dataIndex: "psGroup",
        key: "psGroup",
        width: 60,
        ellipsis: true,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        width: 105,
        ellipsis: true,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        customRender: formatTableCol(assignmentStatus),
        width: 100,
        ellipsis: true,
      },
      {
        title: "PM",
        dataIndex: "pmName",
        key: "pmName",
        customRender: ({ record }) => {
          const pmName = record.pmName ? record.pmName + " " : "";
          const pmEmail = record.pmEmail ? record.pmEmail : "";
          return pmName + pmEmail;
        },
        width: 240,
        ellipsis: true,
      },
      {
        title: "小时数",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        width: 90,
        ellipsis: true,
      },
      {
        title: "原项目时间",
        dataIndex: "onBoardDate",
        key: "onBoardDate",
        customRender: ({ record }) => {
          const onBoardDate = record.onBoardDate ? record.onBoardDate : "";
          const actualReleaseDate = record.actualReleaseDate
            ? record.actualReleaseDate
            : "";
          return onBoardDate + "~" + actualReleaseDate;
        },
        width: 150,
        ellipsis: true,
      },
      {
        title: () =>
          activeTab.value === "1" ? "PM申请上项目时间" : "PM申请下项目时间",
        dataIndex: "newOnBoardDate",
        key: "newOnBoardDate",
        customRender: ({ record }) => {
          const newOnBoardDate = record.newOnBoardDate
            ? record.newOnBoardDate
            : "";
          const newActualReleaseDate = record.newActualReleaseDate
            ? record.newActualReleaseDate
            : "";
          if (activeTab.value === "1") {
            return newOnBoardDate;
          }
          return newActualReleaseDate;
        },
        width: 120,
        ellipsis: true,
      },
      {
        title: "操作",
        key: "operation",
        width: 60,
        slots: { customRender: "action" },
        fixed: "right",
      },
      // {
      //   title: "新项目时间",
      //   dataIndex: "newOnBoardDate",
      //   key: "newOnBoardDate",
      //   customRender: ({ record }) => {
      //     const newOnBoardDate = record.newOnBoardDate ? record.newOnBoardDate : "";
      //     const newEstimateReleaseDate = record.newEstimateReleaseDate
      //       ? record.newEstimateReleaseDate
      //       : "";
      //     return newOnBoardDate + "~" + newEstimateReleaseDate;
      //   },
      //   width: 90,
      //   ellipsis: true
      // }
    ];
    const assDetails = ref();
    const activeTab = ref("");
    let searchCondition = {};

    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        searchClick(activeTab.value, searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        searchClick(activeTab.value, searchCondition);
      },
    });

    const searchClick = (tab, searchParams) => {
      activeTab.value = tab + "";
      searchCondition = searchParams;
      const params = {
        assignmentStatus: activeTab.value,
        consultantName: searchCondition.nameCondition,
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize,
        pmId: searchCondition.selectPM,
        engagementCode: assignmentCondition.value.engagementCode,
        engagementDescription: searchCondition.engagementDescription,
        // serviceLineList: assignmentCondition.value.serviceLineList,
        // subServiceLineList: assignmentCondition.value.subServiceLineList,
      };
      // if (isTodoStatus.value) {
      //   params.assignmentId = route.query.assignmentId;
      //   params.status = route.query.status;
      // }
      $api
        .getAssignmentPendingDetailList(params)
        .then((res) => {
          assDetails.value = res.data.resource;
          pagination.value.total = res.data.total;
          selectedAssignmentIdList.value = [];
          selectedAssignmentList.value = [];
        })
        .catch((error) => {
          error();
        });
    };

    const selectedAssignmentIdList = ref([]);
    const selectedAssignmentList = ref([]);
    const selectedChange = (selectedIdList, selectedList) => {
      selectedAssignmentIdList.value = selectedIdList;
      selectedAssignmentList.value = selectedList;
    };

    const batchEditTimeVisible = ref(false);
    const batchAuditClick = async () => {
      await handleIsFormalCode()
      let dateList = [];
      if (activeTab.value === "1") {
        dateList = selectedAssignmentList.value.map(
          (item) => item["newOnBoardDate"]
        );
      } else {
        dateList = selectedAssignmentList.value.map(
          (item) => item["newEstimateReleaseDate"]
        );
      }
      const dateSet = new Set(dateList);
      if (dateSet.size > 1) {
        noticeVisible.value = true;
      } else {
        batchEditTimeVisible.value = true;
      }
    };
    const isFormalCode = ref(false)
    const handleIsFormalCode = async () => {
      const params = {
        engagementCode:assignmentCondition.value.engagementCode
      }
      await $api.checkFormalCode(params).then((res) => {
        isFormalCode.value = res.data
      });
    }
    const noticeVisible = ref(false);
    const closeNoticeModal = () => {
      noticeVisible.value = false;
    };
    const submitNoticeModal = () => {
      closeNoticeModal();
      batchEditTimeVisible.value = true;
    };
    const closeBatchModal = () => {
      batchEditTimeVisible.value = false;
    };
    const refreshTable = () => {
      searchClick(activeTab.value, searchCondition);
    };

    const recordVisible = ref(false);
    const assignmentInfo = ref();
    const recordClick = (record) => {
      assignmentInfo.value = record;
      recordVisible.value = true;
    };
    const closeRecordModal = () => {
      recordVisible.value = false;
    };
    const resetCurrent = () => {
      pagination.value.current = 1;
    };

    watch(activeTab, () => {
      selectedAssignmentIdList.value = [];
      selectedAssignmentList.value = [];
    });

    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    onBeforeRouteLeave(() => {
      if (isTodoStatus.value) {
        store.commit("SET_IS_TODO_STATUS", false);
      }
    });

    return {
      assignmentCondition,
      serviceLine,
      subServiceLine,
      columns,
      assDetails,
      selectedAssignmentIdList,
      selectedAssignmentList,
      selectedChange,
      pagination,
      searchClick,
      activeTab,
      batchEditTimeVisible,
      batchAuditClick,
      closeBatchModal,
      refreshTable,
      isTodoStatus,
      tableWidth,
      tableHeight,
      noticeVisible,
      closeNoticeModal,
      submitNoticeModal,
      recordVisible,
      recordClick,
      closeRecordModal,
      assignmentInfo,
      isFormalCode,
      handleIsFormalCode,
      btnList,
      resetCurrent,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

.assignment-list {
  flex: 1;
  overflow: auto;
  display: flex;
  background-color: #fff;
  margin: 5px 10px;
  width: calc(100% - 40px);
  //height: calc(100% - 188px);
  position: relative;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  :deep(.org-info) {
    width: 700px;
    //margin-left: 20px;
    background: #f4f4f4;
  }

  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

:deep(.ant-table-body) {
  .active-row {
    background: #e8f4ff !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e8f4ff !important;
    }
  }

  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }

  .deactivate-row.active-row {
    background: #e0e0e0 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e0e0e0 !important;
    }
  }
}

.header {
  margin: 0 20px;
  padding: 0;

  span {
    margin-right: 40px;
  }
}

.service-line {
  margin: 0 20px;

  span {
    margin-right: 40px;
    font-size: 18px;
  }
}

.save-content {
  margin: 40px 0 0;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
  }
}
</style>
