<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    @ok="submitModal"
    :width="560"
    class="fill-assignment"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写驳回原因" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal">取消</a-button>
        <a-button @click="submitModal" type="primary">确定</a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
    >
      <a-form-item
        :label="commentLabel"
        name="rejectReason"
        :label-col="{ span: 22 }"
        :wrapper-col="{ span: 24 }"
      >
        <a-textarea
          v-model:value="formData.rejectReason"
          placeholder="请输入"
          :rows="5"
          showCount
          :maxlength="70"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          @keyup.enter="(e) => { e.stopPropagation() }"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "pmRejectModal",
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        rejectReason: ""
      })
    },
    commentLabel: String
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const formRef = ref();

    const validatorSpace = async (rule, value) => {
      if (value && value.replace(/\s/g, "").length === 0) {
        return Promise.reject("不能只输入空格");
      } else {
        return Promise.resolve();
      }
    };

    const formRules = {
      rejectReason: [
        {
          required: true,
          message: "Please input Reject Reason",
          trigger: "blur"
        },
        {
          validator: validatorSpace,
          message: "不能只输入空格",
          trigger: "blur"
        }
      ]
    };

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        ctx.emit("submit-modal");
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      getRefsAff
    };
  }
});
</script>

<style scoped>
.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

.ant-calendar-picker {
  width: 100%;
}
</style>
