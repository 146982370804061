<template>
  <div ref="assignmentsModifyingRRef">
    <a-drawer :getContainer="()=>$refs.assignmentsModifyingRRef" :maskClosable="true" :closable="true" wrapClassName="merge-drawer" width="86.4vw"
              :title="title" :visible="visible" @update:visible="(val) => $emit('update:visible', val)"
              :zIndex="900">
      <div class="container">
        <div class="input-group">
          <div class="input-wrapper">
            <div class="input-content">
              <span>Engagement Code</span>
              <a-select
                class="item"
                allow-clear
                placeholder="请选择"
                show-search
                v-model:value="selectedEngagementCode" 
                @change="handleFirstSelectChange"
                :filter-option="(input,option)=>option.value.indexOf(input.trim()) >= 0"
              >
                <a-select-option v-for="option in engageCodeOpts" :key="option.key" :value="option.value">{{ option.label }}</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="input-wrapper">
            <div class="input-content">
              <span>Engagement Description</span>
              <a-select
                class="item"
                allow-clear
                placeholder="请选择"
                show-search
                v-model:value="selectedEngagementDescription"
                @change="handleSecondSelectChange"
              >
                <a-select-option v-for="option in descriptionOps" :key="option.key" :value="option.codeAndDescription">{{ option.label}}</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="button">
            <a-button type="primary" @click="refreshData(selectedEngagementCode)">全部更新</a-button>
          </div>
        </div>
      </div>
      <div class="forms mt24">
        <a-form class="new-account" :model="dataSource" ref="formRef">
          <a-table
              :columns="columns"
              :data-source="dataSource.dataList"
              :pagination="false"
              :rowKey="(record, index) => index"
              :scroll="{ x: true }"
              :row-selection="rowSelection"
              class="editable-table"
          >
            <template #bodyCell="{ column, record, index }">
              <template v-if="column.dataIndex === 'selected'">
                <!-- <a-checkbox v-model:checked="record.selected" /> -->
              </template>
              <template v-if="column.dataIndex == 'engagementCode'">
                <a-form-item :name="['dataList', index, 'engagementCode']" label=" "
                             :rules="[{
                             required: true,
                             message: 'Engagement Code 不能为空',
                             trigger: 'change',
                           },]">
                  <a-select class="item"
                            allow-clear
                            placeholder="请选择"
                            show-search
                            optionFilterProp="label"
                            :options="engageCodeOpts"
                            :dropdownMatchSelectWidth="260"
                            v-model:value="record.engagementCode"
                            @change="handleChange(record, index)"
                            :filter-option="(input,option)=>option.value.indexOf(input.trim()) >= 0"
                            />
                </a-form-item>
              </template>
              <template v-if="column.dataIndex == 'engagementDescription'">
                <template v-if="record.type === '2'">
                  <a-form-item :name="['dataList', index, 'engagementDescription']" label=" "
                               :rules="[{
                             required: true,
                             trigger: 'blur',
                             validator: validateDesc
                           }]">
                    <a-input class="item"
                             v-model:value="record.engagementDescription"
                             placeholder="请输入"
                             allowClear
                             autocomplete="off"
                             :maxlength="40"
                             :title="record.engagementDescription"
                    />
                  </a-form-item>
                </template>
                <template v-else>
                  <span :title="record.engagementDescription">{{ record.engagementDescription }}</span>
                </template>
              </template>
            </template>
          </a-table>
        </a-form>
      </div>
      <div class="header bottom-btn">
        <a-space>
          <a-button type="primary" ghost @click="handleCancel">取消</a-button>
          <a-button type="primary" @click="handleSave(dataSource.dataList,false)">确定</a-button>
        </a-space>
      </div>
    </a-drawer>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :cancel="handleCancel1" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watch} from "vue";
import {computed} from "@vue/runtime-core";
import ModalBox from '@/components/ModalBox';
// import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "AssignmentsModifying",
  components: {ModalBox},
  emits: ["update", "handle-save-assignment", "get-detail-by-code", "reset-data-list"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    initialDataList: {
      type: Array,
      default: () => {
        return []
      },
    },
    titleEngagementDescription:{
      type:String,
      default:''
    },
    engageCodeOpts: {
      type: Array,
      default: () => {
        return []
      },
    },
    descriptionOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    oldEngageCode: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      default: "",
    }
  },
  setup(props, {emit}) {
    const selectedEngagementCode = ref('');
    const selectedEngagementDescription = ref('');
    // 监听第一个 select 框的值变化
    // const { $api } = useGlobalPropertyHook();
  

    const handleFirstSelectChange = (data) => {
      selectedEngagementDescription.value = data
    };
    const handleSecondSelectChange = (data) => {
      selectedEngagementCode.value = data 
    }
    const refreshData = (data) => {
      if(!data) {
        emit("reset-data-list", data)
        // props.descriptionOps.value = [];
        selectedEngagementDescription.value = null;
        return
      }
      data = data.split('(')[0].trim()
      const selectedCodeInfo = props.engageCodeOpts.filter(item => item.engagementCode == data)
      if(selectedCodeInfo && selectedCodeInfo[0].codeType !== 'DC') {
        content.value = '当前填写 Code 为 Non-Billable Code，是否继续填写？'
        showIconCom.value = 'ExclamationCircleOutlined'
        modalData.value = {data:{engagementCode:data},index:null}
        modelValue.value = true
      }else {
        emit("get-detail-by-code", data)
      }  
    }
    const formRef = ref();
    const formState = reactive({
      dataSource: [{
        id: "",
        engagementCode: "",
        engagementDescription: "",
        account: "",
        projectStartDate: "",
        projectCloseDate: "",
        projectCompletionDate:"",
        engagementManagerNumber: "",
        engagementManagerName: "",
      }],
    });
    const checkedList = ref([]);
    const checkedListValue = computed(() => {
      return checkedList.value;
    });
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        checkedList.value = [];
        selectedRows.forEach(row => {
          checkedList.value.push({ ...row });
        });
        console.log("selectedRowKeys changed: ", checkedList.value)
      },
    };
    const columns = computed(() => {
      const commonColumns = [
        {
          title: 'Engagement Code',
          dataIndex: 'engagementCode',
          slots: {customRender: "bodyCell"},
        },
        {
          title: 'Engagement Description',
          dataIndex: 'engagementDescription',
          width: 240,
          slots: {customRender: "bodyCell"},
          customHeaderCell: handleBreakHeader,
        },
        {
          title: 'Account',
          dataIndex: 'account',
          ellipsis: true,
        },
        {
          title: 'Project Start Date',
          dataIndex: 'projectStartDate',
          width: 200,
          customHeaderCell: handleBreakHeader,
          customRender: handleTitledRow,
        },
        {
          title: 'Project Close Date',
          dataIndex: 'projectCloseDate',
          width: 200,
          customHeaderCell: handleBreakHeader,
          customRender: handleTitledRow,
        },
        //新增字段Project Completion Date
        {
          title: 'Project Completion Date',
          dataIndex: 'projectCompletionDate',
          width: 240,
          align: "center",
          customHeaderCell: handleBreakHeader,
          customRender: handleTitledRow,
        },
        {
          title: 'Leading CSM Number',
          dataIndex: 'engagementManagerNumber',
          width: 220,
          customHeaderCell: handleBreakHeader,
          customRender: handleTitledRow,
        },
        {
          title: 'Leading CSM Name',
          dataIndex: 'engagementManagerName',
          width: 200,
          customHeaderCell: handleBreakHeader,
          customRender: handleTitledRow,
        },
      ]
      if(props.source === 'batch') {
        return [
          {
            title: 'No.',
            dataIndex: 'index',
            width: '12%',
            ellipsis: true,
          },
          {
            title: 'Candidate Name',
            dataIndex: 'candidateName',
            width: 200,
            customHeaderCell: handleBreakHeader,
            customRender: handleTitledRow,
          },
          ...commonColumns,
          {
            title: 'Hours Per Day',
            dataIndex: 'hoursPerDay',
            width: 160,
            customHeaderCell: handleBreakHeader,
            customRender: handleTitledRow,
          },
        ]
      }else {
        return [
          {
            title: 'No.',
            dataIndex: 'index',
            width: '12%',
            ellipsis: true,
          },
          {
            title: 'Candidate Name',
            dataIndex: 'candidateName',
            width: 200,
            customHeaderCell: handleBreakHeader,
            customRender: handleTitledRow,
          },
          ...commonColumns,
          {
            title: 'Hours Per Day',
            dataIndex: 'hoursPerDay',
            width: 160,
            customHeaderCell: handleBreakHeader,
            customRender: handleTitledRow,
          },
        ]
      }
    });
    
    const handleBreakHeader = () => {
      return {
        style: {
          wordWrap:'break-word',
          wordBreak:'keep-all',
          whiteSpace:'normal',
          width: '100px',
        }
      }
    }
    const handleTitledRow = ({ text }) => {
      return (
          <span title={text}>
            {text}
          </span>
      );
    };
    const updateFormState = () => {
    };

    const recordVisible = ref(false);

    const handleCancel = () => {
      emit("update:visible", false);
    };
    function handleSave(list, isAll) {
    // 判断是否全量
    if (!isAll) {
      // 从数据里筛选已选中的数据
      const selectedList = list.filter(item => {
        // 判断当前项是否在已选中的列表中
        return checkedList.value.some(checkedItem => checkedItem.assignmentId === item.assignmentId);
      });
      console.log("最原始的list", list);
      console.log("checkedlist", checkedList.value);
      console.log("已选中的数据:", selectedList);
      // 检查是否有选中的数据
      if (selectedList.length === 0) {
        message.error("请选择要操作的数据");
        return;
      } else {
        // 对被选中的数据进行校验
        console.log("走着了么1",selectedList)
        Promise.all(selectedList.map(item => {
          // 确保 item 具有 validate 方法
          if (typeof item.validate === 'function') {
            return item.validate();
          } else {
            console.error("item does not have a validate method:", item);
            return Promise.resolve(); // 返回一个已解决的 Promise，以避免 Promise.all 抛出错误
          }
        })).then(() => {
          // 在校验通过后，为每个被选中的数据添加 props.oldEngageCode
          if (props.oldEngageCode) {
            selectedList.forEach(item => {
              item.engagementCodeOld = props.oldEngageCode;
            });
          }
          // 将提交的数据传递给父组件
          emit("handle-save-assignment", selectedList);
        });
      }
    } else {
      console.log("走着2")
      // 对全部数据进行校验
      Promise.all(list.map(item => {
        // 确保 item 具有 validate 方法
        if (typeof item.validate === 'function') {
          return item.validate();
        } else {
          console.error("item does not have a validate method:", item);
          return Promise.resolve(); // 返回一个已解决的 Promise，以避免 Promise.all 抛出错误
        }
      })).then(() => {
        // 在校验通过后，为每个数据添加 props.oldEngageCode
        if (props.oldEngageCode) {
          list.forEach(item => {
            item.engagementCodeOld = props.oldEngageCode;
          });
        }
        // 将提交的数据传递给父组件
        emit("handle-save-assignment", list);
      });
    }
}


    const dataSource = ref({
      dataList: [{
        id: "",
        engagementCode: "",
        engagementDescription: "",
        account: "",
        projectStartDate: "",
        projectCloseDate: "",
        projectCompletionDate:"",
        engagementManagerNumber: "",
        engagementManagerName: "",
      }]
    })
    // const { $api } = useGlobalPropertyHook();
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {data,index} = modalData.value
      emit("get-detail-by-code", data.engagementCode, index)
    }
    const handleCancel1 = () => {
      // const {data,index} = modalData.value
      // data.engagementCode = ""
      // emit("reset-data-list", data.engagementCode, index)
    }
    const handleChange = (data, index) => {
      if(!data?.engagementCode) {
        // emit("reset-data-list", data.engagementCode, index)
        return
      }
      data.engagementCode = data.engagementCode.split('(')[0].trim()
      const selectedCodeInfo = props.engageCodeOpts.filter(item => item.engagementCode == data.engagementCode)
      if(selectedCodeInfo && selectedCodeInfo[0].codeType !== 'DC') {
        content.value = '当前填写 Code 为 Non-Billable Code，是否继续填写？'
        showIconCom.value = 'ExclamationCircleOutlined'
        modalData.value = {data,index}
        modelValue.value = true

        /*Modal.confirm({
          title: () => '提示',
          content: "当前填写Code为Non-Billable Code，是否继续填写？",
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          onOk() {
            emit("get-detail-by-code", data.engagementCode, index)
          },
          onCancel() {
            data.engagementCode = ""
            emit("reset-data-list", data.engagementCode, index)
          },
        });*/
      }else {
        emit("get-detail-by-code", data.engagementCode, index)
      }

    }

    watch(
        () => props.visible,
        (value) => {
          if (value) {
            // 每次抽屉打开都清空表单校验信息
            formRef?.value?.resetFields();
            selectedEngagementCode.value = "";
            selectedEngagementDescription.value = "";
          }else {
            dataSource.value.dataList.splice(0)
          }
        },
        {deep: true}
    );
    watch(
        () => props.initialDataList,
        (value) => {
          // checkedList.value = [];
          // value.forEach(item => {
          //   if (item.engagementCode) {
          //     checkedList.value.push({ ...item });
          //   }
          // // });
          // console.log("value:",value)
          // console.log("checkedList:", checkedList.value);
          const assiganmentDataList = value
          dataSource.value.dataList.splice(0)
          Object.assign(dataSource.value.dataList, assiganmentDataList)
        },
        {deep: true}
    );

    watch(props.titleEngagementDescription, (newValue) => {
      console.log('字符串变化了：', newValue);
      selectedEngagementDescription.value = newValue
    });
    const validateDesc = (rule, value) => {
      if (value && value.trim()) {
        if(/^[\u4e00-\u9fa5'’‘]{1,}$/.test(value)) {
          return Promise.reject('不支持中文、引号');
        }else {
          return Promise.resolve();
        }
      }else {
        return Promise.reject('Engagement Description 不能为空');
      }
    }
    onMounted(async () => {
    });
    return {
      formRef,
      formState,
      handleCancel,
      handleSave,
      recordVisible,
      updateFormState,
      columns,
      dataSource,
      handleChange,
      validateDesc,
      modelValue,
      content,
      showIconCom,
      handleOk,
      handleCancel1,
      rowSelection,
      selectedEngagementCode,
      handleFirstSelectChange,
      handleSecondSelectChange,
      selectedEngagementDescription,
      checkedListValue,
      refreshData
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: flex-end;
}
:deep(.ant-form-item) {
  margin-bottom: 0;
}
:deep(.ant-form-item-label) > label::after {
  content: "" !important;
}
.editable-table {
  :deep(.ant-row) {
    flex-wrap: nowrap;
  }
  :deep(.ant-table-row-cell-break-word) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.container {
  display: flex;
  justify-content: space-between; /* 将子元素在父容器中平均分布 */
}

.input-group {
  display: flex; /* 子元素采用 Flexbox 布局 */
  margin-left: 20px;
  margin-top: 10px;
  position: relative; /* 相对定位，为了让按钮基于此元素定位 */
}

.input-wrapper {
  margin-right: 16px;
  width: 350px;
}

.input-content {
  display: flex;
  flex-direction: column; /* 子元素在父容器中垂直排列 */
}

span {
  margin-bottom: 8px; /* 控制 span 标签下方的间距 */
}

.button {
  margin-left: auto; /* 将按钮推至最右边 */
  margin-top: auto; /* 调整按钮与其他元素的垂直间距 */
}

</style>

