<template>
  <div ref="fillAppraisesRef">
    <a-modal
        :getContainer="()=>$refs.fillAppraisesRef"
        :visible="visible"
        width="40.6vw"
        @cancel="closeModal"
        class="fill-appraises"
        centered
    >
      <template #title>
        <strong class="modal-title">
          {{ title ? title : "填写评价" }}
        </strong>
      </template>
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
          <a-button @click="submitModal" type="primary" class="addEmpBtn">
            确定
          </a-button>
        </div>
      </template>
      <a-form
          :model="formData"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          labelAlign="left"
      >
        <a-row>
          <a-col :span="24">
            <div class="red-text">注：1分为最高分。</div>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Attitude" name="attitude">
              <a-radio-group v-model:value="formData.attitude">
                <a-radio v-for="item in 5" :key="item" :value="item">{{
                    item
                  }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Skill" name="skill">
              <a-radio-group v-model:value="formData.skill">
                <a-radio v-for="item in 5" :key="item" :value="item">{{
                    item
                  }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="English(Optional)" name="english">
              <a-radio-group v-model:value="formData.english">
                <a-radio v-for="item in 5" :key="item" :value="item">{{
                    item
                  }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Teamwork" name="teamwork">
              <a-radio-group v-model:value="formData.teamwork">
                <a-radio v-for="item in 5" :key="item" :value="item">{{
                    item
                  }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Synthetical score" name="syntheticalScore">
              <a-radio-group v-model:value="formData.syntheticalScore">
                <a-radio v-for="item in 5" :key="item" :value="item">{{
                    item
                  }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Review Date" name="reviewDate">
              <a-date-picker v-model:value="formData.reviewDate" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
                label="If Key Talent In Project"
                name="keyTalent"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
            >
              <a-radio-group v-model:value="formData.keyTalent">
                <a-radio value="Y">Y</a-radio>
                <a-radio value="N">N</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
                label="是否有离职风险（Y-H/M/L，N-NA），如果Y，请在 Summary 写明原因"
                name="retentionRisk"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
            >
              <a-radio-group v-model:value="formData.retentionRisk">
                <a-radio value="H">H</a-radio>
                <a-radio value="M">M</a-radio>
                <a-radio value="L">L</a-radio>
                <a-radio value="NA">NA</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
                label="Summary"
                name="summary"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
            >
              <a-textarea
                  v-model:value="formData.summary"
                  placeholder="请输入"
                  :rows="5"
                  showCount
                  :maxlength="200"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
                  @keyup.enter="(e) => { e.stopPropagation() }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "FillAppraises",
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        attitude: "",
        skill: "",
        english: "",
        teamwork: "",
        syntheticalScore: "",
        keyTalent: "",
        reviewDate: "",
        retentionRisk: "",
        summary: "",
      }),
    },
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const formRef = ref();

    const formRules = {
      attitude: [
        {
          required: true,
          message: "Please select attitude score",
          trigger: "change",
          type: "number",
        },
      ],
      skill: [
        {
          required: true,
          message: "Please select skill score",
          trigger: "change",
          type: "number",
        },
      ],
      teamwork: [
        {
          required: true,
          message: "Please select teamwork score",
          trigger: "change",
          type: "number",
        },
      ],
      syntheticalScore: [
        {
          required: true,
          message: "Please select synthetical score",
          trigger: "change",
          type: "number",
        },
      ],
      keyTalent: [
        {
          required: true,
          message: "Please select If Key Talent",
          trigger: "change",
        },
      ],
      reviewDate: [
        {
          required: true,
          message: "Please input Review Date",
          trigger: "change",
          type: "date",
        },
      ],
      retentionRisk: [
        {
          required: true,
          message: "Please select Retention Risk",
          trigger: "change",
        },
      ],
      summary: [
        {
          required: true,
          message: "Please input Summary",
          trigger: "blur",
        },
      ],
    };

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        ctx.emit("submit-modal");
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      getRefsAff,
    };
  },
});
</script>

<style lang="less">
@import "~@/style/aModal.less";
.fill-appraises {
  width: 50vw !important;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    position: relative;

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
    .ant-calendar-picker {
      width: 100%;
    }
  }

  .red-text {
    color: #ff4d4f;
  }
}
</style>
