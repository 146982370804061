<template>
  <a-drawer wrapClassName="drawer" width="86.4vw" :title="title" @close="handleCancel">
    <div v-if="isalltimeshow">
      <span :style="{ 'display': 'block', 'margin': '15px 0 -15px 24px' }"> Welcome Letter邮件发送时间</span>
      <span :style="{ 'margin-left': ' 15px' }">
        <DatePickerselect @setdate="getalldate($event)" @resetadate="resetalldate"></DatePickerselect>
        <a-button type="primary" style="margin-left:4vw;" @click="setalldate"
          :disabled="setalldatedisabled">全部更新</a-button>
      </span>
    </div>
    <a-table :data-source="list" :columns="columns" :rowKey="(record) => record.staffId" :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{
        x:true,
      }">
      <template #WelcomeLetterEmailDate="{ index }">
        <DatePickerselect class="date-picker" @setdate="setdate($event, index)" @resetdate="resetdate(index)" :time="allwelomeletterdata">
        </DatePickerselect>
      </template>
      <template #TemplateName="{ index }">
        <a-select v-model:value="teplateNameValue[index]" show-search placeholder="Select a Template Name"
          style="width: 200px" :options="templateNameOptions[index]" @focus="handleFocus" @blur="handleBlur"
          @change="handleChange($event, index)">
        </a-select>
      </template>
      <template #action="{ record, index }">
<!--        <a-space>
          <span v-auth="['system:staffInfo:welcomelettertemplate']" class="operationLinkblue"
            @click="changeTemplate(record, 'preview', index)">预览</span>
          <span v-auth="['system:staffInfo:welcomelettertemplate']" class="operationLinkblue"
            @click="changeTemplate(record, 'edit', index)">修改模板</span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @EyeOutlined="changeTemplate(record, 'preview', index)" @FormOutlined="changeTemplate(record, 'edit', index)" />
      </template>
    </a-table>
    <div class="feature-line1 bottom-btn">
      <a-button style="margin-left:8px;" @click="handleCancel" v-auth="['system:send:cancel']">取消</a-button>
      <a-button :disabled="isnext" type="primary" style="margin-left:8px;" @click="handleNext" v-auth="['system:send:determine']">确定</a-button>
    </div>
    <WelcomeLetterTamplate v-if="welcomeVisible" title="Welcome Letter 模板" :userId="userId" :id="id"
      :templateId="templateId" :offering="offering" :isPreview="isPreview" v-model:visible="welcomeVisible"
      @handleCancel="welcomeVisible = false" :defaultTemplateId="defaultTemplateId">
    </WelcomeLetterTamplate>
    <div ref="modalDialog">
      <a-modal :getContainer="()=>$refs.modalDialog" :visible="visible" width="20.8vw" @cancel="visible = false" title="提示" :z-index="10000" centered>
        <p>
          <span v-if="arr2.length > 1">其中</span><span v-for="(item, index) in arr2" :key="item">{{ item }} {{ arr2.length -
        1 ===
        index ? '' : '、' }}</span>已经发送过 Welcome Letter 邮件
        </p>
        <template #footer>
          <div class="footerBtn">
            <a-button type="primary" @click="visible = false">确定</a-button>
          </div>
        </template>
      </a-modal>
    </div>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
    <a-modal
      v-model:visible="isWelcomeVisible"
      width="40.6vw"
      title="提示"
      ok-text="确定"
      cancel-text="取消"
      @ok="onOk"
      @cancel="onCancel"
      centered
  >
    <div class="mb12">
      <div class="assignment-infos">
        <span v-html="msg"></span>
      </div>
    </div>
  </a-modal>
  </a-drawer>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  ref,
} from "vue";
import WelcomeLetterTamplate from '@/components/WelcomeLetterTamplate'
import DatePickerselect from '@/components/DatePickerselect'
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '查看',
    icon: 'EyeOutlined',
    auth: ['system:welcomeletter:previewtemplate']
  },
  {
    name: '修改模板',
    icon: 'FormOutlined',
    auth: ['system:welcomeletter:edittemplate']
  },
]

export default defineComponent({
  name: "WelcomeLetter",
  components: { WelcomeLetterTamplate, DatePickerselect, ModalBox, ActionBtn },
  props: {
    title: {
      type: String,
      default: "",
    },
    rowlist: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const isalltimeshow = computed(() => {
      return list.value.length > 1 ? true : false
    })
    const templateNameOptions = ref([])
    const columns = [
      {
        title: "Name",
        dataIndex: "fullName",
        ellipsis: true,
        width: 120,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        ellipsis: true,
        width: 160,
      },
      {
        title: "Capgemini Start Date",
        dataIndex: "onboardDate",
        width: 200,
      },
      {
        title: "Welcome Letter邮件发送时间",
        dataIndex: "",
        align: "left",
        ellipsis: true,
        width: 360,
        slots: { customRender: "WelcomeLetterEmailDate" },
      },
      {
        title: "Template Name",
        dataIndex: "",
        align: "left",
        ellipsis: true,
        slots: { customRender: "TemplateName" },
      },
      {
        title: "操作",
        dataIndex: "",
        align: "left",
        fixed: 'right',
        ellipsis: true,
        width: 140,
        slots: { customRender: "action" }
      },
    ]
    const teplateNameValue = ref([])
    const isPreview = ref(true)
    //全部时间
    const allwelomeletterdata = ref('')
    const allwelomeletterdataobj = ref({})
    const setalldatedisabled = computed(() => {
      return allwelomeletterdataobj.value.selecttime ? false : true
    })
    const handleCancel = () => {
      emit('handleCancel')
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      onNext('1')
      // const arr = []
      // list.value.forEach((item, index) => {
      //   selectedRowKeys.value.forEach(i => {
      //     if (item.staffId === i) {
      //       arr.push({ idNumber: item.idsNumber, sendmailDate: welcomedatalist.value[index] })
      //     }
      //   })
      // })
      // $api.saveSendmailDate(arr).then((res) => {
      //   if (res.code === 200) {
      //     message.success('操作成功');
      //     emit('handleCancel')
      //   }
      // })
    }
    const msg = ref('')
    const onNext = (value) => {
      const arr = []
      list.value.forEach((item, index) => {
        selectedRowKeys.value.forEach(i => {
          if (item.staffId === i) {
            arr.push({ idNumber: item.idsNumber, sendmailDate: welcomedatalist.value[index] })
          }
        })
      })
      const skipPromptMessage = value
      $api.saveSendmailDate({skipPromptMessage,sendmailDateCommands:arr}).then((res) => {
        if (res.code === 200) {
          // res.data
          if (value === '0' && res.data) {
            content.value = '确定发送welcome letter?'
            showIconCom.value = 'ExclamationCircleOutlined'
            modelValue.value = true
          } else  if(value === '0' && !res.data){
            isWelcomeVisible.value = true
            msg.value = res.msg
          } else {
            message.success('操作成功');
            emit('handleCancel')
            isWelcomeVisible.value = false
          }
        }
      })
    }
    const isWelcomeVisible = ref(false)
    const handleNext = () => {
      onNext('0')
      // content.value = '确定发送welcome letter?'
      // showIconCom.value = 'ExclamationCircleOutlined'
      // modelValue.value = true

      /*Modal.confirm({
        title: '确定发送welcome letter?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        onOk() {
          const arr = []
          list.value.forEach((item, index) => {
            selectedRowKeys.value.forEach(i => {
              if (item.staffId === i) {
                arr.push({ idNumber: item.idsNumber, sendmailDate: welcomedatalist.value[index] })
              }
            })
          })
          $api.saveSendmailDate(arr).then((res) => {
            if (res.code === 200) {
              message.success('操作成功');
              emit('handleCancel')
            }
          })
        },
        cancelText: '取消',
      });*/

    }
    const staffWelcomeLetterTemplatecreate = (userIds) => {
      $api.staffWelcomeLetterTemplatecreate(userIds)
    }
    const visible = ref(false)
    const arr2 = ref([])
    const list = ref([])
    const cloneRowlist = ref([])
    onMounted(() => {
      const arr = []
      cloneRowlist.value = props.rowlist;
      cloneRowlist.value.forEach(item => {
        item.templateId = '0'
      })
      let newArrays = ref([])
      // let currentTemplateArr = ref([])
      cloneRowlist.value.forEach((item, index) => {
        if (item?.sendEmailStatus === '1') {
          arr2.value.push(item.fullName)
        } else {
          arr.push(item.staffId)
          list.value.push(item)
          list.value = [...new Set(list.value.map(t => JSON.stringify(t)))].map(s => JSON.parse(s)) //数据去重处理
          welcomedatalist.value.push('')
          teplateNameValue.value[index] = item.currentTemplate;
          cloneRowlist.value[index].templateId = item.currentTemplate;
          // currentTemplateArr.value.push(item.currentTemplate);
          if (item.templateList?.length > 0 || item.templateList?.length == 0) {
            let arr3 = ref([])
            arr3.value = item.templateList.map(j => {
              return {
                value: j.id,
                label: j.name
              }
            });
            templateNameOptions.value[index] = [{
              value: '0',
              label: '默认模板'
            }, ...arr3.value];
          }
          newArrays.value.push(templateNameOptions.value[index])
        }
      });
      newArrays.value.forEach((item,index)=>{
        // teplateNameValue.value[index] = '0'
        console.log(templateNameOptions.value);
        templateNameOptions.value[index]=item;
      })
      selectedRowKeys.value = arr
      if (arr2.value.length > 0) {
        visible.value = true
      }
      staffWelcomeLetterTemplatecreate(arr)
    })
    //查看,修改模板
    const welcomeVisible = ref(false)
    const userId = ref('')
    const id = ref('')
    const offering = ref('')
    const templateId = ref('')
    const defaultTemplateId = ref('')
    const changeTemplate = (record, type, index) => {
      id.value = record.id
      userId.value = record.staffId
      offering.value = record.orgFourOneOne
      templateId.value = cloneRowlist.value[index].templateId
      defaultTemplateId.value = cloneRowlist.value[index].currentTemplate
      welcomeVisible.value = true
      type === 'preview' ? isPreview.value = true : isPreview.value = false
    }
    const selectedRowKeys = ref([])
    const onSelectChange = (keys) => {
      selectedRowKeys.value = keys
    }
    //设置时间
    const welcomedatalist = ref([])
    const setdate = (e, index) => {
      welcomedatalist.value[index] = `${e.value.date} ${e.value.selecttime}`
    }
    // 模板下拉
    const handleChange = (value, index) => {
      cloneRowlist.value[index].templateId = value;
      templateNameOptions.value.forEach(item => {
        if (item.value === value) {
          teplateNameValue[index] = item.label
        }
      })
    };
    const handleBlur = () => {
      console.log('blur');
    };
    const handleFocus = () => {
      console.log('focus');
    };
    const filterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    //清空时间
    const resetdate = (index) => {
      welcomedatalist.value[index] = ''
    }
    const getalldate = (e) => {
      allwelomeletterdataobj.value = e.value
    }

    const resetalldate = () => {
      allwelomeletterdataobj.value = {}
    }
    const setalldate = () => {
      allwelomeletterdata.value = `${allwelomeletterdataobj.value.date} ${allwelomeletterdataobj.value.selecttime}`
    }
    const isnext = computed(() => {
      let flag = false
      if (selectedRowKeys.value.length === 0) return true
      list.value.map((item, index) => {
        selectedRowKeys.value.map(i => {
          if (item.staffId === i) {
            if (!welcomedatalist.value[index]) {
              flag = true
            }
          }
        })
      })
      return flag
    })
    const onCancel = ()=>{
  isWelcomeVisible.value = false
    }
    const onOk = ()=>{
      onNext('1')
    }
    return {
      columns,
      handleCancel,
      handleNext,
      changeTemplate,
      welcomeVisible,
      isalltimeshow,
      selectedRowKeys,
      onSelectChange,
      setdate,
      resetdate,
      getalldate,
      resetalldate,
      allwelomeletterdata,
      setalldate,
      setalldatedisabled,
      userId,
      id,
      offering,
      arr2,
      visible,
      list,
      welcomedatalist,
      isnext,
      handleChange,
      handleBlur,
      handleFocus,
      filterOption,
      templateNameOptions,
      teplateNameValue,
      isPreview,
      cloneRowlist,
      templateId,
      modelValue,
      content,
      showIconCom,
      handleOk,
      btnList,
      defaultTemplateId,
      isWelcomeVisible,
      onOk,
      onCancel,
      msg
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/aModal.less";

.titletop {
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  margin-top: 15px;
}

.txt {
  text-align: center;
}

.ant-table-cell {
  white-space: nowrap
}

.operationLinkblue {
  font-size: 12px;
  color: #3191dc;
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
}

:deep .ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: inherit !important;
}

:deep .ant-form-item-control-input-content {
  margin-left: -14px !important;
}
.date-picker{
  margin-top: 0 !important;
  :deep(.ant-form-item){
    margin-bottom: 0;
  }
}
</style>
