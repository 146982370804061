<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    width="40.6vw"
    @cancel="closeModal"
    class="onBoardConfirm"
    :zIndex="99"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "Assignment 时间确定" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="applyBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      labelAlign="left"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
              label="Code 有效期："
              name=""
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :colon="false"
              class="labelColor"
          >
            {{
              codeTerm.projectStartDate +
              " ~ " +
              codeTerm.projectCloseDate
            }}
          </a-form-item>
        </a-col>
<!--        <a-col :span="24">-->
<!--          <a-form-item-->
<!--            label="Assignment Term"-->
<!--            name="originalOnboardDate"-->
<!--            :label-col="{ span: 24 }"-->
<!--            :wrapper-col="{ span: 24 }"-->
<!--            :colon="false"-->
<!--          >-->
<!--            {{-->
<!--              formData.originalOnboardDate +-->
<!--              " ~ " +-->
<!--              formData.originalActualDate-->
<!--            }}-->
<!--          </a-form-item>-->
<!--        </a-col>-->
        <a-col :span="24" :class="formData.staffAssignmentParts.length > 0 ? 'colColor':''">
          <a-row>
            <a-col :span="12">
              <a-form-item
                  name="originalOnboardDate"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :colon="false"
                  class="labelColor"
              >
                <template v-slot:label>
                  <span style="color: #1890FF" class="status-icon" v-show="formData.staffAssignmentParts.length > 0">●</span>Assignment Term：
                </template>
                <span :style="`margin-left: ${ formData.staffAssignmentParts.length > 0 ? '0.6vw' : ''}`">
                {{
                  formData.originalOnboardDate +
                  " ~ " +
                  formData.originalActualDate
                }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                  label="Hours Per Day："
                  name="hoursPerDay"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :colon="false"
                  class="labelColor"
              >
                {{formData.hoursPerDay}}小时
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24" class="colColor" v-show="formData.staffAssignmentParts.length > 0">
          <div v-for="(item, index) in formData.staffAssignmentParts" :key="index">
            <a-row>
              <a-col :span="12">
                <a-form-item
                    name="originalOnboardDate"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                    :colon="false"
                    class="labelColor"
                >
                  <template v-slot:label>
                    <span style="color: #77CAED"><span class="status-icon">●</span></span>Assignment Term{{index + 1}}：
                  </template>
                  <span style="margin-left: 0.6vw;">
                  {{
                    item.startDate +
                    " ~ " +
                    item.endDate
                  }}
                  </span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                    label="Hours Per Day:"
                    name="hoursPerDay"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                    :colon="false"
                    class="labelColor"
                >
                  {{item.hoursPerDay}}小时
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="12" :style="`margin-top: ${ formData.staffAssignmentParts.length > 0 ? '1.25vw' : ''}`">
          <a-form-item
            label="Project Onboard Date"
            name="onBoardDate"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 22 }"
            :colon="false"
            :class="isShowBtn === true ? 'isShow' : ''"
          >
            <a-date-picker
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              v-model:value="formData.onBoardDate"
              :disabledDate="disabledDate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24" v-show="isShowBtn">
          <a-form-item
              label=" "
              :colon="false"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 22 }"
              style="margin-bottom: 0px"
          >
            <span style="color:red" v-if="isRm">Assignment Term与Code有效期冲突，请补充comments</span>
            <span style="color:red" v-else>Assignment Term与Code有效期冲突</span>
          </a-form-item>
        </a-col>
        <a-col :span="12" :style="`margin-top: ${ formData.staffAssignmentParts.length > 0 ? '1.25vw' : ''}`">
          <a-form-item
              label="Code 预计开出时间"
              name="projectCommitDate"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :colon="false"
          >
            <a-date-picker
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                v-model:value="formData.projectCommitDate"
                :disabledDate="disabledCommitDate"
                allow-clear
                :placeholder="placeHolder"
                :disabled="isDisableCommitDate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="PM Comments"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            :colon="false"
            v-if="isRm && formData.pmReason"
          >
            {{ formData.pmReason }}
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Comments"
            name="applyReason"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            :colon="false"
          >
            <a-textarea
              v-model:value="formData.applyReason"
              placeholder="请输入"
              :rows="5"
              showCount
              :maxlength="65"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import moment from "moment";

const formRules = {
  onBoardDate: [
    {
      required: true,
      message: "Please input Project Onboard Date",
      trigger: "change",
      type: "date",
    },
  ],
  projectCommitDate: [
    {
      required: true,
      message: "Please input Code 预计开出时间",
      trigger: "change",
      type: "date",
    },
  ],
  applyReason: [
    {
      required: true,
      message: "Please input Apply Reason",
      trigger: "blur",
    },
  ],
};

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
export default defineComponent({
  name: "OnBoardConfirm",
  components: {},
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        assignmentId: "",
        onBoardDate: null,
        estimateDate: "",
        applyReason: "",
        originalDate: "",
        projectCommitDate: "",
        staffAssignmentParts: [],
      }),
    },
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isRm: {
      type: Boolean,
      default: true,
    },
    isFormalCode: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const codeTerm = computed(() => props.codeTermData);
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const isShowBtn = ref(false);

    const getIsShow = () => {
      const params = {
          onBoardDate: formData.value.onBoardDate,
          estimateDate: formData.value.estimateReleaseDate,
          projectStartDate: codeTerm.value.projectStartDate,
          projectCloseDate:codeTerm.value.projectCloseDate,
          actualDate: formData.value.actualDate,
      }
      $api.checkCodeAndAssignTerm([params]).then((res) => {
        if (res.data[0] === '1') {
          isShowBtn.value = false
        } else {
          isShowBtn.value = true
        }
      });
    }
    const isTBDCode = computed(() => props.isFormalCode);
    const isDisableCommitDate = ref(false)
    const placeHolder = computed(() =>  isTBDCode.value === false ? "仅适用于TBD Code" : "请选择");

    const handleIsFormalCode = () => {
      if(isTBDCode.value === false) {
        isDisableCommitDate.value = true
        formRules.projectCommitDate[0].required = false
        formData.value.projectCommitDate = ''
      } else {
        isDisableCommitDate.value = false
        formRules.projectCommitDate[0].required = true
        if(formData.value.onBoardDate === null) {
          isDisableCommitDate.value = true
        }
      }
    }

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        ctx.emit("submit-modal");
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const disabledDate = (current) => {
      return current > moment(formData.value.originalActualDate) ||
        current < moment(formData.value.staffOnboardDate) ||
        current > moment(formData.value.staffTerminationDate);
    };
    const disabledCommitDate = (current) => {
      if(moment().diff(formData.value.onBoardDate,'day') <= 0){
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))
            && (moment(current.format("YYYY-MM-DD")) >= moment(projectCommitStartDate.value))) return false
        return true;
      } else {
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))) return false
        return true;
      }
    }
    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getCommitDateRange = async () => {
      if(formData.value.onBoardDate.length > 0 && isTBDCode.value === true) {
        const params = {
          onboardDate: formData.value.onBoardDate
        }
        const { data } =await $api.getCommitDateRange(params)
        projectCommitEndDate.value = data.projectCommitEndDate
        projectCommitStartDate.value = data.projectCommitStartDate
      }
    }

    watch(
        () => formData.value.onBoardDate,
        (val) => {
          if(val) {
            getIsShow();
            getCommitDateRange()
            handleIsFormalCode()
            formData.value.projectCommitDate = ''
          } else {
            isShowBtn.value = false
            handleIsFormalCode()
          }
        }
    )
    onMounted(() => {
      getIsShow();
      handleIsFormalCode();
      getCommitDateRange()
    });

    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      formData,
      formRef,
      formRules,
      getRefsAff,
      closeModal,
      submitModal,
      disabledDate,
      isShowBtn,
      getIsShow,
      codeTerm,
      disabledCommitDate,
      placeHolder,
      isDisableCommitDate,
      handleIsFormalCode,
      projectCommitEndDate,
      projectCommitStartDate,
      getCommitDateRange,
      isTBDCode,
    };
  },
});
</script>

<style lang="less">
.onBoardConfirm {
  //width: 50vw !important;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    position: relative;

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0;
    }
    .ant-calendar-picker {
      width: 100%;
    }
  }
}
</style>
<style lang="less" scoped>
.isShow {
  margin-bottom: 0px;
}
.colColor {
  background-color:#F7FAFC;
  :deep(.ant-form-item) {
    margin-bottom: 0;
  }
}
.labelColor {
  :deep(.ant-form-item-label){
    padding: 0;
    > label {
      color: #999999;
    }
  }
  .status-icon {
    //font-size: 1.4vw;
    margin-right: 0.2vw;
  }
}
</style>
