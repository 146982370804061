<template>
  <div class="batch-edit-modal" ref="batchEditModalRef">
    <a-drawer
      :visible="visible"
      @close="closeModal"
      width="86.4vw"
      :title="title"
      :getContainer="() => $refs.batchEditModalRef"
    >
      <a-form
        v-if="role === 'pm' && actionType === 'apply'"
        :model="formState"
        layout="inline"
      >
        <a-form-item
          label="新上项目时间"
          name="newOnBoardDate"
          :colon="false"
          v-show="activeTab === '1'"
        >
          <a-date-picker
            style="width: 240px"
            placeholder="请选择时间"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            v-model:value="formState.newOnBoardDate"
            :disabledDate="onBoardDisabledDate"
            :allowClear="false"
          />
        </a-form-item>
        <a-form-item
          label="新下项目时间"
          name="newActualReleaseDate"
          :colon="false"
          v-show="activeTab === '2'"
        >
          <a-date-picker
            style="width: 240px"
            placeholder="请选择时间"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            v-model:value="formState.newActualReleaseDate"
            :allowClear="false"
            :disabledDate="estimateDisabledDate"
          />
        </a-form-item>
        <a-form-item
          label="Release 方式"
          name="releaseMode"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 15 }"
          :colon="false"
          v-show="activeTab === '2' && role === 'rm'"
        >
          <a-select
            style="width: 240px"
            v-model:value="formState.releaseMode"
            placeholder="请选择"
            allowClear
          >
            <a-select-option
              v-for="{ key, value } in releaseOps"
              :key="key"
              :value="value"
              >{{ key }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="申请备注"
          name="reason"
          :colon="false"
          v-show="role === 'pm'"
        >
          <a-textarea
            style="width: 240px"
            v-model:value="formState.reason"
            placeholder="请输入"
            autoSize
            showCount
            :maxlength="65"
            @keyup.enter="(e) => { e.stopPropagation() }"
          />
        </a-form-item>
        <a-form-item
          label="审批备注"
          name="comments"
          :colon="false"
          v-show="role === 'rm'"
        >
          <a-textarea
            style="width: 240px"
            v-model:value="formState.comments"
            placeholder="请输入"
            autoSize
            showCount
            :maxlength="65"
            :auto-size="{ minRows: 3, maxRows: 3 }"
            @keyup.enter="(e) => { e.stopPropagation() }"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="applyAll">全部更新</a-button>
        </a-form-item>
      </a-form>
      <a-form :model="dataSource" ref="formRef">
        <a-table
          class="assignment-list mt24"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          row-key="id"
          :row-selection="{
            selectedRowKeys: selectedAssignmentIdList,
            selectedRows: selectedAssignmentList,
            onChange: selectedChange,
          }"
          :scroll="{
            x: 1700,
            scrollToFirstRowOnChange: true,
          }"
          v-if="visible"
        >
          <template #date="{ index, text, record, column }">
  <!--          <TimeOverlapTip-->
  <!--            :record="record"-->
  <!--            :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"-->
  <!--          />-->
            <span :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"><span :class="`status-icon ${record.detailStatus}`" @click="isTimeClick(record)">●</span></span>
            <a-date-picker
              :value="text"
              @update:value="(val) => (dataSource[index][column.key] = val)"
              placeholder="请选择时间"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :allowClear="false"
              @change="handleDateChange(record, index)"
              :disabledDate="current => disabledDate(current, record)"
              :class="{'red-border': checkReleaseModeDate(dataSource[index][column.key],dataSource[index].releaseMode)}"
            />
            <span v-show="checkReleaseModeDate(dataSource[index][column.key],dataSource[index].releaseMode)" style="color: red;" class="error-span">
              {{checkReleaseModeDate(dataSource[index][column.key],dataSource[index].releaseMode)}}
            </span>
          </template>
          <template #projectCommitDate="{ text,index,record }">
            <a-date-picker
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :value="text"
                @update:value="(val) => (dataSource[index].projectCommitDate = val)"
                :disabledDate="current => disabledCommitDate(current, record)"
                :placeholder="pageType === 'todoList' ? (dataSource[index].isCode?'仅适用于TBD Code':'请选择') : placeHolder"
                :disabled="pageType === 'todoList' ? dataSource[index].isCode : isDisableCommitDate"
                @openChange ="getProjectCommitDateRange(record)"
            />
          </template>
          <template #reason="{ index, text }">
            <a-textarea
              v-if="role === 'pm'"
              :value="text"
              @update:value="(val) => (dataSource[index].reason = val)"
              placeholder="请输入"
              :rows="3"
              :maxlength="65"
              showCount
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
            <div class="apply-reason-text" v-else><span :title="text">{{ text }}</span></div>
          </template>
          <template #releaseMode="{ index, text }">
            <a-select
              style="width: 100%"
              :value="text"
              @update:value="(val) => (dataSource[index].releaseMode = val)"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                v-for="{ key, value } in releaseOps"
                :key="key"
                :value="value"
                >{{ key }}</a-select-option
              >
            </a-select>
          </template>
          <template v-auth="['system:assignment:releaseEmailisSend']" #releaseEmailSendMode="{index}">
            <a-select style="width: 100%" v-model:value="dataSource[index].releaseEmailSendMode" placeholder="请选择">
              <a-select-option key="1" :value="1">是</a-select-option>
              <a-select-option key="2" :value="0">否</a-select-option>
            </a-select>
          </template>
          <template v-auth="['system:assignment:releaseEmail']" #releasetime="{ index, text }">
            <date-pickerselect class="date-picker" :visible="visible" @toreleasetime="toreleasetime($event, index)" @releaseSendEmailDatenull="releaseSendEmailDatenull(index)" :actualDate="text"
             :isrequired="dataSource[index].releaseEmailSendMode"></date-pickerselect>
          </template>
          <template #comments="{ index, text }">
            <a-textarea
              :value="text"
              @update:value="(val) => (dataSource[index].comments = val)"
              placeholder="请输入"
              :rows="3"
              :maxlength="65"
              showCount
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
          </template>

          <template #AssignmentTerm="{ text, record }">
            <div v-if="record.staffAssignmentParts.length === '0'">
              {{text}}
            </div>
            <div v-else>
              <div>
                <span style="color: #1890FF">●</span>{{text}}
              </div>
              <div v-for="(item) in record.staffAssignmentParts" :key="item.id">
                <span style="color: #77CAED">●</span><span style="color: #999999">{{item.startDate + '~' + item.endDate }}</span>
              </div>
            </div>
          </template>
          <template #hoursPerDay="{ text, record }">
            <div v-if="record.staffAssignmentParts.length === '0'">
              {{text}}
            </div>
            <div v-else>
              <div>
                {{text}}
              </div>
              <div v-for="(item) in record.staffAssignmentParts" :key="item.id">
                <span style="color: #999999">{{item.hoursPerDay}}</span>
              </div>
            </div>
          </template>
          <template #extensionAssumptionDate="{ index, text, record }">
            <div style="display: flex;margin-top: 1.25vw;">
              <span :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"><span :class="`status-icon ${record.detailStatus}`" @click="isTimeClick(record,index)">●</span></span>
              <a-form-item :name="[index, 'extensionAssumptionDate']"
                           :rules="[{ validator: fcstValidatorSpace,fcstRecord: record, trigger: 'blur'}]">
                <a-date-picker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    :value="text"
                    @update:value="(val) => (dataSource[index].extensionAssumptionDate = val)"
                    :disabledDate="current => disabledExtensionAssumptionDate(current, record)"
                    placeholder="请输入"
                    @change="extensionChabge(record,index)"
                />
              </a-form-item>
            </div>
          </template>
          <template #certainty="{ index, text }">
            <div style="margin-top: 1.5vw">
              <a-form-item :name="[index, 'certainty']"
                           :rules="[{required: dataSource[index].extensionAssumptionDate,message: 'Please select 确定性',trigger: 'change',}]">
                <a-radio-group
                    :value="text"
                    @update:value="(val) => (dataSource[index].certainty = val)"
                    :disabled="!dataSource[index].extensionAssumptionDate"
                >
                  <a-radio value="Firm">Firm</a-radio>
                  <a-radio value="Proposal">Proposal</a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
          </template>
        </a-table>
      </a-form>
      <div class="btn-row bottom-btn">
        <div class="left"></div>
        <div class="right">
          <a-space>
            <a-button type="primary" ghost @click="closeModal">取消</a-button>
            <a-button type="primary" @click="nextClick" :disabled="nextDisabled"
            >下一步
            </a-button>
          </a-space>
        </div>
      </div>
      <BatchConfirmDrawer
        :actionType="actionType"
        v-model:visible="saveVisible"
        :isTBDCode="isTBDCode"
        :edit-data="formState"
        :list="selectedAssignmentList"
        :id-list="selectedAssignmentIdList"
        :role="role"
        :active-tab="activeTab"
        :get-container-ref="$refs.batchEditModalRef"
        :pageType="pageType"
        @cancel-modal="saveCancel"
        @prev-click="pervClick"
        @refresh-table="refreshTable"
      />
    </a-drawer>
    <ModalBox
        v-model="modelValue"
        :okText="okText"
        :cancelText="cancelText"
        :ok="handleOk"
        :cancel="handleCancel"
        :content="content"
        :showIconCom="showIconCom"
        :okButtonProps="okButtonProps"
        :footer="footer"
    />
    <OverlapModal
        :data="overlapData"
        @confirm="timeJudgmentSubmitFunc"
        v-model:visible="overlapModalVisible"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import moment from "moment";
import BatchConfirmDrawer from "./BatchConfirmDrawer";
import { computed } from "@vue/runtime-core";
import { cloneDeep } from 'lodash';
import DatePickerselect from '../components/DatePickerselect.vue'
import {QuestionCircleOutlined} from '@ant-design/icons-vue';
import ModalBox from '@/components/ModalBox'
import Tooltip from '@/components/Tooltip'
import OverlapModal from "@/components/OverlapModal.vue";

export default defineComponent({
  name: "BatchEditTimeDrawer",
  props: {
    visible: Boolean,
    list: Array,
    idList: Array,
    role: String,
    activeTab: String,
    actionType: String,
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isFormalCode: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: String,
      default: '',
    },
  },
  emits: ["close-modal", "refresh-table"],
  components: {OverlapModal, BatchConfirmDrawer,DatePickerselect,ModalBox },
  setup: function (props, {emit}) {
    const {$api} = useGlobalPropertyHook();
    const dataSource = ref([]);
    const selectedAssignmentIdList = ref([]);
    const selectedAssignmentList = computed(() =>
        dataSource.value.filter(({id}) =>
            selectedAssignmentIdList.value.includes(id)
        )
    );
    const assignmentIdMap = ref({});
    const actualReleaseDate = computed(
        () =>
            dataSource.value
                .map(({actualReleaseDate}) => actualReleaseDate)
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())?.[0]
    );
    const formState = ref({
      newOnBoardDate: undefined,
      // newEstimateReleaseDate: undefined,
      newActualReleaseDate: undefined,
      releaseMode: null,
      reason: "",
      comments: "",
    });
    const columns = computed(() => {
      let dateKey =
          props.activeTab === "1" ? "newOnBoardDate" : "newActualReleaseDate";
      let cols = [
        {
          title: "人名",
          dataIndex: "fullName",
          key: "fullName",
          width: 140,
          ellipsis: true,
        },
        {
          title: "GGID",
          dataIndex: "ggid",
          key: "ggid",
          width: 160,
          ellipsis: true,
        },
        {
          title: "Assignment Term",
          dataIndex: "range",
          key: "range",
          width: 240,
          slots: { customRender: "AssignmentTerm" }
        },
        {
          title: "Hours Per Day",
          dataIndex: "hoursPerDay",
          key: "hoursPerDay",
          width: 180,
          ellipsis: true,
          slots: { customRender: "hoursPerDay" }
        },
        {
          title: `${props.role === "rm" ? "PM申请" : ""}${
              props.activeTab === "1" ? "Project Onboard Date" : "Release Date"
          }`,
          dataIndex: dateKey,
          key: dateKey,
          width: 200,
          slots: {customRender: "date"},
        },
      ];
      if (props.activeTab === "1") {
        cols.push(
            {
              title: "Code 预计开出时间",
              dataIndex: "projectCommitDate",
              key: "projectCommitDate",
              width: 200,
              slots: {customRender: "projectCommitDate"},
            },
        )
      }
      if (
          (props.actionType === "confirm" || props.actionType === "audit") &&
          props.activeTab === "2"
      ) {
        cols.push({
              title: "Release 方式",
              dataIndex: "releaseMode",
              key: "releaseMode",
              width: 160,
              slots: {customRender: "releaseMode"},
            },
            {
              title: "Release 邮件是否发送",
              dataIndex: "releaseEmailSendMode",
              key: "releaseEmailSendMode",
              width: 180,
              slots: {customRender: "releaseEmailSendMode"},
            },
            {
              // title: "Release邮件发送时间",
              title: <div>Release邮件发送时间&nbsp;
                <Tooltip title="默认为系统自动发送时间，RM可根据需求修改。">
                  <QuestionCircleOutlined/>
                </Tooltip>
              </div>,
              dataIndex: "newActualReleaseDate",
              key: "releasetime",
              width: 380,
              slots: {customRender: "releasetime"},
            });
      }
      if (props.actionType === "confirm" && props.activeTab === "2") {
        cols.push(
            {
              title: <div class="fcstLabel"><div class="aboveLabel">FCST Extension Assumption Date</div><div class="tipLabel">不会更新调令的结束时间</div></div>,
              //title: "FCST Extension Assumption Date",
              dataIndex: "extensionAssumptionDate",
              key: "extensionAssumptionDate",
              width: 220,
              slots: { customRender: "extensionAssumptionDate" }
            },
            {
              title: "确定性",
              dataIndex: "certainty",
              key: "certainty",
              width: 150,
              slots: { customRender: "certainty" }
            },
        );
      }
      if (props.actionType === "apply" || props.actionType === "audit") {
        cols.push({
          title: "申请备注",
          dataIndex: "reason",
          key: "reason",
          width: 160,
          slots: {customRender: "reason"},
        });
      }
      if (props.actionType === "audit") {
        cols.push({
          title: "审批备注",
          dataIndex: "comments",
          key: "comments",
          width: 140,
          slots: {customRender: "comments"},
        });
      } else if (props.actionType === "confirm") {
        cols.push({
          title: "备注",
          dataIndex: "comments",
          key: "comments",
          width: 200,
          slots: {customRender: "comments"},
        });
      }
      if(props.pageType === 'todoList') {
        cols.unshift(
            {
              title: "Engagement Code",
              dataIndex: "engagementCode",
              key: "engagementCode",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Engagement Description",
              dataIndex: "engagementDescription",
              key: "engagementDescription",
              width: 250,
              ellipsis: true,
            },
        );
      }
      return cols;
    });

    let oldDataSource = [];
    let oldDataSourceObj = {};
    //let dataSouceFnName = ""
    watch(
        () => props.visible,
        (val) => {
          if (val) {
            selectedAssignmentIdList.value = [];
            assignmentIdMap.value = {};
            //获取选中之后的数据
            dataSource.value = props.list.map((item, index) => {
              selectedAssignmentIdList.value.push(item.id);
              assignmentIdMap.value[item.id] = index;
              return {
                ...item,
                reason: item.reason || "",
                comments: item.comments || "",
                range: `${item.onBoardDate || ""}~${
                    item.actualReleaseDate || ""
                }`,
                newOnBoardDate: item.newOnBoardDate || item.onBoardDate,
                newActualReleaseDate:
                    item.newActualReleaseDate || item.actualReleaseDate,
                projectCommitDate: isTBDCode.value === true ? item.projectCommitDate : "",
                releaseMode: props.actionType === "audit" ? null : item.releaseMode,
                detailStatus: "false",
                staffAssignments: [],
                staffWorkTasks: [],
                releaseSendmailDate: '',
                releaseEmailSendMode: 1
              }
            });
            const filterList = dataSource.value
            getDataSource(filterList)
            handleIsFormalCode();
            //batchTimeOverlap()
          }
        }
    );

    const getDataSource = (filterList) => {
      //dataSouceFnName = props.actionType == 'confirm' ? "postCheckOverlap" : "batchOverlap"
      $api.batchOverlap(filterList).then((res) => {
        let arr = []
        dataSource.value.forEach((item) => {
          res.data.forEach(Item => {
            if (item.id === Item.id) {
              item.detailStatus = Item.gatherFlag
              arr.push(Object.assign(item, Item))
            }
          })
        })
        dataSource.value = [...arr]
        // 暂存进来的数据，点击下一步的时候校验时间是否被修改过
        oldDataSource = cloneDeep(dataSource.value);
        oldDataSourceObj = {};
        for (let i = 0; i < oldDataSource.length; i++) {
          oldDataSourceObj[oldDataSource[i].id] = oldDataSource[i]
        }
      })
    }
    // const batchTimeOverlap = () => {
    //   selectedAssignmentList.value.forEach((item) => {
    //     const staffAssignment = {
    //       id: item.id,
    //       hoursPerDay: item.hoursPerDay,
    //       actualReleaseDate: moment(item.newActualReleaseDate).format("YYYY-MM-DD"),
    //       onBoardDate: moment(item.newOnBoardDate).format("YYYY-MM-DD"),
    //       userId: item.userId,
    //       staffAssignmentParts: item.staffAssignmentParts
    //     };
    //     currentOverlapData.value = {
    //       clientName:item.account,
    //       engagementCode: item.engagementCode,
    //       engagementDescription: item.engagementDescription,
    //     }
    //     $api.isTimeJudgments(staffAssignment).then((res) => {
    //       let i = assignmentIdMap.value[res.data.id];
    //       dataSource.value[i].detailStatus = res.data.gatherFlag;
    //     });
    //   })
    // }
    //批量确定自动过滤
    // const postCheckOverlap = () => {
    //   $api.postCheckOverlap(selectedAssignmentList.value).then((res) => {
    //     (res?.data || []).forEach(({id, detailStatus, staffAssignments, staffWorkTasks}) => {
    //       let index = assignmentIdMap.value[id];
    //       dataSource.value[index].detailStatus = detailStatus;
    //       dataSource.value[index].staffAssignments = staffAssignments || [];
    //       dataSource.value[index].staffWorkTasks = staffWorkTasks || [];
    //     })
    //   });
    // }

    // const batchOverlap = () => {
    //   $api.batchOverlap(selectedAssignmentList.value).then((res) => {
    //     (res?.data || []).forEach(({id, detailStatus, staffAssignments, staffWorkTasks}) => {
    //       let index = assignmentIdMap.value[id];
    //       dataSource.value[index].detailStatus = detailStatus;
    //       dataSource.value[index].staffAssignments = staffAssignments || [];
    //       dataSource.value[index].staffWorkTasks = staffWorkTasks || [];
    //     })
    //   });
    // }
    const batchOverlap = (record) => {
      let params = selectedAssignmentList.value.map(item => {
        const staffAssignment = {
          hoursPerDay: item.hoursPerDay,
          actualReleaseDate: moment(item.newActualReleaseDate).format("YYYY-MM-DD"),
          onBoardDate: moment(item.newOnBoardDate).format("YYYY-MM-DD"),
          staffAssignmentParts: item.staffAssignmentParts
        };
        if(item.staffAssignmentParts.length > 0){
          if(props.activeTab === "1"){
            staffAssignment.staffAssignmentParts = item.staffAssignmentParts.filter(i =>
                moment(i.endDate).isAfter(item.newOnBoardDate) || moment(i.endDate).isSame(item.newOnBoardDate)
            ).map(i => {
              return {
                id: i.id,
                startDate: i.startDate,
                endDate: i.endDate,
                hoursPerDay: i.hoursPerDay
              }
            })
            staffAssignment.staffAssignmentParts[0].startDate = item.newOnBoardDate
            if (staffAssignment.staffAssignmentParts.length === 1) {
              staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
              staffAssignment.staffAssignmentParts = []
            }
          } else {
            staffAssignment.staffAssignmentParts = item.staffAssignmentParts.filter(i =>
                moment(i.startDate).isBefore(item.newActualReleaseDate) || moment(i.startDate).isSame(item.newActualReleaseDate)
            ).map(i => {
              return {
                id: i.id,
                startDate: i.startDate,
                endDate: i.endDate,
                hoursPerDay: i.hoursPerDay
              }
            })
            staffAssignment.staffAssignmentParts[staffAssignment.staffAssignmentParts.length - 1].endDate = item.newActualReleaseDate
            if (staffAssignment.staffAssignmentParts.length === 1) {
              staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
              staffAssignment.staffAssignmentParts = []
            }
          }
        }
        return {
          ...item,
          hoursPerDay: staffAssignment.hoursPerDay,
          actualReleaseDate: staffAssignment.actualReleaseDate,
          onBoardDate: staffAssignment.onBoardDate,
          staffAssignmentParts: staffAssignment.staffAssignmentParts
        }
      })
      $api.batchOverlap(params).then((res) => {
        if(record) {
          let i = res.data.map(item =>item.id).indexOf(record.id)
          let indexlist = selectedAssignmentList.value.map(item =>item.id).indexOf(record.id)
          let list = selectedAssignmentList.value[indexlist]
          currentOverlapData.value = {
            clientName:list.account,
            engagementCode: list.engagementCode,
            engagementDescription: list.engagementDescription,
          }
          overlapData.value = {
            status: res.data[i].gatherFlag,
            assignmentList: res.data[i].assignmentQueryPartCommandList || [],
            workTaskList: res.data[i].workTaskList || [],
            overlapCommandList: res.data[i].overlapCommandList || [],
            currentformData: currentOverlapData.value,
            demandList: res.data[i].demandList || [],
          }
        } else {
          (res?.data || []).forEach(({id, gatherFlag}) => {
            let i = assignmentIdMap.value[id];
            dataSource.value[i].detailStatus = gatherFlag;
          })
        }
      });
    }
    // const isTimeOverlap = (record) => {
    //   const staffAssignment = {
    //     id: record.id,
    //     hoursPerDay: record.hoursPerDay,
    //     actualReleaseDate: moment(record.newActualReleaseDate).format("YYYY-MM-DD"),
    //     onBoardDate: moment(record.newOnBoardDate).format("YYYY-MM-DD"),
    //     userId: record.userId,
    //     staffAssignmentParts: record.staffAssignmentParts
    //   };
    //   if(record.staffAssignmentParts.length > 0){
    //     if(props.activeTab === "1"){
    //       staffAssignment.staffAssignmentParts = record.staffAssignmentParts.filter(item =>
    //           moment(item.endDate).isAfter(record.newOnBoardDate) || moment(item.endDate).isSame(record.newOnBoardDate)
    //       ).map(item => {
    //         return {
    //           id: item.id,
    //           startDate: item.startDate,
    //           endDate: item.endDate,
    //           hoursPerDay: item.hoursPerDay
    //         }
    //       })
    //       staffAssignment.staffAssignmentParts[0].startDate = record.newOnBoardDate
    //       if (staffAssignment.staffAssignmentParts.length === 1) {
    //         staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
    //         staffAssignment.staffAssignmentParts = []
    //       }
    //     } else {
    //       staffAssignment.staffAssignmentParts = record.staffAssignmentParts.filter(item =>
    //           moment(item.startDate).isBefore(record.newActualReleaseDate) || moment(item.startDate).isSame(record.newActualReleaseDate)
    //       ).map(item => {
    //         return {
    //           id: item.id,
    //           startDate: item.startDate,
    //           endDate: item.endDate,
    //           hoursPerDay: item.hoursPerDay
    //         }
    //       })
    //       staffAssignment.staffAssignmentParts[staffAssignment.staffAssignmentParts.length - 1].endDate = record.newActualReleaseDate
    //       if (staffAssignment.staffAssignmentParts.length === 1) {
    //         staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
    //         staffAssignment.staffAssignmentParts = []
    //       }
    //     }
    //   }
    //   currentOverlapData.value = {
    //     clientName:record.account,
    //     engagementCode: record.engagementCode,
    //     engagementDescription: record.engagementDescription,
    //   }
    //   $api.isTimeJudgments(staffAssignment).then((res) => {
    //     let i = assignmentIdMap.value[res.data.id];
    //     dataSource.value[i].detailStatus = res.data.gatherFlag;
    //     overlapData.value = {
    //       status: res.data.gatherFlag,
    //       assignmentList: res.data.assignmentQueryPartCommandList || [],
    //       workTaskList: res.data.workTaskList || [],
    //       overlapCommandList: res.data.overlapCommandList || [],
    //       currentformData: currentOverlapData.value
    //     }
    //   });
    // }
    const applyAll = () => {
      dataSource.value.forEach((item) => {
        if (props.activeTab === "1") {
          if (formState.value.newOnBoardDate)
            item.newOnBoardDate = formState.value.newOnBoardDate;
          item.projectCommitDate = '';
        } else {
          if (formState.value.newActualReleaseDate)
            item.newActualReleaseDate =
                formState.value.newActualReleaseDate;
          if (props.role === "rm" && formState.value.releaseMode)
            item.releaseMode = formState.value.releaseMode;
        }
        if (props.role === "pm" && formState.value.reason) {
          item.reason = formState.value.reason;
        } else if (props.role === "rm" && formState.value.comments) {
          item.comments = formState.value.comments;
        }
        //isTimeOverlap(item)
      });
      batchOverlap();
    };
    const handleDateChange = (record, index) => {
      if (props.activeTab === "1") {
        dataSource.value[index].projectCommitDate = ''
      }
      if (selectedAssignmentIdList.value.includes(record.id)) {
        // if (props.actionType == 'confirm') {
        //   postCheckOverlap();
        // } else {
        //   batchOverlap();
        // }
        //isTimeOverlap(record)
        batchOverlap();
      }
    };

    const selectedChange = (selectedIdList, selectedList) => {
      selectedAssignmentIdList.value = selectedIdList;
      selectedAssignmentList.value = selectedList;
      // if (props.actionType == 'confirm') {
      //   postCheckOverlap();
      // } else {
      //   batchOverlap();
      // }
      batchOverlap();
    };
    const onBoardDisabledDate = (date) =>
        date > moment(actualReleaseDate.value).add(1, "days");
    const estimateDisabledDate = (date) => date <= moment();
    const disabledDate = (current, record) => {
      let staffDateDisabled = false
      if (current && record.staffTerminationDate && current > moment(record.staffTerminationDate)) staffDateDisabled = true
      if (current && record.staffOnboardDate && current < moment(record.staffOnboardDate)) staffDateDisabled = true
      if (props.activeTab === "1") {
        return current > moment(record.actualReleaseDate) || staffDateDisabled;
      } else {
        return current <= moment() ||
            // 插入增强校验逻辑 start
            ((record.releaseMode === "1" || record.releaseMode === "3") && current.format("YYYY-MM-DD") > moment().add(30, 'days').format("YYYY-MM-DD")) ||
            (record.releaseMode === "6" && current.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) ||
            // end
            staffDateDisabled;
      }
    };

    const isTBDCode = computed(() => props.isFormalCode);
    const isDisableCommitDate = ref(false)
    const placeHolder = computed(() => isTBDCode.value === false ? "仅适用于TBD Code" : "请选择");
    const handleIsFormalCode = () => {
      if (isTBDCode.value === false) {
        isDisableCommitDate.value = true
        dataSource.value.forEach(item => {
          item.projectCommitDate = ''
        })
      } else {
        isDisableCommitDate.value = false
      }
    }
    const disabledCommitDate = (current, record) => {
      let newDate = props.activeTab === "1" ? record.newOnBoardDate : record.range.slice(0, 10)
      if (moment().diff(newDate, 'day') <= 0) {
        if (current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))
            && (moment(current.format("YYYY-MM-DD")) >= moment(projectCommitStartDate.value))) return false
        return true;
      } else {
        if (current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))) return false
        return true;
      }
    }
    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getProjectCommitDateRange = async (record) => {
      let newDate = props.activeTab === "1" ? record.newOnBoardDate : record.range.slice(0, 10)
      if (newDate && isTBDCode.value === true) {
        const params = {
          onboardDate: newDate
        }
        const {data} = await $api.getCommitDateRange(params)
        projectCommitEndDate.value = data.projectCommitEndDate
        projectCommitStartDate.value = data.projectCommitStartDate
      }
    }

    // 表格内已勾选数据全部填写才能进行下一步
    const nextDisabled = computed(() => {
      let flag = selectedAssignmentList.value.length === 0;
      selectedAssignmentList.value.forEach(
          ({
             projectCommitDate,
             detailStatus,
             reason,
             comments,
             releaseMode,
             releaseSendmailDate,
             releaseEmailSendMode
           }) => {
            if (detailStatus === "prohibit") {
              flag = true;
            }
            if (props.actionType === "apply" && !reason?.trim() || (isTBDCode.value === false ? projectCommitDate : !projectCommitDate)) {
              flag = true;
            }
            if (props.actionType === "audit" || props.actionType === "confirm") {
              if (!comments?.trim()) flag = true;
              if (props.activeTab === "2" && !releaseMode) flag = true;
              if (releaseEmailSendMode === 1) {
                if (props.activeTab === "2" && !releaseSendmailDate) flag = true
              } else if (!(releaseEmailSendMode === 0)) {
                flag = true
              }
            }
          }
      );
      return flag;
    });

    const closeModal = () => {
      selectedAssignmentIdList.value = [];
      selectedAssignmentList.value = [];
      formState.value = {
        newOnBoardDate: undefined,
        newActualReleaseDate: undefined,
        releaseMode: null,
        reason: "",
        comments: "",
      };
      emit("close-modal");
    };
    const saveVisible = ref(false);
    const saveCancel = () => {
      saveVisible.value = false;
      closeModal();
    };

    const footer = ref(false)
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const okText = ref('')
    const cancelText = ref('')
    const okButtonProps = ref()
    const flag = ref()
    const modalData = ref()
    const handleOk = () => {
      saveVisible.value = true;
    }
    const handleCancel = () => {
      if (flag.value === 2){
        dataSource.value.forEach(item => {
          modalData.value.data.map(i => {
            if (i === item.fullName) {
              item.releaseEmailSendMode = 0
            }
          })
        })
      }
    }
    const nextClick = () => {
      const selectedAssignmentIdListObj = {}
      for (let i = 0; i < selectedAssignmentIdList.value.length; i++) {
        selectedAssignmentIdListObj[selectedAssignmentIdList.value[i]] = true
      }
      formRef.value.validate().then(() => {
        if (props.role === 'pm' && props.actionType === 'apply') {
          const keyStr = props.activeTab === "1" ? 'onBoardDate' : 'actualReleaseDate'
          const keyStrNew = props.activeTab === "1" ? 'newOnBoardDate' : 'newActualReleaseDate'
          const duplicateArr = []
          dataSource.value.forEach(item => {
            if (
                oldDataSourceObj[item.id][keyStr] === item[keyStrNew] &&
                selectedAssignmentIdList.value.indexOf(item.id) > -1
            ) {
              duplicateArr.push(oldDataSourceObj[item.id])
              delete selectedAssignmentIdListObj[item.id]
            }
          })
          if (duplicateArr.length) {
            selectedAssignmentIdList.value = Object.keys(selectedAssignmentIdListObj)
            const str = props.activeTab === '1' ? '上' : '下'
            content.value = `以下人员：姓名(${duplicateArr.map(item => item.fullName).join('、')})申请的${str}项目时间和原${str}项目时间一致，
            系统已默认不发起申请${!selectedAssignmentIdList.value.length ? '' : `，其他人员正常申请`} 。`
            showIconCom.value = ''
            okText.value = '确定'
            cancelText.value = '取消'
            okButtonProps.value = {
              disabled: !selectedAssignmentIdList.value.length
            }
            flag.value = 1
            modalData.value = ''
            footer.value = true
            modelValue.value = true

            /*Modal.confirm({
              title: `以下人员：姓名(${duplicateArr.map(item=>item.fullName).join('、')})申请的${str}项目时间和原${str}项目时间一致，
              系统已默认不发起申请${!selectedAssignmentIdList.value.length ? '' : `，其他人员正常申请` } 。`,
              okText: "下一步",
              cancelText: "返回",
              centered: true,
              closable: false,
              width: 800,
              okButtonProps: {
                disabled: !selectedAssignmentIdList.value.length
              },
              onOk() {
                saveVisible.value = true;
              },
              onCancel() {}
            })*/
            return
          }
        }
        if (props.activeTab === '2') {
          const wrongDateArr = []
          dataSource.value.forEach(item => {
            if (
                checkReleaseModeDate(item.newActualReleaseDate, item.releaseMode)
            ) {
              wrongDateArr.push(cloneDeep(item))
              delete selectedAssignmentIdListObj[item.id]
            }
          })
          if (wrongDateArr.length) {
            selectedAssignmentIdList.value = Object.keys(selectedAssignmentIdListObj)
            content.value = `以下顾问：姓名(${wrongDateArr.map(item => item.fullName).join('、')})由于 Release 方式与时间冲突无法继续${!selectedAssignmentIdList.value.length ? '' : `，其他人员正常进行下一步`} 。`
            showIconCom.value = ''
            okText.value = '下一步'
            cancelText.value = '取消'
            okButtonProps.value = {
              disabled: !selectedAssignmentIdList.value.length
            }
            flag.value = 1
            modalData.value = ''
            modelValue.value = true
            /*Modal.confirm({
              title: `以下顾问：姓名(${wrongDateArr.map(item => item.fullName).join('、')})由于Release方式与时间冲突无法继续${!selectedAssignmentIdList.value.length ? '' : `，其他人员正常进行下一步`} 。`,
              okText: "下一步",
              cancelText: "返回",
              centered: true,
              closable: false,
              width: 800,
              okButtonProps: {
                disabled: !selectedAssignmentIdList.value.length
              },
              onOk() {
                saveVisible.value = true;
              },
              onCancel() {
              }
            })*/
            return
          }
        }
        if ((props.actionType === "confirm" || props.actionType === "audit") && props.activeTab === "2") {
          let ids = []
          dataSource.value.map(item => {
            if (item.releaseEmailSendMode === 1) {
              ids.push(item.id)
            }
          })
          $api.checkReleaseEmailSendStatus(ids).then(res => {
            if (res?.data.length > 0) {
              let str = ''
              res.data.forEach((item, index) => {
                if (index === 0) {
                  str = `${item}`
                } else {
                  str = `${str}、${item}`
                }
              })

              content.value = `${str}的调令已触发过邮件提醒，是否根据新 Release 邮件发送时间再次发送？`
              showIconCom.value = 'ExclamationCircleOutlined'
              okText.value = '确定'
              cancelText.value = '取消'
              okButtonProps.value = {}
              flag.value = 2
              modalData.value = res
              modelValue.value = true
              /*Modal.confirm({
                title: `${str}的调令已触发过邮件提醒，是否根据新Release邮件发送时间再次发送？`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                  saveVisible.value = true;
                },
                cancelText: '取消',
                onCancel() {
                  dataSource.value.forEach(item => {
                    debugger
                    res.data.map(i => {
                      if (i === item.fullName) {
                        item.releaseEmailSendMode = 0
                      }
                    })
                  })
                }
              });*/
            } else {
              saveVisible.value = true;
            }
          })
          return
        }
        saveVisible.value = true;
      })
    };
    const pervClick = () => {
      saveVisible.value = false;
    };

    const refreshTable = () => {
      saveCancel();
      emit("refresh-table");
    };

    const releaseOps = [
      {
        value: "1",
        key: "RM Interrupt",
      },
      {
        value: "2",
        key: "Normal Release",
      },
      {
        value: "3",
        key: "Sudden Release",
      },
    ];
    const title = computed(
        () =>
            ({
              apply: "批量申请",
              confirm: props.activeTab === "1" ? "批量确认" : "批量 Release",
              audit: "批量审核",
            }[props.actionType])
    );
    // 前端校验日期逻辑
    const forwardOver1Month = (value) => {
      return moment(value).subtract(30, 'days').format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
    }
    const checkReleaseModeDate = (value, releaseMode) => {
      switch (releaseMode) {
        case "1":
        case "3":
          if (forwardOver1Month(value)) {
            return '当前 Release 方式请选择距当前时间30天之内的日期';
          } else {
            return false;
          }
        case "6":
          if (moment(value).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
            return '当前 Release 方式无法选择当天及之前的日期';
          } else {
            return false;
          }
        default:
          return false;
      }
    }
    //返回release邮件发送时间
    const toreleasetime = (e, index) => {
      dataSource.value[index].releaseSendmailDate = `${e.value.releasetime} ${e.value.selecttime}`
    }
    const releaseSendEmailDatenull = (index) => {
      dataSource.value[index].releaseSendmailDate = ''
    }
    //8小时校验
    const isTimeClick = (record) => {
      //isTimeOverlap(record,index)
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([record.newOnBoardDate,record.newActualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(record.extensionAssumptionDate))
      batchOverlap(record)
      overlapModalVisible.value = true
    }
    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      demandList: [],
    });
    const currentOverlapData = ref({
      clientName:'',
      engagementCode: '',
      engagementDescription: '',
    })
    const timeJudgmentSubmitFunc = () => {
      overlapModalVisible.value = false
    }

    //FCST
    const formRef = ref();
    const disabledExtensionAssumptionDate = (current, record) => {
      return current < moment(record.newActualReleaseDate) || current > moment(record.terminationDate)
    }
    const extensionChabge = (record,index) => {
      if(!record.extensionAssumptionDate) {
        dataSource.value[index].certainty = null
      }
      batchOverlap();
    }
    const fcstValidatorSpace = async (rule, value) => {
      if(value) {
        const diffStart = moment(rule.fcstRecord.newOnBoardDate).diff(moment(value), 'days') < 0;
        const diffEnd = moment(rule.fcstRecord.newActualReleaseDate).diff(moment(value), 'days') > 0;
        const diffDtate = diffStart && diffEnd;
        if (diffDtate) {
          return Promise.reject("该日期不能在Assignment Term范围内，请重新选择");
        } else {
          return Promise.resolve();
        }
      }
    }
    return {
      checkReleaseModeDate,
      title,
      columns,
      selectedAssignmentIdList,
      selectedAssignmentList,
      closeModal,
      selectedChange,
      formState,
      onBoardDisabledDate,
      estimateDisabledDate,
      disabledDate,
      saveVisible,
      nextClick,
      saveCancel,
      pervClick,
      nextDisabled,
      refreshTable,
      releaseOps,
      dataSource,
      applyAll,
      handleDateChange,
      toreleasetime,
      releaseSendEmailDatenull,
      isTBDCode,
      isDisableCommitDate,
      placeHolder,
      handleIsFormalCode,
      disabledCommitDate,
      projectCommitEndDate,
      projectCommitStartDate,
      getProjectCommitDateRange,
      footer,
      modelValue,
      content,
      showIconCom,
      okText,
      cancelText,
      okButtonProps,
      handleOk,
      handleCancel,
      isTimeClick,
      overlapModalVisible,
      overlapData,
      currentOverlapData,
      timeJudgmentSubmitFunc,
      //batchTimeOverlap,
      batchOverlap,
      extensionChabge,
      fcstValidatorSpace,
      disabledExtensionAssumptionDate,
      formRef,
    };
  },
});
</script>

<style lang="less" scoped>
.btn-row {
  display: flex;
  .left {
    width: 50%;
  }
  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.assignment-list {
  margin-bottom: 20px;
  .status-icon {
    font-size: 20px;
    margin-right: 12px;
    color: #58b99e;
    pointer-events: none;
    &.true {
      color: #fdcd46;
      cursor: pointer;
      pointer-events: auto;
    }
    &.prohibit {
      color: #dc5e4f;
      cursor: pointer;
      pointer-events: auto;
    }
    &.matchingOverlap {
      color: #58b99e;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}
</style>

<style lang="less">
.batch-edit-modal {
  .ant-input-textarea-show-count {
    position: relative;
    &::after {
      font-size: 12px;
      position: absolute;
      right: 12px;
      bottom: 5px;
      line-height: 1;
      margin-bottom: unset !important;
    }
  }
}
.assignment-popover {
  width: 888px;
  .ant-popover-title {
    padding: 24px 24px 0 24px;
    border-bottom: none;
  }
  .popover-cards {
    max-height: 40vh;
    overflow-y: auto;
    .popover-card {
      height: 184px;
      padding: 24px;
      background: #f7fafc;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      display: flex;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .item-column {
        flex: 1;
        .item-row {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 12px;
          .label {
            color: #505050;
            margin-right: 16px;
          }
          .value {
            color: #999999;
          }
        }
      }
    }
  }
}
.apply-reason-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style lang="less" scoped>
.overlap-hidden {
  visibility: hidden;
}
.error-span{
  position: relative;
  font-size: 11px;
  display: block;
  left: 20px;
  top: -5px;
  width: 0;
  height: 0;
}
.red-border /deep/ input{
  border-color:red;
}
:deep(.ant-row .ant-form-item) {
  margin-top: 15px;
}
.date-picker{
  margin-top: 0 !important;
  :deep(.ant-form-item){
    margin-bottom: 0;
  }
}
.fcstLabel {
  .aboveLabel{
    margin-top:20px;
  }
  .tipLabel {
    color: #3182ce;
    font-size: 14px;
  }
}
</style>
