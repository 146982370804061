<template>
  <section class="top-filter">
    <FilterLayout
        ref="filterLayoutRef"
        @doReset="handleReset"
        @doSearch="doSearch"
        @doCollapseEffect="handleResize"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList">
      <template #customform>
<!--        <a-form
            class="form rms-form"
            name="custom-validation"
            ref="formRef"
            :model="formState"
            layout="vertical"
        >
          <a-form-item label="Team" name="orgThreeTwoList" v-auth="['system:todoAssignment:team']" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgThreeTwoList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
                @change="changeOrgThreeTwo"
            >
              <a-select-option
                  v-for="(item, index) in orgThreeTwoOps"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoAssignment:offering']" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.orgFourOneOneList"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
            >
              <a-select-option
                  v-for="(item, index) in orgFourOneOneOps"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Engagement Description" name="engagementCode" class="rms-form-item">
            <a-input
                v-model:value="formState.engagementCode"
                placeholder="项目名称/项目code"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
          <a-form-item label="Account" name="accountList" class="rms-form-item">
            <SelectWithAll v-model:value="formState.accountList"
                           :options="accountParamList"
                           placeholder="请选择"
                           optionFilterProp="label"
                           allowClear>
            </SelectWithAll>
          </a-form-item>
          &lt;!&ndash;      调令：调令RM PM状态内容不同 &ndash;&gt;
          &lt;!&ndash;      匹配：只有PM显示Status &ndash;&gt;
          <a-form-item label="Status" name="status" class="rms-form-item">
            <a-select
                v-model:value="formState.status"
                placeholder="请选择"
                :options="assignmentStatusOps"
                allowClear
                show-search
                :maxTagCount="1"
            />
          </a-form-item>
          <a-form-item label="Project Onboard Date" name="onBoradDateList" class="rms-form-item">
            <a-range-picker
                v-model:value="formState.onBoradDateList"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                @change="handleOnboradDateChange"
            />
          </a-form-item>
          <a-form-item label="Estimate Release Date" name="releaseDateList" class="rms-form-item">
            <a-range-picker
                v-model:value="formState.releaseDateList"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                @change="handleReleaseDateChange"
            />
          </a-form-item>
          <a-form-item label="搜索" name="nameCondition" class="rms-form-item">
            <a-input
                v-model:value="formState.nameCondition"
                placeholder="Name/GGID"
                allowClear
                autocomplete="off"
            />
          </a-form-item>
        </a-form>-->
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <template v-for="(item,index) in queryList" :key="index">
            <template v-if="index<=3">
              <a-form-item :label="item.code" name="name" class="rms-form-item">
                <template v-if="item.type==='2'">
                  <a-select
                      v-model:value="formState[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                      :maxTagCount="1"
                  >
                  </a-select>
                </template>
                <template v-if="item.type==='3'">
                  <a-input
                      v-model:value="formState[item.inputDataType]"
                      :placeholder="getPlaceHoder(item.inputDataType)"
                      allowClear
                      autocomplete="off"
                  />
                </template>
                <template v-if="item.type==='5'">
                  <SelectWithAll
                      v-model:value="formState[item.inputDataType]"
                      placeholder="请选择"
                      mode="multiple"
                      showArrow
                      :maxTagCount="1"
                      allowClear
                      :options="getOptions(item.inputDataType)"
                      @change="getSelectChange(item.inputDataType)"
                  >
                  </SelectWithAll>
                </template>
                <template v-if="item.type==='6'">
                  <a-range-picker
                      v-model:value="formState[item.inputDataType]"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                      @change="getPickerChange(item.inputDataType)"
                  />
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index>3">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                        :maxTagCount="1"
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState[item.inputDataType]"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                        @change="getPickerChange(item.inputDataType)"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <a-space>
            <a-button class="" type="primary" ghost :disabled="rowlist.every(item => !['2','3'].includes(item.status))"
                      @click="batchApplyClick('confim')" v-auth="['system:todoList:assignment:batchupperConfirm']">
              上项目确认
            </a-button>
            <a-button class="" type="primary" ghost :disabled="rowlist.every(item => !['6','7'].includes(item.status))"
                      @click="batchApplyClick('release')" v-auth="['system:todoList:assignment:batchBelowConfirm']">
              下项目确认
            </a-button>
            <Tooltip title="导出">
              <a-button class="" type="primary" ghost @click="handleExport" v-auth="['system:todoList:assignment:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </a-space>
        </div>
      </template>
    </FilterLayout>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
    <BatchEditTimeDrawer
        :actionType="actionType"
        v-model:visible="batchEditTimeVisible"
        :list="rowAlllist"
        :id-list="[]"
        :role="role"
        :active-tab="activeTab"
        @close-modal="closeBatchModal"
        @refresh-table="refreshTable"
        :pageType="pageType"
    />
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {useStore} from "vuex";
import {cloneDeep, debounce} from "lodash";
import { ExportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import BatchEditTimeDrawer from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/BatchEditTimeDrawer";

export default defineComponent({
  name: "TopFilter",
  components: {Tooltip, ExportOutlined, MoreFormList, FormModalBox, BatchEditTimeDrawer},
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    accountParamList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    },
    isPM: {
      type: Boolean,
      default: false,
    },
    rowlist: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: ["search", "refresh-table", "getSize", "handleOrgChange", "exportClick", "getOrgList", "getPMOrgList","clearSelectedRows"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const assignmentStatusOps = ref([]);
    let initData = {
      demandCondition: "",
      engagementCode: "",
      nameCondition: "",
      accountList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      status: null,
      onBoardBegin: '',
      onBoardEnd: '',
      estimateReleaseBegin: '',
      estimateReleaseEnd: '',
      onBoardBeginList: [],
      estimateReleaseBeginList: [],
    }
    const formState = reactive({...initData});

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = cloneDeep(formState);
    }

    const handleResize = () => {
      emit("getSize")
    }

    const doSearch = () => {
      updateParams()
      emit("search", currentParams);
    };

    const handleReset = () => {
      sessionStorage.setItem('itemData','')
      sessionStorage.setItem('dashboardStatus','')
      formRef.value.resetFields();
      formState.onBoardBegin = '';
      formState.onBoardEnd = '';
      formState.estimateReleaseBegin = '';
      formState.estimateReleaseEnd = '';
      Object.assign(formState,initData)
      updateParams()
      emit("search", currentParams);
      emit("clearSelectedRows")
      // 重新获取team offering下拉
      if(props.isRM) {
        emit("getOrgList")
      }
      if(props.isPM) {
        emit("getPMOrgList")
      }
    };

    const handleExport = () => {
      emit("exportClick", currentParams)
    };

    const getAssignmentTypes = () => {
      // 区分显示status筛选项的内容
      let dictStatusType;
      dictStatusType = isPm.value || isPmAuth.value? 'todoListAssPmStatus' : isRmLeader.value?'todoListAssRmLederStatus':'todoListAssStatus';
      $api
          .getDictionaryList({
            params: {
              dictType: "todoListAssStatus,todoListAssPmStatus,todoListAssRmLederStatus",
            },
          })
          .then((res) => {
            assignmentStatusOps.value = res.data && res.data[dictStatusType] ?
                res.data[dictStatusType].map(({ dictCode, dictValue}) => ({
                  label: dictValue,
                  value: dictCode,
                })) : [];
          });
    };

    const handleOnboradDateChange = () => {
      formState.onBoardBegin = formState.onBoardBeginList[0]
      formState.onBoardEnd = formState.onBoardBeginList[1]
    }

    const handleReleaseDateChange = () => {
      formState.estimateReleaseBegin = formState.estimateReleaseBeginList[0]
      formState.estimateReleaseEnd = formState.estimateReleaseBeginList[1]
    }

    const store = useStore();
    const isPm = ref(false)
    const isPmAuth = ref(false)
    const isRmLeader = ref(false)
    const getUserRole = () => {
      const roles = store.state.app.user.roles;
      isPm.value = roles.indexOf("3") !== -1
      isPmAuth.value = roles.indexOf("14") !== -1
      isRmLeader.value = roles.indexOf("11") !== -1
    };

    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList)
    };

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'todoList',
        secondMenu: 'assignment',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'nameCondition':
          return 'Name/GGID'
        case 'engagementCode':
          return '项目名称/项目code'
        case 'customerCondition':
          return '客户名称/详细客户名称'
        default :
          ''  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return props.orgThreeTwoOps
        case 'orgFourOneOneList':
          return props.orgFourOneOneOps
        case 'accountList':
          return props.accountParamList
        case 'status':
          return assignmentStatusOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return changeOrgThreeTwo()
        default :
          ''  // 如果没
      }
    },500)
    const getPickerChange = debounce((data)=> {
      switch (data) {
        case 'onBoardBeginList':
          return handleOnboradDateChange()
        case 'estimateReleaseBeginList':
          return handleReleaseDateChange()
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = () => {
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getUserRole()
      getAssignmentTypes();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onActivated(() => {
      emit("refresh-table");
    });

    const moreSeachHanlder = () => {
          doSearch()
          closeMoreForm()
    }

    const rowAlllist = ref([])
    const batchApplyClick = (type) => {
      if(type === 'confim') {
        activeTab.value = '1'
      } else {
        activeTab.value = '2'
      }
      rowAlllist.value = props.rowlist
      // rowAlllist.value = rowAlllist.value.map(item => {
      //   return {
      //     ...item,
      //     staffAssignmentParts: [],
      //     isCode: false,
      //   }
      // })
      batchEditTimeVisible.value = true;
    };
    const batchEditTimeVisible = ref(false);
    let actionType = 'confirm'
    let role = 'pm'
    const activeTab = ref('')
    let pageType = 'todoList'
    const closeBatchModal = () => {
      batchEditTimeVisible.value = false;
    };
    const refreshTable = () => {
      handleReset()
    };

    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      assignmentStatusOps,
      isPm,
      isPmAuth,
      updateParams,
      handleOnboradDateChange,
      handleReleaseDateChange,
      changeOrgThreeTwo,
      handleResize,
      handleExport,
      queryFormList,
      queryList,
      checkList,
      getPlaceHoder,
      getOptions,
      getSelectChange,
      getPickerChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      isRmLeader,
      moreSeachHanlder,
      batchApplyClick,
      batchEditTimeVisible,
      activeTab,
      actionType,
      role,
      closeBatchModal,
      refreshTable,
      rowAlllist,
      pageType,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  .search {
    text-align: right;
  }
}
</style>
