<template>
  <div ref="emailModalRef">
    <a-modal :getContainer="()=>$refs.emailModalRef" :visible="visible" @cancel="closeModal" width="62.5vw" centered>
      <template #title>
        <strong class="modal-title">
          {{ title ? title : "查看邮件发送记录" }}
        </strong>
      </template>
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">取消</a-button>
        </div>
      </template>
      <a-table :data-source="tableData" :columns="columns" rowKey="id" :scroll="{
      y: tableHeight,
      scrollToFirstRowOnChange: true,
    }" :pagination="false">
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import moment from "moment";

export default defineComponent({
  name: "recordModal",
  props: {
    visible: Boolean,
    title: String,
    assignmentInfo: Object,
  },
  emits: ["close-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const columns = ref([
      {
        title: "操作人",
        dataIndex: "operator",
        ellipsis: true,
        width: 33,
      },
      {
        title: "操作时间",
        dataIndex: "operationTime",
        ellipsis: true,
        width: 40,
      },
      {
        title: "Release 邮件发送时间",
        dataIndex: "releaseSendmailDate",
        ellipsis: true,
        width: 40,
      },
    ]);
    const tableData = ref([]);
    const getTableData = () => {
      // 清除上一次数据
      tableData.value = []
      const params = {
        assignmentId: props.assignmentInfo.id,
      };
      $api.getqueryMailRecord(params).then((res) => {
        const data = res.data
        data.forEach(item => {
          item.operationTime = getTime(item.operationTime)
        });
        console.log(data);
        tableData.value = data;
      });
    };
    watch(
      () => props.visible,
      (val) => {
        if (val) {
          getTableData();
        }
      }
    );

    const closeModal = () => {
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };
    //处理时间格式
    const getTime = (value) => {
      let time = moment(value).format('YYYY-MM-DD HH:mm:ss')
      return time
    }
    const tableHeight = ref(0);

    onMounted(() => {
      nextTick(() => {
        const clientHeight = document.body.clientHeight;
        tableHeight.value = (clientHeight * 50) / 100;
      });
    });

    return {
      columns,
      tableData,
      getRefsAff,
      closeModal,
      tableHeight,
      getTime
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
::v-deep .ant-table-thead>tr>th {
  background-color: #fafafa !important;
}

::v-deep .ant-table-body {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-table-thead > tr > th) {
  background-color: #e9f4ff;
}

.ant-table-tbody {

  >tr:hover:not(.ant-table-expanded-row)>td,
  .ant-table-row-hover,
  .ant-table-row-hover>td {
    background: #e9f4ff;
  }
}

.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

.ant-table.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td {
  border-bottom: #d0e8ff 1px solid;
}

.ant-table-striped ::v-deep(.table-striped) td {
  background-color: #fefeff;
}

.editable-row-operations a {
  margin-right: 8px;
}

.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

.ant-calendar-picker {
  width: 100%;
}
:deep(.ant-modal-footer){
  display: none;
}
</style>
