<template>
  <a-drawer
    :getContainer="getRefsAff"
    :visible="visible"
    @close="closeModal"
    width="83.3vw"
    class="fill-assignment"
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写驳回原因" }}
      </strong>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
    >
      <a-row>
<!--        <a-col :span="24" v-show="true" class="red-text">
          <a-form-item
            label="想要确定？"
            name="confirm"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-button
              type="primary"
              danger
              @click="confirmModal"
              v-auth="['system:prematching:rmReject']"
            >
              返回确定页
            </a-button>
          </a-form-item>
        </a-col>-->
        <a-col :span="6">
          <a-form-item label="Service Line">
            {{ formData.serviceLine }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Sub Service Line">
            {{ formData.subServiceLine }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Candidate Name">
            <a-input v-model:value="formData.candidateName" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Candidate Contact">
            <a-input v-model:value="formData.candidateContact" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Candidate Grade">
            {{ formData.candidateGrade }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="ADRC/人员成本">
            {{ formData.adrc }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Request Account" name="clientName">
            {{ formData.matchType === '2'? formData.pipelineDetailVO.account : formData.demandClientName }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Request Industry" name="industryLine">
            {{ formData.matchType === '2'? formData.pipelineDetailVO.industryLine : formData.demandIndustryLine }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Account">
            <a-input v-model:value="formData.account" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Industry Line" name="industryLine">
            <a-input v-model:value="formData.industryLine" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Engagement Code" name="engagementCode">
            <a-input v-model:value="formData.engagementCode" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Engagement Description">
            <a-input v-model:value="formData.engagementDescription" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Assignment Term" name="assignmentTerm">
            <a-range-picker v-model:value="formData.assignmentTerm" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Code有效期" name="codeTerm">
            {{ codeTerm }}
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Code预计开出时间" name="projectCommitDate">
            <a-input v-model:value="formData.projectCommitDate" disabled> </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Engagement Manager">
            <a-input v-model:value="formData.engagementManager" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6" v-show="isFormalCode">
          <a-form-item label="Leading CSM">
            <a-input v-model:value="formData.leadingCSM" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Work City" name="workCity">
            <a-input v-model:value="formData.workCity" disabled> </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="PM">
            <a-input v-model:value="formData.pmInfo" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Work Location">
            <a-input v-model:value="formData.workLocation" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Hours per Day">
            <a-input v-model:value="formData.hoursPerDay" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Copy">
            <a-select
              v-model:value="formData.copyList"
              mode="multiple"
              disabled
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Reject Type"
            name="rejectWay"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-radio-group v-model:value="formData.rejectWay">
              <a-radio value="1">驳回PM</a-radio>
              <a-radio value="2">匹配失效</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Reject Reason"
            name="rejectReason"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-textarea
              v-model:value="formData.rejectReason"
              :rows="5"
              showCount
              :maxlength="70"
              :auto-size="{ minRows: 2, maxRows: 2 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="footerBtn bottom-btn">
      <a-button class="btn-left" type="primary" danger @click="confirmModal" v-auth="['system:prematching:rmReject']">返回确定页</a-button>
      <a-button @click="closeModal">取消</a-button>
      <a-button @click="submitModal" type="primary">确定</a-button>
    </div>
  </a-drawer>
<!--  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    :width="900"
    class="fill-assignment"
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写驳回原因" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal">取消</a-button>
        <a-button @click="submitModal" type="primary">确定</a-button>
      </div>
    </template>
    <a-form
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
    >
      <a-row>
        <a-col :span="24" v-show="true" class="red-text">
          <a-form-item
            label="想要确定？"
            name="confirm"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-button
              type="primary"
              danger
              @click="confirmModal"
              v-auth="['system:prematching:rmReject']"
            >
              返回确定页
            </a-button>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Service Line">
            {{ formData.serviceLine }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Sub Service Line">
            {{ formData.subServiceLine }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Candidate Name">
            <a-input v-model:value="formData.candidateName" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Candidate Contact">
            <a-input v-model:value="formData.candidateContact" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Candidate Grade">
            {{ formData.candidateGrade }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="ADRC/人员成本">
            {{ formData.adrc }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Request Account" name="clientName">
            {{ formData.demandClientName }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Request Industry" name="industryLine">
            {{ formData.demandIndustryLine }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Account">
            <a-input v-model:value="formData.account" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Industry Line" name="industryLine">
            <a-input v-model:value="formData.industryLine" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Engagement Code" name="engagementCode">
            <a-input v-model:value="formData.engagementCode" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Engagement Description">
            <a-input v-model:value="formData.engagementDescription" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Assignment Term" name="assignmentTerm">
            <a-range-picker v-model:value="formData.assignmentTerm" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Code有效期" name="codeTerm">
            {{ codeTerm }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Code预计开出时间" name="projectCommitDate">
            <a-input v-model:value="formData.projectCommitDate" disabled> </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Engagement Manager">
            <a-input v-model:value="formData.engagementManager" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12" v-show="isFormalCode">
          <a-form-item label="Leading CSM">
            <a-input v-model:value="formData.leadingCSM" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Work City" name="workCity">
            <a-input v-model:value="formData.workCity" disabled> </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="PM">
            <a-input v-model:value="formData.pmInfo" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Work Location">
            <a-input v-model:value="formData.workLocation" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Hours per Day">
            <a-input v-model:value="formData.hoursPerDay" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Copy">
            <a-select
              v-model:value="formData.copyList"
              mode="multiple"
              disabled
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Reject Type"
            name="rejectWay"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-radio-group v-model:value="formData.rejectWay">
              <a-radio value="1">驳回PM</a-radio>
              <a-radio value="2">匹配失效</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="Reject Reason"
            name="rejectReason"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-textarea
              v-model:value="formData.rejectReason"
              :rows="5"
              showCount
              :maxlength="70"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>-->
<!--  <a-modal :visible="noLoginvisible" @cancel="noLoginCancel" title="填写调令" :width="'20vw'">-->
  <div ref="rejectModalRef">
    <a-modal
        :getContainer="()=>$refs.rejectModalRef"
        :visible="noLoginvisible"
        @cancel="noLoginCancel"
        title="填写调令"
        :width="'20vw'"
        :z-index="1001"
        centered
    >
      <div>
        <div style="margin-bottom: 10px">请选择操作流程</div>
        <div>
          <a-radio-group v-model:value="noLoginValue">
            <a-radio style="display: block" :value="1">常规流程</a-radio>
            <a-radio style="display: block" :value="2">免登录链接流程</a-radio>
          </a-radio-group>
        </div>
      </div>
      <template #footer>
        <div class="footerBtn bottom-btn">
          <a-button @click="noLoginCancel">取消</a-button>
          <a-button @click="noLoginConfirm" type="primary">确认</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import {formatCodeTerm} from "@/utils/common";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "rejectModal",
  props: {
    visible: Boolean,
    title: String,
    formState: {
      type: Object,
      default: () => ({
        rejectReason: "",
      }),
    },
    rejectNoLoginState:String,
  },
  emits: ["close-modal", "submit-modal", "open-confirm-modal", "nologin-submit-modal"],
  setup(props, ctx) {
    const formData = computed(() => props.formState);
    const formRef = ref();
    const {$api} = useGlobalPropertyHook();
    const formRules = {
      rejectWay: [
        {
          required: true,
          message: "Please input Reject Type",
          trigger: "change",
        },
      ],
      rejectReason: [
        {
          required: true,
          message: "Please input Reject Reason",
          trigger: "blur",
        },
      ],
    };

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        if(formData.value.rejectWay === '2'){
          ctx.emit("submit-modal");
        } else {
          noLoginvisible.value = true
        }
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const confirmModal = () => {
      ctx.emit("open-confirm-modal",formData.value);
    };
    const codeTerm = ref("")
    const isFormalCode = ref(false)

    const noLoginCancel = () => {
      noLoginvisible.value = false
      noLoginValue.value = 1
    }
    const noLoginValue = ref(1);
    const noLoginvisible = ref(false)
    const noLoginConfirm = () => {
      if(noLoginValue.value === 1){
        ctx.emit("submit-modal");
        noLoginCancel()
      } else {
        noLoginValue.value = 1
        ctx.emit("nologin-submit-modal");
        noLoginCancel()
      }
    }

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            console.log('hhaha',val)
            codeTerm.value = formatCodeTerm(formData.value.codeStartDate, formData.value.codeEndDate)
            $api.checkFormalCode({engagementCode : formData.value.engagementCode}).then((res) => {
               isFormalCode.value = res.data
            });
          }
        }
    )
    return {
      labelCol: { span: 22 },
      wrapperCol: { span: 22 },
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      getRefsAff,
      confirmModal,
      codeTerm,
      isFormalCode,
      noLoginValue,
      noLoginvisible,
      noLoginCancel,
      noLoginConfirm,
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

/*.red-text {
  :deep(.ant-form-item-label) {
    > label {
      color: #ff4d4f;
    }
  }

  :deep(.ant-form-item-control-input) {
    color: #ff4d4f;
  }
  :deep(.ant-btn-primary:not(.ant-btn-sm)) {
    &:hover,
    &:focus,
    &:active {
      background:  #ff4d4f;
      border-color:  #ff4d4f;
    }
  }
}*/
:deep(.ant-btn-primary:not(.ant-btn-sm)) {
  &:hover,
  &:focus,
  &:active {
    background:  #ff4d4f;
    border-color:  #ff4d4f;
  }
}
.ant-calendar-picker {
  width: 100%;
}
</style>
