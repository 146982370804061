<template>
  <div class="batch-edit-modal" ref="batchEditModalRef">
    <a-drawer
      :visible="visible"
      @close="closeModal"
      width="86.4vw"
      title="批量修改 Assignment 时间"
      :getContainer="() => $refs.batchEditModalRef"
    >
      <a-form :model="dataSource" class="newHoursPerDayClass" ref="formRef">
      <a-table
        class="assignment-list mt24"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        row-key="id"
        :row-selection="{
          selectedRowKeys: selectedAssignmentIdList,
          selectedRows: selectedAssignmentList,
          onChange: selectedChange,
        }"
        :scroll="{
          x: 2200,
        scrollToFirstRowOnChange: true,
      }"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        :expandedRowKeys="curExpandedRowKeys"
      >
        <template #newRange="{ index, text, record }">
<!--          <TimeOverlapTip-->
<!--            :record="record"-->
<!--            :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"-->
<!--          />-->
          
          <div style="position: relative">
          <a-row>
            <a-col :span="1">
              <span :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"><span :class="`status-icon ${record.detailStatus}`" @click="isTimeClick(record,index)">●</span></span>
            </a-col>
            <a-col :span="11">
              <a-date-picker
                  :value="text[0]"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @update:value="(val) => (dataSource[index].newRange[0] = val)"
                  :disabled-date="current => onBoardDisabledDate(current, record)"
                  @change="onBoardDateChange(record, index)"
                  style="width: 100%"
                  :allowClear="false"
                  :disabled="assignmentStatus === '5' ||
                  assignmentStatus === '6' ||
                  assignmentStatus === '7' ||
                  assignmentStatus === '8' ||
                  curExpandedRowKeys.includes(record.id)"
              />
            </a-col>
            <a-col :span="1">
              <span> ~ </span>
            </a-col>
            <a-col :span="11">
              <a-date-picker
                  :value="text[1]"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @change="actualReleaseDateChange(record, index)"
                  @update:value="(val) => (dataSource[index].newRange[1] = val)"
                  :disabled-date="current => estimateDisabledDate(current, record)"
                  style="width: 100%"
                  :allowClear="false"
                  :disabled="curExpandedRowKeys.includes(record.id)"
          />
            </a-col>
          </a-row>
          <div v-show="record.isShowData === '0'" style="color:#DC5E4F;position: absolute;font-size: 0.725vw">
            Assignment Term与Code有效期冲突,请确定是否继续
          </div>
          </div>
        </template>
        <template #projectCommitDate="{ index, text, record }">
          <a-date-picker
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :value="text"
              @update:value="(val) => (dataSource[index].projectCommitDate = val)"
              :disabledDate="current => disabledCommitDate(current, record)"
              :placeholder="placeHolder"
              :disabled="isDisableCommitDate"
              @openChange ="getProjectCommitDateRange(record)"
          />
        </template>
        <template #reason="{ index, text }">
          <a-textarea
            :value="text"
            @update:value="(val) => (dataSource[index].reason = val)"
            placeholder="请输入"
            :rows="3"
            :maxlength="65"
            showCount
            :auto-size="{ minRows: 3, maxRows: 3 }"
            @keyup.enter="(e) => { e.stopPropagation() }"
          />
        </template>

        <template #AssignmentTerm="{ text, record }">
          <div v-if="record.oldStaffAssignmentParts.length === '0'">
            {{text}}
          </div>
          <div v-else>
            <div>
              <span style="color: #1890FF">●</span>{{text}}
            </div>
            <div v-for="(item) in record.oldStaffAssignmentParts" :key="item.id">
              <span v-if="item.startDate">
              <span style="color: #77CAED">●</span><span style="color: #999999">{{item.startDate + '~' + item.endDate }}</span>
              </span>
            </div>
          </div>
        </template>
        <template #hoursPerDay="{ text, record }">
          <div v-if="record.oldStaffAssignmentParts.length === '0'">
            {{text}}
          </div>
          <div v-else>
            <div>
              {{text}}
            </div>
            <div v-for="(item) in record.oldStaffAssignmentParts" :key="item.id">
              <span style="color: #999999">{{item.hoursPerDay}}</span>
            </div>
          </div>
        </template>
        <template #newHoursPerDay="{ index, text, record }">
          <div v-if="!curExpandedRowKeys.includes(record.id)" style="margin-top: 1.25vw">
              <a-form-item :name="[index, 'newHoursPerDay']"
                           :rules="[{
                                 required: true,
                                 message: 'Please input Hours Per Day',
                                 trigger: 'change',
                                 },
                                 {
                                  pattern: /^[0-7]\.[1-9]$|^[1-8]$/,
                                  message: 'Please input the correct format Hours Per Day (>0 & <=8)',
                                  trigger: 'change',
                                  },
                             ]"
              >
                <a-input
                    style="width: 96%"
                    :value="text"
                    @update:value="(val) => (dataSource[index].newHoursPerDay = val)"
                    placeholder="请输入"
                    @blur="newHoursPerDayChange(record)"
                />
              </a-form-item>
          </div>
          <div v-else>
            {{text}}
          </div>
        </template>
        <template #RowRender="{ index,record }">
          <span @click="segmentedEditingClick(index,record)" style="cursor: pointer;"><img src="@/assets/NoLogin/segmente.png" alt=""/></span>
        </template>
        <template #expandedRowRender="{ record }">
          <a-form :model="record.staffAssignmentParts" ref="subFormRef" class="segmentedtaleclass">
          <a-table
              class="segmentedClass"
              :columns="staffcolumns"
              :data-source="record.staffAssignmentParts"
              :pagination="false"
              row-key="id"
              :scroll="{
                  x: 2000
              }"
              :showHeader="false"
              :row-selection="{
                columnTitle: ' ',
              }"
          >
            <template #staffNewRange="{ index }">
              <div style="position: relative">
              <a-row>
                <a-col :span="1">
                  <span :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"><span :class="`status-icon ${record.staffAssignmentParts[index].detailStatus}`" @click="segmentedTimeClick(record,index)">●</span></span>
                </a-col>
                <a-col :span="11">
                  <a-form-item :name="[index,'startDate']"
                               :rules="[
                                   {
                                      validator: startValidatorSpace,
                                      startDateIndex: index,
                                      startRecord: record,
                                      trigger: 'change',
                                   }
                      ]"
                  >
                  <a-date-picker
                      :value="record.staffAssignmentParts[index].startDate"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                      @update:value="(val) => (record.staffAssignmentParts[index].startDate = val)"
                      @change="startDateChange(index,record)"
                      :disabled-date="current => segmenteOnBoardDisabledDate(current, record,index)"

                      :allowClear="false"
                      :disabled="index === 0 ? (assignmentStatus === '5' ||
                  assignmentStatus === '6' ||
                  assignmentStatus === '7' ||
                  assignmentStatus === '8'): false"
                  />
                  </a-form-item>
                </a-col>
                <a-col :span="1">
                  <span> ~ </span>
                </a-col>
                <a-col :span="11">
                  <a-form-item :name="[index,'endDate']"
                               :rules="[
                                   {
                                      validator: endValidatorSpace,
                                      endDateIndex: index,
                                      endRecord: record,
                                      trigger: 'change',
                                   }]"
                  >
                  <a-date-picker
                      :value="record.staffAssignmentParts[index].endDate"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                      @update:value="(val) => (record.staffAssignmentParts[index].endDate = val)"
                      @change="endDateChange(index,record)"
                      :disabled-date="current => segmenteEstimateReleaseDate(current, record, index)"

                      :allowClear="false"
                  />
                  </a-form-item>
                </a-col>
              </a-row>
              <div v-show="record.staffAssignmentParts[index].isShowData === '0'" style="color:#DC5E4F;position: absolute;top:2.25vw;font-size: 0.725vw">
                Assignment Term与Code有效期冲突,请确定是否继续
              </div>
              </div>
            </template>

            <template #staffHoursPerDay="{ index, text }">
              <div>
              <a-form-item :name="[index, 'hoursPerDay']"
                           :rules="[
                                 {
                                  pattern: /^[0-7]\.[0-9]$|^[0-8]$/,
                                  message: 'Please input the correct format Hours Per Day (>=0 & <=8)',
                                  trigger: 'blur',
                                  },
                                  {
                                  validator: hoursValidatorSpace,
                                  hoursIndex: index,
                                  hoursRecord: record,
                                  trigger: 'blur',
                               }
                             ]"
              >
                <a-input
                    style="width: 96%"
                    :value="text"
                    @update:value="(val) => (record.staffAssignmentParts[index].hoursPerDay = val)"
                    placeholder="请输入"
                    @change="hoursPerDayChange(record)"
                    @blur="hoursPerDayBlur(record, index)"
                />
              </a-form-item>
              </div>
            </template>

            <template #action="{ index }">
              <div v-if="index === record.staffAssignmentParts.length - 1" style="font-size: 1vw;margin-bottom: 1.25vw">
                  <span @click.stop="segmentedAdd(index,record)">
                    <PlusOutlined style="color: #3182CE;cursor: pointer;"/>
                  </span>
                <a-divider type="vertical" style="background-color: #EBEBEB" />
                <span @click.stop="segmentedDel(index,record)" :class="record.staffAssignmentParts.length > 2 ? 'operationLink underline' : 'operationLink disabled underline'">
                    <DeleteOutlined :style="`color: ${ record.staffAssignmentParts.length > 2 ? '#DC5E4F' : '#999999'};cursor: pointer`"/>
                  </span>
              </div>
            </template>
          </a-table>
          </a-form>
        </template>
        <template #extensionAssumptionDate="{ index, text, record }">
          <div style="display: flex;margin-top: 1.25vw;">
            <span :class="selectedAssignmentIdList.indexOf(record.id) === -1 ? 'overlap-hidden' : ''"><span :class="`status-icon ${record.detailStatus}`" @click="fcstTimeClick(record,index)">●</span></span>
              <a-form-item :name="[index, 'extensionAssumptionDate']"
                           :rules="[{ validator: fcstValidatorSpace,fcstRecord: record, trigger: 'blur'}]">
                <a-date-picker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    :value="text"
                    @update:value="(val) => (dataSource[index].extensionAssumptionDate = val)"
                    :disabledDate="current => disabledExtensionAssumptionDate(current, record)"
                    placeholder="请输入"
                    @change="extensionChabge(record,index)"
                />
              </a-form-item>
          </div>
        </template>
        <template #certainty="{ index, text }">
          <div style="margin-top: 1.5vw">
              <a-form-item :name="[index, 'certainty']"
                           :rules="[{required: dataSource[index].extensionAssumptionDate,message: 'Please select 确定性',trigger: 'change',}]">
                <a-radio-group
                    :value="text"
                    @update:value="(val) => (dataSource[index].certainty = val)"
                    :disabled="!dataSource[index].extensionAssumptionDate"
                >
                  <a-radio value="Firm">Firm</a-radio>
                  <a-radio value="Proposal">Proposal</a-radio>
                </a-radio-group>
              </a-form-item>
          </div>
        </template>
      </a-table>
      </a-form>
      <div class="btn-row bottom-btn">
        <div class="left"></div>
        <div class="right">
          <a-space>
            <a-button type="primary" ghost @click="closeModal">取消</a-button>
            <a-button type="primary" @click="nextClick" :disabled="nextDisabled"
            >下一步
            </a-button>
          </a-space>
        </div>
      </div>
      <BatchEditAssignmentTimeConfirmDrawer
        v-model:visible="saveVisible"
        :isTBDCode="isTBDCode"
        :list="selectedAssignmentList"
        :get-container-ref="$refs.batchEditModalRef"
        @cancel-modal="saveCancel"
        @prev-click="pervClick"
        @refresh-table="refreshTable"
      />
    </a-drawer>
  </div>
  <OverlapModal
      :data="overlapData"
      @confirm="timeJudgmentSubmitFunc"
      v-model:visible="overlapModalVisible"
  />
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import moment from "moment";
import BatchEditAssignmentTimeConfirmDrawer from "./BatchEditAssignmentTimeConfirmDrawer";
import { computed } from "@vue/runtime-core";
import { message } from "ant-design-vue";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons-vue";
import OverlapModal from "@/components/OverlapModal.vue";

export default defineComponent({
  name: "BatchEditAssignmentTimeDrawer",
  props: {
    visible: Boolean,
    list: Array,
    codeTermData: {
      type: Object,
      default: () => ({
        projectStartDate: '',
        projectCloseDate: '',
      }),
    },
    isFormalCode: {
      type: Boolean,
      default: false,
    },
    orgInfo: Object,
  },
  emits: ["close-modal", "refresh-table"],
  components: {OverlapModal, DeleteOutlined, PlusOutlined, BatchEditAssignmentTimeConfirmDrawer },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const dataSource = ref([]);
    const codeTerm = computed(() => props.codeTermData);
    const selectedAssignmentIdList = ref([]);
    const selectedAssignmentList = computed(() =>
      dataSource.value.filter(({ id }) =>
        selectedAssignmentIdList.value.includes(id)
      )
    );
    const assignmentIdMap = ref({});
    const columns = computed(() => {
      return [
        {
          title: "人名",
          dataIndex: "fullName",
          key: "fullName",
          width: 120,
          ellipsis: true
        },
        {
          title: "GGID",
          dataIndex: "ggid",
          key: "ggid",
          width: 100,
          ellipsis: true
        },
        {
          title: "Assignment Term",
          dataIndex: "range",
          key: "range",
          width: 160,
          slots: { customRender: "AssignmentTerm" }
        },
        {
          title: "Hours Per Day",
          dataIndex: "hoursPerDay",
          key: "hoursPerDay",
          width: 120,
          ellipsis: true,
          slots: { customRender: "hoursPerDay" }
        },
        {
          title: "Code有效期",
          dataIndex: "getCodeTerm",
          key: "getCodeTerm",
          width: 160
        },
        {
          title: "修改Assignment Term",
          dataIndex: "newRange",
          key: "newRange",
          width: 300,
          slots: { customRender: "newRange" }
        },
        {
          title: "修改Hours Per Day",
          dataIndex: "newHoursPerDay",
          key: "newHoursPerDay",
          width: 180,
          slots: { customRender: "newHoursPerDay" }
        },
        {
          title: " ",
          dataIndex: "",
          key: "",
          width: 100,
          slots: { customRender: "RowRender" }
        },
        {
          title: "Code预计开出时间",
          dataIndex: "projectCommitDate",
          key: "projectCommitDate",
          width: 140,
          slots: { customRender: "projectCommitDate" }
        },
        {
          title: <div class="fcstLabel"><div class="aboveTitle">FCST Extension Assumption Date</div><div class="tipLabel">不会更新调令的结束时间</div></div>,
          //title: "FCST Extension Assumption Date",
          dataIndex: "extensionAssumptionDate",
          key: "extensionAssumptionDate",
          width: 220,
          slots: { customRender: "extensionAssumptionDate" }
        },
        {
          title: "确定性",
          dataIndex: "certainty",
          key: "certainty",
          width: 150,
          slots: { customRender: "certainty" }
        },
        {
          title: "备注",
          dataIndex: "reason",
          key: "reason",
          width: 140,
          slots: { customRender: "reason" }
        }
      ];
    });

    const getIsShow = () => {
      const params = JSON.parse(JSON.stringify(dataSource.value)).map(item => {
        return {
          onBoardDate: item.newRange[0],
          estimateDate: item.newRange[1],
          projectStartDate: item.codeStartDate || "",
          projectCloseDate:item.codeEndDate || "",
          actualDate: item.actualDate,
        }
      })
      $api.checkCodeAndAssignTerm(params).then((res) => {
        res.data.forEach((v,i) => {
          dataSource.value[i].isShowData = v;
        })
      });
    }
    //判断分段数据 Assignment Term与Code有效期冲突
    const getTermAndCodeConflict = (record,index) => {
      const params = [{
        onBoardDate: record.staffAssignmentParts[index].startDate,
        estimateDate: record.staffAssignmentParts[index].endDate,
        projectStartDate: record.codeStartDate || "",
        projectCloseDate:record.codeEndDate || "",
      }]
      $api.checkCodeAndAssignTerm(params).then((res) => {
        let i = dataSource.value.map(item =>item.id).indexOf(record.id)
        dataSource.value[i].staffAssignmentParts[index].isShowData = res.data.toString();
      });
    }
    const getIsShowConflict = () => {
      dataSource.value.forEach((item,index) => {
        if(item.staffAssignmentParts.length > 0){
          const params = item.staffAssignmentParts.map(v => {
            return {
              onBoardDate: v.startDate,
              estimateDate: v.endDate,
              projectStartDate: item.codeStartDate || "",
              projectCloseDate:item.codeEndDate || "",
            }
          })
          $api.checkCodeAndAssignTerm(params).then((res) => {
            res.data.forEach((v,i) => {
              dataSource.value[index].staffAssignmentParts[i].isShowData = v;
            })
          });
        }
      })
    }

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          selectedAssignmentIdList.value = [];
          assignmentIdMap.value = {};
          dataSource.value = props.list.map((item, index) => {
            selectedAssignmentIdList.value.push(item.id);
            assignmentIdMap.value[item.id] = index;
            return {
              ...item,
              reason: item.reason || "",
              range: `${item.onBoardDate || ""}~${
                item.actualReleaseDate || ""
              }`,
              newOnBoardDate: item.newOnBoardDate || item.onBoardDate,
              /*newEstimateReleaseDate:
                item.newEstimateReleaseDate || item.estimateReleaseDate,*/
              newActualReleaseDate: item.newActualReleaseDate || item.actualReleaseDate,
              newRange: [
                item.newOnBoardDate || item.onBoardDate,
                item.newActualReleaseDate || item.actualReleaseDate
              ],
              projectCommitDate:item.projectCommitDate || "",
              detailStatus: "false",
              staffAssignments: [],
              staffWorkTasks: [],
              getCodeTerm: `${item.codeStartDate || ""}~${
                  item.codeEndDate || ""
              }`,
              isShowData:'',
              newHoursPerDay: item.hoursPerDay,
              oldStaffAssignmentParts: item.staffAssignmentParts.map(item => {
                return {
                  hoursPerDay: item.hoursPerDay,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  id: item.id
                }
              }),
              staffAssignmentParts: item.staffAssignmentParts.map(item => {
                return {
                  staffNewRange: [item.startDate,item.endDate],
                  hoursPerDay: item.hoursPerDay,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  id: item.id,

                }
              }).filter(item => item),
              oldExtensionAssumptionDate: item.extensionAssumptionDate,
              oldCertainty: item.certainty,
            }
          });
          dataSource.value.forEach(item => {
            if(item.staffAssignmentParts.length > 0){
              curExpandedRowKeys.value.push(item.id)
            }
          })
          batchOverlap();
          //batchTimeOverlap()
          getIsShow();
          getIsShowConflict()
          handleIsFormalCode();
        }
      }
    );
    // const batchOverlap = () => {
    //   $api.batchOverlap(selectedAssignmentList.value).then((res) => {
    //     (res?.data || []).forEach(({ id, detailStatus, staffAssignments, staffWorkTasks,  }) => {
    //       let index = assignmentIdMap.value[id];
    //       dataSource.value[index].detailStatus = detailStatus;
    //       dataSource.value[index].staffAssignments = staffAssignments || [];
    //       dataSource.value[index].staffWorkTasks = staffWorkTasks || [];
    //     })
    //   });
    // }
    const batchOverlap = () => {
      let params = selectedAssignmentList.value.map(item => {
        let staffAssignment = item.staffAssignmentParts
        if(item.staffAssignmentParts.length > 0) {
          staffAssignment = item.staffAssignmentParts.filter(item => item.startDate && item.endDate && item.hoursPerDay)
        }
        return {
          ...item,
          staffAssignmentParts: staffAssignment,
          onBoardDate:item.newOnBoardDate,
          actualReleaseDate:item.newActualReleaseDate,
          hoursPerDay:item.newHoursPerDay
        }
      })
      $api.batchOverlap(params).then((res) => {
        (res?.data || []).forEach(({ id, gatherFlag, overlapCommandList}) => {
          let index = assignmentIdMap.value[id];
          dataSource.value[index].detailStatus = gatherFlag;
          if(dataSource.value[index].staffAssignmentParts.length > 0){
            dataSource.value[index].staffAssignmentParts = dataSource.value[index].staffAssignmentParts.map(it => {
              let detailStatus = 'false'
              if(it.startDate && it.endDate && it.hoursPerDay) {
                let arr = overlapCommandList?.filter(i => i.num !== 0).filter(i => (moment(it.startDate).isBefore(i.startDate) || moment(it.startDate).isSame(i.startDate)) &&
                    (moment(it.endDate).isAfter(i.endDate) || moment(it.endDate).isSame(i.endDate))).map(i => i.sumHoursPerDay)
                if (arr.length > 0) {
                  // arr.forEach(i => {
                  //   detailStatus = i > 8 ? 'prohibit' : 'true'
                  // })
                  detailStatus = arr.some(item => item > 8) ? 'prohibit' : 'true'
                }
              }
              return {
                ...it,
                detailStatus: detailStatus
              }
            })
          }
        })
      });
    }
    const batchOverlapClick = (type,record,index) => {
      let params = selectedAssignmentList.value.map(item => {
        let staffAssignment = item.staffAssignmentParts
        if(item.staffAssignmentParts.length > 0) {
          staffAssignment = item.staffAssignmentParts.filter(item => item.startDate && item.endDate && item.hoursPerDay)
        }
        return {
          ...item,
          staffAssignmentParts: staffAssignment,
          onBoardDate:item.newOnBoardDate,
          actualReleaseDate:item.newActualReleaseDate,
          hoursPerDay:item.newHoursPerDay
        }
      })
      currentOverlapData.value = {
        clientName:record.account,
        engagementCode: record.engagementCode,
        engagementDescription: record.engagementDescription,
      }
      $api.batchOverlap(params).then((res) => {
        if(type === 'time'){
          (res?.data || []).forEach(item => {
            if(item.id === record.id) {
              overlapData.value = {
                status: item.gatherFlag,
                assignmentList: item.assignmentQueryPartCommandList || [],
                workTaskList: item.workTaskList || [],
                overlapCommandList: item.overlapCommandList || [],
                currentformData: currentOverlapData.value,
                demandList: item.demandList || [],
              }
            }
          })
        } else {
          let i = res.data.map(item =>item.id).indexOf(record.id)
          let indexa = selectedAssignmentList.value.map(item =>item.id).indexOf(record.id);
          let staffAssignment = selectedAssignmentList.value[indexa].staffAssignmentParts[index]
          let overlapCommandList = res.data[i].overlapCommandList.filter(i => i.num !== 0).filter(i => (moment(staffAssignment.startDate).isBefore(i.startDate) || moment(staffAssignment.startDate).isSame(i.startDate)) &&
              (moment(staffAssignment.endDate).isAfter(i.endDate) || moment(staffAssignment.endDate).isSame(i.endDate)))
          let workTaskList = res.data[i].workTaskList.filter(i => (moment(staffAssignment.startDate).isBefore(i.beginDate) || moment(staffAssignment.startDate).isSame(i.beginDate)) ||
              (moment(staffAssignment.endDate).isAfter(i.endDate) || moment(staffAssignment.endDate).isSame(i.endDate)))
          let assignmentQueryPartCommandList = res.data[i].assignmentQueryPartCommandList.filter(i => (moment(staffAssignment.startDate).isBefore(i.on_board_date) || moment(staffAssignment.startDate).isSame(i.on_board_date)) ||
              (moment(staffAssignment.endDate).isAfter(i.actual_release_date) || moment(staffAssignment.endDate).isSame(i.actual_release_date)))
          let demandList = res.data[i].demandList
          overlapData.value = {
            status: staffAssignment.detailStatus,
            assignmentList: assignmentQueryPartCommandList || [],
            workTaskList: workTaskList || [],
            overlapCommandList: overlapCommandList || [],
            currentformData: currentOverlapData.value,
            demandList: demandList || [],
          }
        }
      });
    }

    // const isTimeOverlap = (type,record,index) => {
    //   const staffAssignment = {
    //     id: record.id,
    //     hoursPerDay: record.newHoursPerDay,
    //     actualReleaseDate: moment(record.newRange[1]).format("YYYY-MM-DD"),
    //     onBoardDate: moment(record.newRange[0]).format("YYYY-MM-DD"),
    //     userId: record.userId,
    //     staffAssignmentParts: type === 'time'? record.staffAssignmentParts : [record.staffAssignmentParts[index]]
    //   };
    //   currentOverlapData.value = {
    //     clientName:record.account,
    //     engagementCode: record.engagementCode,
    //     engagementDescription: record.engagementDescription,
    //   }
    //   $api.isTimeJudgments(staffAssignment).then((res) => {
    //     let i = assignmentIdMap.value[res.data.id];
    //     if(type === 'time') {
    //       dataSource.value[i].detailStatus = res.data.gatherFlag;
    //     } else {
    //       dataSource.value[i].staffAssignmentParts[index].detailStatus = res.data.gatherFlag;
    //     }
    //
    //     overlapData.value = {
    //       status: res.data.gatherFlag,
    //       assignmentList: res.data.assignmentQueryPartCommandList || [],
    //       workTaskList: res.data.workTaskList || [],
    //       overlapCommandList: res.data.overlapCommandList || [],
    //       currentformData: currentOverlapData.value
    //     }
    //   });
    // }
    //红绿灯判断
    // const batchTimeOverlap = () => {
    //    selectedAssignmentList.value.forEach((item) => {
    //     const staffAssignment = {
    //       id: item.id,
    //       hoursPerDay: item.newHoursPerDay,
    //       actualReleaseDate: moment(item.newRange[1]).format("YYYY-MM-DD"),
    //       onBoardDate: moment(item.newRange[0]).format("YYYY-MM-DD"),
    //       userId: item.userId,
    //       staffAssignmentParts: item.staffAssignmentParts
    //     };
    //     currentOverlapData.value = {
    //       clientName:item.account,
    //       engagementCode: item.engagementCode,
    //       engagementDescription: item.engagementDescription,
    //     }
    //     $api.isTimeJudgments(staffAssignment).then((res) => {
    //       let i = assignmentIdMap.value[res.data.id];
    //       dataSource.value[i].detailStatus = res.data.gatherFlag;
    //
    //       if(dataSource.value[i].staffAssignmentParts.length > 0){
    //         dataSource.value[i].staffAssignmentParts = dataSource.value[i].staffAssignmentParts.map(it => {
    //           let detailStatus = 'false'
    //           let arr = res.data.overlapCommandList?.filter(i => i.num !== 0).filter(i => (moment(it.startDate).isBefore(i.startDate) || moment(it.startDate).isSame(i.startDate)) &&
    //               (moment(it.endDate).isAfter(i.endDate) || moment(it.endDate).isSame(i.endDate))).map(i => i.sumHoursPerDay)
    //           if(arr.length > 0) {
    //             arr.forEach(i => {
    //               detailStatus = i > 8? 'prohibit' : 'true'
    //             })
    //           }
    //           return {
    //             ...it,
    //             detailStatus: detailStatus
    //           }
    //         })
    //       }
    //     });
    //   })
    // }

    const assignmentStatus = computed(() => props.orgInfo.status);
    const onBoardDateChange = (record, index) => {
      if(moment(record.newRange[0])) dataSource.value[index].projectCommitDate = ''
      dataSource.value.forEach(item => {
        item.newActualReleaseDate = item.newRange[1]
        item.newOnBoardDate = item.newRange[0]
      })
      hoursPerDayRe(record.newHoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && record.newHoursPerDay && hoursPerDayTime.value) batchOverlap();
      getIsShow();
    }
    const actualReleaseDateChange = (record, index) => {
      if (moment(record.newRange[1]) <= moment()) {
        dataSource.value[index].newRange[1] = '';
        message.error("下项目时间不能早于当天");
        return;
      }
      dataSource.value.forEach(item => {
        item.newActualReleaseDate = item.newRange[1]
        item.newOnBoardDate = item.newRange[0]
      })
      hoursPerDayRe(record.newHoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && record.newHoursPerDay && hoursPerDayTime.value) batchOverlap();
      getIsShow();
    }
    const newHoursPerDayChange = (record) => {
      hoursPerDayRe(record.newHoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && hoursPerDayTime.value) batchOverlap();
    }


    const selectedChange = (selectedIdList, selectedList) => {
      selectedAssignmentIdList.value = selectedIdList;
      selectedAssignmentList.value = selectedList;
      batchOverlap();
     // batchTimeOverlap()
    };

    // 表格内已勾选数据全部填写才能进行下一步
    const nextDisabled = computed(() =>
      selectedAssignmentList.value.length === 0 ||
      selectedAssignmentList.value.some(
        ({
           newRange,
           newOnBoardDate,
           newActualReleaseDate,
           projectCommitDate,
           reason,
           detailStatus,
           id,
           staffAssignmentParts,
         }) =>
          newRange.length === 0 ||
          !newOnBoardDate ||
          !newActualReleaseDate ||
          (isTBDCode.value === false ? projectCommitDate : !projectCommitDate) ||
          !reason ||
          detailStatus === "prohibit" ||
          (curExpandedRowKeys.value.includes(id) ? staffAssignmentParts.some(
              ({startDate,endDate,hoursPerDay}) =>
                  !hoursPerDay ||
                  !startDate ||
                  !endDate
          ) : false)
      )
    );

    const closeModal = () => {
      selectedAssignmentIdList.value = [];
      selectedAssignmentList.value = [];
      dataSource.value = []
      curExpandedRowKeys.value = []
      emit("close-modal");
    };
    const saveVisible = ref(false);
    const saveCancel = () => {
      saveVisible.value = false;
      closeModal();
    };
    const nextClick = () => {
      formRef.value.validate().then(() =>{
        if(dataSource.value.some(item => item.staffAssignmentParts?.length > 0)) {
          let fcstDateValidate = dataSource.value.every(item => {
            let validate = true
            if(item.extensionAssumptionDate && item.staffAssignmentParts.length > 0) {
              const diffStart = moment(item.newRange[0]).diff(moment(item.extensionAssumptionDate), 'days') < 0;
              const diffEnd = moment(item.newRange[1]).diff(moment(item.extensionAssumptionDate), 'days') > 0;
              const diffDtate = diffStart && diffEnd;
              if (diffDtate) {
                validate = false

              }
            }
            return validate
          })
          if(fcstDateValidate) {
            saveVisible.value = true;
          }
        } else {
          saveVisible.value = true;
        }
      })
    };
    const pervClick = () => {
      saveVisible.value = false;
    };

    const refreshTable = () => {
      saveCancel();
      emit("refresh-table");
    };

    const onBoardDisabledDate = (current,record) => {
      return current > moment(record.newRange[1]) ||
          current < moment(record.staffOnboardDate) ||
          current > moment(record.staffTerminationDate);
    };

    const estimateDisabledDate = (current,record) => {
      return current < moment(record.newRange[0]) ||
          current < moment() ||
          current < moment(record.staffOnboardDate) ||
          current > moment(record.staffTerminationDate);
    };
    const isTBDCode = computed(() => props.isFormalCode);
    const isDisableCommitDate = ref(false)
    const placeHolder = computed(() =>  isTBDCode.value === false ? "仅适用于TBD Code" : "请选择");
    const handleIsFormalCode = () => {
      if(isTBDCode.value === false) {
        isDisableCommitDate.value = true
        dataSource.value.forEach(item => {
          item.projectCommitDate = ''
        })
      } else {
        isDisableCommitDate.value = false
      }
    }
    const disabledCommitDate = (current, record) => {
      if(moment().diff(record.newRange[0],'day') <= 0){
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))
            && (moment(current.format("YYYY-MM-DD")) >= moment(projectCommitStartDate.value))) return false
        return true;
      } else {
        if(current
            && (moment(current.format("YYYY-MM-DD")) <= moment(projectCommitEndDate.value))) return false
        return true;
      }
    }
    const projectCommitEndDate = ref("")
    const projectCommitStartDate = ref("")
    const getProjectCommitDateRange = async (record) => {
      if(record.newRange[0].length > 0 && isTBDCode.value === true) {
        const params = {
          onboardDate: record.newRange[0]
        }
        const { data } =await $api.getCommitDateRange(params)
        projectCommitEndDate.value = data.projectCommitEndDate
        projectCommitStartDate.value = data.projectCommitStartDate
      }
    }

    //分段编辑
    const curExpandedRowKeys = ref([])
    const segmentedEditingClick = (i,rowkey) => {
      if (curExpandedRowKeys.value.length > 0) {
        let index = curExpandedRowKeys.value.indexOf(rowkey.id);
        if (index > -1) {
          curExpandedRowKeys.value.splice(index, 1);
          dataSource.value[i].staffAssignmentParts = []
        } else {
          //curExpandedRowKeys.value.splice(0, curExpandedRowKeys.value.length);
          curExpandedRowKeys.value.push(rowkey.id);
          if(assignmentStatus.value === '5' || assignmentStatus.value === '6' || assignmentStatus.value === '7' || assignmentStatus.value === '8'){
            dataSource.value[i].staffAssignmentParts = [
              {
                hoursPerDay: undefined,
                startDate: dataSource.value[i].newRange[0],
                endDate: undefined,
                staffNewRange:[dataSource.value[i].newRange[0],]
              },
              {
                hoursPerDay: undefined,
                startDate: undefined,
                endDate: undefined,
                staffNewRange:[]
              },
            ]
          }else {
            dataSource.value[i].staffAssignmentParts = [
              {
                hoursPerDay: undefined,
                startDate: undefined,
                endDate: undefined,
                staffNewRange:[]
              },
              {
                hoursPerDay: undefined,
                startDate: undefined,
                endDate: undefined,
                staffNewRange:[]
              },
            ]
          }
        }
      } else {
        curExpandedRowKeys.value.push(rowkey.id);
        if(assignmentStatus.value === '5' || assignmentStatus.value === '6' || assignmentStatus.value === '7' || assignmentStatus.value === '8'){
          dataSource.value[i].staffAssignmentParts = [
            {
              hoursPerDay: undefined,
              startDate: dataSource.value[i].newRange[0],
              endDate: undefined,
              staffNewRange:[dataSource.value[i].newRange[0],]
            },
            {
              hoursPerDay: undefined,
              startDate: undefined,
              endDate: undefined,
              staffNewRange:[]
            },
          ]
        }else {
          dataSource.value[i].staffAssignmentParts = [
            {
              hoursPerDay: undefined,
              startDate: undefined,
              endDate: undefined,
              staffNewRange:[]
            },
            {
              hoursPerDay: undefined,
              startDate: undefined,
              endDate: undefined,
              staffNewRange:[]
            },
          ]
        }
      }

    }
    const staffcolumns = [
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   width: 50,
      //   ellipsis: true
      // },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 120,
        ellipsis: true
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 100,
        ellipsis: true
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 160,
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 120,
        ellipsis: true,
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 160
      },
      {
        title: "修改Assignment Term",
        dataIndex: "staffNewRange",
        key: "staffNewRange",
        width: 300,
        slots: { customRender: "staffNewRange" }
      },
      {
        title: "修改Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        width: 180,
        slots: { customRender: "staffHoursPerDay" }
      },
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 100,
        slots: { customRender: "action" },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 140,
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 220,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 150,
        slots: { customRender: "certainty" }
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 140,
      }
    ]
    const formRef = ref();
    const subFormRef = ref();
    const segmentedFormRules = ref({});

    const segmentedAdd = (index,record) => {
      let i = dataSource.value.map(item =>item.id).indexOf(record.id)
      let newRow = {
        hoursPerDay: '',
        startDate: '',
        endDate: '',
        staffNewRange:[]
      }
      if(dataSource.value[i].staffAssignmentParts[dataSource.value[i].staffAssignmentParts.length-1].endDate) {
        let staffnewRow = {
          hoursPerDay: '',
          startDate: moment(record.staffAssignmentParts[index].endDate).add(1, 'days').format('YYYY-MM-DD'),
          endDate: '',
          staffNewRange: [moment(record.staffAssignmentParts[index].endDate).add(1, 'days').format('YYYY-MM-DD'),]
        }
        dataSource.value[i].staffAssignmentParts.push(staffnewRow)
      } else {
        dataSource.value[i].staffAssignmentParts.push(newRow)
      }
      dataSource.value[i].oldStaffAssignmentParts.push(newRow)
    }
    const segmentedDel = (index,record) => {
      let i = dataSource.value.map(item =>item.id).indexOf(record.id)
      dataSource.value[i].staffAssignmentParts.splice(index,1)
      dataSource.value[i].oldStaffAssignmentParts.splice(index,1)
      let arr = record.staffAssignmentParts.map(item => item.hoursPerDay).filter(item => item)
      dataSource.value[i].newHoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      dataSource.value[i].newRange[1] = record.staffAssignmentParts[index-1].endDate
      if (selectedAssignmentIdList.value.includes(record.id)) batchOverlap()
    }

    const startDateChange = (index,record) => {
      let i = dataSource.value.map(item =>item.id).indexOf(record.id)
      if(index > 0 && record.staffAssignmentParts[index].startDate && !dataSource.value[i].staffAssignmentParts[index-1].endDate){
        dataSource.value[i].staffAssignmentParts[index-1].endDate = moment(record.staffAssignmentParts[index].startDate).subtract(1, 'days').format("YYYY-MM-DD")
      }
      if(record.staffAssignmentParts[index].startDate) {
        dataSource.value[i].newRange[0] = record.staffAssignmentParts[0].startDate
      }
      staffHoursPerDayRe(record.staffAssignmentParts[index].hoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && record.staffAssignmentParts[index].endDate && record.staffAssignmentParts[index].hoursPerDay && hoursPerDayTime.value) batchOverlap();
      if (record.staffAssignmentParts[index].endDate) getTermAndCodeConflict(record,index);
    }

    const endDateChange = (index,record) => {
      let i = dataSource.value.map(item =>item.id).indexOf(record.id)
      if(index === record.staffAssignmentParts.length-1) {
        if (moment(record.staffAssignmentParts[index].endDate) <= moment()) {
          dataSource.value[i].staffAssignmentParts[index].endDate = ''
          dataSource.value[i].newRange[1] = ''
          message.error("下项目时间不能早于当天");
          return;
        }
      }
      if(index < record.staffAssignmentParts.length-1 && record.staffAssignmentParts[index].endDate) {
        dataSource.value[i].staffAssignmentParts[index+1].startDate = moment(record.staffAssignmentParts[index].endDate).add(1, 'days').format("YYYY-MM-DD")
      }
      if(index === record.staffAssignmentParts.length-1) {
        dataSource.value[i].newRange[1] = record.staffAssignmentParts[record.staffAssignmentParts.length-1].endDate
      }
      staffHoursPerDayRe(record.staffAssignmentParts[index].hoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && record.staffAssignmentParts[index].startDate && record.staffAssignmentParts[index].hoursPerDay && hoursPerDayTime.value) batchOverlap();
      if (record.staffAssignmentParts[index].startDate) getTermAndCodeConflict(record,index);
      formRef.value.validate().then(res=>{
        console.log("res",res)
      })
    }
    const hoursPerDayChange = (record) => {
      let i = dataSource.value.map(item =>item.id).indexOf(record.id)
      let arr = record.staffAssignmentParts.map(item => item.hoursPerDay).filter(item => item)
      dataSource.value[i].newHoursPerDay = arr.length > 1 ? Math.min(...arr) + "~" + Math.max(...arr) : arr.toString()
      //formData.value.hoursPerDay = segmentedData.value.map(item => item.hoursPerDay).reduce((prev,curr)=> (Number(prev)*10+Number(curr)*10)/10)
    }
    const hoursPerDayBlur = (record, index) => {
      staffHoursPerDayRe(record.staffAssignmentParts[index].hoursPerDay)
      if (selectedAssignmentIdList.value.includes(record.id) && record.staffAssignmentParts[index].startDate && record.staffAssignmentParts[index].endDate && record.staffAssignmentParts[index].hoursPerDay && hoursPerDayTime.value) batchOverlap();
    }

    const segmenteOnBoardDisabledDate = (current,record,index) => {
      if(record.staffAssignmentParts[index].endDate) {
        return current > moment(record.staffAssignmentParts[index].endDate)
      }
    };
    const segmenteEstimateReleaseDate = (current,record,index) => {
      if(record.staffAssignmentParts[index].startDate) {
        return current < moment(record.staffAssignmentParts[index].startDate)
      }
    };

    const startValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.startDateIndex > 0 && rule.startRecord.staffAssignmentParts[rule.startDateIndex - 1].endDate) {
          let endDate = moment(rule.startRecord.staffAssignmentParts[rule.startDateIndex - 1].endDate).add(1, 'days').format("YYYY-MM-DD")
          if (!moment(value).isSame(endDate)) {
            return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
          }
          // if(rule.startRecord.staffAssignmentParts[rule.startDateIndex].endDate) {
          //   if (!moment(value).isBefore(moment(rule.startRecord.staffAssignmentParts[rule.startDateIndex].endDate).add(1, 'days'))) {
          //     return Promise.reject("您所选择的时间不符合规定");
          //   } else {
          //     return Promise.resolve();
          //   }
          // }
        }
      }
    };
    const endValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Assignment Term");
      }else {
        if (rule.endDateIndex < rule.endRecord.staffAssignmentParts.length - 1) {
          if (rule.endRecord.staffAssignmentParts[rule.endDateIndex + 1].startDate) {
            let startDate = moment(rule.endRecord.staffAssignmentParts[rule.endDateIndex + 1].startDate).subtract(1, 'days').format("YYYY-MM-DD")
            if (!moment(value).isSame(startDate)) {
              return Promise.reject("连续两个terms的结束时间和开始时间需要连续");
            }
          }
        }
        if(rule.endRecord.staffAssignmentParts[rule.endDateIndex].startDate) {
          if (!moment(value).isAfter(moment(rule.endRecord.staffAssignmentParts[rule.endDateIndex].startDate).subtract(1, 'days'))) {
            return Promise.reject("您所选择的时间不符合规定");
          } else {
            return Promise.resolve();
          }
        }
      }
    };
    const hoursValidatorSpace = async (rule, value) => {
      if(!value) {
        return Promise.reject("Please input Hours Per Day");
      }else {
        if (rule.hoursIndex > 0) {
          if (value === rule.hoursRecord.staffAssignmentParts[rule.hoursIndex - 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else if (rule.hoursIndex < rule.hoursRecord.staffAssignmentParts.length - 1) {
            if (value === rule.hoursRecord.staffAssignmentParts[rule.hoursIndex + 1].hoursPerDay) {
              return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
            }
          } else {
            return Promise.resolve();
          }
        } else {
          if (value === rule.hoursRecord.staffAssignmentParts[rule.hoursIndex + 1].hoursPerDay) {
            return Promise.reject("连续两个terms的Hours Per Day配置不可相同");
          } else {
            return Promise.resolve();
          }
        }
      }
    }

    //8小时校验
    const isTimeClick = (record,index) => {
      //isTimeOverlap('time',record,index)
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([record.newOnBoardDate,record.newActualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(record.extensionAssumptionDate))
      batchOverlapClick('time',record,index)
      overlapModalVisible.value = true
    }
    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      demandList: [],
    });
    const currentOverlapData = ref({
      clientName:'',
      engagementCode: '',
      engagementDescription: '',
    })
    const timeJudgmentSubmitFunc = () => {
      overlapModalVisible.value = false
    }
    const segmentedTimeClick = (record,index) => {
      //isTimeOverlap('segmentedTime',record,index)
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([record.staffAssignmentParts[index].startDate,record.staffAssignmentParts[index].endDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(record.extensionAssumptionDate))
      batchOverlapClick('segmentedTime',record,index)
      overlapModalVisible.value = true
    }
    const hoursPerDayTime = ref(false)
    const hoursPerDayRe = (data) => {
      let re = new RegExp(/^[0-7]\.[1-9]$|^[1-8]$/)
      hoursPerDayTime.value = re.test(data)
    }
    const staffHoursPerDayRe = (data) => {
      let re = new RegExp(/^[0-7]\.[0-9]$|^[0-8]$/)
      hoursPerDayTime.value = re.test(data)
    }

    //FCST
    const disabledExtensionAssumptionDate = (current, record) => {
      return current < moment(record.newActualReleaseDate) || current > moment(record.terminationDate)
    }
    const extensionChabge = (record,index) => {
      if(!record.extensionAssumptionDate) {
        dataSource.value[index].certainty = null
      }
      batchOverlap();
      getIsShow();
      formRef.value.validate()
    }
    const fcstValidatorSpace = async (rule, value) => {
      if(value) {
        const diffStart = moment(rule.fcstRecord.newRange[0]).diff(moment(value), 'days') < 0;
        const diffEnd = moment(rule.fcstRecord.newRange[1]).diff(moment(value), 'days') > 0;
        const diffDtate = diffStart && diffEnd;
        if (diffDtate) {
          return Promise.reject("该日期不能在Assignment Term范围内，请重新选择");
        } else {
          return Promise.resolve();
        }
      }
    }
    const fcstTimeClick = (record,index) => {
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([record.newOnBoardDate,record.newActualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(record.extensionAssumptionDate))
      let type = record.staffAssignmentParts?.length>0? 'segmentedTime' : 'time'
      batchOverlapClick(type,record,index)
      overlapModalVisible.value = true
    }

    return {
      columns,
      selectedAssignmentIdList,
      selectedAssignmentList,
      closeModal,
      selectedChange,
      saveVisible,
      nextClick,
      saveCancel,
      pervClick,
      nextDisabled,
      refreshTable,
      dataSource,
      //handleDateChange,
      getIsShow,
      codeTerm,
      isTBDCode,
      isDisableCommitDate,
      placeHolder,
      handleIsFormalCode,
      disabledCommitDate,
      projectCommitEndDate,
      projectCommitStartDate,
      getProjectCommitDateRange,
      assignmentStatus,
      onBoardDateChange,
      actualReleaseDateChange,
      onBoardDisabledDate,
      estimateDisabledDate,
      curExpandedRowKeys,
      segmentedEditingClick,
      staffcolumns,
      formRef,
      subFormRef,
      segmentedAdd,
      segmentedDel,
      startDateChange,
      endDateChange,
      hoursPerDayChange,
      segmenteOnBoardDisabledDate,
      segmenteEstimateReleaseDate,
      startValidatorSpace,
      endValidatorSpace,
      hoursValidatorSpace,
      segmentedFormRules,
      //isTimeOverlap,
      newHoursPerDayChange,
      isTimeClick,
      overlapModalVisible,
      overlapData,
      currentOverlapData,
      timeJudgmentSubmitFunc,
      segmentedTimeClick,
      hoursPerDayBlur,
      getTermAndCodeConflict,
      getIsShowConflict,
      hoursPerDayTime,
      hoursPerDayRe,
      batchOverlap,
      batchOverlapClick,
      staffHoursPerDayRe,
      disabledExtensionAssumptionDate,
      extensionChabge,
      fcstValidatorSpace,
      fcstTimeClick,
    };
  }
});
</script>

<style lang="less" scoped>
.btn-row {
  display: flex;
  .left {
    width: 50%;
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.assignment-list {
  margin-bottom: 20px;

  .status-icon {
    font-size: 20px;
    margin-right: 12px;
    color: #58b99e;
    pointer-events: none;

    &.true {
      color: #fdcd46;
      cursor: pointer;
      pointer-events: auto;
    }

    &.prohibit {
      color: #dc5e4f;
      cursor: pointer;
      pointer-events: auto;
    }

    &.matchingOverlap {
      color: #58b99e;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}

.overlap-hidden {
  visibility: hidden;
}
.segmentedtaleclass {
  padding: 0 !important;

  :deep(.ant-form-item-has-error) {
    .ant-col {
      min-height: auto;
    }
  }
  :deep(.ant-table .ant-table-tbody > tr > td) {
    padding: 0 0.83333vw !important;
  }
}
.segmentedClass {
  padding-top:1.25vw;
  :deep(.ant-checkbox-inner){
    border: 0;
    width: 0;
    height: 0;
  }
  :deep(.ant-table .ant-table-tbody > tr > td){
    border-bottom: 0;
    padding: 0.2vw 0.81667vw 0.6vw !important;
  }
  :deep(.ant-table .ant-table-tbody){
    background: #FCFCFC;
  }
  :deep(.ant-form-item) {
    flex-direction: row;
    //margin-bottom: 1vw;
  }
  :deep(.ant-calendar-picker){
    width: 100%;
  }
}
:deep(tr.ant-table-expanded-row) {
  td {
    padding: 0 !important;
  }
}
.newHoursPerDayClass {
  padding: 0;
}
</style>

<style lang="less">
.batch-edit-modal {
  .ant-input-textarea-show-count {
    position: relative;

    &::after {
      font-size: 12px;
      position: absolute;
      right: 12px;
      bottom: 5px;
      line-height: 1;
      margin-bottom: unset !important;
    }
  }
}
.fcstLabel {
  .aboveTitle{
    margin-top:20px;
  }
  .tipLabel {
    color: #3182ce;
    font-size: 14px;
  }
}
</style>
