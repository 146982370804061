<template>
    <a-form :style="`margin-top: ${ label? '0' : '15px'}`" :model="formData" :rules="isrequired ? formRules : ''"
        :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" ref="releasetimeformref" class="dataPickselect">
        <a-form-item name="releasetime" :label-col="{ span: 22 }" :wrapper-col="{ span: 22 }" :colon="false">
        <template v-if="label" v-slot:label>
            <span>{{label}}</span>
            <a-popover placement="right">
          <template #content>
           <span>默认为系统自动发送时间，RM可根据需求修改。</span>
          </template>
          <QuestionCircleOutlined :style="{fontSize: '10px', position: 'relative', color:'#3A99F2',margin:'0 0 0 3px'}" />
          </a-popover>
        </template>
            <a-date-picker :disabled="!isrequired" @change="seltime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                v-model:value="formData.releasetime" :disabledDate="releasetimedisabledDate" :class="label? 'adatepickerClass' : ''"/>
            <a-select :disabled="!isrequired" @change="changeselecttime" v-model:value="formData.selecttime"
                placeholder="请选择" :class="label? 'formClass' : 'tableClass'">
                <a-select-option v-for="item in selecttimeops" :key="item" :value="item.value"><span :title="item.title">{{
                    item.title
                }}</span>
                </a-select-option>
            </a-select>
        </a-form-item>
    </a-form>
</template>
<script>
import moment from "moment";
import { defineComponent, ref, onMounted, watch } from "vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
    name: "DatePickerselect",
    components: {
        QuestionCircleOutlined
    },
    props: {
        actualDate: String,
        label: String,
        visible: Boolean,
        isrequired: Boolean,
    },
    emits: ['toreleasetime', 'releaseSendEmailDatenull'],
    setup(props, ctx) {
        const selecttimeops = ref([])
        const releasetimeformref = ref()
        const formData = ref({ releasetime: '', selecttime: '' })
        const releasetimedisabledDate = (current) => {
            return current < moment().subtract(1, 'day').endOf('day');
        }
        const formRules = {
            releasetime: [{
                required: true,
                trigger: ["blur", 'change'],
                validator: (rules, value) => {
                    // console.log(rules);
                    let selecttime = formData.value.selecttime
                    console.log('selecttime>>', selecttime, value);
                    console.log('formData>>', formData.value);
                    if (!value || !selecttime) {
                        return Promise.reject('Please input Release Date')
                    }
                    // return Promise.reject()
                }
            },
            ]
        }
        //选择日期触发函数
        const seltime = () => {
            formData.value.selecttime = ''
            if (formData.value.releasetime === moment().format("YYYY-MM-DD")) {
                hour.value = moment().hour() + 1
                getslecttimeops()
            } else {
                hour.value = 0
                getslecttimeops()
            }
            ctx.emit('releaseSendEmailDatenull')
        }
        //选择时间(小时触发)
        const changeselecttime = () => {
            releasetimeformref.value.validateFields('releasetime');
            ctx.emit('toreleasetime', formData)
        }
        const hour = ref(0)
        //设置小时选择范围
        const getslecttimeops = () => {
            let ops = []
            for (let i = hour.value; i < 24; i++) {
                if (i < 10) {
                    ops.push({ value: `0${i}:00:00`, title: `${i}:00` })
                } else {
                    ops.push({ value: `${i}:00:00`, title: `${i}:00` })
                }
            }
            selecttimeops.value = ops
        }
        //设置release默认日期
        const getreleasetime = () => {
            if (props.actualDate) {
                if (moment(props.actualDate).isAfter(moment().format("YYYY-MM-DD"))||(moment(props.actualDate).isSame(moment().format("YYYY-MM-DD"))&&(moment().hour()<18))) {
                    //Actual Release Date晚于当前时间
                    formData.value.releasetime = props.actualDate
                    seltime()
                    formData.value.selecttime = '18:00:00'
                    ctx.emit('toreleasetime', formData)
                } else {
                    if (moment().hour() === 23) {
                        formData.value.releasetime = moment().add(1, 'd').format("YYYY-MM-DD")
                        seltime()
                        formData.value.selecttime = '00:00:00'
                    } else {
                        formData.value.releasetime = moment().format("YYYY-MM-DD")
                        seltime()
                        formData.value.selecttime = `${moment().hour() + 1}:00:00`
                    }
                    ctx.emit('toreleasetime', formData)
                }
            }
        }
        onMounted(() => {
            getslecttimeops()
            getreleasetime()
            console.log('onMounted>>', props.actualDate);
        })
        watch(
            () => props.visible,
            () => {
                ctx.emit('toreleasetime', formData)
            }
        )
        watch(
            ()=> props.actualDate,
            ()=>{
                getreleasetime()
            }
        )
        return {
            selecttimeops,
            seltime,
            hour,
            getslecttimeops,
            getreleasetime,
            releasetimedisabledDate,
            formData,
            formRules,
            releasetimeformref,
            changeselecttime,
        };
    },
});
</script>

<style lang="less">
.dataPickselect {

  .ant-form-item-with-help{
    margin-bottom: 30px !important;
  }
  .adatepickerClass {
    width: 45%;
  }
  .formClass{
    width: 45%;
    margin-left: 10%;
  }
  .tableClass{
    width: 6vw;
    margin-left: 20px;
  }
}
</style>
