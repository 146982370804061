<template>
  <!--  已去掉info权限：v-auth="['system:assignmentDetails:info']"-->
  <a-page-header
    class="header"
    v-if="!isTodoStatus && roleType !== 'person'"
  >

    <template #title>
      <span>Account: {{ assignmentCondition.account }}</span>
      <span>Code: {{ assignmentCondition.engagementCode }}</span>
      <span>
        Engagement Description:
        <a-tooltip
          placement="top"
          :title="assignmentCondition.engagementDescription"
          >{{ assignmentCondition.engagementDescription }}</a-tooltip
        >
      </span>
    </template>
  </a-page-header>
  <div class="service-line">
    <span v-show="serviceLine"> Service Line: {{ serviceLine }} </span>
    <span v-show="subServiceLine">
      Sub-Service Line: {{ subServiceLine }}
    </span>
  </div>
  <section class="assignment-list">
    <a-table
      :columns="columns"
      :data-source="assDetails"
      :pagination="pagination"
      row-key="id"
      :customRow="customRowFunc"
      :rowClassName="rowClassNameFunc"
      :scroll="{
        x: tableWidth,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :row-selection="
        (activeTab === '1' || activeTab === '2') &&
        (roles.includes('2') || roles.includes('3')|| roles.includes('14'))
          ? {
              selectedRowKeys: selectedAssignmentIdList,
              selectedRows: selectedAssignmentList,
              onChange: selectedChange,
            }
          : null
      "
    >
      <template #actualReleaseDate="{ text }">
        <span>{{ text.status.match(/^8|9|13$/) ? text.actualReleaseDate : '-' }}</span>
      </template>
      <template #extensionAssumptionDate="{ text }">
        <span>{{ text? text : '-' }}</span>
      </template>
      <template #certainty="{ text }">
        <span>{{ text? text : '-' }}</span>
      </template>
    </a-table>
    <div class="batch-btn">
      <a-space class="batch-btn">
        <a-button
          type="primary"
          :disabled="selectedAssignmentIdList.length === 0"
          v-show="activeTab === '1' || activeTab === '2'"
          v-auth="['system:assignmentDetails:batchApply']"
          @click="batchApplyClick('apply')"
        >
          批量申请
        </a-button>
        <a-button
          v-show="activeTab === '1' || activeTab === '2'"
          type="primary"
          :disabled="
            selectedAssignmentIdList.length === 0 ||
            selectedAssignmentList.some(
              ({ status }) => status !== '1' && status !== '2' && status !== '4' && status !== '5' && status !== '6' && status !== '8'
            )
          "
          v-auth="['system:assignmentDetails:batchAudit']"
          @click="batchEditTimeClick"
        >
          批量修改时间
        </a-button>
        <a-button
          v-show="activeTab === '1' || activeTab === '2'"
          type="primary"
          :disabled="selectedAssignmentIdList.length === 0"
          v-auth="['system:assignmentDetails:batchConfirm']"
          @click="batchApplyClick('confirm')"
        >
        <!-- 批量确定选中状态规则判断
          :disabled="
            selectedAssignmentIdList.length === 0 ||
            selectedAssignmentList.some(
              ({ status }) => status === '3' || status === '7'
            )"
        -->
          {{
            activeTab === '1' ? "批量确认" : "批量 Release"
          }}
        </a-button>
        <a-button
            v-show="activeTab === '1' || activeTab === '2'"
            type="primary"
            :disabled="
            selectedAssignmentIdList.length === 0 ||
            selectedAssignmentList.some(
              ({ status }) => status === '3' || status === '7'
            )
          "
            v-auth="['system:assignmentDetails:batchModify']"
            @click="batchModifyClick()"
        >

          批量修改
        </a-button>

      </a-space>
    </div>
    <!-- 这里 -->
    <AssignmentInfo
      :visible="infoVisible"
      :orgInfo="activeRow"
      :active-tab="activeTab"
      :has-operate-btn="infoHasBtn"
      :isPmOrConsultant="isPm || isConsultant"
      :annexColumns="annexColumns"
      :annexDataList="annexDataList"
      :isShowAnnexRelated="isShowAnnexRelated"
      :isFormalCode="isFormalCode"
      @update-file-list="updateFileList"
      @download-annexes ="downloadAnnexClick"
      @delete-annexes="deleteAnnexClick"
      @replace-annexes="replaceAnnexClick"
      @close-info="closeInfo"
      @pm-confirm="pmConfirm"
      @pm-on-board-confirm="pmOnBoardConfirm"
      @pm-estimate-confirm="pmEstimateConfirm"
      @open-appraises="openAppraises"
      @modify-info="modifyInfo"
      @open-release="openRelease"
      @rm-on-board-confirm="rmOnBoardConfirm"
      @rm-estimate-confirm="rmEstimateConfirm"
      @rm-restart-assignment="restartAssignmentClick"
      @rmleader-assignment-Confirm="restartAssignmentConfirm"
    />
    <BatchEditAssignmentTimeDrawer
        v-model:visible="batchEditTimeAssignmentVisible"
        :list="selectedAssignmentList"
        v-model:codeTermData="codeTermData"
        v-model:isFormalCode="isFormalCode"
        @close-modal="batchEditTimeAssignmentVisible = false"
        @refresh-table="refreshTable"
        :orgInfo="activeRow"
    />
    <BatchEditTimeDrawer
        :actionType="actionType"
        v-model:visible="batchEditTimeVisible"
        v-model:codeTermData="codeTermData"
        v-model:isFormalCode="isFormalCode"
        :list="selectedAssignmentList"
        :id-list="selectedAssignmentIdList"
        :role="actionRole"
        :active-tab="activeTab"
        @close-modal="closeBatchModal"
        @refresh-table="refreshTable"
    />
    <FillAppraises
        v-model:visible="appraisesVisible"
        title="Assignment Review"
        :form-state="formState"
        @close-modal="closeAppraises"
        @submit-modal="submitAppraises"
    />
    <Release
        v-model:visible="releaseVisible"
        title="Release"
        :form-state="releaseFormState"
        @close-modal="closeRelease"
        @submit-modal="submitRelease"
    >
    </Release>
    <OnBoardConfirm
        v-if="onBoardConfirmVisible"
        v-model:visible="onBoardConfirmVisible"
        title="Assignment Project Onboard Date 时间确定"
        v-model:formState="onBoardConfirmFormState"
        v-model:codeTermData="codeTermData"
        v-model:isFormalCode="isFormalCode"
        @close-modal="closeOnBoardConfirm"
        @submit-modal="submitOnBoardConfirm"
    >
    </OnBoardConfirm>
    <EstimateConfirm
        v-if="estimateConfirmVisible"
        v-model:visible="estimateConfirmVisible"
        title="Assignment Estimate Date 时间确定"
        v-model:formState="estimateConfirmFormState"
        v-model:codeTermData="codeTermData"
        :is-rm="actionRole === 'rm'"
        @close-modal="closeEstimateConfirm"
        @submit-modal="submitEstimateConfirm"
    >
    </EstimateConfirm>
    <fill-assignment
        ref="fillAssignmentRef"
        v-model:visible="assignmentVisible"
        v-model:formState="formData"
        :modifyDisabled="modifyDisabled"
        :is-can-reject="false"
        :is-rm="actionRole === 'rm'"
        :accountNameOps="clientNameOps"
        :originalCodeAndDateObj="originalCodeAndDateObj"
        :industryLineOps="industryLineOps"
        :accountIndustryRelaction="accountIndustryRelaction"
        :codeRelationList="codeRelationList"
        :isShowSendEmail="ismodifyInfo"
        :orgInfo="activeRow"
        source="assignmentDetail"
        title="Assignment 信息修改"
        @close-modal="closeAssignmentModal"
        @submit-modal="submitAssignmentModal"
        @rm-edit-time="openEditTime"
        :tabStatus="1"
    />
    <edit-time-modal
        v-model:visible="editTimeVisible"
        title="修改 Assignment 时间"
        :form-state="editTimeFormState"
        v-model:codeTermData="codeTermData"
        v-model:isFormalCode="isFormalCode"
        @close-modal="closeEditTime"
        @submit-modal="editTimeSubmit"
        :orgInfo="activeRow"
    />
    <OverlapModal
        :data="overlapData"
        @confirm="timeJudgmentSubmitFunc"
        v-model:visible="overlapModalVisible"
    />
    <AssignmentsModifying
        title="修改"
        v-model:visible="modifyingVisible"
        source="batch"
        :initialDataList="initialDataList"
        :engageCodeOpts="engageCodeOpts"
        :descriptionOps = "descriptionOps"
        :oldEngageCode="oldEngageCode"
        @get-detail-by-code="getTheDetailByCode"
        @handle-save-assignment="handleCheckAssignment"
        @reset-data-list="resetDataList"
        :titleEngagementDescription = "titleEngagementDescription"
    />
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
    <RestartAssignmentModal
        v-model:visible="restartAssignmentVisible"
        @close-modal="restartAssignmentClose"
        @submit-modal="restartAssignmentSubmit"
        :orgInfo="activeRow"
        v-model:codeTermData="codeTermData"
        v-model:isFormalCode="isFormalCode"
        :form-state="restartFormState"
    />
    <restart-assignment-confirm
        v-model:visible="restartConfirmVisible"
        :form-state="restartData"
        :isFormalCode="isFormalCode"
        @close-modal="restartConfirmClose"
        @submit-modal="restartConfirmSubmit"
    />
    <FcstDateModal
        v-model:visible="fcstDateVisible"
        :infos="fcstExtensionDate"
        @close-modal="fcstClose"
        @submit-modal="fcstSubmit"
    />
  </section>
</template>
<script>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  inject,
  onActivated,
} from "vue";
import {onBeforeRouteLeave, useRoute} from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import FillAppraises from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/FillAppraises";
import Release from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/Release";
import OnBoardConfirm from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/OnBoardConfirm";
import EstimateConfirm from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/EstimateConfirm";
import FillAssignment from "@/views/home/ResourceManagement/Match/matchingList/components/FillAssignment";
import { message } from "ant-design-vue";
import moment from "moment";
import {downloadFile, formatTableCol, useTableHeight} from "@/utils/common";
import AssignmentInfo from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/AssignmentInfo";
import { assignmentStatus } from "@/utils/formatStatus";
import { useStore } from "vuex";
import EditTimeModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/EditTimeModal";
import BatchEditTimeDrawer from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/BatchEditTimeDrawer";
import BatchEditAssignmentTimeDrawer from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/BatchEditAssignmentTimeDrawer";
import OverlapModal from "@/components/OverlapModal";
import AssignmentsModifying from '@/views/home/ResourceManagement/Assignment/component/AssignmentsModifying'
import {cloneDeep} from "lodash";
import ModalBox from '@/components/ModalBox'
import RestartAssignmentModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/RestartAssignmentModal.vue";
import RestartAssignmentConfirm from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/RestartAssignmentConfirm.vue";
import FcstDateModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/FcstDateModal";

export default defineComponent({
  name: "AssignmentDetails",
  components: {
    FcstDateModal,
    RestartAssignmentConfirm,
    RestartAssignmentModal,
    OverlapModal,
    BatchEditTimeDrawer,
    BatchEditAssignmentTimeDrawer,
    EditTimeModal,
    AssignmentInfo,
    FillAppraises,
    Release,
    OnBoardConfirm,
    EstimateConfirm,
    FillAssignment,
    AssignmentsModifying,
    ModalBox
  },
  emits: ['goTodoBack', 'getAllDescOps'],
  setup(_,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute();
    const { $EventBus } = useGlobalPropertyHook();
    const store = useStore();
    const query = inject("query")
    const roles = store.state.app.user.roles;
    const roleType = computed(() => store.state.page.assignmentCondition.type);
    const assignmentCondition = computed(
      () => store.state.page.assignmentCondition
    );
    const titleEngagementDescription = ref("");
    const serviceLine = assignmentCondition.value.serviceLineList
      ? assignmentCondition.value.serviceLineList.join(",")
      : undefined;
    const subServiceLine = assignmentCondition.value.subServiceLineList
      ? assignmentCondition.value.subServiceLineList.join(",")
      : undefined;
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const columns = ref([])
    // Pm和Consultant 只显示Release Date
    const isPm = store.state.app?.user?.roles.includes('3')
    const isConsultant = store.state.app?.user?.roles.includes('4')

    const currentColumns = computed(() => {
      let col = [
        {
          title: "Candidate Name",
          dataIndex: "fullName",
          key: "fullName",
          width: 130,
          fixed: "left",
          ellipsis: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          customRender: formatTableCol(assignmentStatus),
          width: 120,
          fixed: "left",
          ellipsis: true,
        },
        {
          title: "PM",
          dataIndex: "pmInfo",
          key: "pmInfo",
          width: 180,
          ellipsis: true,
          customRender: ({ text }) => (
              <div>
                <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
                <div>{text ? text.split(" ")[2] : null}</div>
              </div>

          ),
          customCell:() => {
            return {
              style: {
                lineHeight: 1.2,
                padding:'6px 10px',
              }
            }
          }
        },
        {
          title: "Grade",
          dataIndex: "psGroup",
          key: "psGroup",
          width: 80,
          ellipsis: true,
        },
        {
          title: "ADRC",
          dataIndex: "personCost",
          key: "personCost",
          width: 80,
          ellipsis: true,
        },
        {
          title: "Service Line",
          dataIndex: "serviceLine",
          key: "serviceLine",
          width: 100,
          ellipsis: true,
        },
        {
          title: "Sub Service Line",
          dataIndex: "subServiceLine",
          key: "subServiceLine",
          width: 130,
          ellipsis: true,
        },
        {
          title: "Project Onboard Date",
          dataIndex: "onBoardDate",
          key: "onBoardDate",
          width: 160,
          ellipsis: true,
        },
        {
          title: "Estimate Release Date",
          dataIndex: "estimateReleaseDate",
          key: "estimateReleaseDate",
          width: 170,
          ellipsis: true,
        },
        {
          title: "Actual Release Date",
          // dataIndex: "actualReleaseDate",
          key: "actualReleaseDate",
          width: 150,
          ellipsis: true,
          slots: { customRender: "actualReleaseDate" }
        },
        {
          title: "Hours Per Day",
          dataIndex: "hoursPerDay",
          key: "hoursPerDay",
          width: 120,
          ellipsis: true,
        },
      ]
      if(isRM.value || isRMLeader.value) {
        col.push(
            {
              title: "FCST Extension Assumption Date",
              dataIndex: "extensionAssumptionDate",
              key: "extensionAssumptionDate",
              width: 200,
              slots: { customRender: "extensionAssumptionDate" }
            },
            {
              title: "确定性",
              dataIndex: "certainty",
              key: "certainty",
              width: 100,
              slots: { customRender: "certainty" }
            },
        )
      }
      return col
    })
    const assDetails = ref();
    let activeTab = ref("");
    let searchCondition = {};
    const showReason = (status) => {
      if (status == "6" || status == "3") {
        return true;
      } else {
        return false;
      }
    };

    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        searchClick(activeTab.value, searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        searchClick(activeTab.value, searchCondition);
      },
    });

    const searchClick = async (tab, searchParams) => {
      closeInfo();
      activeTab.value = tab + "";
      searchCondition = searchParams;
      const params = {
        status: activeTab.value,
        type: roleType.value ? roleType.value : "project",
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      if (isTodoStatus.value) {
        // params.assignmentId = route.query.assignmentId;
        // params.status = route.query.status;
        params.assignmentId = query?.value?.value?.assignmentId;
        // params.status = query?.value?.value?.status;
      } else {
        params.engagementDescription = searchCondition.engagementDescription.join(',');
        params.nameConditon = searchCondition.nameCondition;
        params.engagementCode = assignmentCondition.value.engagementCode;
        params.serviceLineList = assignmentCondition.value.serviceLineList;
        params.subServiceLineList = assignmentCondition.value.subServiceLineList;
        // 调令详情加team offering筛选
        params.orgFourOneOneList = assignmentCondition.value.orgFourOneOne;
        params.orgThreeTwoList = assignmentCondition.value.orgThreeTwo;
      }
      if (noticePreMatchingId.value) {
        if (getActiveTab.value === '') {
          params.status = ''
          params.assignmentId = noticePreMatchingId.value
          params.engagementCode = ''
        }
      }
      await $api
        .getAssignmentDetails(params)
        .then((res) => {
          assDetails.value = res.data.detailPage.resource;
          pagination.value.total = res.data.detailPage.total;
          if (assDetails.value.length > 0) {
            activeRow.value = assDetails.value[0];
            getAnnexData(activeRow.value.id)
            isShowAnnexRelated.value = activeRow.value.replaceReason ? true : false
            infoVisible.value = true;
            activeRow.value.codeEndDate = activeRow.value.projectStatus === 'Close' ? activeRow.value.codeEndDate : activeRow.value.projectCompletionDate
            codeTermData.value.projectStartDate = activeRow.value.codeStartDate ? activeRow.value.codeStartDate : ""
            codeTermData.value.projectCloseDate = activeRow.value.codeEndDate ? activeRow.value.codeEndDate : ""
          }
          selectedAssignmentIdList.value = [];
          selectedAssignmentList.value = [];
          if(noticePreMatchingId.value){
            assignmentCondition.value.engagementCode = assDetails.value[0].engagementCode
            assignmentCondition.value.account = assDetails.value[0].account
            assignmentCondition.value.engagementDescription = assDetails.value[0].engagementDescription
            handlegetActiveTab()
          }
        })
        .catch((error) => {
          error();
        });
      handleIsFormalCode()
    };

    const getActiveTab = ref('')
    const noticePreMatchingId = computed(()=> route.query.id)
    const handlegetActiveTab = () => {
      if(assDetails.value.length > 0) {
        let hardBook = ['1', '2', '3', '4']
        let inProgress = ["5", "6", "7", "8"]
        let Completed = ["9", "13", "14"]
        let Invalid = ["12"]
        if (hardBook.includes(assDetails.value[0].status)) {
          getActiveTab.value = '1'
        } else if (inProgress.includes(assDetails.value[0].status)) {
          getActiveTab.value = '2'
        } else if (Completed.includes(assDetails.value[0].status)) {
          getActiveTab.value = '3'
        } else if (Invalid.includes(assDetails.value[0].status)) {
          getActiveTab.value = '4'
        }
        $EventBus.emit("getActiveTab", getActiveTab.value);
      }
    }

    const appraisesVisible = ref(false);
    const releaseVisible = ref(false);
    const onBoardConfirmVisible = ref(false);
    const estimateConfirmVisible = ref(false);
    const formState = ref({});
    const releaseFormState = ref({});
    const onBoardConfirmFormState = ref({});
    const estimateConfirmFormState = ref({});
    const openAppraises = (record) => {
      $api.getAppraises({ assignmentId: record.id }).then((res) => {
        if (res.data) {
          formState.value = res.data;
          formState.value.attitude = parseInt(res.data.attitude);
          formState.value.english = parseInt(res.data.english);
          formState.value.skill = parseInt(res.data.skill);
          formState.value.teamwork = parseInt(res.data.teamwork);
          formState.value.syntheticalScore = parseInt(
            res.data.syntheticalScore
          );
          formState.value.reviewDate = moment(res.data.reviewDate);
        } else {
          formState.value = {
            assignmentId: record.id,
            attitude: "",
            skill: "",
            english: "",
            teamwork: "",
            syntheticalScore: "",
            keyTalent: "",
            reviewDate: moment(),
            retentionRisk: "",
            summary: "",
          };
        }
      });
      appraisesVisible.value = true;
    };
    const closeAppraises = () => {
      formState.value = {};
      appraisesVisible.value = false;
    };
    const submitAppraises = () => {
      formState.value.reviewDate =
        formState.value.reviewDate.format("YYYY-MM-DD");
      $api.pmAppraises(formState.value).then((res) => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        searchClick(activeTab.value, searchCondition);
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
        message.success(res.msg);
        closeAppraises();
      });
    };
    //修改信息
    const formData = reactive({});
    const assignmentVisible = ref(false);
    const modifyDisabled = ref(false);

    // const clientNameOps = computed(() => store.state.app.dictionary.clientName);
    const clientNameOps = ref([]);
    const industryLineOps = ref([]);
    // const getClientInfo = async (userId) => {
    //   await $api.getCaptives({userId}).then((res) => {
    //     clientNameOps.value = dictionaryOptionsCode(res.data.clientName);
    //     industryLineOps.value = dictionaryOptionsCode(res.data.industryLine);
    //     store.commit("SET_DICTIONARY", res.data);
    //   })
    // }
    const accountIndustryRelaction = ref()
    const getAccountOptions = async () => {
      const params = {
        pagelndex: 1,
        pageSize: 20,
      }
      const result = await Promise.all([$api.getAccountIndustryLineOpts(), $api.getAccountIndustryRelations(), $api.getCodeDataOps(params)])
      clientNameOps.value = result[0].data.account.map((item) => {
        return {
          value: item,
          label: item,
          key: item,
        }
      });
      accountIndustryRelaction.value = result[1].data;
      codeRelationList.allCodeOptionsList = [...new Set(result[2].data.resource.map((item) => {
        return {
          ...item,
          label: item.codeAndDescription,
          value: item.codeAndDescription,
          type: item.type,
        }
      }))]
    }

    const codeRelationList = reactive({
      relationsList: [],
      allCodeOptionsList: [],
    })

    // const getCodeOptions = async () => {
    //   const { data } = await $api.getCodeRelations()
    //   codeRelationList.relationsList = data
    //   codeRelationList.allCodeOptionsList = [...new Set(codeRelationList.relationsList.map((item) => {
    //     return {
    //       ...item,
    //       label: item.codeAndDescription,
    //       value: item.codeAndDescription,
    //       type: item.type,
    //     }
    //   }))]
    //   codeRelationList.codeOps = codeRelationList.allCodeOptionsList.slice(0,10)
    // }

    const ismodifyInfo = ref(false)
    const originalCodeAndDateObj = ref({})
    const modifyInfo = async (item) => {
      ismodifyInfo.value = true
      handleResetFormData()
      //await getCodeOptions()
      await getAccountOptions()
      const params = {
        assignmentId: item.id,
      };
      await $api.getAssignmentById(params).then((res) => {
        Object.assign(formData, res.data);
        Object.assign(originalCodeAndDateObj.value, {
          engagementCode: res.data.engagementCode,
          projectCommitDate: res.data.projectCommitDate,
        });
        modifyDisabled.value = true;
        formData.assignmentTerm = [
          moment(res.data.onBoardDate),
          moment(res.data.actualReleaseDate),
        ];
        formData.hoursPerDay = formData.hoursPerDay + "";
        const clientName = clientNameOps.value.find(item => item.dictCode === formData.clientName)
        if (!clientName) {
          formData.clientName = undefined
          formData.industryLine = undefined
        }
        formData.codeEndDate = res.data.projectStatus === 'Close' ? res.data.codeEndDate : res.data.projectCompletionDate
        formData.terminationDate = activeRow.value.terminationDate
      });
      $api.getADRC({ crc: item.CRC }).then((res) => {
        formData.adrc = res.data;
      });
      assignmentVisible.value = true;
    };
    //判断是否是正式的code
    const isFormalCode = ref(false)
    const handleIsFormalCode = async () => {
      const params = {
        engagementCode:isTodoStatus.value ? query?.value?.value?.assignmentData : assignmentCondition.value.engagementCode
      }
      await $api.checkFormalCode(params).then((res) => {
          isFormalCode.value = res.data
      });
    }

    const submitAssignmentFunc = () => {
      //修改时间弹窗提交
      // const params = {
      //   assignmentId: formData.assignmentId,
      //   onBoardDate: formData.assignmentTerm[0].format("YYYY-MM-DD"),
      //   actualDate: formData.assignmentTerm[1].format("YYYY-MM-DD"),
      //   reason: formData.applyReason,
      //   projectCommitDate: formData.projectCommitDate,
      //   projectStartDate: codeTermData.value.projectStartDate,
      //   projectCloseDate: codeTermData.value.projectCloseDate,
      // };
      // $api.rmEditAssignmentTime(params).then(() => {});
      //修改调令弹窗提交
      const data = {
        id: formData.id,
        candidateContact: formData.candidateContact,
        candidateGrade: formData.candidateGrade,
        candidateName: formData.candidateName,
        clientName: formData.clientName,
        costCenter: formData.costCenter,
        costCtr: formData.costCtr,
        demandId: formData.demandId,
        engagementCode: formData.engagementCode,
        copyList: formData.copyList,
        engagementDescription: formData.engagementDescription,
        engagementManager: formData.engagementManager,
        actualReleaseDate: formData.assignmentTerm[1].format("YYYY-MM-DD"),
        account: formData.account,
        hoursPerDay: formData.hoursPerDay,
        onBoardDate: formData.assignmentTerm[0].format("YYYY-MM-DD"),
        personCost: formData.adrc,
        pmId: formData.pmId,
        preMatchingId: formData.preMatchingId,
        rmId: formData.rmId,
        serviceLine: formData.serviceLine,
        subServiceLine: formData.subServiceLine,
        userId: formData.userId,
        workCity: formData.workCity,
        workLocation: formData.workLocation,
        industryLine: formData.industryLine,
        codeStartDate: formData.codeStartDate,
        codeEndDate: formData.codeEndDate,
        projectCommitDate: formData.projectCommitDate,
        isEmail:formData.isEmail,
        applyReason: formData.applyReason,
        extensionAssumptionDate:  formData.extensionAssumptionDate,
        certainty: formData.certainty,
      };
      data.staffAssignmentParts = segmenteData.value
      if(!data.engagementCode || !data.onBoardDate ) {
        message.error("Engagement Code 和 Assignment Term 的开始时间均是必填项")
        return
      }
      $api.sumbitInfo(data).then((res) => {
        if (res.code == 200) {
          searchClick(activeTab.value, searchCondition);
          message.success("修改成功");
          assignmentVisible.value = false;
        }
      });
    };
    let editAssignmentType = "";
    const timeJudgmentSubmitFunc = () => {
      overlapModalVisible.value = false;
      switch (editAssignmentType) {
        case "hoursPerDay":
          submitAssignmentFunc();
          return;
        case "onBoardDate":
          if (actionRole.value === "pm") {
            pmSubmitOnBoardConfirm();
          } else {
            rmSubmitOnBoardConfirm();
          }
          return;
        case "estimateReleaseDate":
          if (actionRole.value === "pm") {
            pmSubmitEstimateConfirm();
          } else {
            rmSubmitEstimateConfirm();
          }
          return;
        case "rmEditTime":
          rmEditTimeFunc();
          return;
        case "rmRestartAssignment":
          rmRestartAssignmentFunc();
      }
    };
    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      demandList: [],
    });
    const currentOverlapData = ref({
      clientName:'',
      engagementCode: '',
      engagementDescription: '',
    })
    const timeJudgmentsFunc = (res) => {
      if(res.code !== 200) {
        message.error(res.msg)
        return
      }
      if (res.data.gatherFlag === "false") {
        // 时间不重叠
        timeJudgmentSubmitFunc();
      } else {
        overlapData.value = {
          status: res.data.gatherFlag,
          assignmentList: res.data.assignmentQueryPartCommandList || [],
          workTaskList: res.data.workTaskList || [],
          overlapCommandList: res.data.overlapCommandList || [],
          currentformData: currentOverlapData.value,
          demandList: res.data.demandList || [],
        }
        overlapModalVisible.value = true;
      }
    };
    const batchEditTimeClick = async () => {
      await handleIsFormalCode()
      batchEditTimeAssignmentVisible.value = true
    }

    const submitAssignmentModal = (data,segmentedData) => {
      segmenteData.value = segmentedData
      if (formData.hoursPerDay === activeRow.value.hoursPerDay && formData.extensionAssumptionDate === activeRow.value.extensionAssumptionDate) {
        submitAssignmentFunc();
        return;
      }
      editAssignmentType = "hoursPerDay";
      const staffAssignment = {
        id: formData.id,
        actualReleaseDate: formData.assignmentTerm[1].format("YYYY-MM-DD"),
        hoursPerDay: formData.hoursPerDay,
        onBoardDate: formData.assignmentTerm[0].format("YYYY-MM-DD"),
        userId: formData.userId,
        extensionAssumptionDate: formData.extensionAssumptionDate,
        certainty: formData.certainty,
      };
      staffAssignment.staffAssignmentParts = segmentedData
      currentOverlapData.value = {
        clientName:data.clientName,
        engagementCode: data.code,
        engagementDescription: data.description,
      }
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([staffAssignment.onBoardDate,staffAssignment.actualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(formData.extensionAssumptionDate))
      $api.isTimeJudgments(staffAssignment).then(timeJudgmentsFunc);
    };

    const closeAssignmentModal = () => {
      assignmentVisible.value = false;
      handleResetFormData()
    };
    const handleResetFormData = () => {
      for(let key in formData) {
        formData[key] = undefined
      }
      formData.assignmentTerm = []
    }

    const openRelease = (record) => {
      releaseFormState.value = {
        assignmentId: record.id,
        status: record.status,
        releaseMode: "",
        actualDate: moment(),
        releaseReason: "",
      };
      releaseVisible.value = true;
    };
    const closeRelease = () => {
      releaseFormState.value = {};
      releaseVisible.value = false;
    };
    const submitRelease = () => {
      const result = {
        assignmentId: releaseFormState.value.assignmentId,
        releaseMode: releaseFormState.value.releaseMode,
        actualDate: releaseFormState.value.actualDate.format("YYYY-MM-DD"),
        releaseReason: releaseFormState.value.releaseReason,
      };
      $api.rmSpecialRelease(result).then(() => {
        closeRelease();
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        searchClick(activeTab.value, searchCondition);
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
      });
    };

    let actionRole = ref("");
    const openOnBoardConfirm = async () => {
      await handleIsFormalCode()
      const params = {
        id: activeRow.value.id,
      };
      $api.getPMAssApply(params).then((res) => {
        const content = {
          ...activeRow.value,
          assignmentId: activeRow.value.id,
          actualDate: activeRow.value.actualReleaseDate,
          onBoardDate: activeRow.value.onBoardDate,
          originalActualDate: activeRow.value.actualReleaseDate,
          originalOnboardDate: activeRow.value.onBoardDate,
          applyReason: "",
          projectCommitDate: activeRow.value.projectCommitDate,
          newHoursPerDay: activeRow.value.hoursPerDay,
        };
        if (res.data.id) {
          content.onBoardDate = res.data.onBoardDate;
          content.projectCommitDate = res.data.projectCommitDate
          if (actionRole.value === "pm") {
            content.applyReason = res.data.applyReason;
          } else {
            content.pmReason = res.data.applyReason;
          }
        }
        onBoardConfirmFormState.value = content;
        onBoardConfirmVisible.value = true;
      });
    };
    const closeOnBoardConfirm = () => {
      actionRole.value = "";
      onBoardConfirmFormState.value = {};
      onBoardConfirmVisible.value = false;
    };

    /**
     * 上项目提交
     */
    const submitOnBoardConfirm = () => {
      editAssignmentType = "onBoardDate";
      const staffAssignment = {
        id: activeRow.value.id,
        hoursPerDay: activeRow.value.hoursPerDay,
        actualReleaseDate: activeRow.value.actualReleaseDate,// 替换
        onBoardDate: onBoardConfirmFormState.value.onBoardDate,
        userId: activeRow.value.userId,
        staffAssignmentParts: onBoardConfirmFormState.value.staffAssignmentParts,
        extensionAssumptionDate: onBoardConfirmFormState.value.extensionAssumptionDate,
        certainty: onBoardConfirmFormState.value.certainty,
      }
      if(onBoardConfirmFormState.value.staffAssignmentParts.length > 0){
        staffAssignment.staffAssignmentParts = onBoardConfirmFormState.value.staffAssignmentParts.filter(item =>
            moment(item.endDate).isAfter(onBoardConfirmFormState.value.onBoardDate) || moment(item.endDate).isSame(onBoardConfirmFormState.value.onBoardDate)
        ).map(item => {
          return {
            id: item.id,
            startDate: item.startDate,
            endDate: item.endDate,
            hoursPerDay: item.hoursPerDay
          }
        })
        staffAssignment.staffAssignmentParts[0].startDate = onBoardConfirmFormState.value.onBoardDate
        if(staffAssignment.staffAssignmentParts.length === 1) {
          staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
          onBoardConfirmFormState.value.newHoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
          staffAssignment.staffAssignmentParts = []
        } else {
          onBoardConfirmFormState.value.newHoursPerDay = staffAssignment.hoursPerDay
        }
      }
      segmenteData.value = staffAssignment.staffAssignmentParts
      currentOverlapData.value = {
        clientName:activeRow.value.account,
        engagementCode: activeRow.value.engagementCode,
        engagementDescription: activeRow.value.engagementDescription,
      }
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([staffAssignment.onBoardDate,staffAssignment.actualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(onBoardConfirmFormState.value.extensionAssumptionDate))
     $api.isTimeJudgments(staffAssignment).then(timeJudgmentsFunc);
    };

    /**
     * PM上项目提交Func
     */
    const pmSubmitOnBoardConfirm = () => {
      const result = {
        assignmentId: onBoardConfirmFormState.value.assignmentId,
        actualDate: "",
        onBoardDate: onBoardConfirmFormState.value.onBoardDate,
        reason: onBoardConfirmFormState.value.applyReason,
        projectCommitDate: onBoardConfirmFormState.value.projectCommitDate,
        staffAssignmentParts: segmenteData.value,
        hoursPerDay: onBoardConfirmFormState.value.newHoursPerDay
      };
      $api.pmAssApply(result).then(() => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeOnBoardConfirm();
        searchClick(activeTab.value, searchCondition);
        message.success("申请成功");
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
      });
    };

    /**
     * RM上项目提交Func
     */
    const rmSubmitOnBoardConfirm = () => {
      const result = {
        assignmentId: onBoardConfirmFormState.value.assignmentId,
        estimateDate: "",
        actualDate: "",
        onBoardDate: onBoardConfirmFormState.value.onBoardDate,
        reason: onBoardConfirmFormState.value.applyReason,
        projectCommitDate: onBoardConfirmFormState.value.projectCommitDate,
        projectStartDate: codeTermData.value.projectStartDate,
        projectCloseDate: codeTermData.value.projectCloseDate,
        staffAssignmentParts: segmenteData.value,
        hoursPerDay: onBoardConfirmFormState.value.newHoursPerDay
      };
      $api.rmAudit(result).then(() => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeOnBoardConfirm();
        searchClick(activeTab.value, searchCondition);
        message.success("操作成功");
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
      });
    };

    const openEstimateConfirm = () => {
      const params = {
        id: activeRow.value.id,
      };
      $api.getPMAssApply(params).then((res) => {
        const content = {
          ...activeRow.value,
          assignmentId: activeRow.value.id,
          actualDate: activeRow.value.actualReleaseDate,
          onBoardDate: activeRow.value.onBoardDate,
          applyReason: "",
          originalActualDate: activeRow.value.actualReleaseDate,
          originalOnboardDate: activeRow.value.onBoardDate,
          newHoursPerDay: activeRow.value.hoursPerDay,
        };
        if (res.data.id) {
          content.actualDate = res.data.actualReleaseDate;// 替换
          if (actionRole.value === "pm") {
            content.applyReason = res.data.applyReason;
          } else {
            content.pmReason = res.data.applyReason;
          }
        }
        estimateConfirmFormState.value = content;
        estimateConfirmVisible.value = true;
      });
    };
    const closeEstimateConfirm = () => {
      actionRole.value = "";
      estimateConfirmFormState.value = {};
      estimateConfirmVisible.value = false;
    };
        //release邮件发送时间
    const releaseSendmailDate=ref('')
    const releaseEmailSendMode=ref('')
    const submitEstimateConfirm = (e) => {
      editAssignmentType = "estimateReleaseDate";
      releaseSendmailDate.value= e.releaseSendmailDate
      releaseEmailSendMode.value= e.releaseEmailSendMode
      const staffAssignment = {
        id: activeRow.value.id,
        hoursPerDay: activeRow.value.hoursPerDay,
        actualReleaseDate: estimateConfirmFormState.value.actualDate,// 替换
        onBoardDate: activeRow.value.onBoardDate,
        userId: activeRow.value.userId,
        staffAssignmentParts: e.staffAssignmentParts,
        extensionAssumptionDate: e.extensionAssumptionDate,
        certainty: e.certainty,
      };
      if(e.staffAssignmentParts.length > 0){
        staffAssignment.staffAssignmentParts = e.staffAssignmentParts.filter(item =>
            moment(item.startDate).isBefore(estimateConfirmFormState.value.actualDate) || moment(item.startDate).isSame(estimateConfirmFormState.value.actualDate)
        ).map(item => {
          return {
            id: item.id,
            startDate: item.startDate,
            endDate: item.endDate,
            hoursPerDay: item.hoursPerDay
          }
        })
        //if(moment(staffAssignment.staffAssignmentParts[staffAssignment.staffAssignmentParts.length - 1].endDate).isAfter(estimateConfirmFormState.value.actualDate)){
        staffAssignment.staffAssignmentParts[staffAssignment.staffAssignmentParts.length - 1].endDate = estimateConfirmFormState.value.actualDate
        //}
        if(staffAssignment.staffAssignmentParts.length === 1) {
          staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
          estimateConfirmFormState.value.newHoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
          staffAssignment.staffAssignmentParts = []
        } else {
          estimateConfirmFormState.value.newHoursPerDay = staffAssignment.hoursPerDay
        }
      }
      segmenteData.value = staffAssignment.staffAssignmentParts
      currentOverlapData.value = {
        clientName:activeRow.value.account,
        engagementCode: activeRow.value.engagementCode,
        engagementDescription: activeRow.value.engagementDescription,
      }
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([staffAssignment.onBoardDate,staffAssignment.actualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(e.extensionAssumptionDate))
      if(e?.extensionAssumptionDate && moment(e.actualDate).isAfter(e?.extensionAssumptionDate)) {
        fcstDateVisible.value = true
        fcstExtensionDate.value = e
        fcstStaffAssignmentData.value = staffAssignment
      } else {
        $api.isTimeJudgments(staffAssignment).then(timeJudgmentsFunc);
      }
    };

    /**
     * PM下项目提交Func
     */
    const pmSubmitEstimateConfirm = () => {
      const result = {
        assignmentId: estimateConfirmFormState.value.assignmentId,
        actualDate: estimateConfirmFormState.value.actualDate,
        onBoardDate: "",
        releaseMode: estimateConfirmFormState.value.releaseMode,
        reason: estimateConfirmFormState.value.applyReason,
        staffAssignmentParts: segmenteData.value,
        hoursPerDay: estimateConfirmFormState.value.newHoursPerDay
      };
      $api.pmAssApply(result).then(() => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeEstimateConfirm();
        searchClick(activeTab.value, searchCondition);
        message.success("申请成功");
      });
    };

    /**
     * RM下项目提交Func
     */
    const rmSubmitEstimateConfirm = () => {
      const result = {
        assignmentId: estimateConfirmFormState.value.assignmentId,
        actualDate: estimateConfirmFormState.value.actualDate,
        onBoardDate: "",
        releaseMode: estimateConfirmFormState.value.releaseMode,
        reason: estimateConfirmFormState.value.applyReason,
        releaseSendmailDate:releaseSendmailDate.value,
        releaseEmailSendMode:releaseEmailSendMode.value,
        staffAssignmentParts: segmenteData.value,
        hoursPerDay: estimateConfirmFormState.value.newHoursPerDay,
        extensionAssumptionDate:  estimateConfirmFormState.value.extensionAssumptionDate,
        certainty: estimateConfirmFormState.value.certainty,
      };
      $api.rmReleaseAudit(result).then(() => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        closeEstimateConfirm();
        searchClick(activeTab.value, searchCondition);
        message.success("操作成功");
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
      });
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref('')
    const modalData = ref()
    const handleOk = () => {
      if(flag.value === 1) {
        const params = {
          id: activeRow.value.id,
        };
        $api.PMConfirm(params).then(() => {
          searchClick(activeTab.value, searchCondition);
          message.success("确定成功");
          // 获取待办数量
          store.dispatch("getTodoAllNum");
          if(isTodoStatus.value) {
            emit("goTodoBack")
          }
        });
      }else if(flag.value === 2){
        handleSaveAssignment(modalData.value)
      }else if(flag.value === 3){
        const params = {
          businessType: "2",
          fileId: modalData.value.id
        }
        $api.delTheAnnex(params).then(() => {
          message.success("删除成功！")
          getAnnexData(activeRow.value.id)
        })
      }else if(flag.value === 4 || flag.value === 5) {
        editAssignmentType = "rmRestartAssignment";
        const staffAssignment = {
          id: restartFormState.value.id,
          actualReleaseDate: restartFormState.value.assignmentTerm[1],
          hoursPerDay: restartFormState.value.hoursPerDay,
          onBoardDate: restartFormState.value.onBoardDate,
          userId: restartFormState.value.userId,
          staffAssignmentParts: segmenteData.value
        };
        currentOverlapData.value = {
          clientName:restartFormState.value.clientName,
          engagementCode: restartFormState.value.engagementCode,
          engagementDescription: restartFormState.value.engagementDescription,
        }
        localStorage.setItem('PreEightTimeLine',
            JSON.stringify([staffAssignment.onBoardDate,staffAssignment.actualReleaseDate])
        )
        $api.isTimeJudgments(staffAssignment).then(timeJudgmentsFunc);
      }
    }
    /**
     * PM上项目确定、PM下项目确定
     */
    const pmConfirm = () => {
      let title = "";
      if (activeRow.value.status === "2") {
        title =
          "确定上项目时间为原计划 Project Onboard Date（" +
          activeRow.value.onBoardDate +
          "），确定继续？";
      } else {
        title =
          "确定下项目时间为原计划 Release Date（" +
          activeRow.value.actualReleaseDate +
          "），确定继续？";
      }
      content.value = title
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 1
      modalData.value = ''
      modelValue.value = true
      /*Modal.confirm({
        title,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          const params = {
            id: activeRow.value.id,
          };
          $api.PMConfirm(params).then(() => {
            searchClick(activeTab.value, searchCondition);
            message.success("确定成功");
            // 获取待办数量
            store.dispatch("getTodoAllNum");
            if(isTodoStatus.value) {
              emit("goTodoBack")
            }
          });
        },
        centered: true,
        closable: true,
      });*/
    };

    /**
     * PM上项目申请
     */
    const pmOnBoardConfirm = () => {
      actionRole.value = "pm";
      openOnBoardConfirm();
    };

    /**
     * PM下项目申请
     */
    const pmEstimateConfirm = () => {
      actionRole.value = "pm";
      openEstimateConfirm();
    };

    const rmOnBoardConfirm = () => {
      actionRole.value = "rm";
      openOnBoardConfirm();
    };

    const rmEstimateConfirm = () => {
      actionRole.value = "rm";
      openEstimateConfirm();
    };

    const activeRow = ref({
      id: "",
    });
    const infoVisible = ref(false);

    const customRowFunc = (record) => {
      return {
        onClick: () => {
          activeRow.value = record;
          infoVisible.value = true;
          getAnnexData(activeRow.value.id)
          isShowAnnexRelated.value = record.replaceReason ? true : false
          codeTermData.value.projectStartDate = activeRow.value.codeStartDate ? activeRow.value.codeStartDate : ""
          codeTermData.value.projectCloseDate = activeRow.value.codeEndDate ? activeRow.value.codeEndDate : ""
        },
      };
    };
    const rowClassNameFunc = (record) => {
      if (record.isDeleted) {
        if (record.id === activeRow.value.id) {
          return "active-row deactivate-row";
        }
        return "deactivate-row";
      }
      if (record.id === activeRow.value.id) {
        return "active-row";
      }
      return null;
    };

    const closeInfo = () => {
      activeRow.value = {
        id: "",
      };
      infoVisible.value = false;
    };

    const infoHasBtn = computed(() => roleType.value !== "person");

    const editTimeVisible = ref(false);
    const editTimeFormState = ref({});
    const openEditTime = async (data) => {
      await $api.checkFormalCode({engagementCode:data.engagementCode}).then((res) => {
        isFormalCode.value = res.data
      });
      editTimeFormState.value = {
        ...activeRow.value,
        assignmentId: activeRow.value.id,
        assignmentTerm: [
          moment(formData.onBoardDate),
          moment(formData.actualDate? formData.actualDate : formData.actualReleaseDate),
        ],
        onBoardDate: formData.onBoardDate,
        actualDate: formData.actualDate? formData.actualDate : formData.actualReleaseDate,// 替换
        applyReason: "",
        originalActualDate: formData.actualDate? formData.actualDate : formData.actualReleaseDate,
        originalOnboardDate: formData.onBoardDate,
        projectCommitDate: data.projectCommitDate,
        hoursPerDay: formData.hoursPerDay,
        newHoursPerDay: formData.hoursPerDay,
        staffAssignmentParts: formData.staffAssignmentParts,
        clientName:formData.clientName,
        engagementCode: formData.engagementCode,
        engagementDescription: formData.engagementDescription,
        extensionAssumptionDate: formData.extensionAssumptionDate,
        certainty: formData.certainty,
      };
      editTimeVisible.value = true;
    };
    const closeEditTime = () => {
      editTimeFormState.value = {};
      editTimeVisible.value = false;
    };
    const editTimeSubmit = (segmentedData) => {
      editTimeFormState.value.onBoardDate = segmentedData.length > 0? segmentedData[0].startDate : editTimeFormState.value.onBoardDate
      editTimeFormState.value.actualDate = segmentedData.length > 0? segmentedData[segmentedData.length - 1].endDate : editTimeFormState.value.actualDate
      const staffAssignment = {
        id: editTimeFormState.value.assignmentId,
        actualReleaseDate: editTimeFormState.value.actualDate,// 替换
        hoursPerDay: editTimeFormState.value.newHoursPerDay,
        onBoardDate: editTimeFormState.value.onBoardDate,
        userId: activeRow.value.userId,
        extensionAssumptionDate: editTimeFormState.value.extensionAssumptionDate,
        certainty: editTimeFormState.value.certainty,
      };
      segmenteData.value = segmentedData
      staffAssignment.staffAssignmentParts = segmentedData
      editAssignmentType = "rmEditTime";
      currentOverlapData.value = {
        clientName:editTimeFormState.value.account,
        engagementCode: editTimeFormState.value.engagementCode,
        engagementDescription: editTimeFormState.value.engagementDescription,
      }
      localStorage.setItem('PreEightTimeLine',
          JSON.stringify([staffAssignment.onBoardDate,staffAssignment.actualReleaseDate])
      )
      localStorage.setItem('FcstEightTimeLine',
          JSON.stringify(editTimeFormState.value.extensionAssumptionDate)
      )
      $api.isTimeJudgments(staffAssignment).then(timeJudgmentsFunc);
    };
    const fillAssignmentRef = ref()
    const rmEditTimeFunc = () => {
      // const params = {
      //   assignmentId: editTimeFormState.value.assignmentId,
      //   onBoardDate: editTimeFormState.value.onBoardDate,
      //   actualDate: editTimeFormState.value.actualDate,// 替换
      //   reason: editTimeFormState.value.applyReason,
      //   projectCommitDate: editTimeFormState.value.projectCommitDate,
      //   projectStartDate: codeTermData.value.projectStartDate,
      //   projectCloseDate: codeTermData.value.projectCloseDate,
      // };
      // $api.rmEditAssignmentTime(params).then(() => {
      //   closeEditTime();
      //   searchClick(activeTab.value, searchCondition);
      //   message.success("修改成功");
      // });
      formData.assignmentId = editTimeFormState.value.assignmentId
      formData.projectCommitDate = editTimeFormState.value.projectCommitDate
      formData.onBoardDate = editTimeFormState.value.onBoardDate
      formData.actualDate = editTimeFormState.value.actualDate
      formData.assignmentTerm = [
        moment(editTimeFormState.value.onBoardDate),
        moment(editTimeFormState.value.actualDate),
      ];
      formData.hoursPerDay = editTimeFormState.value.newHoursPerDay
      formData.staffAssignmentParts = segmenteData.value
      closeEditTime();
      fillAssignmentRef.value.fcstDateValidator()
    };
    const codeTermData = ref({
      projectStartDate: '',
      projectCloseDate: '',
    })
    const selectedAssignmentIdList = ref([]);
    const selectedAssignmentList = ref([]);
    const selectedChange = (selectedIdList, selectedList) => {
      selectedAssignmentIdList.value = selectedIdList;
      selectedAssignmentList.value = selectedList;
    };

    const batchEditTimeVisible = ref(false);
    const actionType = ref("");
    const batchApplyClick = async(type) => {
      await handleIsFormalCode()
      actionType.value = type;
      actionRole.value = "pm";
      batchEditTimeVisible.value = true;
    };
    // 批量修改开始
    const modifyingVisible = ref(false)
    const initialDataList = ref([])
    const engageCodeOpts = ref([])
    const oldEngageCode = ref("")
    const descriptionOps = ref([])
    let orginalModifyingData = []
    const batchModifyClick = async () => {
      await getEngageCodeOpts()
      console.log(engageCodeOpts.value,"engageCodeOpts")
      console.log(descriptionOps.value,"descriptionOps")
      orginalModifyingData = selectedAssignmentList.value.map((item, index) => {
        return {
          assignmentId: item.id,
          index: index+1,
          candidateName: item.candidateName,
          hoursPerDay: item.hoursPerDay,
          estimateReleaseDate: item.estimateReleaseDate,
          onBoardDate: item.onBoardDate,
        }
      })
      initialDataList.value = cloneDeep(orginalModifyingData)
      modifyingVisible.value = true
    }

    const getEngageCodeOpts = async () => {
      const { data } = await $api.getCodeItems()
      engageCodeOpts.value = data.map((item, index) => {
        return {
          ...item,
          key: index,
          label: item.codeAndDescription,
          value: item.codeAndDescription,
        }
      })
      console.log(data,"data")
      descriptionOps.value = data.map((item, index) => {
        return {
          ...item,
          key: index,
          label: item.engagementDescription,
          value: item.engagementDescription,
        }
      })
    }
    const getTheDetailByCode = (engagementCode, key) => {
      const params = {
        engagementCode,
      }
      $api.getCodeDataByEngagementCode(params).then((res) => {
        if(res.data.length > 0) {
          if(!key&&key!=0){
            initialDataList.value = initialDataList.value.map((item) => {
              return item = Object.assign({},item, res.data[0])
            })
            titleEngagementDescription.value = initialDataList.value[0].engagementDescription
          }else{
          initialDataList.value[key] = Object.assign({},orginalModifyingData[key], res.data[0])

          }
        }else {
          message.error("暂无数据");
        }
      })
    }
    const resetDataList = (engagementCode, key) => {
      if(initialDataList.value.length > 0) {
        if(!key&&key!=0){
          titleEngagementDescription.value = ""
          initialDataList.value = initialDataList.value.map((ele) => {
            return Object.assign({}, ele, {
              engagementCode:"",
              engagementDescription:"",
              account : "",
              projectStartDate : "",
              projectCloseDate : "",
              engagementManagerName : "",
              engagementManagerNumber : "",
            })
          })
        }else{
        initialDataList.value[key].engagementDescription = ""
        initialDataList.value[key].account = ""
        initialDataList.value[key].projectStartDate = ""
        initialDataList.value[key].projectCloseDate = ""
        initialDataList.value[key].engagementManagerName = ""
        initialDataList.value[key].engagementManagerNumber = ""
        }
      }
    }
    const handleCheckAssignment = (dataList) => {
      const failedIndexList = []
      let isPass = true
      dataList.forEach((item, index) => {
        if(isDateConflict(item)) {
          isPass = false
          failedIndexList.push(index + 1)
        }
      })
      // 都通过校验 -调保存方法
      if(isPass) {
        handleSaveAssignment(dataList)
      }else {// 不通过 -弹窗提示
        content.value = `第${failedIndexList.join('、')}行，调令开始/结束时间与Code有效期冲突,请确定是否修改?`
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 2
        modalData.value = dataList
        modelValue.value = true
        /*Modal.confirm({
          title: "提示",
          content: `第${failedIndexList.join('、')}行，调令开始/结束时间与Code有效期冲突,请确定是否修改?`,
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          onOk() {
            handleSaveAssignment(dataList)
          },
        });*/
      }
    }
    const isDateConflict = (data) => {
      let flag = false
      // Project Start Date≤On Board Date≤Estimate Release Date≤Project Closed Date
      if(moment(data.projectStartDate) > moment(data.onBoardDate)) {
        flag = true
        return flag
      }
      if(data.projectCloseDate && (moment(data.estimateReleaseDate)) > moment(data.projectCloseDate)) {
        flag = true
        return flag
      }
      return flag
    }
    const handleSaveAssignment = async (paramList) => {
      const params = {
        codeDataPojoList: paramList
      }
      const { code } = await $api.bachSaveAssignments(params)
      if(code === 200) {
        message.success("保存成功！");
        modifyingVisible.value = false;
        searchClick(activeTab.value, searchCondition);
        emit("getAllDescOps")
      }
    }
    // 批量修改结束
    //重置页码
    const resetCurrent = () => {
      pagination.value.current = 1;
    };
    const closeBatchModal = () => {
      actionRole.value = "";
      batchEditTimeVisible.value = false;
    };
    const refreshTable = () => {
      searchClick(activeTab.value, searchCondition);
    };
    watch(activeTab, () => {
      selectedAssignmentIdList.value = [];
      selectedAssignmentList.value = [];
      if(activeTab.value === '1') {
        columns.value = currentColumns.value.filter(item=>item.dataIndex !== 'actualReleaseDate')
      } else {
        columns.value = currentColumns.value;
      }
      getSize()
    });
    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight(true, ".ant-table", "", "", 60);
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });
    onActivated(()=>{

    })

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    onBeforeRouteLeave(() => {
      if (isTodoStatus.value) {
        store.commit("SET_IS_TODO_STATUS", false);
      }
      pagination.value.current = 1
      getActiveTab.value = ''
    });
    watch(
        () => route.query.id,
        (val) => {
          if(val) {
            getActiveTab.value = ''
            noticePreMatchingId.value = route.query.id
            searchClick(activeTab.value, searchCondition);
          }
        }
    )
    const batchEditTimeAssignmentVisible = ref(false);

    // 附件相关
    const isShowAnnexRelated = ref(false)
    const annexColumns = [
      {
        title: "名称",
        dataIndex: "fileName",
        key: "fileName",
        width: 150,
        ellipsis: true,
      },
      {
        title: "时间",
        dataIndex: "operationTime",
        key: "operationTime",
        width:150,
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      }
    ]
    const annexDataList = ref([])
    const getAnnexData = (id) => {
      $api.getAnnexList(id).then((res) => {
        annexDataList.value = res.data
      })
    }
    const updateFileList = (fileList) => {
      if(fileList?.length > 4) {
        message.error("单次上传附件数量上限为4个，请合理安排分批上传。")
        return false
      }
      if(fileList?.length + annexDataList.value?.length > 8) {
        message.error("累计上传附件数量上限为8个，当前已超出上限，详情请联系Operation。")
        return false
      }
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileRole", 'RM');
      formData.append("id", activeRow.value.id);
      $api.uploadAnnex(formData).then(({code}) => {
        if(code === 200) {
          message.success("上传成功！")
          getAnnexData(activeRow.value.id)
        }
      })
    }
    // 下载附件
    const downloadAnnexClick = (annexInfo) => {
      const params = {
        businessType: "2",
        fileId: annexInfo.id
      }
      $api.downloadTheAnnex(params).then((res) => {
        downloadFile(res.file, annexInfo.fileName);
      })
    }

    // 删除附件
    const deleteAnnexClick = (annexInfo) => {
      content.value = '确定删除这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 3
      modalData.value = annexInfo
      modelValue.value = true
      /*Modal.confirm({
        title: "确定删除这条数据吗?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          const params = {
            businessType: "2",
            fileId: annexInfo.id
          }
          $api.delTheAnnex(params).then(() => {
            message.success("删除成功！")
            getAnnexData(activeRow.value.id)
          })
        },
      });*/

    }
    // 替换附件
    const replaceAnnexClick = (fileList, data) => {
      if(fileList?.length > 1) {
        message.error("只能替换一个文件。")
        return false
      }
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileRole", 'RM');
      formData.append("businessType", '2');
      formData.append("fileId", data.id);
      $api.replaceTheAnnex(formData).then(() => {
        message.success("替换成功！")
        getAnnexData(activeRow.value.id)
      })
    }
    //分段数据
    const segmenteData = ref([])

    /**
     * RM重启调令
     */
    const restartAssignmentClick = () => {
      restartFormState.value = {
        ...activeRow.value,
        assignmentTerm: [
          moment(activeRow.value.onBoardDate),
          moment(activeRow.value.actualReleaseDate),
        ],
        originalActualDate: activeRow.value.actualReleaseDate,
        originalOnboardDate: activeRow.value.onBoardDate,
        clientName:activeRow.value.account,
        engagementCode: activeRow.value.engagementCode,
        engagementDescription: activeRow.value.engagementDescription,
      }
      restartAssignmentVisible.value = true
    }
    const restartAssignmentVisible = ref(false)
    const restartAssignmentClose = () => {
      restartAssignmentVisible.value = false
    }
    const restartAssignmentSubmit = (segmentedData,isSendUpdateEmail) => {
      segmenteData.value = segmentedData
      restartFormState.value.isEmail = isSendUpdateEmail
      if(moment().isBefore(restartFormState.value.assignmentTerm[1]) || moment(restartFormState.value.assignmentTerm[1]).isSame(moment(), "day")){
        flag.value = 4
        content.value = "是否确认申请重启调令？"
      } else {
        flag.value = 5
        content.value = "您当前选择的下项目日期早于当日，审批通过后调令将不会重启，请确定是否修改调令时间？"
      }
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
    }
    const restartFormState = ref({});
    const rmRestartAssignmentFunc = () => {
      const params = {
        id: restartFormState.value.id,
        actualReleaseDate: restartFormState.value.assignmentTerm[1],
        estimateReleaseDate: restartFormState.value.assignmentTerm[1],
        hoursPerDay: restartFormState.value.hoursPerDay,
        onBoardDate: restartFormState.value.onBoardDate,
        isEmail: restartFormState.value.isEmail,
        reason: restartFormState.value.applyReason,
        staffAssignmentParts: segmenteData.value
      }
      $api.rmRestartAssignment(params).then(() => {
        // 获取待办数量
        store.dispatch("getTodoAllNum");
        restartAssignmentVisible.value = false
        searchClick(activeTab.value, searchCondition);
        message.success("操作成功");
        if(isTodoStatus.value) {
          emit("goTodoBack")
        }
      });
    }

    /**
     * rmleader(Jiang tingting)审批重启调令
     */
    const restartAssignmentConfirm = async () => {
      const {data} = await $api.reopenAssignmentInfo(activeRow.value.id)
      Object.assign(restartData.value , activeRow.value, data)
      restartConfirmVisible.value = true
    }
    const restartConfirmVisible = ref(false)
    const restartData = ref({})
    const restartConfirmClose = () => {
      restartConfirmVisible.value = false
    }
    const restartConfirmSubmit = () => {
      restartConfirmVisible.value = false
      searchClick(activeTab.value, searchCondition);
    }

    /**
     * rm Release/确定/审批 后如果Rlease时间在FCST Extension Date范围外时弹出弹窗
     */
    const fcstDateVisible = ref(false)
    const fcstExtensionDate = ref()
    //8小时检验接口传参
    const fcstStaffAssignmentData = ref()
    const fcstClose = () => {
      fcstDateVisible.value = false
    }
    const fcstSubmit = (e) => {
      fcstDateVisible.value = false
      estimateConfirmFormState.value.extensionAssumptionDate= e?.extensionAssumptionDate
      estimateConfirmFormState.value.certainty= e?.certainty
      fcstStaffAssignmentData.value.extensionAssumptionDate= e?.extensionAssumptionDate
      fcstStaffAssignmentData.value.certainty= e?.certainty
      localStorage.setItem('FcstEightTimeLine', JSON.stringify(fcstStaffAssignmentData.value.extensionAssumptionDate))
      $api.isTimeJudgments(fcstStaffAssignmentData.value).then(timeJudgmentsFunc);
    }

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const isRMLeader = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("11") !== -1
    })

    return {
      // route,
      roles,
      roleType,
      assignmentCondition,
      serviceLine,
      subServiceLine,
      currentColumns,
      columns,
      assDetails,
      selectedAssignmentIdList,
      selectedAssignmentList,
      selectedChange,
      pagination,
      assignmentVisible,
      modifyInfo,
      modifyDisabled,
      searchClick,
      appraisesVisible,
      releaseVisible,
      formState,
      releaseFormState,
      openAppraises,
      closeAppraises,
      submitAppraises,
      formData,
      showReason,
      openRelease,
      closeRelease,
      submitRelease,
      closeAssignmentModal,
      submitAssignmentModal,
      onBoardConfirmVisible,
      onBoardConfirmFormState,
      openOnBoardConfirm,
      closeOnBoardConfirm,
      submitOnBoardConfirm,
      estimateConfirmVisible,
      estimateConfirmFormState,
      openEstimateConfirm,
      closeEstimateConfirm,
      submitEstimateConfirm,
      pmConfirm,
      pmOnBoardConfirm,
      pmEstimateConfirm,
      rmOnBoardConfirm,
      rmEstimateConfirm,
      activeRow,
      customRowFunc,
      rowClassNameFunc,
      activeTab,
      actionRole,
      infoVisible,
      infoHasBtn,
      closeInfo,
      editTimeVisible,
      openEditTime,
      editTimeFormState,
      closeEditTime,
      editTimeSubmit,
      batchEditTimeVisible,
      batchApplyClick,
      closeBatchModal,
      refreshTable,
      isTodoStatus,
      tableWidth,
      tableHeight,
      batchEditTimeAssignmentVisible,
      actionType,
      overlapModalVisible,
      overlapData,
      timeJudgmentSubmitFunc,
      annexColumns,
      annexDataList,
      getAnnexData,
      downloadAnnexClick,
      deleteAnnexClick,
      replaceAnnexClick,
      updateFileList,
      isShowAnnexRelated,
      isConsultant,
      isPm,
      clientNameOps,
      industryLineOps,
      // getClientInfo,
      batchModifyClick,
      modifyingVisible,
      initialDataList,
      engageCodeOpts,
      descriptionOps,
      oldEngageCode,
      getTheDetailByCode,
      handleCheckAssignment,
      originalCodeAndDateObj,
      accountIndustryRelaction,
      codeTermData,
      isFormalCode,
      handleIsFormalCode,
      codeRelationList,
      handlegetActiveTab,
      noticePreMatchingId,
      resetDataList,
      batchEditTimeClick,
      ismodifyInfo,
      releaseSendmailDate,
      releaseEmailSendMode,
      modelValue,
      content,
      showIconCom,
      handleOk,
      segmenteData,
      currentOverlapData,
      resetCurrent,
      restartAssignmentClick,
      restartAssignmentVisible,
      restartAssignmentClose,
      restartAssignmentSubmit,
      restartFormState,
      rmRestartAssignmentFunc,
      restartAssignmentConfirm,
      restartConfirmVisible,
      restartConfirmClose,
      restartData,
      restartConfirmSubmit,
      fillAssignmentRef,
      fcstDateVisible,
      fcstExtensionDate,
      fcstClose,
      fcstSubmit,
      fcstStaffAssignmentData,
      isRM,
      isRMLeader,
      titleEngagementDescription
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
.assignment-list {
  ::v-deep .ant-table-body {
    overflow: auto;
  }
}

.assignment-list {
  flex: 1;
  overflow: auto;
  display: flex;
  background-color: #fff;
  width: calc(100% - 40px);
  // height: calc(100% - 188px);
  position: relative;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  :deep(.org-info) {
    width: 700px;
    //margin-left: 20px;
    background: #f4f4f4;
  }

  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

:deep(.ant-table-body) {
  .active-row {
    background: #e8f4ff !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e8f4ff !important;
    }
  }

  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }

  .deactivate-row.active-row {
    background: #e0e0e0 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e0e0e0 !important;
    }
  }
}

.header {
  margin: 0 20px;
  padding: 0;

  span {
    margin-right: 40px;
  }
}

.service-line {
  margin: 0 20px;

  span {
    margin-right: 40px;
    font-size: 18px;
  }
}
</style>
