<template>
  <a-drawer
    :visible="visible"
    @close="pervClick"
    width="38.5vw"
    title="批量修改 Assignment 时间确定"
    :getContainer="() => getContainerRef"
  >
    <div class="cards">
      <div class="card" v-for="item in list" :key="item.id">
        <div class="name">
          <span>{{ item.fullName }}</span>
        </div>
        <div class="item">
          <span class="label">GGID:</span>
          <span class="value">{{ item.idNumber }}</span>
        </div>

        <div class="item">
          <span>
            <span class="label"><span style="color: #1890FF" v-if="item.oldStaffAssignmentParts.length > 0">●</span>原Assignment Term:</span>
            <span class="value">{{ item.range }}</span>
          </span>
          <span>
            <span class="hourLabel">原Hours Per Day:</span>
            <span class="value">{{ item.hoursPerDay }}小时</span>
          </span>
        </div>
        <div v-for="(item, index) in item.oldStaffAssignmentParts" :key="index" class="item">
          <span v-if="item.startDate">
            <span>
              <span class="label"><span style="color: #77CAED">●</span>原Assignment Term{{index + 1}}:</span>
              <span class="value">{{item.startDate + "~" + item.endDate }}</span>
            </span>
            <span>
              <span class="hourLabel">原Hours Per Day:</span>
              <span class="value">{{item.hoursPerDay}}小时</span>
            </span>
          </span>
        </div>

        <div class="item">
          <span>
            <span class="label"><span style="color: #1890FF" v-if="item.staffAssignmentParts.length > 0">●</span>修改Assignment Term:</span>
            <span class="value">
              <span :style="`color: ${ item.onBoardDate !== item.newRange[0] ? '#12ABDB' : ''}`">{{ item.newRange[0]}}</span>
              <span>{{'~'}}</span>
              <span :style="`color: ${ item.actualReleaseDate !== item.newRange[1] ? '#12ABDB' : ''}`">{{ item.newRange[1]}}</span>
            </span>
          </span>
          <span>
            <span class="hourLabel">修改Hours Per Day:</span>
            <span class="value" :style="`color: ${ item.hoursPerDay !== item.newHoursPerDay ? '#12ABDB' : ''}`">{{ item.newHoursPerDay }}小时</span>
          </span>
        </div>
        <div v-for="(i, staffIndex) in item.staffAssignmentParts" :key="staffIndex" class="item">
          <span>
          <span class="label"><span style="color: #77CAED">●</span>修改Assignment Term{{staffIndex + 1}}:</span>
            <span class="value" :style="`color: ${ staffIndex >= item.oldStaffAssignmentParts.length ? '#12ABDB' : item.oldStaffAssignmentParts[staffIndex].startDate !== i.startDate ? '#12ABDB' : ''}`">{{i.startDate}}</span>
            <span style="color:#999999 ">{{'~'}}</span>
            <span class="value" :style="`color: ${staffIndex >= item.oldStaffAssignmentParts.length ? '#12ABDB' : item.oldStaffAssignmentParts[staffIndex].endDate !== i.endDate ? '#12ABDB' : ''}`">{{i.endDate}}</span>
          </span>
          <span>
          <span class="hourLabel">修改Hours Per Day:</span>
          <span class="value" :style="`color: ${staffIndex >= item.oldStaffAssignmentParts.length ? '#12ABDB' : item.oldStaffAssignmentParts[staffIndex].hoursPerDay !== i.hoursPerDay ? '#12ABDB' : ''}`">{{i.hoursPerDay}}小时</span>
          </span>
        </div>

        <div class="item">
          <span class="label">Code 预计开出时间:</span>
          <span class="value" v-if="isTBDCode">{{ item.projectCommitDate }}</span>
          <span class="value" v-else>仅适用于TBD Code</span>
        </div>
        <div class="item">
          <span style="width: 19vw">
            <span class="label" style="width: 14vw">原FCST Extension Assumption Date:</span>
            <span class="value" style="margin-left: 0.5vw;">{{item.oldExtensionAssumptionDate?item.oldExtensionAssumptionDate: '-' }}</span>
          </span>
          <span>
            <span class="hourLabel">原确定性:</span>
            <span class="value">{{item.oldCertainty?item.oldCertainty:'-'}}</span>
          </span>
        </div>
        <div class="item">
          <span style="width: 19vw">
            <span class="label" style="width: 14vw">修改FCST Extension Assumption Date:</span>
            <span class="value" style="margin-left: 0.5vw;">{{item.extensionAssumptionDate?item.extensionAssumptionDate: '-' }}</span>
          </span>
          <span>
            <span class="hourLabel">修改确定性:</span>
            <span class="value">{{item.certainty?item.certainty:'-'}}</span>
          </span>
        </div>
        <div class="item">
          <span class="label">备注:</span>
          <span class="value">{{ item.reason }}</span>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <a-space style="float: right">
        <a-button type="primary" @click="pervClick">上一步</a-button>
        <a-button type="primary" @click="saveClick">确定</a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import { defineComponent } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "BatchConfirmModal",
  props: {
    visible: Boolean,
    editData: {
      type: Object,
      default: () => {},
    },
    isTBDCode: {
      type: Boolean,
      default: false,
    },
    list: Array,
    idList: Array,
    role: String,
    activeTab: String,
    getContainerRef: Object,
  },
  emits: ["cancel-modal", "prev-click", "refresh-table"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const pervClick = () => {
      emit("prev-click");
    };

    const saveClick = () => {
      const params = {
        assignmentList:props.list.map((item) => ({
          ...item,
          assignmentId: item.id,
          newActualReleaseDate: item.newRange[1],
          newOnBoardDate: item.newRange[0]
        })),
      }
      console.log(params)
      $api
        .rmBatchModifyTime(params)
        .then(() => {
          message.success("操作成功");
          emit("refresh-table");
        });
    };

    return {
      pervClick,
      saveClick,
    };
  },
});
</script>

<style lang="less" scoped>
.cards {
  .card {
    margin: 24px;
    padding: 12px;
    background: #f7fafc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    .name {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
      margin-bottom: 12px;
    }
    .item {
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 8px;
      display: flex;

      .label {
        color: #2d3748;
        width: 10vw;
        display: inline-block;
        flex-shrink: 0;
      }
      .hourLabel {
        color: #2d3748;
        width: 8vw;
        display: inline-block;
        flex-shrink: 0;
        margin-left: 1.5vw;
      }

      .value {
        color: #999999;
        width: 100%;
        word-break: break-all;
      }
    }
  }
}
</style>
