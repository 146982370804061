<template>
    <a-drawer class="drawer" width="86.4vw" :title="title">
        <!-- <div class="feature-line">
            <a-button style="margin-left:8px;" @click="handleCancel">取消</a-button>
            <a-button type="primary" style="margin-left:8px;" @click="handleConfirm">确定</a-button>
        </div> -->
      <div v-if="isPreview" class="table box-padding">
        <div class="black"><span>邮件主题: &nbsp;&nbsp;</span><span>{{ templateData.subject }}</span></div>
        <div class="black"><span>CC: &nbsp;&nbsp;</span><span>{{ccPersonList.join(';')}}</span></div>
        <div class="gray">
          <span v-html="templateData.remarks?.replace(/\n/g,'<br />')"></span>
        </div>
      </div>
      <div v-if="!isPreview" class="table box-padding">
        <p>邮件主题: &nbsp;&nbsp;{{ templateData.subject }}</p>
        <div style="margin-top: 30px" class="rms-form-item">
            <div class="label">CC</div>
            <SelectWithAll :disabled="isPreview" class="item" v-model:value="ccPersonList" placeholder="请选择" allowClear
                showArrow mode="multiple" :maxTagCount="1" @dropdownVisibleChange="ccPersonListChange">
                <a-select-option v-for="item in CClist" :key="item.fullName + ' ' + item.email"
                    :value="item.fullName + ' ' + item.email"><span :title="item.fullName + ' ' + item.email">{{
                        item.fullName + " " + item.email
                    }}</span>
                </a-select-option>
            </SelectWithAll>
        </div>

        <p style="margin-top: 30px">Welcome Letter邮件模板</p>
        <p class="text">
            <!--
            欢迎
            <span class="operationLink">{{ templateData.fullName }}</span>正式加入
            <span class="operationLink">{{ templateData.orgFourOneOne }}</span>团队,
            <span class="operationLink">{{ templateData.orgFourOneOne }}</span>
            部门负责人是<span class="operationLink">{{ templateData.departmentManager }}</span>。
        -->
            <a-textarea :disabled="isPreview" style="margin-top: 15px" v-model:value="templateData.remarks"
                :auto-size="{ minRows: 1 }" :maxlength="500" showCount />
        </p>
        <div class="btn-box box-padding">
          <a-button v-show="!isPreview" class="btn-add" type="primary" @click="handledadd">
            <PlusOutlined />新增
          </a-button>
        </div>
      </div>
        <a-table v-if="isPreview" class="box-padding" :class="[isPreview ? 'nodrag' : '']" style="margin-top: 15px;" :dataSource="dataList"
            :columns="columnsIspreview" :pagination="false" :rowKey="(record) => record.userId">
        </a-table>

        <a-table v-else class="box-padding" :customRow="customRow" style="margin-top: 15px;" :dataSource="dataList" :columns="columns"
            :pagination="false" :rowKey="(record) => record.userId">
            <template #position="{ index }">
                <a-select style="width: 130px" @change="changePosition($event, index)"
                    v-model:value="dataList[index].position" placeholder="请选择" :options="positionlist" />
            </template>
            <template #email="{ index, record }">
                <a-select style="width: 180px" @change="changeemail($event, index)" v-model:value="dataList[index].email"
                    placeholder="请选择" show-search  @click="emailClick(index,record)">
                    <a-select-option v-for="item in CClistRh" :value="item.email" :key="item.email">{{ item.email
                    }}</a-select-option>
                </a-select>
            </template>
            <template #description="{ index }">
                <a-textarea style="position: relative" v-model:value="dataList[index].description"
                    :auto-size="{ minRows: 1, maxRows: 3 }" :maxlength="50" />
            </template>
            <template #action="{ index }">
                <a-space class="flex-box">
                    <!-- <span v-if="dataList.length === (index + 1)" class="operationLink" @click="handledadd(index)">添加</span> -->
                    <a-tooltip class="tooltip-list" placement="top" color="#fff">
                      <template #title>
                        <span class="font-color">删除</span>
                      </template>
                      <span class="operationLinkdel icon-btn" @click="handledel(index)"><DeleteOutlined :style="{display:'block'}" /></span>
                    </a-tooltip>
                </a-space>
            </template>
        </a-table>

        <!-- 调令 -->
        <div class="box-padding" style="margin-top: 30px;">
            <div class="add-comment gray">
                <RightOutlined class="img-svg" v-if="!showassignmentdata" @click="showClick" />
                <DownOutlined class="img-svg" v-if="showassignmentdata" @click="showClick" />
                <p>
                    添加调令内容：如下是您即将进入的项目信息，详细信息请参考 （
                    <span class="operationLinklist">staffingmanagement.ch@capgemini.com</span>
                    ）邮箱发送的调令邮件，邮件标题【RMS-Assignment Letter】顾问-项目名称。
                </p>
            </div>
            <div v-if="showassignmentdata">
                <a-button v-show="!isPreview" class="btn-addIdc" @click="handledTransferOrder" type="primary">
                    <PlusOutlined />新增
                </a-button>
                <a-table v-if="isPreview" :class="[isPreview ? 'nodrag' : '']" :dataSource="assignmentdata"
                    :columns="columns2isPreview" :pagination="false">
                </a-table>
                <a-table v-else :customRow="customRowAssign" :dataSource="assignmentdata" :columns="columns2"
                    :pagination="false">
                    <template #pl="{ index }">
                        <!-- <a-textarea v-model:value="assignmentdata[index].pl" :auto-size="{ minRows: 1, maxRows: 1 }" /> -->
                        <a-select style="width: 110px" v-model:value="assignmentdata[index].pl" placeholder="请选择" showArrow
                            allowClear :maxTagCount="1" @change="orgAccountPLChange($event, 'pl', index)" show-search>
                            <a-select-option v-for="(item, index) in paramListObj.plOps" :key="index" :value="item"><span
                                    :title="item">{{ item }}</span>
                            </a-select-option>
                        </a-select>
                    </template>
                    <template #account="{ index }">
                        <!-- <a-textarea v-model:value="assignmentdata[index].account"
                            :auto-size="{ minRows: 1, maxRows: 1 }" /> -->
                        <a-select style="width: 110px" v-model:value="assignmentdata[index].account" showArrow allowClear
                            :maxTagCount="1" placeholder="请选择" @change="orgAccountPLChange($event, 'account', index)"
                            show-search>
                            <a-select-option v-for="(item, index) in paramListObj.accountOps[index]" :key="index"
                                :value="item"><span :title="item">{{ item }}</span>
                            </a-select-option>
                        </a-select>
                    </template>
                    <template #engagementDescription="{ index }">
                        <a-textarea v-model:value="assignmentdata[index].engagementDescription"
                            :auto-size="{ minRows: 1, maxRows: 1 }" />
                    </template>
                    <template #engagementManager="{ index }">
                        <a-select style="width: 180px" v-model:value="assignmentdata[index].engagementManager"
                            placeholder="请选择" show-search>
                            <a-select-option v-for="item in CClist" :value="item.fullName + ' ' + item.email"
                                :key="item.fullName + ' ' + item.email">{{ item.fullName + " " + item.email
                                }}</a-select-option>
                        </a-select>
                    </template>
                    <template #action="{ index }">
                        <a-space class="flex-box">
                            <a-tooltip class="tooltip-list" placement="top" color="#fff">
                              <template #title>
                                <span class="font-color">删除</span>
                              </template>
                              <span class="operationLinkdel icon-btn" @click="handleOrderDel(index)"><DeleteOutlined :style="{display:'block'}" /></span>
                            </a-tooltip>
                        </a-space>
                    </template>
                </a-table>
            </div>
        </div>
        <div class="box-padding" style="margin-top: 30px;">
          <div class="gray">
            <p>如下是您所属 <span class="operationLinklist">{{ templateData.orgFourOneOne }}</span>团队的IDC
              Code,适用于年假、病假、培训等情况.每个IDC
              Code下面有多种Task,可在Timecard系统里点击"Task"字段下面的放大镜图标,然后点击"go"进行选择.如不清楚适用不同情况应使用哪种IDC Code,请向RM咨询。</p>
          </div>
            <a-button v-show="!isPreview" class="btn-addIdc" type="primary" @click="handledaddIdc">
                <PlusOutlined />新增
            </a-button>
            <a-button v-show="!isPreview" class="btn-addIdc" style="margin-right: 5px" type="primary"
                @click="handledUpdateIdc">
                更改
            </a-button>
            <a-table v-if="isPreview" :class="[isPreview ? 'nodrag' : '']" :dataSource="idcCodeList"
                :columns="columns3isPreview" :pagination="false">
            </a-table>
            <a-table v-else :customRow="customRowIdc" :dataSource="idcCodeList" :columns="columns3" :pagination="false">
                <template #entity="{ index }">
                    <a-select class="sel-with" v-model:value="idcCodeList[index].entity" placeholder="请选择"
                        :options="entityList" allowClear @change="entityChange($event, index)" />
                </template>
                <template #organization="{ index }">
                    <a-select class="sel-with" v-model:value="idcCodeList[index].organization" placeholder="请选择"
                        show-search allowClear @change="organizationChange($event, index)" showArrow>
                        <a-select-option v-for="item in organizationList" :value="item" :key="item">
                            <span :title="item">{{ item }}</span></a-select-option>
                    </a-select>
                </template>
                <template #projectNumber="{ index, record }">
                    <a-select class="sel-with" v-model:value="idcCodeList[index].projectNumber"
                        @change="projectNumberChange($event, index)" placeholder="请选择" show-search allowClear
                        @dropdownVisibleChange="projectNumberOps($event, record)">
                        <a-select-option v-for="item in projectNumlists" :value="item.value" :key="item.key">{{ item.label
                        }}</a-select-option>
                    </a-select>
                </template>
                <template #projectName="{ index, record }">
                    <a-select class="sel-with" v-model:value="idcCodeList[index].projectName"
                        @change="projectNameChange($event, index)" placeholder="请选择" show-search allowClear
                        @dropdownVisibleChange="projectNameOps($event, record)" showArrow>
                        <a-select-option v-for="item in projectNamelists" :value="item" :key="item">
                            <span :title="item">{{ item }}</span></a-select-option>
                    </a-select>
                </template>
                <template #action="{ index }">
                    <a-space class="flex-box">
                      <a-tooltip class="tooltip-list" placement="top" color="#fff">
                        <template #title>
                          <span class="font-color">删除</span>
                        </template>
                        <span class="operationLinkdel icon-btn" @click="handledelIdc(index)"><DeleteOutlined :style="{display:'block'}" /></span>
                      </a-tooltip>
                    </a-space>
                </template>
            </a-table>
        </div>
        <div class="box-padding" style="margin-top: 30px;">
            <p><span class="operationLink-entry">入职Checklist：</span></p>
          <div class="gray">
            <p>为帮助您更好的快速适应公司和团队，请仔细阅读附件入职指引PPT, 并建议于入职两周内解锁信息：</p>
            <p>•培训&必修课（未按时完成课程，账号将会被冻结，且完成结果与Learning KPI关联） </p>
            <p>•预计入职材料交齐一周后可使用GTE考勤系统Timecard（累计两次未按时提交，将会扣除500元） </p>
            <p>•入职一周内完成RMS系统信息维护&凯捷模板简历更新（请参考附件CG China CV
              Template编辑个人简历，根据PPT内RMS系统登陆方法及维护要求，修改个人信息并上传简历） </p>
          </div>
        </div>
        <!-- <div v-if="templateIdFlag === '0'" style="margin-top: 30px;">
            <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true"
                accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar" :beforeUpload="handleBeforeUpload"
                :remove="removeFile">
                <p class="ant-upload-drag-icon">
                    <UploadOutlined />
                </p>
                <p class="ant-upload-text">上传文件</p>
            </a-upload-dragger>
            <div class="annex-alert-info">
                <a-alert message="提示:" type="warning" showIcon>
                    <template #icon><smile-outlined /></template>
                    <template #description>
                        <p>1、可上传除入职指引和简历模板外其他附件，最多上传3个附件，小于50M</p>
                        <p>2、可上传附件格式doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar</p>
                    </template>
                </a-alert>
            </div>
        </div> -->
        <!-- 简历模板列表 -->
        <div class="table box-padding">
            <a-table :data-source="tableListResume" size="small" :columns="columnsResume" :rowKey="(record) => record.id"
                class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
                <template #icon="{ record, text }">
                    <FileZipFilled class="zip" v-if="record.fileName.indexOf('zip') !== -1" />
                    {{ text }}
                </template>
            </a-table>
            <p class="blue">
                <ExclamationCircleOutlined />&nbsp;&nbsp;默认附件，凯捷统一模板不可更改
            </p>
        </div>
        <!-- 入职指引列表 -->
        <div class="table box-padding">
            <a-button v-if="tableListEntry == null || tableListEntry?.length == 0 && !isPreview" class="others-files"
                type="primary" @click="uploadClick1">上传入职指引</a-button>
            <a-table :data-source="tableListEntry" size="small" :columns="columnsEntry" :rowKey="(record) => record.id"
                class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
                <template #icon="{ record, text }">
                    <FilePptFilled class="ppt" v-if="record.fileName.indexOf('ppt') !== -1" />
                    <FileZipFilled class="zip" v-else-if="record.fileName.indexOf('zip') !== -1" />
                    <FileWordFilled class="word" v-else />
                    {{ text }}
                </template>
                <template #operation="{ record }">
                    <a-space class="flex-box">
                        <a-tooltip class="tooltip-list" placement="top" color="#fff">
                          <template #title>
                            <span class="font-color">预览</span>
                          </template>
                          <span v-if="record.fileName?.indexOf('zip') !== -1" class="operationLinkgray icon-btn"
                                v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
                          <span v-else class="operationLinklist icon-btn" @click="preview(record, 1)"
                                v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
                        </a-tooltip>
                        <a-tooltip class="tooltip-list" placement="top" color="#fff">
                          <template #title>
                            <span class="font-color">上传</span>
                          </template>
                          <span v-if="!isPreview" class="operationLinklist icon-btn" @click="uploadClick()"
                                v-auth="['system:roleMaintain:preview']"><UploadOutlined :style="{display:'block'}" /></span>
                        </a-tooltip>
                        <a-tooltip class="tooltip-list" placement="top" color="#fff">
                          <template #title>
                            <span class="font-color">删除</span>
                          </template>
                          <span v-if="!isPreview" class="operationLinkdel icon-btn" @click="deleteClick(record)"
                                v-auth="['system:roleMaintain:preview']"><DeleteOutlined :style="{display:'block'}" /></span>
                        </a-tooltip>
                    </a-space>
                </template>
            </a-table>
            <p class="blue">
                <ExclamationCircleOutlined />&nbsp;&nbsp;默认附件，可在入职指引模块更新，也可通过上传更新
            </p>
        </div>
        <!-- 其他附件列表 -->
        <div class="table box-padding">
            <a-button v-show="!isPreview" class="others-files" type="primary" @click="uploadOthersFiles">上传其他附件</a-button>
            <a-table :data-source="tableListOthers" size="small" :columns="columnsOthers" :rowKey="(record) => record.id"
                class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
                <template #icon="{ record, text }">
                    <FilePptFilled class="ppt" v-if="record.fileName.indexOf('ppt') !== -1" />
                    <FileZipFilled class="zip" v-else-if="record.fileName.indexOf('zip') !== -1" />
                    <FileWordFilled class="word" v-else />
                    {{ text }}
                </template>
                <template #operation="{ record }">
                    <a-space class="flex-box">
                      <a-tooltip class="tooltip-list" placement="top" color="#fff">
                        <template #title>
                          <span class="font-color">预览</span>
                        </template>
                        <span v-if="record.fileName?.indexOf('zip') !== -1" class="operationLinkgray icon-btn"><FileImageOutlined :style="{display:'block'}" /></span>
                        <span v-else class="operationLinklist icon-btn" @click="preview(record, 2)"><FileImageOutlined :style="{display:'block'}" /></span>
                      </a-tooltip>
                      <a-tooltip class="tooltip-list" placement="top" color="#fff">
                        <template #title>
                          <span class="font-color">删除</span>
                        </template>
                        <span v-show="!isPreview" class="operationLinkdel icon-btn" @click="deleteOtherFilesFun(record)"><DeleteOutlined :style="{display:'block'}" /></span>
                      </a-tooltip>
                    </a-space>
                </template>
            </a-table>
            <p class="blue">
                <ExclamationCircleOutlined />&nbsp;&nbsp;最多上传三个附件
            </p>
        </div>
        <div ref="demoModalRef">
          <!-- 预览 -->
          <a-modal
              :getContainer="$refs.demoModalRef"
              v-model:visible="previewVisible"
              title="预览"
              width="62.5vw"
              :z-index="10001"
              @cancel="closePreviewModal"
              centered
          >
            <template #footer>
              <a-button @click="closePreviewModal">关闭</a-button>
            </template>
            <iframe id="printIframe" :src="previewUrl" style="width: 100%; height: 744px; border: none"></iframe>
          </a-modal>
          <!-- 上传附件 -->
          <a-modal
              :getContainer="$refs.demoModalRef"
              class="demo-modal"
              v-model:visible="uploadOthersVisible"
              width="29.2vw"
              title="上传其他附件"
              cancelText="取消"
              okText="确定"
              @ok="handleUploadOk"
              :z-index="10001"
              centered
          >
            <div>
              <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true"
                                accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar" :beforeUpload="handleBeforeUpload"
                                :remove="removeFile">
                <p class="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p class="ant-upload-text">上传文件</p>
                <div class="ant-upload-text-alert">
                  <p>1、可上传除入职指引和简历模板外其他附件，最多上传3个附件，小于50M</p>
                  <p>2、可上传附件格式doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar</p>
                </div>
              </a-upload-dragger>
            </div>
          </a-modal>
          <!-- 另存为弹窗 -->
          <a-modal
              :getContainer="$refs.demoModalRef"
              class="demo-modal"
              width="29.2vw"
              v-model:visible="othersSaveVisible"
              title="另存为" cancelText="取消"
              okText="确定"
              @ok="handleOthersUploadOk"
              :z-index="10001"
              centered
          >
            <div>
              <a-form class="form rms-form" name="custom-validation" ref="formRef" :model="formStateOthers"
                      layout="inline">
                <a-form-item label="Team" name="orgThreeTwoList">
                  <a-select disabled class="form-item-inline" style="width:224px;"
                            v-model:value="formStateOthers.orgThreeTwoList" placeholder="请选择" showArrow allowClear
                            :maxTagCount="1" @change="changeOrgThreeTwoOthers" show-search>
                    <a-select-option v-for="(item, index) in orgThreeTwoOpsOthers" :key="index" :value="item"><span
                        :title="item">{{
                        item }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoAssignment:offering']">
                  <a-select disabled class="form-item-inline" style="width:224px;"
                            v-model:value="formStateOthers.orgFourOneOneList" placeholder="请选择" showArrow allowClear
                            :maxTagCount="1" @change="changeOrgFourOneOneOthers" show-search>
                    <a-select-option v-for="(item, index) in orgFourOneOneOpsOthers" :key="index"
                                     :value="item"><span :title="item">{{
                        item }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Template Name" name="templateName" style="margin-top: 20px;">
                  <a-input class="item" style="width: 224px;" :maxLength="80"
                           v-model:value="formStateOthers.templateName" placeholder="请输入模板名称" allowClear
                           autocomplete="off" />
                </a-form-item>
              </a-form>
            </div>
          </a-modal>
        </div>
        <import-modal v-model:visible="modalVisible" apiFuncName="uploadOrganizations" :uploadParams="{ templateId: id }"
            source="OrientationGuidelines" accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar"
            @closeModal="handleCloseModal" @resetTable="handleResetTable">
        </import-modal>
        <import-modal v-model:visible="modalVisible1" apiFuncName="uploadGuide" :uploadParams="offerObj"
            source="OrientationGuidelines" accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar"
            @closeModal="handleCloseModal1" @resetTable="handleResetTable1">
        </import-modal>
        <div v-show="!isPreview" class="feature-line1 bottom-btn">
            <div class="refresh"><a-button type="primary" ghost style="margin-left:8px;"
                    @click="getRefreshTemplate" v-auth="['system:template:refresh']">刷新</a-button><span>刷新后所有信息为当前系统最新数据，手动编辑部分也会恢复到默认值。</span></div>
            <div><a-button style="margin-left:8px;" @click="handleCancel">取消</a-button>
                <a-button type="primary" style="margin-left:8px;"
                    @click="handleOthersSave" v-auth="['system:template:saveas']">另存为</a-button>
                <a-button type="primary" style="margin-left: 8px" @click="handleConfirm('edit')" v-auth="['system:template:save']">保存</a-button>
            </div>
        </div>
        <UpdateIdc
            v-model:visible="updateIdcVisible"
            @close-modal="closeUpdateIdc"
            :updateIdcOffering="updateIdcOffering"
            @submitModal="updateIdcData"
        />
      <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
    </a-drawer>
</template>
<script>
import {
  UploadOutlined,
  RightOutlined,
  DownOutlined,
  PlusOutlined,
  FilePptFilled,
  FileWordFilled,
  FileZipFilled,
  ExclamationCircleOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from 'ant-design-vue';
import ImportModal from "@/components/ImportModal.vue";
import UpdateIdc from "@/views/home/WelLetterManagement/components/UpdateIdc";
import ModalBox from '@/components/ModalBox'
const columns = [
    {
        title: "姓名",
        dataIndex: "fullName",
        ellipsis: true,
        width: 200,
    },
    {
        title: "职位",
        dataIndex: "position",
        ellipsis: true,
        width: 200,
        slots: { customRender: "position" },
    },
    {
        title: "联系方式",
        dataIndex: "email",
        ellipsis: true,
        width: 250,
        slots: { customRender: "email" },
    },

    {
        title: "负责事宜",
        dataIndex: "description",
        ellipsis: true,
        slots: { customRender: "description" },
    },
    {
        title: "操作",
        dataIndex: "action",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    },
];
const columnsIspreview = [
    {
        title: "姓名",
        dataIndex: "fullName",
        ellipsis: true,
        width: 200,
    },
    {
        title: "职位",
        dataIndex: "position",
        ellipsis: true,
        width: 200,
        slots: { customRender: "position" },
    },
    {
        title: "联系方式",
        dataIndex: "email",
        ellipsis: true,
        width: 250,
        slots: { customRender: "email" },
    },

    {
        title: "负责事宜",
        dataIndex: "description",
        ellipsis: true,
        slots: { customRender: "description" },
    },
];
const columns2 = [
    {
        title: "P&L",
        dataIndex: "pl",
        ellipsis: true,
        width: 200,
        slots: { customRender: "pl" },
    },
    {
        title: "Account",
        dataIndex: "account",
        ellipsis: true,
        width: 200,
        slots: { customRender: "account" },
    },
    {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        ellipsis: true,
        slots: { customRender: "engagementDescription" },
    },
    {
        title: "Engagement Manager",
        dataIndex: "engagementManager",
        ellipsis: true,
        slots: { customRender: "engagementManager" },
    },
    {
        title: "操作",
        dataIndex: "option",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    },
];
const columns2isPreview = [
    {
        title: "P&L",
        dataIndex: "pl",
        ellipsis: true,
        width: 200,
        slots: { customRender: "pl" },
    },
    {
        title: "Account",
        dataIndex: "account",
        ellipsis: true,
        width: 200,
        slots: { customRender: "account" },
    },
    {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        ellipsis: true,
        slots: { customRender: "engagementDescription" },
    },
    {
        title: "Engagement Manager",
        dataIndex: "engagementManager",
        ellipsis: true,
        slots: { customRender: "engagementManager" },
    },
];
const columns3 = [
    {
        title: "Entity",
        dataIndex: "entity",
        ellipsis: true,
        slots: { customRender: "entity" },
    },
    {
        title: "Organization",
        dataIndex: "organization",
        ellipsis: true,
        slots: { customRender: "organization" },
    },
    {
        title: "Project Number",
        dataIndex: "projectNumber",
        ellipsis: true,
        slots: { customRender: "projectNumber" },
    },
    {
        title: "Project Name",
        dataIndex: "projectName",
        ellipsis: true,
        slots: { customRender: "projectName" },
    },
    {
        title: "Project Type",
        dataIndex: "projectType",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "option",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    },
];
const columns3isPreview = [
    {
        title: "Entity",
        dataIndex: "entity",
        ellipsis: true,
        slots: { customRender: "entity" },
    },
    {
        title: "Organization",
        dataIndex: "organization",
        ellipsis: true,
    },
    {
        title: "Project Number",
        dataIndex: "projectNumber",
        ellipsis: true,
        slots: { customRender: "projectNumber" },
    },
    {
        title: "Project Name",
        dataIndex: "projectName",
        ellipsis: true,
    },
    {
        title: "Project Type",
        dataIndex: "projectType",
        ellipsis: true,
    },
];
const columnsResume = [
    {
        title: "简历模板",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "",
        dataIndex: "operation",
        ellipsis: true,
        width: 10,
        slots: { customRender: "operation" },
    },
];
const columnsEntry = [
    {
        title: "入职指引",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "operation",
        ellipsis: true,
        width: 140,
        slots: { customRender: "operation" },
        fixed: "right",
    },
];
const columnsOthers = [
    {
        title: "其他附件",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "operation",
        ellipsis: true,
        width: 100,
        slots: { customRender: "operation" },
        fixed: "right",
    },
];
const positionlist = [
    // { label: "Line Manager", value: "Line Manager", key: "1" },
    { label: "Resource Manager", value: "Resource Manager", key: "2" },
    { label: "HRBP", value: "HRBP", key: "3" },
];
const entityList = [
    { label: "PRC", value: "PRC", key: "1" },
    { label: "PRK", value: "PRK", key: "2" },
    { label: "PRJ", value: "PRJ", key: "3" },
];
export default defineComponent({
    name: "WelcomeLetterTamplate",
    components: {
        UpdateIdc,
        UploadOutlined,
        DownOutlined,
        PlusOutlined,
        RightOutlined,
        FilePptFilled,
        FileWordFilled,
        ImportModal,
        FileZipFilled,
        ExclamationCircleOutlined,
        ModalBox,
        DeleteOutlined,
        FileImageOutlined,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        offering: {
            type: String,
            default: "",
        },
        templateId: {
            type: String,
            default: "",
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
        defaultTemplateId: {
          type: String,
          default: "",
        },
    },
    setup(props, { emit }) {
        const { $api } = useGlobalPropertyHook();
        const templateData = ref({});
        const assignmentdata = ref([]);
        const dataList = ref([]);
        const idcCodeList = ref([]);
        const projectNumlists = ref([]);
        const CClist = ref([]);
        const CClistRh = ref([]);
        const ccPersonList = ref([]);
        const modalVisible1 = ref(false);
        const showassignmentdata = ref(false);
        const othersSaveVisible = ref(false);
        const uploadOthersVisible = ref(false);
        let tableListResume = ref([]);
        let tableListEntry = ref([]);
        let tableListOthers = ref([]);
        let offerObj = reactive({
            offering: "",
        });
        const formStateOthers = reactive({
            templateName: "默认模板",
            orgThreeTwoList: '',
            orgFourOneOneList: ''
        });
        const orgThreeTwoOpsOthers = ref([]);
        const orgFourOneOneOpsOthers = ref([]);
        const getEMOptions = () => {
            $api.findAllStaffs().then((res) => {
                CClist.value = res.data;
            });
        };
        const paramListObj = reactive({
            accountOps: [],
            plOps: [],
        });
        const organizationList = ref([]);
        const projectNamelists = ref([]);
        const allRelation = ref([]);
        //获得Account P&L下拉列表
        const getAccountPLItems = async () => {
            await $api.getAccountPLItems().then((res) => {
                const { account, pl } = res.data;
                paramListObj.accountOps = account;
                paramListObj.plOps = pl;
            });
        };
        //Account、Domain、P&L、Sub_P&L change对应关系
        const orgAllList = ref([]);
        const getAccountPLRelations = async () => {
            await $api.getAccountPLRelations().then((res) => {
                orgAllList.value = res.data;
            });
        };
        // 选择pl account事件
        const orgAccountPLChange = (val, type, index) => {
            if (type == "pl") {
                assignmentdata.value[index].account = "";
                paramListObj.accountOps[index] = orgAllList.value.map((item) => {
                    if (item.pl == val) {
                        return item.account;
                    }
                }).filter((item, index, array)=>{return array.indexOf(item)=== index && item !== null });
                if (val == undefined) {
                    paramListObj.accountOps[index] = orgAllList.value.map((i) => {
                        return i.account;
                    }).filter((item, index, array)=>{return array.indexOf(item)=== index && item !== null });
                }
                paramListObj.accountOps[index] = [
                    ...new Set(paramListObj.accountOps[index]),
                ];
            } else {
                orgAllList.value.forEach((item) => {
                    if (item.account == val) {
                        assignmentdata.value[index].pl = item.pl;
                    }
                });
            }
        };
        //查询org3.2/org4.2
        const getOrgList = () => {
            $api.getOrgThree_FourList().then((res) => {
                const { orgThreeTwo, orgFourOneOne } = res.data;
                orgThreeTwoOpsOthers.value = orgThreeTwo;
                orgFourOneOneOpsOthers.value = orgFourOneOne;
                updateIdcOffering.value = orgFourOneOne;
            });
        };
        const getIdcOptions = () => {
            $api.getIdcCodes().then((res) => {
                projectNumlists.value = res.data.relations.map((item, index) => {
                    return {
                        label: item.projectNumber,
                        value: item.projectNumber,
                        key: index,
                        organization: item.organization
                            ? item.organization
                            : tempOrganization.value,
                        projectName: item.projectName,
                        projectType: item.projectType,
                        entity: item.entity,
                    };
                });
                organizationList.value = res.data.organization;
                projectNamelists.value = res.data.projectName;
                allRelation.value = res.data.relations;
            });
        };
        const handleUploadOk = () => {
            // tableListOthers.value = fileList.value.map(item => {
            //     return {
            //         fileName: item.name,
            //         operationTime: item.lastModifiedDate
            //     }
            // })
            uploadFiles();
            uploadOthersVisible.value = false;
        };
        const uploadFiles = () => {
            const formData = new FormData();
            fileList.value.forEach((item) => {
                formData.append("file", item);
            });
            $api.welcomeLetterTemplateUpload(formData).then((res) => {
                if (res.code == 200) {
                    tableListOthers.value = res.data;
                }
            });
        };
        const handleOthersUploadOk = () => {
            handleConfirmOther('other');
        }
        // 上传入职指引
        const modalVisible = ref(false);
        const templateId = ref("");
        const handleCloseModal = () => {
            modalVisible.value = false;
        };
        const handleCloseModal1 = () => {
            modalVisible1.value = false;
        };
        const handleResetTable = () => {
            changeOrgFourOneOne(props.offering);
        };
        const handleResetTable1 = () => {
            changeOrgFourOneOne(props.offering);
        };
        const uploadClick = () => {
            templateId.value = props.offering;
            modalVisible1.value = true;
        };
        //删除入职指引
        const deleteClick = (record) => {
          content.value = `是否确认删除${props.offering}对应的入职指引附件`
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = record.id
          flag.value = 6
          modelValue.value = true
            /*Modal.confirm({
                title: `是否确认删除${props.offering}对应的入职指引附件`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确认',
                width: "30%",
                centered: true,
                onOk() {
                    $api.organizationsDeleteFile(record.id, props.offering).then((res) => {
                        if (res.msg == '当前有人员引用了该入职指引，不可删除') {
                            message.error('当前有人员引用了该入职指引，不可删除！');
                        } else {
                            getstaffWelcomeLetterTemplate(props.userId)
                            message.success('删除成功！');
                        }

                    })
                },
                cancelText: '取消',
            });*/
        }

        const uploadClick1 = () => {
            modalVisible1.value = true;
        };
        const changeOrgFourOneOne = (e) => {
            if (e == undefined) {
                e = "";
            }
            $api.getAllFiles(e).then((res) => {
                tableListEntry.value = res.data.guideFileList;
            });
            $api.getIdcData({ offering: e, entity: null }).then((res) => {
                idcCodeList.value = res.data;
            });
        };
        //刷新模板
        const getRefreshTemplate = () => {
            if (props.templateId === "0") {
                $api.getRefreshTemplate(props.userId,'1').then((res) => {
                    templateData.value = res.data.template;
                    ccPersonList.value = res.data.ccPersonList
                        ? res.data.ccPersonList
                        : [];
                    idcCodeList.value = res.data.idcCodeList;
                    fileList.value = res.data.staffFiles ? res.data.staffFiles : [];
                    fileList.value.forEach((item) => {
                        item.name = item.fileName;
                    });
                    dataList.value = res.data.staffWelcomeLetterContactsItems;
                    // assignmentdata.value[0] = res.data.template;
                    // showassignmentdata.value = assignmentdata.value[0].engagementManager
                    //     ? false
                    //     : true;
                    tableListResume.value = res.data.templateFileList;
                    tableListEntry.value = res.data.guideFileList;
                    formStateOthers.orgThreeTwoList = res.data.template.orgThreeTwo;
                    formStateOthers.orgFourOneOneList = res.data.template.orgFourOneOne;
                    offerObj.offering = res.data.template.orgFourOneOne;
                    tableListOthers.value = res.data.otherFileList;
                    assignmentdata.value = res.data.assignments;
                    showassignmentdata.value = assignmentdata.value?.length > 0
                        ? false
                        : true;
                });
            } else {
                getstaffWelcomeLetterTemplateDefineFun(
                    props.templateId,
                    props.offering,
                    props.userId,
                    "refresh"
                );
            }
        };
        // 默认模板查询
        const getstaffWelcomeLetterTemplate = (userId) => {
            $api.getstaffWelcomeLetterTemplate(userId).then(res => {
                // console.log('团队', res.data.template.orgThreeTwo)
                // console.log('团队', res.data.template.orgFourOneOne)
                formStateOthers.orgThreeTwoList = res.data.template.orgThreeTwo;
                formStateOthers.orgFourOneOneList = res.data.template.orgFourOneOne;
                offerObj.offering = res.data.template.orgFourOneOne;
                templateData.value = res.data.template;
                ccPersonList.value = res.data.ccPersonList ? res.data.ccPersonList : [];
                res.data.idcCodeList.forEach((item) => {
                    item.templateId = res.data.template.id;
                });
                idcCodeList.value = res.data.idcCodeList;
                tableListOthers.value = res.data.staffFiles;
                tableListOthers.value.forEach((item) => {
                    item.name = item.fileName;
                });
                tempOrganization.value =
                    res.data.template.costCtr + res.data.template.orgFourOneOne;
                dataList.value = res.data.staffWelcomeLetterContactsItems;
                assignmentdata.value = res.data.welcomeLetterAssignments;
                // assignmentdata.value[0] = res.data.template
                // showassignmentdata.value = assignmentdata.value[0].engagementManager ? false : true
                tableListResume.value = res.data.templateFileList;
                tableListEntry.value = res.data.guideFileList;

            })
        }

      const modelValue = ref(false)
      const content = ref('')
      const showIconCom = ref('')
      const modalData = ref()
      const flag = ref('')
      const handleOk = () => {
          switch (flag.value) {
            case 1:
              tableListOthers.value.forEach((item, index) => {
                if (modalData.value == item.id) {
                  tableListOthers.value.splice(index, 1);
                }
              })
              break
            case 2:
              fileList.value.splice(fileList.value.indexOf(modalData.value), 1)
              break
            case 3:
              dataList.value.splice(modalData.value, 1)
              break
            case 4:
              assignmentdata.value.splice(modalData.value, 1)
              break
            case 5:
              idcCodeList.value.splice(modalData.value, 1)
              break
            case 6:
              $api.organizationsDeleteFile(modalData.value, props.offering).then((res) => {
                if (res.msg == '当前有人员引用了该入职指引，不可删除') {
                  message.error('当前有人员引用了该入职指引，不可删除！');
                } else {
                  getstaffWelcomeLetterTemplate(props.userId)
                  message.success('删除成功！');
                }

              })
              break
            default:
              break
          }
        modalData.value = ''
        flag.value = ''
      }
        // 删除其他附件
        const deleteOtherFilesFun = (record) => {
          content.value = '确定删除这条数据吗?'
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = record.id
          flag.value = 1
          modelValue.value = true
            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    tableListOthers.value.forEach((item, index) => {
                        if (record.id == item.id) {
                            tableListOthers.value.splice(index, 1);
                        }
                    });
                },
            });*/
        }
        let tempOrganization = ref('')
        // 自定义模板查询
        const getstaffWelcomeLetterTemplateDefineFun = (
            templateId,
            offering,
            userId,
            type
        ) => {
            offerObj.offering = offering;
            if (type === 'find') {
                $api.getstaffWelcomeLetterTemplateDefine(templateId, offering, userId).then(res => {
                    templateData.value.subject = res.data.subject;
                    ccPersonList.value = res.data.ccPerson ? res.data.ccPerson : [];
                    templateData.value.remarks = res.data.remarks;
                    dataList.value = res.data.items;
                    assignmentdata.value = res.data.assignments;
                    idcCodeList.value = res.data.idcCodes;
                    tableListResume.value = res.data.files.templateFileList;
                    tableListEntry.value = res.data.files.guideFileList;
                    tableListOthers.value = res.data.files.otherFileList;
                    formStateOthers.orgThreeTwoList = res.data.team;
                    formStateOthers.orgFourOneOneList = res.data.offering;
                    formStateOthers.templateName = res.data.name;
                    tempOrganization.value = res.data.costCtr + res.data.offering;
                    let k = res.data.assignments?.length;
                    $api.getAccountPLItems().then((res) => {
                        const { account } = res.data
                        for (let i = 0; i < k; i++)
                            paramListObj.accountOps[i] = account;
                    })
                })
            } else {
                $api
                    .getWelcomeLetterCustomTemplateRefresh(templateId, offering, userId)
                    .then((res) => {
                        templateData.value.subject = res.data.subject;
                        ccPersonList.value = res.data.ccPerson ? res.data.ccPerson : [];
                        templateData.value.remarks = res.data.remarks;
                        dataList.value = res.data.items;
                        assignmentdata.value = res.data.assignments;
                        idcCodeList.value = res.data.idcCodes;
                        tableListResume.value = res.data.files.templateFileList;
                        tableListEntry.value = res.data.files.guideFileList;
                        tableListOthers.value = res.data.files.otherFileList;
                        formStateOthers.orgThreeTwoList = res.data.team;
                        formStateOthers.orgFourOneOneList = res.data.offering;
                        formStateOthers.templateName = res.data.name;
                        tempOrganization.value = res.data.costCtr + res.data.offering;
                        let k = res.data.assignments?.length;
                        $api.getAccountPLItems().then((res) => {
                            const { account } = res.data;
                            for (let i = 0; i < k; i++) paramListObj.accountOps[i] = account;
                        });
                    });
            }
        };
        const changeemail = (value, index) => {
            CClist.value.forEach((item) => {
                if (item.email === value) {
                    dataList.value[index].fullName = item.fullName;
                }
            });
        };
        let CClistRh1 = ref([]);
        let CClistRh2 = ref([]);
        let CClistRh3 = ref([]);
        const getHRBPAndRMDataFun = () => {
            $api.getHRBPAndRMData().then((res) => {
                CClistRh1.value = res.data[0].info;
                CClistRh2.value = res.data[1].info;
            });
            $api.authorizedPerson().then((res) => {
              CClistRh3.value = res.data
            });
        };
        const changePosition = (value, index) => {
            if (value === 'HRBP') {
                CClistRh.value = CClistRh2.value;
                dataList.value[index].description =
                    "人事政策流程，如五险一金、公司福利、休假制度等";
            } else {
                CClistRh.value = CClistRh1.value;
                dataList.value[index].description =
                    "项目安排(上下项目信息、潜在项目机会协调、UT管理、Code填写)；考勤系统使用辅导";
            }
        }
      const emailClick = (index,record) => {
        if (record.position === 'HRBP') {
          CClistRh.value = CClistRh2.value;
          dataList.value[index].description = "人事政策流程，如五险一金、公司福利、休假制度等"
        } else if (record.position === 'Resource Manager') {
          CClistRh.value = CClistRh1.value;
          dataList.value[index].description = "项目安排(上下项目信息、潜在项目机会协调、UT管理、Code填写)；考勤系统使用辅导"
        } else if (record.position === 'Line Manager') {
          CClistRh.value = CClistRh3.value;
        } else {
          CClistRh.value = [];
        }
      }
        const entityChange = (value, index) => {
            idcCodeList.value[index].projectName = "";
            idcCodeList.value[index].projectNumber = "";
            idcCodeList.value[index].projectType = "";
        };
        const organizationChange = (value, index) => {
            idcCodeList.value[index].projectName = "";
            idcCodeList.value[index].projectNumber = "";
            idcCodeList.value[index].projectType = "";
        };
        const projectNumberChange = (value, index) => {
            if (value) {
                projectNumlists.value.forEach((item) => {
                    if (item.value === value) {
                        (idcCodeList.value[index].projectName = item.projectName),
                            (idcCodeList.value[index].projectType = item.projectType),
                            (idcCodeList.value[index].organization = item.organization),
                            (idcCodeList.value[index].entity = item.entity);
                    }
                });
            } else {
                idcCodeList.value[index].projectName = "";
                idcCodeList.value[index].projectType = "";
            }
        };
        const projectNameChange = (value, index) => {
            if (value) {
                allRelation.value.forEach((item) => {
                    if (item.projectName === value) {
                        (idcCodeList.value[index].projectNumber = item.projectNumber),
                            (idcCodeList.value[index].projectType = item.projectType),
                            (idcCodeList.value[index].organization = item.organization),
                            (idcCodeList.value[index].entity = item.entity);
                    }
                });
            } else {
                idcCodeList.value[index].projectNumber = "";
                idcCodeList.value[index].projectType = "";
            }
        };
        const projectNumberOps = (value, record) => {
            if (value === true) {
                if (record.entity || record.organization) {
                    projectNumlists.value = (
                        record.entity && record.organization
                            ? allRelation.value
                                .filter((item) => item.entity === record.entity)
                                .filter((item) => item.organization === record.organization)
                            : record.entity
                                ? allRelation.value.filter(
                                    (item) => item.entity === record.entity
                                )
                                : allRelation.value.filter(
                                    (item) => item.organization === record.organization
                                )
                    ).map((item, index) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber,
                            key: index,
                            organization: item.organization,
                            projectName: item.projectName,
                            projectType: item.projectType,
                            entity: item.entity,
                        };
                    });
                } else {
                    projectNumlists.value = allRelation.value.map((item, index) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber,
                            key: index,
                            organization: item.organization,
                            projectName: item.projectName,
                            projectType: item.projectType,
                            entity: item.entity,
                        };
                    });
                }
            }
        };
        const projectNameOps = (value, record) => {
            if (value === true) {
                if (record.entity || record.organization) {
                    projectNamelists.value = (
                        record.entity && record.organization
                            ? allRelation.value
                                .filter((item) => item.entity === record.entity)
                                .filter((item) => item.organization === record.organization)
                            : record.entity
                                ? allRelation.value.filter(
                                    (item) => item.entity === record.entity
                                )
                                : allRelation.value.filter(
                                    (item) => item.organization === record.organization
                                )
                    ).map((item) => item.projectName);
                } else {
                    projectNamelists.value = allRelation.value.map(
                        (item) => item.projectName
                    );
                }
            }
        };
        const fileList = ref([]);
        let messageTimer = null;
        const handleBeforeUpload = (file, list) => {
            return new Promise(() => {
                for (let i = 0; i < fileList.value.length; i++) {
                    if (fileList.value[i]) {
                        if (fileList.value[i].name === file.name) {
                            // settimeout 防止弹出多个消息提示
                            clearTimeout(messageTimer);
                            messageTimer = setTimeout(() => {
                                message.error("请不要选择重复的文件！");
                            });
                            return false;
                        }
                    }
                }
                const num = 3;
                const info = "最多上传三个附件";
                if (list.length > num) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error(info);
                    });
                    return false;
                }
                if (fileList.value.length + 1 > num) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error(info);
                    });
                    return false;
                }
                if (file.size === 0) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error("文件为空文件，请检查后再次上传!");
                    });
                    return false;
                }
                const isLt50M = file.size / 1024 / 1024 < 50;
                if (!isLt50M) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error("文件大小限制50MB，请检查后再次上传!");
                    });
                    return false;
                }

                if (
                    !file.type.includes("application/msword") &&
                    !file.type.includes(
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) &&
                    !file.type.includes("application/vnd.ms-powerpoint") &&
                    !file.type.includes(
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    ) &&
                    !file.type.includes("application/vnd.ms-excel") &&
                    !file.type.includes(
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) &&
                    !file.type.includes("text/plain") &&
                    !file.type.includes("application/pdf") &&
                    !(file.name.slice(-3) === ".7z") &&
                    !file.type.includes("application/x-zip-compressed") &&
                    !(file.name.slice(-4) === ".rar")
                ) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error(
                            "只可以上传doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar格式的文件！"
                        );
                    });
                    return false;
                }
                fileList.value = [...fileList.value, file];
            });
        };
        const uploadOthersFiles = () => {
            uploadOthersVisible.value = true;
        };
        // 另存为弹窗
        const handleOthersSave = () => {
            othersSaveVisible.value = true;
        };
        const clicktoshowtable = () => {
            showassignmentdata.value = false;
        };
        const showClick = () => {
            showassignmentdata.value = !showassignmentdata.value;
        };
        const handleConfirm = () => {
            if (props.templateId === "0") {
                // if ((assignmentdata.value[0].pl || assignmentdata.value[0].account || assignmentdata.value[0].engagementDescription) && (!assignmentdata.value[0].engagementManager)
                // ) {
                //     return message.error('请填写Engagement Manager字段')
                // }
                let checkoutFlag = true;
                dataList.value.forEach((item) => {
                    if (!item.position || !item.email) {
                        checkoutFlag = false;
                        return message.warning("职位联系方式不能为空");
                    }
                });
                if (showassignmentdata.value) {
                    assignmentdata.value.forEach((item) => {
                        if (
                            !item.pl ||
                            !item.account ||
                            !item.engagementDescription ||
                            !item.engagementManager
                        ) {
                            checkoutFlag = false;
                            return message.warning("添加调令列表各个值不能为空");
                        }
                    });
                }
                idcCodeList.value?.forEach((item) => {
                    if (!item.projectNumber) {
                        checkoutFlag = false;
                        return message.warning("IDC列表code字段不能为空");
                    }
                });
                let arr = [];
                arr = fileList.value.filter((item) => item.id);
                let staffFileIds = [];
                arr.forEach((item) => {
                    staffFileIds.push(item.id);
                });
                const fileData = fileList.value.filter((item) => item.uid);
                const formData = new FormData();
                fileData.forEach((item) => {
                    formData.append("file", item);
                });
                dataList.value.map((item, index) => {
                    return (item.orderNumber = String(index));
                });
                idcCodeList.value.map((item, index) => {
                    return (item.orderNumber = String(index));
                });
                //把默认模板的保存改为自定义模板保存接口
                // const data = {
                //     ccPersonList: ccPersonList.value,
                //     staffFileIds,
                //     staffWelcomeLetterContactsItems: dataList.value,
                //     staffIDCCodes: idcCodeList.value,
                //     // pl: assignmentdata.value[0].pl,
                //     // account: assignmentdata.value[0].account,
                //     // engagementDescription: assignmentdata.value[0].engagementDescription,
                //     // engagementManager: assignmentdata.value[0].engagementManager,
                //     assignments: showassignmentdata.value ? assignmentdata.value : [],
                //     remarks: templateData.value.remarks,
                //     id: templateData.value.id,
                // };
                const data = {
                  id: props.userId,
                  templateId: props.templateId,
                  subject: templateData.value.subject,
                  ccPerson: ccPersonList.value,
                  remarks: templateData.value.remarks,
                  items: dataList.value,
                  idcCodes: idcCodeList.value,
                  assignments: showassignmentdata.value ? assignmentdata.value : [],
                  otherFileList: tableListOthers.value,
                  guideFileList: tableListEntry.value,
                }
                if (checkoutFlag) {
                    $api.saveWelcomeLetterTemplateDeleteFile(data).then((res) => {
                        if (res.code === 200) {
                            message.success("success");
                            if (fileData.length > 0) {
                                $api
                                    .staffWelcomeLetterTemplateUpload(formData, {
                                        templateId: templateData.value.id,
                                    })
                                    .then((res) => {
                                        if (res.code != 200) {
                                            return message.error(
                                                "附件上传失败,但删除的文件已经删除成功"
                                            );
                                        } else {
                                            message.success("修改成功");
                                            emit("handleCancel");
                                        }
                                    });
                            } else {
                                emit("handleCancel");
                            }
                        }
                    });
                }
            } else {
                let checkoutFlag = true;
                dataList.value.forEach((item) => {
                    if (!item.position || !item.email) {
                        checkoutFlag = false;
                        return message.warning("职位联系方式不能为空");
                    }
                });
                if (showassignmentdata.value) {
                    assignmentdata.value.forEach((item) => {
                        if (
                            !item.pl ||
                            !item.account ||
                            !item.engagementDescription ||
                            !item.engagementManager
                        ) {
                            checkoutFlag = false;
                            return message.warning("添加调令列表各个值不能为空");
                        }
                    });
                }
                idcCodeList.value?.forEach((item) => {
                    if (!item.projectNumber) {
                        checkoutFlag = false;
                        return message.warning("IDC列表code字段不能为空");
                    }
                });
                if (tableListEntry.value?.length == 0) {
                    checkoutFlag = false;
                    return message.warning("入职指引不能为空");
                }
                let arr = [];
                arr = fileList.value.filter((item) => item.id);
                let staffFileIds = [];
                arr.forEach((item) => {
                    staffFileIds.push(item.id);
                });
                const fileData = fileList.value.filter((item) => item.uid);
                const formData = new FormData();
                fileData.forEach((item) => {
                    formData.append("file", item);
                });
                dataList.value.map((item, index) => {
                    return (item.orderNumber = String(index));
                });
                idcCodeList.value.map((item, index) => {
                    return (item.orderNumber = String(index));
                });
                const params = {
                    id: props.userId,
                    templateId: props.templateId,
                    subject: templateData.value.subject,
                    ccPerson: ccPersonList.value,
                    remarks: templateData.value.remarks,
                    items: dataList.value,
                    idcCodes: idcCodeList.value,
                    assignments: showassignmentdata.value ? assignmentdata.value : [],
                    otherFileList: tableListOthers.value,
                    guideFileList: tableListEntry.value,
                };
                if (checkoutFlag) {
                    $api.saveWelcomeLetterTemplateDeleteFile(params).then((res) => {
                        if (res.code === 200) {
                            message.success(res.msg);
                            emit("handleCancel");
                        }
                    });
                }
            }
        };
        const handleConfirmOther = () => {
            let arr = [];
            arr = fileList.value.filter((item) => item.id);
            let staffFileIds = [];
            arr.forEach((item) => {
                staffFileIds.push(item.id);
            });
            const fileData = fileList.value.filter((item) => item.uid);
            const formData = new FormData();
            fileData.forEach((item) => {
                formData.append("file", item);
            });
            dataList.value.map((item, index) => {
                return (item.orderNumber = String(index));
            });
            idcCodeList.value.map((item, index) => {
                return (item.orderNumber = String(index));
            });
            const params = {
                team: formStateOthers.orgThreeTwoList,
                offering: formStateOthers.orgFourOneOneList,
                templateName: formStateOthers.templateName,
                subject: templateData.value.subject,
                ccPerson: ccPersonList.value,
                remarks: templateData.value.remarks,
                items: dataList.value.filter((item) => item.position != "Line Manager"),
                idcCodes: idcCodeList.value,
                assignments: assignmentdata.value,
                otherFileList: tableListOthers.value,
                guideFileList: tableListEntry.value,
            };
            $api.saveAsDefineTemplate(params).then((res) => {
                if (res.code === 200) {
                    message.success(res.msg);
                    emit("handleCancel");
                }
            });
        };
        const onCancel = () => {
            return false;
        };
        const fuc = (file) => {
          content.value = '确定删除这条数据吗?'
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = file
          flag.value = 2
          modelValue.value = true
            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    const index = fileList.value.indexOf(file)
                    fileList.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const removeFile = (file) => {
            fuc(file);
            return false;
        };
        const handledel = (index) => {
          content.value = '确定删除这条数据吗?'
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = index
          flag.value = 3
          modelValue.value = true
            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    dataList.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const handleOrderDel = (index) => {
          content.value = '确定删除这条数据吗?'
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = index
          flag.value = 4
          modelValue.value = true
            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    assignmentdata.value.splice(index, 1);
                },
                cancelText: '取消',
            });*/
        }
        const handledelIdc = (index) => {
          content.value = '确定删除这条数据吗?'
          showIconCom.value = 'ExclamationCircleOutlined'
          modalData.value = index
          flag.value = 5
          modelValue.value = true
            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    idcCodeList.value.splice(index, 1);
                },
                cancelText: '取消',
            });*/
        }
        const handledTransferOrder = () => {
            assignmentdata.value.push({
                pl: "",
                account: "",
                engagementDescription: "",
                engagementManager: "",
            });
            paramListObj.accountOps[assignmentdata.value.length - 1] =
                orgAllList.value.map((i) => {
                    return i.account;
                }).filter((item, index, array)=>{return array.indexOf(item)=== index && item !== null });
        };
        const handledadd = () => {
            dataList.value.push({
                description: "",
                email: "",
                fullName: "",
                position: "",
            });
        };
        const handledaddIdc = () => {
            idcCodeList.value.push({
                entity: "",
                organization: "",
                projectNumber: "",
                projectName: "",
                projectType: "",
                templateId:
                    dataList.value.length > 0 ? dataList.value[0].templateId : "",
            });
        };
        //预览
        const previewVisible = ref(false);
        const previewUrl = ref();
        const preview = async (item, type) => {
            const { data } =
                type === 1
                    ? await $api.previewManual(item.id)
                    : await $api.previewOthers(item.id);
            previewVisible.value = true;
            if (data.indexOf("pdf") !== -1) {
                previewUrl.value = data;
            } else {
                previewUrl.value = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                    data
                )}`;
            }
        };
        const closePreviewModal = () => {
            previewVisible.value = false;
            previewUrl.value = "";
        };
        const handleCancel = () => {
            emit("handleCancel");
        };
        // 另存为team改变时更新offering下拉框内容
        const changeOrgThreeTwoOthers = () => {
            formStateOthers.orgFourOneOneList = '';
            handleOrgChangeOthers(formStateOthers.orgThreeTwoList);
        };
        // 另存为team改变时更新offering下拉框内容
        const handleOrgChangeOthers = (val) => {
            if (val) {
                $api.getOrgFour({ condition: val }).then((res) => {
                    orgFourOneOneOpsOthers.value = res.data.orgFourOneOne;
                });
            }
        };
        const changeOrgFourOneOneOthers = (e) => {
            formStateOthers.templateName = e;
        };
        // 联系人列表位置记录 拖拽方法实现
        const position = {
            start: undefined,
            end: undefined,
            sourceEl: undefined,
        };
        // 排序
        const reorder = ({ start, end }) => {
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    // 当开始大于结束
                    let temp = dataList.value[start];
                    dataList.value.splice(start, 1);
                    dataList.value.splice(end, 0, temp);
                } else if (start < end) {
                    // 结束大于开始
                    let temp = dataList.value[start];
                    dataList.value.splice(start, 1);
                    dataList.value.splice(end, 0, temp);
                }
                let arr = dataList.value?.map((item) => item.id);
                emit("onDeviceList", arr);
            }
        };
        function customRow(_record, index) {
            return {
                style: {
                    cursor: "move",
                },
                // 鼠标移入
                onMouseenter: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    ev.target.draggable = true;
                },
                // 开始拖拽
                onDragstart: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    // 阻止冒泡
                    ev.stopPropagation();
                    // 得到源目标数据;
                    position.start = index;
                    const tr = ev.target;
                    position.sourceEl = tr;
                },
                // 拖动元素经过的元素
                onDragover: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                },
                // 松开
                onDrop: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                    position.end = index;
                    reorder(position);
                    animation(position);
                },
            };
        }
        // 实现动画效果
        function animation({ start, end, sourceEl }) {
            // 48 是每行的高度，也可以自动获取，根据情况而定
            let count = 48 * (start - end);
            sourceEl.style.translate = `0px ${count}px`;
            setTimeout(() => {
                sourceEl.style.transition = "all 0.5s";
                sourceEl.style.translate = `0px 0px`;
            });
        }

        // IDC 拖拽位置记录 拖拽方法实现
        const positionIdc = {
            start: undefined,
            end: undefined,
            sourceEl: undefined,
        };
        // IDC 排序
        const reorderIdc = ({ start, end }) => {
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    // 当开始大于结束
                    let temp = idcCodeList.value[start];
                    idcCodeList.value.splice(start, 1);
                    idcCodeList.value.splice(end, 0, temp);
                } else if (start < end) {
                    // 结束大于开始
                    let temp = idcCodeList.value[start];
                    idcCodeList.value.splice(start, 1);
                    idcCodeList.value.splice(end, 0, temp);
                }
            }
        };
        function customRowIdc(_record, index) {
            return {
                style: {
                    cursor: "move",
                },
                // 鼠标移入
                onMouseenter: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    ev.target.draggable = true;
                },
                // 开始拖拽
                onDragstart: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    // 阻止冒泡
                    ev.stopPropagation();
                    // 得到源目标数据;
                    positionIdc.start = index;
                    const tr = ev.target;
                    positionIdc.sourceEl = tr;
                },
                // 拖动元素经过的元素
                onDragover: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                },
                // 松开
                onDrop: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                    positionIdc.end = index;
                    reorderIdc(positionIdc);
                    animation(positionIdc);
                },
            };
        }
        // assignment调令 拖拽位置记录 拖拽方法实现
        const positionAssign = {
            start: undefined,
            end: undefined,
            sourceEl: undefined,
        };
        // assignment 排序
        const reorderAssign = ({ start, end }) => {
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    // 当开始大于结束
                    let temp = assignmentdata.value[start];
                    assignmentdata.value.splice(start, 1);
                    assignmentdata.value.splice(end, 0, temp);
                } else if (start < end) {
                    // 结束大于开始
                    let temp = assignmentdata.value[start];
                    assignmentdata.value.splice(start, 1);
                    assignmentdata.value.splice(end, 0, temp);
                }
            }
        };
        function customRowAssign(_record, index) {
            return {
                style: {
                    cursor: "move",
                },
                // 鼠标移入
                onMouseenter: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    ev.target.draggable = true;
                },
                // 开始拖拽
                onDragstart: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    // 阻止冒泡
                    ev.stopPropagation();
                    // 得到源目标数据;
                    positionAssign.start = index;
                    const tr = ev.target;
                    positionAssign.sourceEl = tr;
                },
                // 拖动元素经过的元素
                onDragover: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                },
                // 松开
                onDrop: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                    positionAssign.end = index;
                    reorderAssign(positionAssign);
                    animation(positionAssign);
                },
            };
        }
        const templateIdFlag = ref("");
        const updateIdcOffering = ref([]);
        const updateIdcVisible = ref(false);
        const handledUpdateIdc = () => {
            updateIdcVisible.value = true;
        };
        const closeUpdateIdc = () => {
            updateIdcVisible.value = false;
        };
        //更改IDCcode
        const updateIdcData = (data) => {
            idcCodeList.value = data;
        };
        //cc选中的人出现在最上方
        const ccPersonListChange = (state) => {
            if (ccPersonList.value.length > 0 && state === true) {
                let selectCC = CClist.value.filter((item) =>
                    ccPersonList.value.includes(item.fullName + " " + item.email)
                );
                CClist.value = selectCC.concat(
                    CClist.value.filter(
                        (item) =>
                            !ccPersonList.value.includes(item.fullName + " " + item.email)
                    )
                );
            }
        };
        onMounted( async () => {
            templateIdFlag.value = props.templateId;
            getAccountPLItems();
            await getAccountPLRelations()
            getEMOptions();
            getIdcOptions()
            if(props.templateId === '0') {
               await $api.getRefreshTemplate(props.userId,'0').then((res) => {
                templateData.value = res.data.template;
                ccPersonList.value = res.data.ccPersonList
                    ? res.data.ccPersonList
                    : [];
                idcCodeList.value = res.data.idcCodeList;
                fileList.value = res.data.staffFiles ? res.data.staffFiles : [];
                fileList.value.forEach((item) => {
                  item.name = item.fileName;
                });
                dataList.value = res.data.staffWelcomeLetterContactsItems;
                // assignmentdata.value[0] = res.data.template;
                // showassignmentdata.value = assignmentdata.value[0].engagementManager
                //     ? false
                //     : true;
                tableListResume.value = res.data.templateFileList;
                tableListEntry.value = res.data.guideFileList;
                formStateOthers.orgThreeTwoList = res.data.template.orgThreeTwo;
                formStateOthers.orgFourOneOneList = res.data.template.orgFourOneOne;
                offerObj.offering = res.data.template.orgFourOneOne;
                tableListOthers.value = res.data.otherFileList;
                assignmentdata.value = res.data.assignments;
                showassignmentdata.value = assignmentdata.value?.length > 0
                     ? false
                     : true;
              });
              paramListObj.accountOps[assignmentdata.value.length - 1] =
                  orgAllList.value.map((i) => {
                    return i.account;
                  }).filter((item, index, array)=>{return array.indexOf(item)=== index && item !== null });
            } else if(props.defaultTemplateId === '0') {
              getstaffWelcomeLetterTemplateDefineFun(
                  props.templateId,
                  props.offering,
                  props.userId,
                  "refresh"
              );
            } else {
              getstaffWelcomeLetterTemplateDefineFun(
                  props.templateId,
                  props.offering,
                  props.userId,
                  "find"
              );
            }
            getOrgList()
            getHRBPAndRMDataFun()
            console.log(ccPersonList.value, 'ccPersonList.value')
        })
        return {
            templateData,
            CClist,
            dataList,
            columns,
            columns2,
            columns3,
            getEMOptions,
            getIdcOptions,
            positionlist,
            entityList,
            changeemail,
            projectNumberChange,
            clicktoshowtable,
            assignmentdata,
            idcCodeList,
            showassignmentdata,
            handleConfirm,
            fileList,
            handleBeforeUpload,
            removeFile,
            onCancel,
            ccPersonList,
            handledel,
            handledadd,
            handleCancel,
            showClick,
            getRefreshTemplate,
            customRow,
            customRowIdc,
            reorder,
            reorderIdc,
            handledaddIdc,
            handledelIdc,
            positionAssign,
            reorderAssign,
            customRowAssign,
            projectNumlists,
            handledTransferOrder,
            handleOrderDel,
            tableListResume,
            tableListEntry,
            tableListOthers,
            columnsResume,
            columnsEntry,
            columnsOthers,
            handleOthersSave,
            othersSaveVisible,
            changeOrgThreeTwoOthers,
            orgThreeTwoOpsOthers,
            orgFourOneOneOpsOthers,
            changeOrgFourOneOneOthers,
            previewVisible,
            preview,
            closePreviewModal,
            uploadOthersFiles,
            uploadOthersVisible,
            handleUploadOk,
            handleOthersUploadOk,
            formStateOthers,
            getOrgList,
            columnsIspreview,
            columns3isPreview,
            columns2isPreview,
            uploadFiles,
            deleteOtherFilesFun,
            templateIdFlag,
            previewUrl,
            handleConfirmOther,
            offerObj,
            modalVisible1,
            handleCloseModal1,
            handleResetTable1,
            uploadClick1,
            deleteClick,
            modalVisible,
            handleCloseModal,
            handleResetTable,
            uploadClick,
            getAccountPLItems,
            paramListObj,
            getAccountPLRelations,
            orgAccountPLChange,
            changePosition,
            getHRBPAndRMDataFun,
            CClistRh,
            tempOrganization,
            updateIdcVisible,
            handledUpdateIdc,
            closeUpdateIdc,
            updateIdcOffering,
            updateIdcData,
            projectNamelists,
            organizationList,
            entityChange,
            organizationChange,
            projectNameChange,
            allRelation,
            projectNumberOps,
            ccPersonListChange,
            projectNameOps,
            modelValue,
            content,
            showIconCom,
            flag,
            handleOk,
            emailClick,
        };
    },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
:deep(.ant-drawer .ant-drawer-content){
  padding-bottom: 0 !important;
}
.table{
  margin-top: 30px;
}
.btn-box{
  overflow: auto;
}
.box-padding{
  padding: 0 24px;
}
.black{
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #4d4d4d;
}
.rms-form-item {
    // display: flex;
    margin-bottom: 6px;

    .label {
        margin-right: 10px;
        margin-bottom: 6px;
    }

    .item {
        width: 500px;
        overflow: hidden;
    }
}

.annex-alert-info {
    margin-top: 20px;

    .ant-alert-description {
        p {
            margin-bottom: 0;
        }
    }

    :deep(.ant-alert-with-description .ant-alert-icon) {
        margin-top: -3px;
    }
}

:deep .ant-table-row {
    background: url(../assets/iconfont/drap.png) no-repeat left;
    background-size: 15px 15px;
}

.nodrag {
    :deep .ant-table-row {
        background: url("../assets/iconfont/drap.png") no-repeat left;
        background-size: 1px 1px;
    }
}

:deep .ant-spin-container {
    z-index: 1;
}

.operationLinkdel {
    color: #de685a;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.btn-add {
    float: right;
    margin-top: 20px;
    z-index: 99;
}

.btn-addIdc {
    float: right;
    margin-bottom: 10px;
    z-index: 99;
}

.add-comment {
    display: flex;

    p {
        margin-top: -4px;
    }
}
.gray {
  font-size: 16px;
  color: #999;
}
.img-svg{
  margin-right: 8px;
  line-height: 24px;
  width: 24px;
  color: #3182ce;
}
.others-files {
    float: right;
    margin: 20px 0;
    z-index: 999;
}

.feature-line1 {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.form-item-inline {
    width: 200px;
}

.text {
    word-break: break-all;
}

.refresh {
    display: flex;
}

.ppt {
    color: #f16c41;
}

.word {
    color: #4f6bf6;
}

.zip {
    color: #ffc757;
}

.blue {
    color: #3182ce;
    margin: 8px 0;
}

.refresh span {
    width: 320px;
    color: #b8b8b8;
    display: inline-block;
    margin-left: 16px;
    margin-top: -5px;
    text-align: left;
}
:deep(.ant-btn-background-ghost){
  color: #3A99F2 !important;
  border-color: #3A99F2 !important;
}

.operationLinklist {
    color: #3182ce;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.operationLinkgray {
    font-size: 12px;
    text-decoration: underline;
    color: #5a5959;
}

:deep .ant-select-selection-item {
    border-color: aliceblue !important;
}

.anticon {
    margin-left: -5px;
}

.ant-row {
    display: flex;
    flex-flow: column !important;
    align-items: baseline !important;
}

:deep .ant-form-item-label>label::after {
    content: "";
}
:deep(.ant-form){
  padding: 0;
}
:deep(.rms-form){
  border: 0;
}
.operationLink-entry {
  font-size: 16px;
  font-weight: bold;
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}

.font-color{
  color:#4d4d4d;
}
.sel-with{
  width: 180px;
}
</style>
