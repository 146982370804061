<template>
  <div ref="assignmentChangeRecordRef">
    <a-modal
        :getContainer="()=>$refs.assignmentChangeRecordRef"
        :visible="visible"
        @cancel="closeModal"
        width="62.5vw"
        centered
    >
      <template #title>
        <strong class="modal-title">
          {{ title ? title : "查看变更记录" }}
        </strong>
      </template>
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">取消</a-button>
        </div>
      </template>
      <a-table
          :data-source="tableData"
          :columns="columns"
          rowKey="id"
          :scroll="{
            x:1100,
        scrollToFirstRowOnChange: true,
      }"
          :pagination="pagination"
      >
        <template #assignmentTerm="{ record }">
          <div v-if="record.staffAssignmentPartList.length === 0">
            <div v-if="record.dateStatus === '0'">{{'-'}}</div>
            <div v-else>
              <span :class="(record.dateStatus === '1' || record.dateStatus === '3')? 'blue' : ''">{{record.startDate}}</span>
              <span>{{' ~ '}}</span>
              <span :class="(record.dateStatus === '2' || record.dateStatus === '3')? 'blue' : ''">{{record.endDate}}</span>
            </div>
          </div>
          <div v-else>
            <div v-if="record.staffAssignmentPartList.every(item => item.dateStatus === '0')">
              {{'-'}}
            </div>
            <div v-else>
              <div>
                <span style="color: #1890FF">●</span>
                <span :class="(record.dateStatus === '1' || record.dateStatus === '3')? 'blue' : ''">{{record.startDate}}</span>
                <span>{{' ~ '}}</span>
                <span :class="(record.dateStatus === '2' || record.dateStatus === '3')? 'blue' : ''">{{record.endDate}}</span>
              </div>
              <div v-for="(item) in record.staffAssignmentPartList" :key="item.id">
                <span style="color: #77CAED">●</span>
                <span :class="(item.dateStatus === '1' || item.dateStatus === '3')? 'blue' : ''">{{item.startDate}}</span>
                <span>{{' ~ '}}</span>
                <span :class="(item.dateStatus === '2' || item.dateStatus === '3')? 'blue' : ''">{{item.endDate}}</span>
              </div>
            </div>
          </div>
        </template>
        <template #hoursPerDay="{ record }">
          <div v-if="record.staffAssignmentPartList.length === 0">
            <div v-if="record.hoursPerDayStatus === '0'">{{'-'}}</div>
            <div v-else>
              <span class="blue">{{record.hoursPerDay}}</span>
            </div>
          </div>
          <div v-else>
            <div v-if="record.staffAssignmentPartList.every(item => item.hoursPerDayStatus === '0')">
              {{'-'}}
            </div>
            <div v-else>
              <div v-if="record.hoursPerDay">
                <span :class="record.staffAssignmentPartList.filter(item => item.hoursPerDay !== record.hoursPerDay.split('~')[0]).every(item => item.hoursPerDayStatus === '0') ? 'blue' : ''">{{record.hoursPerDay.split('~')[0]}}</span>
                <span>{{' ~ '}}</span>
                <span :class="record.staffAssignmentPartList.filter(item => item.hoursPerDay !== record.hoursPerDay.split('~')[1]).every(item => item.hoursPerDayStatus === '0') ? 'blue' : ''">{{record.hoursPerDay.split('~')[1]}}</span>
              </div>
              <div v-for="(item) in record.staffAssignmentPartList" :key="item.id">
                <span :class="item.hoursPerDayStatus === '1' ? 'blue' : ''">{{item.hoursPerDay}}</span>
              </div>
            </div>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, ref, watch, reactive, computed} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import Tooltip from '@/components/Tooltip'
import {QuestionCircleOutlined} from "@ant-design/icons-vue";
export default defineComponent({
  name: "AssignmentChangeRecord",
  components: {},
  props: {
    info: Object,
    visible: Boolean,
    title: String,
    id: String
  },
  emits: ["close-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData();
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData();
      }
    });

    const columns = computed(() => {
      let cols = [
        {
          title: "操作人",
          dataIndex: "createOperator",
          ellipsis: true,
          width: 130,
        },
        {
          title: "操作时间",
          dataIndex: "createTime",
          ellipsis: true,
          width: 190,
        },
        {
          title: <div>修改后Assignment Term&nbsp;
            <Tooltip title="每条修改部分将以蓝色标识">
              <span><QuestionCircleOutlined class="blue"/></span>
            </Tooltip>
          </div>,
          dataIndex: "assignmentTerm",
          ellipsis: true,
          width: 240,
          slots: { customRender: "assignmentTerm" },
        },
        {
          title: <div>修改后Hours Per Day&nbsp;
            <Tooltip title="每条修改部分将以蓝色标识">
              <QuestionCircleOutlined class="blue"/>
            </Tooltip>
          </div>,
          dataIndex: "hoursPerDay",
          ellipsis: true,
          width: 220,
          slots: { customRender: "hoursPerDay" },
        },
        {
          title: "变更内容",
          dataIndex: "changeContent",
          ellipsis: true,
        }
      ];
      return cols;
    });
    const tableData = ref([]);
    const getTableData = () => {
      const params = {
        id: props.info.id,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      };
      $api.getAssignmentRecordList(params).then((res) => {
        pagination.current = res.data?.pageIndex + 1;
        pagination.pageSize = res.data?.pageSize;
        pagination.total = res.data?.total;
        tableData.value = res.data?.resource || [];
      });
    };

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            pagination.current = 1;
            getTableData();
          }
        }
    );

    const closeModal = () => {
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      columns,
      tableData,
      getRefsAff,
      closeModal,
      pagination
    };
  }
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
::v-deep .ant-table-thead > tr > th {
  background-color: #fafafa !important;
}

::v-deep .ant-table-body {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-table-thead > tr > th) {
  background-color: #e9f4ff;
}

.ant-table-tbody {
  > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #e9f4ff;
  }
}

.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

.ant-table.ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td {
  border-bottom: #d0e8ff 1px solid;
}

.ant-table-striped ::v-deep(.table-striped) td {
  background-color: #fefeff;
}

.editable-row-operations a {
  margin-right: 8px;
}

.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

.ant-calendar-picker {
  width: 100%;
}
:deep(.ant-modal-footer){
  display: none;
}
.blue {
  color: #3182CE;
}
:deep(.ant-modal-body) {
  max-height: 80vh;
}
</style>
